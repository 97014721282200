import React, { Fragment } from "react";
import { Table, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const CrowdContentOrdersReadyOrdersTable = (props) => {
  const { contentOrders, setFocusedCrowdContentOrder } = props;

  const appearShorter = (title) => {
    if (title.length > 35) {
      title = title.substring(0, 32) + "...";
    }
    return title;
  };

  const determineQuality = (quality) => {
    let qualityStars = "";
    for (let i = 0; i < quality; i++) {
      qualityStars.concat("<FontAwesomeIcon Icon={faStar}> ");
    }
    console.log(qualityStars);
  };

  return (
    <Fragment>
      {contentOrders.length === 0 ? (
        <span>
          There are no orders ready for review at this time. Check back soon.
        </span>
      ) : (
        <Table size="sm" hover>
          <tbody>
            {contentOrders.map((order) => (
              <tr>
                <td className="pt-2">
                  <a
                    href={`https://www.crowdcontent.com/client/orders/view/${order.order_id}#summary`}
                    target="_blank"
                  >
                    <span>{order.order_id}</span>
                  </a>
                </td>
                <td className="pt-2">{appearShorter(order.title)}</td>
                <td className="pt-2">{`${order.min_word_count}/${order.max_word_count}`}</td>
                <td className="pt-2">{`$${order.max_cost}`}</td>
                <td className="pt-2">{`${order.quality} Star`}</td>
                <td className="text-right">
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => setFocusedCrowdContentOrder(order)}
                  >
                    Review Now
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Fragment>
  );
};

export default CrowdContentOrdersReadyOrdersTable;
