import React from "react";
import { Card } from "reactstrap";

import { default as CustomerDetailsTable } from "./CustomerDetailsTable";

const CustomerDetailsCard = (props) => {
  return (
    <Card className="p-2 mt-1">
      <div className="d-flex">
        <div className="flex-grow-1 text-center pb-1">Client Information</div>
      </div>
      <CustomerDetailsTable />
    </Card>
  );
};

export default CustomerDetailsCard;
