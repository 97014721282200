import { NAME } from "./constants";

export const getFocusedContentOrderDetails = (state) => {
  return state.pages.contentDashboard.modalData[NAME].focusedContentOrder;
};

export const getFocusedContentOrderPreview = (state) => {
  return state.pages.contentDashboard.modalData[NAME].preview;
};

export const getFocusedContentOrderLoadingStatus = (state) => {
  return state.pages.contentDashboard.modalData[NAME].isLoading;
};

export const getFocusedContentOrderSubmitLockedStatus = (state) => {
  return state.pages.contentDashboard.modalData[NAME].submitLocked;
};

export const getSubmissionStatus = (state) => {
  return state.pages.contentDashboard.modalData[NAME].submissionStatus;
};

export const getActiveTab = (state) => {
  return state.pages.contentDashboard.modalData[NAME].activeTab;
};

export const getMetaEditMode = (state) => {
  return state.pages.contentDashboard.modalData[NAME].metaEditMode;
};
