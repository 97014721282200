// reducers.js
import types from "./types";
import { combineReducers } from "redux";

const isLoading = (state = true, action) => {
  switch (action.type) {
    case types.BEGIN_LOADING_PROJECTS_ADMINISTRATION:
      return true;
    case types.FINISH_LOADING_PROJECTS_ADMINISTRATION:
      return false;
    default:
      return state;
  }
};

const projects = (state = [], action) => {
  switch (action.type) {
    case types.UPDATE_SORTED_PROJECTS_BY_USER:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  isLoading,
  projects,
});
