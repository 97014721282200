import { postData } from "../../../../../utils/fetch-requests";
import types from "./types";

const updateNewNote = (note) => (dispatch) => {
  dispatch({
    type: types.SET_PROJECT_NEW_NOTE_CONTENTS,
    payload: note,
  });
};

const createNewProjectNote = (data) => (dispatch) => {
  postData("/api/project/add-note-to-project", data).then((project) => {
    dispatch({
      type: "RECEIVE_PROJECT_DETAILS",
      payload: project,
    });
    dispatch({
      type: "DISMISS_MODAL",
    });
  });
};

export { updateNewNote, createNewProjectNote };
