import React, { Fragment } from "react";
import { Table } from "reactstrap";
import Moment from "react-moment";

const ContentOrdersNeedsPostingTable = (props) => {
  const { contentOrders } = props;

  const appearShorter = (title) => {
    if (title.length > 50) {
      title = title.substring(0, 47) + "...";
    }
    return title;
  };

  return (
    <Fragment>
      {contentOrders.length === 0 ? (
        <span>There are no orders needing to be Posting at this time.</span>
      ) : (
        <Table size="sm" hover>
          <tbody>
            {contentOrders.map((order) => (
              <tr>
                <td className="pt-2">{order.textBroker._id}</td>
                <td className="pt-2">
                  <a href={`/project/view/${order.meta.project._id}`}>
                    {appearShorter(
                      order.meta.project.meta.parent.local.business.name
                    )}
                  </a>
                </td>
                <td className="pt-2">
                  {appearShorter(order.textBroker.orderTitle)}
                </td>
                <td className="pt-2 text-center">
                  <Moment date={order.meta.publishedOn} format="MM/DD/YYYY" />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Fragment>
  );
};

export default ContentOrdersNeedsPostingTable;
