// reducers.js
import types from "./types";
import { combineReducers } from "redux";

const isLoggedIn = (state = false, action) => {
  switch (action.type) {
    case types.AUTHENTICATION_SUCCEEDED:
      return true;
    case types.AUTHENTICATION_FAILED:
      return false;
    default:
      return state;
  }
};

const checkingAuth = (state = true, action) => {
  switch (action.type) {
    case types.AUTHENTICATION_FAILED:
      return false;
    case types.AUTHENTICATION_SUCCEEDED:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  isLoggedIn,
  checkingAuth
});
