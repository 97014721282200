import types from "./types";

const fetchProject = (id) => (dispatch) => {
  fetch(`/api/project/fetch-details/${id}`)
    .then((res) => res.json())
    .then((project) => {
      dispatch({
        type: types.RECEIVE_PROJECT_DETAILS,
        payload: project,
      });
      dispatch({ type: types.FINISH_PROJECT_DETAILS_LOADING });
    });
};

const setActiveTab = (tab) => (dispatch) => {
  dispatch({
    type: types.PROJECT_VIEW_SET_ACTIVE_TAB,
    payload: tab,
  });
};

export { fetchProject, setActiveTab };
