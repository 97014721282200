import { connect } from "react-redux";
import { default as component } from "./AddProjectLink.jsx";

// UI actions
import { dismissModal } from "../../../../common/UI/ActiveModal/duck/actions";
// UI selectors
import { getActiveModal } from "../../../../common/UI/ActiveModal/duck/selectors";

// Local selectors
import {
  getNewProjectLinkUrl,
  getNewProjectLinkService,
  getNewProjectLinkFormValidity,
  getNewProjectLinkDisplayWarning,
} from "./duck/selectors";
import { getProjectID } from "../../../duck/selectors.js";

// Local actions
import {
  setNewProjectLinkUrl,
  setNewProjectLinkService,
  addNewProjectLink,
} from "./duck/actions";

const mapStateToProps = (state) => {
  return {
    activeModal: getActiveModal(state),
    projectID: getProjectID(state),
    formValidity: getNewProjectLinkFormValidity(state),
    displayWarning: getNewProjectLinkDisplayWarning(state),
    newProjectLink: {
      url: getNewProjectLinkUrl(state),
      service: getNewProjectLinkService(state),
    },
  };
};

const mapDispatchToProps = {
  dismissModal,
  setNewProjectLinkUrl,
  setNewProjectLinkService,
  addNewProjectLink,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
