import React, { useState } from "react";
import { Input } from "reactstrap";

import { MODAL_NAME } from "./duck/constants";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const SetProjectMarketingTypeModal = (props) => {
  const {
    activeModal,
    dismissModal,
    projectID,
    setProjectMarketingType,
    currentMarketingType,
  } = props;
  const [localProjectMarketingType, setLocalProjectMarketingType] = useState(
    currentMarketingType
  );
  return (
    <Modal
      isOpen={activeModal === MODAL_NAME}
      toggle={() => dismissModal(MODAL_NAME)}
    >
      <ModalHeader>Set Project SEO Package</ModalHeader>
      <ModalBody>
        <Input
          type="select"
          defaultValue={
            currentMarketingType === null ? "Not Set" : currentMarketingType
          }
          className="form-control"
          onChange={(event) => setLocalProjectMarketingType(event.target.value)}
        >
          <option value="Not Set" disabled>
            Not Set
          </option>
          <option value="IMO">IMO</option>
          <option value="RYNO Built">RYNO Built</option>
        </Input>
      </ModalBody>
      <ModalFooter>
        <Button
          color="success"
          onClick={() =>
            setProjectMarketingType({
              projectID,
              marketingType: localProjectMarketingType,
            })
          }
          disabled={
            localProjectMarketingType === null ||
            currentMarketingType === localProjectMarketingType
          }
        >
          Save
        </Button>
        <Button color="secondary" onClick={() => dismissModal(MODAL_NAME)}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SetProjectMarketingTypeModal;
