const NEW_CONTENT_ORDER_SET_MINIMUM_WORDS =
  "NEW_CONTENT_ORDER_SET_MINIMUM_WORDS";
const NEW_CONTENT_ORDER_SET_MAXIMUM_WORDS =
  "NEW_CONTENT_ORDER_SET_MAXIMUM_WORDS";
const NEW_CONTENT_ORDER_SET_TURNAROUND_TIME =
  "NEW_CONTENT_ORDER_SET_TURNAROUND_TIME";
const NEW_CONTENT_ORDER_SET_DUE_DATE = "NEW_CONTENT_ORDER_SET_DUE_DATE";
const NEW_CONTENT_ORDER_SET_COMPANY_SHORT_CODE =
  "NEW_CONTENT_ORDER_SET_COMPANY_SHORT_CODE";
const NEW_CONTENT_ORDER_SET_CONTENT_TYPE = "NEW_CONTENT_ORDER_SET_CONTENT_TYPE";
const NEW_CONTENT_ORDER_SET_TEMPLATE_TYPE =
  "NEW_CONTENT_ORDER_SET_TEMPLATE_TYPE";
const NEW_CONTENT_ORDER_SET_ORDER_TITLE = "NEW_CONTENT_ORDER_SET_ORDER_TITLE";
const NEW_CONTENT_ORDER_SET_ORDER_DESCRIPTION =
  "NEW_CONTENT_ORDER_SET_ORDER_DESCRIPTION";
const NEW_CONTENT_ORDER_SET_SPECIAL_NOTES =
  "NEW_CONTENT_ORDER_SET_SPECIAL_NOTES";
const NEW_CONTENT_ORDER_SET_TARGETED_PLACE =
  "NEW_CONTENT_ORDER_SET_TARGETED_PLACE";
const NEW_CONTENT_ORDER_SET_TARGETED_KEYWORDS =
  "NEW_CONTENT_ORDER_SET_TARGETED_KEYWORDS";
const NEW_CONTENT_ORDER_SET_FILE_UPLOADS = "NEW_CONTENT_ORDER_SET_FILE_UPLOADS";
const NEW_CONTENT_ORDER_SET_FORM_VALIDITY =
  "NEW_CONTENT_ORDER_SET_FORM_VALIDITY";
const NEW_CONTENT_ORDER_SET_CURRENT_VIEW = "NEW_CONTENT_ORDER_SET_CURRENT_VIEW";
const NEW_CONTENT_ORDER_RESET_FILE_UPLOADS =
  "NEW_CONTENT_ORDER_RESET_FILE_UPLOADS";

const NEW_CONTENT_ORDER_BEGIN_SENDING = "NEW_CONTENT_ORDER_BEGIN_SENDING";
const NEW_CONTENT_ORDER_BEGIN_UPDATING_KEYWORDS =
  "NEW_CONTENT_ORDER_BEGIN_UPDATING_KEYWORDS";
const NEW_CONTENT_ORDER_FINISH_SENDING = "NEW_CONTENT_ORDER_FINISH_SENDING";
const NEW_CONTENT_ORDER_FINISHED_SENDING_SUCCESS =
  "NEW_CONTENT_ORDER_FINISHED_SENDING_SUCCESS";
const NEW_CONTENT_ORDER_FINISHED_SENDING_FAILURE =
  "NEW_CONTENT_ORDER_FINISHED_SENDING_FAILURE";

const NEW_CONTENT_ORDER_RESET_FORM = "NEW_CONTENT_ORDER_RESET_FORM";
const NEW_CONTENT_ORDER_RETRY_SENDING = "NEW_CONTENT_ORDER_RETRY_SENDING";
const NEW_CONTENT_ORDER_RESET_KEYWORDS = "NEW_CONTENT_ORDER_RESET_KEYWORDS";

export default {
  NEW_CONTENT_ORDER_SET_CURRENT_VIEW,
  NEW_CONTENT_ORDER_SET_MINIMUM_WORDS,
  NEW_CONTENT_ORDER_SET_MAXIMUM_WORDS,
  NEW_CONTENT_ORDER_SET_TURNAROUND_TIME,
  NEW_CONTENT_ORDER_SET_DUE_DATE,
  NEW_CONTENT_ORDER_SET_COMPANY_SHORT_CODE,
  NEW_CONTENT_ORDER_SET_CONTENT_TYPE,
  NEW_CONTENT_ORDER_SET_TEMPLATE_TYPE,
  NEW_CONTENT_ORDER_SET_ORDER_TITLE,
  NEW_CONTENT_ORDER_SET_ORDER_DESCRIPTION,
  NEW_CONTENT_ORDER_SET_SPECIAL_NOTES,
  NEW_CONTENT_ORDER_SET_TARGETED_PLACE,
  NEW_CONTENT_ORDER_SET_TARGETED_KEYWORDS,
  NEW_CONTENT_ORDER_SET_FILE_UPLOADS,
  NEW_CONTENT_ORDER_SET_FORM_VALIDITY,
  NEW_CONTENT_ORDER_RESET_FILE_UPLOADS,
  NEW_CONTENT_ORDER_BEGIN_SENDING,
  NEW_CONTENT_ORDER_BEGIN_UPDATING_KEYWORDS,
  NEW_CONTENT_ORDER_FINISH_SENDING,
  NEW_CONTENT_ORDER_FINISHED_SENDING_SUCCESS,
  NEW_CONTENT_ORDER_FINISHED_SENDING_FAILURE,
  NEW_CONTENT_ORDER_RESET_FORM,
  NEW_CONTENT_ORDER_RETRY_SENDING,
  NEW_CONTENT_ORDER_RESET_KEYWORDS,
};
