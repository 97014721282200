import React from "react";
import { MODAL_NAME } from "./duck/constants";
import MilestoneChecklistTable from "./components/MilestoneChecklistTable/MilestoneChecklistTable.js";
import MilestoneChecklistSelectorTable from "./components/MilestoneCheclistSelectorTable/MilestoneChecklistSelectorTable.js";

import {
  Badge,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

const ProjectMilestoneTrackerModal = (props) => {
  // Globals UI props
  const { activeModal, dismissModal } = props;
  // Local props from state
  const { currentView } = props;
  // Local Action Triggers
  const { toggleMilestoneTrackerView } = props;

  return (
    <Modal
      isOpen={activeModal === MODAL_NAME}
      toggle={() => dismissModal(MODAL_NAME)}
    >
      <ModalHeader cssModule={{ "modal-title": "w-100" }}>
        <div className="d-flex">
          <div className="flex-grow-1">Milestone Tracker</div>
          <div>
            <Button
              size="sm"
              outline
              onClick={() => toggleMilestoneTrackerView(currentView)}
            >
              {currentView === "milestoneChecklists" ? (
                <span>Checklist Picker</span>
              ) : (
                <span>View Checklists</span>
              )}
            </Button>
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        {currentView === "milestoneChecklists" ? (
          <MilestoneChecklistTable />
        ) : (
          <MilestoneChecklistSelectorTable />
        )}
      </ModalBody>
    </Modal>
  );
};

export default ProjectMilestoneTrackerModal;
