import React from "react";
import { Badge } from "reactstrap";

const ContentOrderStatusBadge = (props) => {
  const { status } = props;

  const determineColor = (status) => {
    switch (status) {
      case "1": {
        return "#0373FC";
      }
      case "2": {
        return "#FC03C2";
      }
      case "3": {
        return "#48d368";
      }
      case "69": {
        return "#b213ea";
      }
      case "86": {
        return "#a74528";
      }
      case "420": {
        return "#38d00a";
      }
      default: {
        break;
      }
    }
  };

  const determineStatus = (status) => {
    switch (status) {
      case "2": {
        return "Submitted";
      }
      case "3": {
        return "Being Written";
      }
      case "4": {
        return "Ready for Review";
      }
      case "69": {
        return "Needs Posting";
      }
      case "86": {
        return "Deleted";
      }
      case "420": {
        return "Published";
      }
      default:
        break;
    }
  };

  return (
    <Badge
      size="sm"
      className="mr-1"
      style={{ backgroundColor: determineColor(status) }}
    >
      {status === "Recently Published" ? (
        <span>Published</span>
      ) : (
        <span>{determineStatus(status)}</span>
      )}
    </Badge>
  );
};

export default ContentOrderStatusBadge;
