import React, { Component } from "react";
import { Form, Input, Button, Card } from "reactstrap";
import { postData } from "../utils/fetch-requests";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newUser: {
        email: "",
        password: "",
      },
    };
  }

  buildUser = (e) => {
    let newState = Object.assign({}, this.state);
    newState.newUser[e.target.id] = e.target.value;
    this.setState(newState);
  };

  attemptLogin = () => {
    postData("/api/user/authenticate", this.state.newUser)
      .then((res) => {
        // set JWT Token for Box and other Auth
        localStorage.setItem("authorization", res.token);
        if (res.message === "Login was successful")
          window.location.href = "/customers";
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <div className="d-flex h-100 justify-content-center justify-content-middle text-center">
        <div className="col-md-3" style={{ marginTop: "10%" }}>
          <Card className="p-3 card-shadow">
            <div className="align-self-center">
              <img
                src="https://cdn.rynoss.com/wp-content/themes/rynoss/assets/images/RYNOSS-logo.png"
                alt=""
              />
            </div>
            <h5 className="text-muted">Please login below.</h5>
            <div className="col-12">
              <Form>
                <Input
                  id="email"
                  className="form-control mb-1"
                  placeholder="E-mail address"
                  onChange={(event) => this.buildUser(event)}
                />
                <Input
                  id="password"
                  className="form-control mb-1"
                  placeholder="Password"
                  type="password"
                  onChange={(event) => this.buildUser(event)}
                />
              </Form>
              <Button
                className="ryno-orange-bg"
                block
                onClick={() => this.attemptLogin()}
              >
                Login
              </Button>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

export default LoginPage;
