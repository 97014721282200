import React, { Fragment } from "react";
import { Table } from "reactstrap";
import { availableCategories } from "./duck/constants";

import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MilestoneChecklistSelectorTable = (props) => {
  const {
    projectID,
    milestoneCategories,
    addMilestoneCategoryToProject,
    removeMilestoneCategoryFromProject,
  } = props;
  return (
    <Table size="sm" hover>
      <tbody>
        {availableCategories.map((category) => (
          <Fragment>
            {milestoneCategories.includes(category) ? (
              <tr style={{ backgroundColor: "#90EE90" }}>
                <td
                  onClick={() =>
                    removeMilestoneCategoryFromProject(projectID, category)
                  }
                >
                  <div className="d-flex">
                    <div className="flex-grow-1">{category}</div>
                    <div>
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </div>
                  </div>
                </td>
              </tr>
            ) : (
              <tr>
                <td
                  onClick={() =>
                    addMilestoneCategoryToProject(projectID, category)
                  }
                >
                  {category}
                </td>
              </tr>
            )}
          </Fragment>
        ))}
      </tbody>
    </Table>
  );
};

export default MilestoneChecklistSelectorTable;
