import { connect } from "react-redux";
import { activateModal } from "../../common/UI/ActiveModal/duck/actions";
import { default as component } from "./CustomerProjectsTable.jsx";

// Local selectors
import { getCustomerDetails } from "../duck/selectors"

const mapStateToProps = state => {
  return { projects: getCustomerDetails(state).local.projects };
};

const mapDispatchtoProps = {
  activateModal
};

export default connect(mapStateToProps, mapDispatchtoProps)(component);
