import { connect } from "react-redux";
import { default as component } from "./CrowdContentOrdersPendingOrdersTable.jsx";

// global selectors and actions
import { activateModal } from "../../../common/UI/ActiveModal/duck/actions";

// local selectors and actions
import { getPlacedCrowdContentOrders, isLoading } from "../../duck/selectors";

const mapStateToProps = (state) => {
  return {
    isLoading: isLoading(state),
    contentOrders: getPlacedCrowdContentOrders(state),
  };
};

const mapDispatchToProps = {
  activateModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
