import types from "./types";
import { combineReducers } from "redux";

const specialNote = (state = null, action) => {
  switch (action.type) {
    case types.UPDATE_NEW_PROJECT_SPECIAL_NOTE_CONTENTS: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({
  specialNote,
});
