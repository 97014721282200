import { connect } from "react-redux";
import { default as component } from "./ContentOrdersCard.jsx";

// UI Selectors
import { getActiveModal } from "../../../common/UI/ActiveModal/duck/selectors";
// UI Actions
import { activateModal } from "../../../common/UI/ActiveModal/duck/actions";

// Local Selectors
import { getContentOrders } from "../../duck/selectors";

const mapStateToProps = (state) => {
  return {
    activeModal: getActiveModal(state),
    contentOrders: getContentOrders(state),
  };
};

const mapDispatchToProps = {
  activateModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
