import types from "./types";
import { postData } from "../../../../../utils/fetch-requests";

const addProjectSpecialNoteUpdateNote = (note) => (dispatch) => {
  dispatch({
    type: types.UPDATE_NEW_PROJECT_SPECIAL_NOTE_CONTENTS,
    payload: note,
  });
};

const saveProjectSpecialNote = (projectID, specialNotes) => (dispatch) => {
  const requestData = {
    projectID,
    specialNotes,
  };
  postData("/api/project/add-special-note-to-project", requestData).then(
    (updatedProject) => {
      dispatch({
        type: "RECEIVE_PROJECT_DETAILS",
        payload: updatedProject,
      });
      dispatch({
        type: "DISMISS_MODAL",
      });
      dispatch({
        type: types.ADD_NEW_PROJECT_SPECIAL_NOTE_RESET_DATA,
      });
    }
  );
};

export { addProjectSpecialNoteUpdateNote, saveProjectSpecialNote };
