import { connect } from "react-redux";
import { default as component } from "./PublishCrowdContentOrderModal.jsx";

// Global selectors and actions
import { getActiveModal } from "../../../../common/UI/ActiveModal/duck/selectors";
import { dismissModal } from "../../../../common/UI/ActiveModal/duck/actions";

// Local selectors and actions
import {
  toggleSubmitLocked,
  setFocusedCrowdContentOrderForPublishing,
  setPublishCrowdContentOrderActiveTab,
  publishCrowdContentOrder,
} from "./duck/actions";

import {
  getSubmissionStatus,
  getFocusedCrowdContentOrderLoadingStatus,
  getFocusedCrowdContentOrderSubmitLockedStatus,
  getFocusedCrowdContentOrderDetails,
  getFocusedCrowdContentOrderPublishModalActiveTab,
} from "./duck/selectors";

const mapStateToProps = (state) => {
  return {
    isLoading: getFocusedCrowdContentOrderLoadingStatus(state),
    activeTab: getFocusedCrowdContentOrderPublishModalActiveTab(state),
    submissionStatus: getSubmissionStatus(state),
    submitLocked: getFocusedCrowdContentOrderSubmitLockedStatus(state),
    activeModal: getActiveModal(state),
    contentOrder: getFocusedCrowdContentOrderDetails(state),
  };
};

const mapDispatchToProps = {
  dismissModal,
  toggleSubmitLocked,
  setPublishCrowdContentOrderActiveTab,
  setFocusedCrowdContentOrderForPublishing,
  publishCrowdContentOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
