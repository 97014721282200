import { connect } from "react-redux";
import { default as component } from "./CityListCard.jsx";

// UI Actions
import { activateModal } from "../../../common/UI/ActiveModal/duck/actions";

// UI Selectors
import { getActiveModal } from "../../../common/UI/ActiveModal/duck/selectors";
import { getLoggedInUserAccess } from "../../../common/UserDetails/duck/selectors";
import { getTargetablePlaces } from "../../duck/selectors";

const mapStateToProps = state => {
  return {
    activeModal: getActiveModal(state),
    loggedInUserAccess: getLoggedInUserAccess(state),
    places: getTargetablePlaces(state)
  };
};

const mapDispatchToProps = {
  activateModal
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
