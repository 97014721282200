import React, { Component } from "react";
import {
  Alert,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Input,
} from "reactstrap";

class AddProjectModal extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      newProject: {
        local: {
          domain: "",
        },
      },
    };
  }

  updateDetails = (e) => {
    let newState = Object.assign({}, this.state);
    newState.newProject.local[e.target.id] = e.target.value;
    this.setState(newState);
  };

  validateForm = () => {
    const entry = this.state.newProject.local.domain;
    if (entry.length < 6) {
      return true;
    } else if (entry.includes("www.")) {
      return true;
    } else if (entry.includes("/")) {
      return true;
    } else if (entry.includes(":")) {
      return true;
    } else if (entry.includes(" ")) {
      return true;
    } else if (
      !entry.includes(".com") &&
      !entry.includes(".com.au") &&
      !entry.includes(".net") &&
      !entry.includes(".org") &&
      !entry.includes(".ca") &&
      !entry.includes(".co") &&
      !entry.includes(".co.uk") &&
      !entry.includes(".us") &&
      !entry.includes(".solutions") &&
      !entry.includes(".pro")
    ) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    let { newProject } = this.state;
    const { activeModal, dismissModal, addProject, _id } = this.props;
    return (
      <Modal
        isOpen={activeModal === "addNewProject"}
        toggle={() => dismissModal("addNewProject")}
      >
        <ModalHeader>Add New Project</ModalHeader>
        <ModalBody>
          <Form onSubmit={(e) => e.preventDefault()}>
            <label>Domain Name:</label>
            <Input
              className="form-control mb-1"
              id="domain"
              placeholder="Naked domain only! --> example.com"
              onChange={(event) => this.updateDetails(event)}
            />
          </Form>
          {this.validateForm() && newProject.local.domain !== "" && (
            <Alert color="info" className="mt-2">
              <span style={{ fontSize: "12px", lineHeight: "12px" }}>
                <em>
                  This field plays into a ton of other features, so we have to
                  be careful to only use naked domains here. This means skip the
                  following: <br />
                  <ul>
                    <li>http:// or https://</li>
                    <li>www.</li>
                    <li>trailing slashes</li>
                    <li>spaces</li>
                  </ul>
                </em>
              </span>
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            className="mr-1"
            color="success"
            onClick={() => addProject(this.state.newProject, _id)}
            disabled={this.validateForm()}
          >
            Add Project
          </Button>

          <Button
            color="secondary"
            onClick={() => dismissModal("addNewProject")}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default AddProjectModal;
