import { postData } from "../../utils/fetch-requests";
import types from "./types";

export const updateUserProfile = (values) => (dispatch) => {
  dispatch({ type: types.BEGIN_LOADING_USER_PROFILE });
  postData("/api/user/update-user-personal-details", values).then((res) => {
    dispatch({ type: "UPDATE_USER_DETAILS", payload: res });
  });
  dispatch({ type: "DISMISS_MODAL" });
  dispatch({ type: types.FINISH_LOADING_USER_PROFILE });
};

export const fetchClientsByLoggedInUser = () => (dispatch) => {
  dispatch({ type: types.BEGIN_LOADING_USER_CLIENTS });
  fetch("/api/customer/fetch-clients-by-logged-in-user")
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      dispatch({
        type: types.UPDATE_PROFILE_PAGE_CLIENTS_FOR_LOGGED_IN_USER,
        payload: data,
      });
      dispatch({ type: types.FINISH_LOADING_USER_CLIENTS });
    });
};
