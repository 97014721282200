import { postData } from "../../../../../utils/fetch-requests";

const setProjectMonthlySpend = (data) => (dispatch) => {
  // Have to convert input to int
  let convertedData = {};
  convertedData.projectID = data.projectID;
  convertedData.monthlySpend = parseInt(data.localProjectMonthlySpend);
  console.log(convertedData);

  // send data and update view
  postData("/api/project/set-project-monthly-spend", convertedData).then(
    (project) => {
      dispatch({
        type: "RECEIVE_PROJECT_DETAILS",
        payload: project,
      });
      dispatch({
        type: "DISMISS_MODAL",
      });
    }
  );
};

export { setProjectMonthlySpend };
