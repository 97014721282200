const PUBLISHING_CROWD_CONTENT_ORDERS_SET_ORDER_TO_NEEDS_POSTING =
  "PUBLISHING_CROWD_CONTENT_ORDERS_SET_ORDER_TO_NEEDS_POSTING";

const PUBLISHING_CROWD_CONTENT_ORDERS_SET_FOCUSED_ORDER =
  "PUBLISHING_CROWD_CONTENT_ORDERS_SET_FOCUSED_ORDER";

const PUBLISHING_CROWD_CONTENT_ORDERS_RECEIVE_CROWD_CONTENT_ORDER_HTML_CONTENTS =
  "PUBLISHING_CROWD_CONTENT_ORDERS_RECEIVE_CROWD_CONTENT_ORDER_HTML_CONTENTS";

const PUBLISHING_CROWD_CONTENT_ORDERS_BEGIN_LOADING_FOCUSED_CROWD_CONTENT_ORDER_PREVIEW =
  "PUBLISHING_CROWD_CONTENT_ORDERS_BEGIN_LOADING_FOCUSED_CROWD_CONTENT_ORDER_PREVIEW";

const PUBLISHING_CROWD_CONTENT_ORDERS_FINISH_LOADING_FOCUSED_CROWD_CONTENT_ORDER_PREVIEW =
  "PUBLISHING_CROWD_CONTENT_ORDERS_FINISH_LOADING_FOCUSED_CROWD_CONTENT_ORDER_PREVIEW";

const PUBLISHING_CROWD_CONTENT_ORDERS_SET_FOCUSED_ORDER_FOR_PUBLISHING =
  "PUBLISHING_CROWD_CONTENT_ORDERS_SET_FOCUSED_ORDER_FOR_PUBLISHING";

const PUBLISHING_CROWD_CONTENT_ORDERS_UPDATE_FOCUSED_ORDER_HTML =
  "PUBLISHING_CROWD_CONTENT_ORDERS_UPDATE_FOCUSED_ORDER_HTML";

const PENDING_CROWD_CONTENT_ORDER_TOGGLE_SUBMIT_LOCK =
  "PENDING_CROWD_CONTENT_ORDER_TOGGLE_SUBMIT_LOCK";

const PUBLISHING_CROWD_CONTENT_ORDERS_SUBMISSION_STARTED =
  "PUBLISHING_CROWD_CONTENT_ORDERS_SUBMISSION_STARTED";
const PUBLISHING_CROWD_CONTENT_ORDERS_SUBMISSION_SUCCESSFUL =
  "PUBLISHING_CROWD_CONTENT_ORDERS_SUBMISSION_SUCCESSFUL";
const PUBLISHING_CROWD_CONTENT_ORDERS_SUBMISSION_FAILED =
  "PUBLISHING_CROWD_CONTENT_ORDERS_SUBMISSION_FAILED";

const PUBLISH_CROWD_CONTENT_ORDERS_MODAL_SET_ACTIVE_TAB =
  "PUBLISH_CROWD_CONTENT_ORDERS_MODAL_SET_ACTIVE_TAB";

const UNSET_PUBLISH_CROWD_CONTENT_ORDER_MODAL =
  "UNSET_PUBLISH_CROWD_CONTENT_ORDER_MODAL";

export default {
  PUBLISHING_CROWD_CONTENT_ORDERS_SET_ORDER_TO_NEEDS_POSTING,
  PUBLISHING_CROWD_CONTENT_ORDERS_SET_FOCUSED_ORDER,
  PUBLISHING_CROWD_CONTENT_ORDERS_UPDATE_FOCUSED_ORDER_HTML,
  PUBLISHING_CROWD_CONTENT_ORDERS_RECEIVE_CROWD_CONTENT_ORDER_HTML_CONTENTS,
  PUBLISHING_CROWD_CONTENT_ORDERS_BEGIN_LOADING_FOCUSED_CROWD_CONTENT_ORDER_PREVIEW,
  PUBLISHING_CROWD_CONTENT_ORDERS_FINISH_LOADING_FOCUSED_CROWD_CONTENT_ORDER_PREVIEW,
  PUBLISHING_CROWD_CONTENT_ORDERS_SET_FOCUSED_ORDER_FOR_PUBLISHING,
  PENDING_CROWD_CONTENT_ORDER_TOGGLE_SUBMIT_LOCK,
  PUBLISHING_CROWD_CONTENT_ORDERS_SUBMISSION_STARTED,
  PUBLISHING_CROWD_CONTENT_ORDERS_SUBMISSION_SUCCESSFUL,
  PUBLISHING_CROWD_CONTENT_ORDERS_SUBMISSION_FAILED,
  PUBLISH_CROWD_CONTENT_ORDERS_MODAL_SET_ACTIVE_TAB,
  UNSET_PUBLISH_CROWD_CONTENT_ORDER_MODAL,
};
