import React, { Fragment } from "react";
import LoadingSpinnerWithMessage from "../../../../../../common/UI/Spinners/LoadingSpinnerWithMessage.jsx";
import { Alert } from "reactstrap";

const TextBrokerOrderResults = (props) => {
  const { requestStage } = props;
  switch (requestStage) {
    case "default": {
      return <Fragment />;
    }
    case "sending": {
      return (
        <LoadingSpinnerWithMessage message="Waiting on Text Broker results" />
      );
    }
    case "success": {
      return (
        <Alert className="p-3 text-center" color="success">
          Good to go!
        </Alert>
      );
    }
    case "updating keywords": {
      return (
        <Fragment>
          <LoadingSpinnerWithMessage message="Adding Keywords to Order" />
          <Alert className="p-3 text-center" color="success">
            New Order Created Successfully
          </Alert>
        </Fragment>
      );
    }
    case "failed": {
      return (
        <Alert className="p-3 text-center" color="danger">
          There was an issue processing your order
        </Alert>
      );
    }
    default: {
      return;
    }
  }
};

export default TextBrokerOrderResults;
