import types from "./types";
import { combineReducers } from "redux";

const contentOrder = (state = null, action) => {
  switch (action.type) {
    case types.SET_FOCUSED_CONTENT_ORDER_FOR_REVIEW: {
      return action.payload;
    }
    case types.UNSET_FOCUSED_CONTENT_ORDER_FOR_REVIEW: {
      return null;
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({
  contentOrder,
});
