import { connect } from "react-redux";
import { default as component } from "./ContentDashboard.jsx";

// Local Store
import { isLoading, getContentOrders, getActiveTab } from "./duck/selectors.js";
import { fetchWorkableOrders, setActiveTab } from "./duck/actions.js";

const mapStateToProps = (state) => {
  return {
    isLoading: isLoading(state),
    activeTab: getActiveTab(state),
    contentOrders: getContentOrders(state),
  };
};

const mapDispatchToProps = {
  fetchWorkableOrders,
  setActiveTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
