import { postData } from "../../../../../utils/fetch-requests";

const setNewProjectStatus = (data) => (dispatch) => {
  console.log(data);
  postData("/api/project/set-new-project-status", data).then((project) => {
    dispatch({
      type: "RECEIVE_PROJECT_DETAILS",
      payload: project,
    });
    dispatch({
      type: "DISMISS_MODAL",
    });
  });
};

export { setNewProjectStatus };
