import { connect } from "react-redux";
import { default as component } from "./CrowdContentOrdersRecentlyPublishedTable.jsx";

// local selectors and actions
import {
  getRecentlyPublishedCrowdContentOrders,
  isLoading,
} from "../../duck/selectors";

const mapStateToProps = (state) => {
  return {
    isLoading: isLoading(state),
    contentOrders: getRecentlyPublishedCrowdContentOrders(state),
  };
};

export default connect(mapStateToProps, null)(component);
