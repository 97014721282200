import { connect } from "react-redux";
import { default as component } from "./ProjectSpecialNotesCard.jsx";

// UI actions
import { activateModal } from "../../../common/UI/ActiveModal/duck/actions";

// local selectors
import { getProjectSpecialNotes } from "./duck/selectors";

const mapStateToProps = (state) => {
  return {
    specialNotes: getProjectSpecialNotes(state),
  };
};

const mapDispatchToProps = {
  activateModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
