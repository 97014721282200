import React, { Fragment } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Progress,
  Badge,
} from "reactstrap";

import Moment from "react-moment";
import moment from "moment";

const ViewContentOrderStatsModal = (props) => {
  const {
    activeModal,
    unsetFocusedContentOrderForReview,
    focusedContentOrder,
    contentOrderProvider,
  } = props;

  const returnTimeBar = (meta, type) => {
    const createdOn = moment(meta.createdOn);
    const authorWriting = moment(meta.authorWriting);
    const readyForRYNO = moment(meta.readyForRYNO);
    const finishedEditing = moment(meta.finishedEditing);
    const publishedOn = moment(meta.publishedOn);

    // endpoints
    const totalTimeDays = moment.duration(publishedOn.diff(createdOn));
    const totalTimeToPickup = moment.duration(authorWriting.diff(createdOn));
    const totalTimeSpentWriting = moment.duration(
      readyForRYNO.diff(authorWriting)
    );
    const totalTimeWaitingForEdits = moment.duration(
      finishedEditing.diff(readyForRYNO)
    );
    const totalTimeToPublishEditedContent = moment.duration(
      publishedOn.diff(finishedEditing)
    );
    switch (type) {
      case "Total Days":
        return totalTimeDays.asDays().toFixed(2);
        break;
      case "Time To Pick Up":
        return totalTimeToPickup.asDays().toFixed(2);
        break;
      case "Time To Pick Up Percentage":
        const pickupPercentage =
          (totalTimeToPickup.asDays().toFixed(2) /
            totalTimeDays.asDays().toFixed(2)) *
          100;
        return pickupPercentage.toFixed(2);
        break;
      case "Time Spent Writing":
        return totalTimeSpentWriting.asDays().toFixed(2);
        break;
      case "Time Spent Writing Percentage":
        const writingPercentage =
          (totalTimeSpentWriting.asDays().toFixed(2) /
            totalTimeDays.asDays().toFixed(2)) *
          100;
        return writingPercentage.toFixed(2);
        break;
      case "Time Spent Editing":
        return totalTimeWaitingForEdits.asDays().toFixed(2);
        break;
      case "Time Spent Editing Percentage":
        const editingPercentage =
          (totalTimeWaitingForEdits.asDays().toFixed(2) /
            totalTimeDays.asDays().toFixed(2)) *
          100;
        return editingPercentage.toFixed(2);
        break;
      case "Time Waiting to Publish":
        return totalTimeToPublishEditedContent.asDays().toFixed(2);
        break;
      case "Time Waiting to Publish Percentage":
        const publishingPercentage =
          (totalTimeToPublishEditedContent.asDays().toFixed(2) /
            totalTimeDays.asDays().toFixed(2)) *
          100;
        return publishingPercentage.toFixed(2);
        break;
      default:
        break;
    }
  };

  const determineProvider = (order) => {
    if (order.textBroker === undefined) {
      return "crowdContent";
    } else {
      return "textBroker";
    }
  };

  return (
    <Fragment>
      {focusedContentOrder !== null ? (
        <Modal
          isOpen={activeModal === "ViewContentOrderStatsModal"}
          toggle={() => unsetFocusedContentOrderForReview()}
          size="lg"
        >
          <ModalHeader>
            Content Order #
            {focusedContentOrder[determineProvider(focusedContentOrder)]._id}{" "}
            Details
          </ModalHeader>
          <ModalBody>
            <div className="m-4 text-center">
              <span style={{ fontSize: "20px" }}>
                Start to Finish:{" "}
                {returnTimeBar(focusedContentOrder.meta, "Total Days")} days
              </span>
            </div>
            <div className="m-2 text-center">
              <div style={{ fontSize: "16px" }}>
                <Badge className="mr-2" color="primary">
                  {returnTimeBar(focusedContentOrder.meta, "Time To Pick Up")}{" "}
                  days Waiting for Pick Up
                </Badge>
                <Badge className="mr-2" color="success">
                  {returnTimeBar(
                    focusedContentOrder.meta,
                    "Time Spent Writing"
                  )}{" "}
                  days Being Written
                </Badge>
                <Badge className="mr-2" color="info">
                  {returnTimeBar(
                    focusedContentOrder.meta,
                    "Time Spent Editing"
                  )}{" "}
                  days Editing
                </Badge>
                <Badge className="mr-2" color="warning">
                  {returnTimeBar(
                    focusedContentOrder.meta,
                    "Time Waiting to Publish"
                  )}{" "}
                  days Waiting for Publishing
                </Badge>
              </div>
            </div>
            <Progress multi>
              <Progress
                bar
                value={returnTimeBar(
                  focusedContentOrder.meta,
                  "Time To Pick Up Percentage"
                )}
              />
              <Progress
                bar
                color="success"
                value={returnTimeBar(
                  focusedContentOrder.meta,
                  "Time Spent Writing Percentage"
                )}
              />
              <Progress
                bar
                color="info"
                value={returnTimeBar(
                  focusedContentOrder.meta,
                  "Time Spent Editing Percentage"
                )}
              />
              <Progress
                bar
                color="warning"
                value={returnTimeBar(
                  focusedContentOrder.meta,
                  "Time Waiting to Publish Percentage"
                )}
              />
            </Progress>
            <table class="table table-sm">
              <thead>
                <tr>
                  <th scope="col">Stage</th>
                  <th scope="col" className="text-right">
                    Stamp
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Created On</td>
                  <td className="text-right">
                    <Moment format="dddd - MM/DD/YYYY @ HH:mm:ss">
                      {focusedContentOrder.meta.createdOn}
                    </Moment>
                  </td>
                </tr>
                <tr>
                  <td>Started Writing</td>
                  <td className="text-right">
                    <Moment format="dddd - MM/DD/YYYY @ HH:mm:ss">
                      {focusedContentOrder.meta.authorWriting}
                    </Moment>
                  </td>
                </tr>
                <tr>
                  <td>Ready for Editing</td>
                  <td className="text-right">
                    <Moment format="dddd - MM/DD/YYYY @ HH:mm:ss">
                      {focusedContentOrder.meta.readyForRYNO}
                    </Moment>
                  </td>
                </tr>
                <tr>
                  <td>Finished Editing</td>
                  <td className="text-right">
                    <Moment format="dddd - MM/DD/YYYY @ HH:mm:ss">
                      {focusedContentOrder.meta.finishedEditing}
                    </Moment>
                  </td>
                </tr>
                <tr>
                  <td>Published On</td>

                  <td className="text-right">
                    <Moment format="dddd - MM/DD/YYYY @ HH:mm:ss">
                      {focusedContentOrder.meta.publishedOn}
                    </Moment>
                  </td>
                </tr>
              </tbody>
            </table>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => unsetFocusedContentOrderForReview()}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      ) : (
        <Fragment />
      )}
    </Fragment>
  );
};

export default ViewContentOrderStatsModal;
