import { connect } from "react-redux";
import { default as component } from "./addKeywordGeneratorList.jsx";

// UI selectors
import { getActiveModal } from "../../../common/UI/ActiveModal/duck/selectors";

// UI Actions
import { dismissModal } from "../../../common/UI/ActiveModal/duck/actions";

// local actions
import {
  addKeywordGeneratorList,
  setKeywordsForKeywordList,
  setTitleForKeywordList,
  checkAddKeywordGeneratorListFormValidity,
} from "./duck/actions";

// local selectors
import {
  getNewKeywordListFormValidity,
  getNewKeywordListKeywords,
  getNewKeywordListTitle,
} from "./duck/selectors";

const mapStateToProps = (state) => {
  return {
    activeModal: getActiveModal(state),
    newKeywordListTitle: getNewKeywordListTitle(state),
    newKeywordListKeywords: getNewKeywordListKeywords(state),
    formValidity: getNewKeywordListFormValidity(state),
  };
};

const mapDispatchToProps = {
  addKeywordGeneratorList,
  setKeywordsForKeywordList,
  setTitleForKeywordList,
  checkAddKeywordGeneratorListFormValidity,
  dismissModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
