import { connect } from "react-redux";
import { default as component } from "./ReviewCrowdContentOrderModal.jsx";

// Global selectors and actions
import { getActiveModal } from "../../../../common/UI/ActiveModal/duck/selectors";
import { dismissModal } from "../../../../common/UI/ActiveModal/duck/actions";

// Local selectors and actions
import {
  setActiveTab,
  updateFocusedCrowdContentOrderText,
  approvePendingCrowdContentOrder,
  toggleSubmitLocked,
  setMetaEditMode,
  setMetaFieldData,
  saveNewMetaFieldData,
} from "./duck/actions";

import {
  getActiveTab,
  getMetaEditMode,
  getSubmissionStatus,
  getFocusedCrowdContentOrderLoadingStatus,
  getFocusedCrowdContentOrderSubmitLockedStatus,
  getFocusedCrowdContentOrderDetails,
  getFocusedCrowdContentOrderPreview,
  getFocusedCrowdContentOrderKeywords,
  getFocusedCrowdContentOrderMeta,
  getFocusedCrowdContentOrderContentType,
  getFocusedCrowdContentOrderShortCode,
  getFocusedCrowdContentOrderMinWords,
  getFocusedCrowdContentOrderMaxWords,
  getFocusedCrowdContentOrderTotalWords,
} from "./duck/selectors";

const mapStateToProps = (state) => {
  return {
    isLoading: getFocusedCrowdContentOrderLoadingStatus(state),
    submissionStatus: getSubmissionStatus(state),
    submitLocked: getFocusedCrowdContentOrderSubmitLockedStatus(state),
    activeModal: getActiveModal(state),
    activeTab: getActiveTab(state),
    metaEditMode: getMetaEditMode(state),
    contentOrder: getFocusedCrowdContentOrderDetails(state),
    contentOrderKeywords: getFocusedCrowdContentOrderKeywords(state),
    contentOrderMeta: getFocusedCrowdContentOrderMeta(state),
    contentType: getFocusedCrowdContentOrderContentType(state),
    shortCode: getFocusedCrowdContentOrderShortCode(state),
    preview: getFocusedCrowdContentOrderPreview(state),
    minWordCount: getFocusedCrowdContentOrderMinWords(state),
    maxWordCount: getFocusedCrowdContentOrderMaxWords(state),
    totalWordCount: getFocusedCrowdContentOrderTotalWords(state),
  };
};

const mapDispatchToProps = {
  dismissModal,
  setActiveTab,
  setMetaEditMode,
  setMetaFieldData,
  saveNewMetaFieldData,
  toggleSubmitLocked,
  updateFocusedCrowdContentOrderText,
  approvePendingCrowdContentOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
