import React, { Fragment } from "react";
import { Badge } from "reactstrap";
import AddProjectSpecialNote from "../modals/AddProjectSpecialNote/AddProjectSpecialNote.js";
import EditProjectSpecialNote from "../modals/EditProjectSpecialNote/EditProjectSpecialNote.js";

const ProjectSpecialNotesCard = (props) => {
  const { specialNotes, activateModal } = props;
  return (
    <Fragment>
      {specialNotes !== null ? (
        <Fragment>
          <div class="col-12 text-right">
            <Badge
              href="#"
              color="success"
              onClick={() => activateModal("editProjectSpecialNoteModal")}
            >
              Edit Special Notes
            </Badge>
          </div>
          <div class="col-12">
            <pre>{specialNotes}</pre>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div class="col-12 text-right">
            <Badge
              href="#"
              color="success"
              onClick={() => activateModal("addProjectSpecialNoteModal")}
            >
              Add Special Notes
            </Badge>
          </div>
          <div class="col-12">
            <em>There are no notes attached to this project.</em>
          </div>
        </Fragment>
      )}
      <AddProjectSpecialNote />
      <EditProjectSpecialNote />
    </Fragment>
  );
};

export default ProjectSpecialNotesCard;
