import { NAME } from "./constants";

export const isLoading = (state) => {
  return state.pages[NAME].isLoading;
};

export const getActiveTab = (state) => {
  return state.pages[NAME].activeTab;
};

export const getContentOrders = (state) => {
  return state.pages[NAME].contentOrders;
};

export const getContentOrdersThatNeedEditing = (state) => {
  return state.pages[NAME].contentOrders.needsEditing;
};

export const getContentOrdersThatNeedPosting = (state) => {
  return state.pages[NAME].contentOrders.needsPosting;
};

export const getContentOrdersBeingWritten = (state) => {
  return state.pages[NAME].contentOrders.inQueue;
};

export const getRecentlyPublishedContentOrders = (state) => {
  const sortedArray = state.pages[NAME].contentOrders.recentlyPublished;
  sortedArray.sort(function compare(a, b) {
    var dateA = new Date(a.meta.publishedOn);
    var dateB = new Date(b.meta.publishedOn);
    return dateB - dateA;
  });
  return sortedArray;
};
