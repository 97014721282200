import { connect } from "react-redux";

import { default as component } from "./ContentOrderPlacePicker.jsx";

//Local Selectors
import { getProjectTargetedPlaces } from "../../../../../duck/selectors";
import { getContentOrderTargetedPlace } from "../../duck/selectors";
//Local Actions
import { setContentOrderTargetPlace } from "../../duck/actions";

const mapStateToProps = state => {
  return {
    availableTargets: getProjectTargetedPlaces(state),
    targetedPlace: getContentOrderTargetedPlace(state)
  };
};

const mapDispatchToProps = {
  setContentOrderTargetPlace
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
