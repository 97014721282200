import { NAME } from "./constants";

export const getFocusedCrowdContentOrderDetails = (state) => {
  return state.pages.crowdContentDashboard.modalData[NAME]
    .focusedCrowdContentOrder;
};

export const getFocusedCrowdContentOrderMeta = (state) => {
  return state.pages.crowdContentDashboard.modalData[NAME]
    .focusedCrowdContentFromDB.contentMeta;
};

export const getFocusedCrowdContentOrderKeywords = (state) => {
  return state.pages.crowdContentDashboard.modalData[NAME]
    .focusedCrowdContentFromDB.keywords;
};

export const getFocusedCrowdContentOrderContentType = (state) => {
  return state.pages.crowdContentDashboard.modalData[NAME]
    .focusedCrowdContentFromDB.contentType;
};

export const getFocusedCrowdContentOrderShortCode = (state) => {
  return state.pages.crowdContentDashboard.modalData[NAME]
    .focusedCrowdContentFromDB.shortCode;
};

export const getFocusedCrowdContentOrderMinWords = (state) => {
  return state.pages.crowdContentDashboard.modalData[NAME]
    .focusedCrowdContentOrder.min_word_count;
};

export const getFocusedCrowdContentOrderMaxWords = (state) => {
  return state.pages.crowdContentDashboard.modalData[NAME]
    .focusedCrowdContentOrder.max_word_count;
};

export const getFocusedCrowdContentOrderTotalWords = (state) => {
  return state.pages.crowdContentDashboard.modalData[NAME]
    .focusedCrowdContentOrder.words_written;
};

export const getFocusedCrowdContentOrderPreview = (state) => {
  return state.pages.crowdContentDashboard.modalData[NAME].preview;
};

export const getFocusedCrowdContentOrderLoadingStatus = (state) => {
  return state.pages.crowdContentDashboard.modalData[NAME].isLoading;
};

export const getFocusedCrowdContentOrderSubmitLockedStatus = (state) => {
  return state.pages.crowdContentDashboard.modalData[NAME].submitLocked;
};

export const getSubmissionStatus = (state) => {
  return state.pages.crowdContentDashboard.modalData[NAME].submissionStatus;
};

export const getActiveTab = (state) => {
  return state.pages.crowdContentDashboard.modalData[NAME].activeTab;
};

export const getMetaEditMode = (state) => {
  return state.pages.crowdContentDashboard.modalData[NAME].metaEditMode;
};
