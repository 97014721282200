import { connect } from "react-redux";
import { default as component } from "./editKeywordGeneratorList.jsx";

// UI selectors
import { getActiveModal } from "../../../common/UI/ActiveModal/duck/selectors";

// UI Actions
import { dismissModal } from "../../../common/UI/ActiveModal/duck/actions";

// local actions
import {
  editKeywordGeneratorList,
  setAlteredKeywordsForKeywordList,
  setAlteredTitleForKeywordList,
  setAlteredStackOrderIDForKeywordList,
  checkEditKeywordGeneratorListFormValidity,
} from "./duck/actions";

// local selectors
import {
  getAlteredKeywordListFormValidity,
  getAlteredKeywordListKeywords,
  getAlteredKeywordListTitle,
  getAlteredKeywordListStackOrderID,
  getFocusedKeywordList,
} from "./duck/selectors";

const mapStateToProps = (state) => {
  return {
    activeModal: getActiveModal(state),
    alteredKeywordListTitle: getAlteredKeywordListTitle(state),
    alteredKeywordListKeywords: getAlteredKeywordListKeywords(state),
    alteredKeywordListStackOrderID: getAlteredKeywordListStackOrderID(state),
    formValidity: getAlteredKeywordListFormValidity(state),
    focusedKeywordList: getFocusedKeywordList(state),
  };
};

const mapDispatchToProps = {
  editKeywordGeneratorList,
  setAlteredKeywordsForKeywordList,
  setAlteredTitleForKeywordList,
  setAlteredStackOrderIDForKeywordList,
  checkEditKeywordGeneratorListFormValidity,
  dismissModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
