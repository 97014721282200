import { connect } from "react-redux";
import { default as component } from "./ContentOrdersWaitingOnContentTable.jsx";

// Local Actions & Selectors
import { isLoading, getContentOrdersBeingWritten } from "../../duck/selectors";

const mapStateToProps = (state) => {
  return {
    isLoading: isLoading(state),
    contentOrders: getContentOrdersBeingWritten(state),
  };
};

export default connect(mapStateToProps, null)(component);
