// reducers.js
import types from "./types";

const INITIAL_STATE = {
  isCollapsed: true
};

const navigationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Front-END
    case types.TOGGLE_COLLAPSE: {
      return {
        isCollapsed: !state.isCollapsed
      };
    }

    default: {
      return state;
    }
  }
};

export default navigationReducer;
