import React from "react";
import { Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const ContentOrderPlacePicker = (props) => {
  console.log(props);
  const { targetedPlace, availableTargets, setContentOrderTargetPlace } = props;
  return (
    <Table size="sm" hover>
      <tbody>
        {availableTargets.map((target) => (
          <tr onClick={() => setContentOrderTargetPlace(targetedPlace, target)}>
            <td>
              {target.details.name}, {target.details.parent}
            </td>
            <td className="text-right">
              {targetedPlace._id === target._id && (
                <FontAwesomeIcon icon={faCheck} color="success" />
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ContentOrderPlacePicker;
