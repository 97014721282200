import React, { Component, Fragment } from "react";
import classNames from "classnames";

import {
  availableUnitedStates,
  availableCanadianTerritories,
  availableAustralianTerritories,
  DEFAULT_TAB,
  CITY_SEARCH_TAB,
  ADD_NEW_LOCATION_VIEW,
} from "./duck/constants";

import {
  Button,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TabContent,
  TabPane,
  Table,
  Nav,
  NavItem,
  NavLink,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPlus } from "@fortawesome/free-solid-svg-icons";
import { DebounceInput } from "react-debounce-input";

export default class AdjustPlacesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCountry: "United States",
      currentState: "unselected",
      stateFilter: null,
    };
  }

  setStateFilter = (event) => {
    let newState = Object.assign({}, this.state);
    newState.stateFilter = event.target.value;
    this.setState(newState);
  };

  renderCountryPicker = () => {
    const { currentCountry } = this.state;
    const AvailableCountries = ["United States", "Canada", "Australia"];
    return (
      <div>
        <label>Select a Country:</label>
        <Input
          type="select"
          className="form-control"
          id="state"
          defaultValue={currentCountry}
          onChange={(event) => this.setSelectedCountry(event.target.value)}
        >
          {AvailableCountries.map((countryName, index) => (
            <option
              selected={countryName === AvailableCountries[index]}
              value={countryName}
            >
              {countryName}
            </option>
          ))}
        </Input>
      </div>
    );
  };

  setSelectedCountry = (selectedCountry) => {
    let newState = Object.assign({}, this.state);
    newState.currentCountry = selectedCountry;
    if (selectedCountry === "United States") {
      newState.currentState = "unselected";
    } else {
      newState.currentState = "unselected";
    }
    this.setState(newState);
  };

  renderUnitedStatesPicker = () => {
    return (
      <div className="mt-1">
        <label>Select a State:</label>
        <Input
          type="select"
          className="form-control"
          id="state"
          defaultValue="unselected"
          value={this.state.currentState}
          onChange={(event) => this.setSelectedState(event.target.value)}
        >
          <option value="unselected" disabled>
            Please select a State
          </option>
          {availableUnitedStates.map((stateName, index) => (
            <option
              selected={stateName === availableUnitedStates[index]}
              value={stateName}
            >
              {stateName}
            </option>
          ))}
        </Input>
      </div>
    );
  };

  renderCanadianTerritoriesPicker = () => {
    return (
      <div className="mt-1">
        <label>Select a Territory:</label>
        <Input
          type="select"
          className="form-control"
          id="state"
          defaultValue="Alberta"
          value={this.state.currentState}
          onChange={(event) => this.setSelectedState(event.target.value)}
        >
          <option value="unselected" disabled>
            Please Select a Territory
          </option>
          {availableCanadianTerritories.map((territoryName, index) => (
            <option
              selected={territoryName === availableCanadianTerritories[index]}
              value={territoryName}
            >
              {territoryName}
            </option>
          ))}
        </Input>
      </div>
    );
  };

  renderAustralianTerritoriesPicker = () => {
    return (
      <div className="mt-1">
        <label>Select a Territory:</label>
        <Input
          type="select"
          className="form-control"
          id="state"
          defaultValue="Sydney"
          value={this.state.currentState}
          onChange={(event) => this.setSelectedState(event.target.value)}
        >
          <option value="unselected" disabled>
            Please Select a Territory
          </option>
          {availableAustralianTerritories.map((territoryName, index) => (
            <option
              selected={territoryName === availableCanadianTerritories[index]}
              value={territoryName}
            >
              {territoryName}
            </option>
          ))}
        </Input>
      </div>
    );
  };

  setSelectedState = (selectedState) => {
    let newState = Object.assign({}, this.state);
    newState.currentState = selectedState;
    this.setState(newState);
  };

  placeAlreadyExists = (place) =>
    this.props.targetedPlaces.some((entry) => entry._id === place._id);

  render() {
    const {
      //state
      activeModal,
      activeTab,
      activeView,
      addedPlaces,
      hasSearched,
      projectID,
      searchResults,
      existingPlaces,
      existingPlacesSearchQuery,
      targetedPlaces,
      //Actions
      addNewTargetablePlace,
      assignProjectToPlace,
      dismissModal,
      removeProjectFromPlace,
      searchForPlaces,
      searchForExistingPlaces,
      setActiveTab,
      setActiveView,
    } = this.props;

    const { currentCountry, currentState } = this.state;
    return (
      <Modal
        isOpen={activeModal === "pickPlacesModal"}
        toggle={() => dismissModal("pickPlacesModal")}
      >
        <ModalHeader>Place Picker</ModalHeader>
        <ModalBody>
          {activeView === "search" ? (
            <Fragment>
              <Nav className="mb-1" tabs>
                <NavItem>
                  <NavLink
                    className={classNames({
                      active: activeTab === DEFAULT_TAB,
                    })}
                    onClick={() => {
                      setActiveTab(DEFAULT_TAB);
                    }}
                  >
                    <span className="mr-2">Assigned Places</span>
                    <Badge color="secondary">{targetedPlaces.length}</Badge>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classNames({
                      active: activeTab === CITY_SEARCH_TAB,
                    })}
                    onClick={() => {
                      setActiveTab(CITY_SEARCH_TAB);
                    }}
                  >
                    City Search
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="default">
                  {targetedPlaces.length === 0 ? (
                    <div className="p-3">
                      <em>Please use the search to add places.</em>
                    </div>
                  ) : (
                    <Table size="sm" hover>
                      <tbody>
                        {targetedPlaces.map((place) => (
                          <tr>
                            <td className="pt-2">
                              {place.details.name}, {place.details.parent}
                            </td>
                            <td className="text-right pt-1">
                              {/* <Link
                            to={`/places/view/${place._id}`}
                            target="_blank"
                          >
                            <Button
                              className="mr-1"
                              outline
                              size="sm"
                              color="info"
                            >
                              <FontAwesomeIcon icon={faInfo} /> View
                            </Button>
                          </Link> */}
                              <Button
                                outline
                                size="sm"
                                color="danger"
                                onClick={() =>
                                  removeProjectFromPlace(projectID, place._id)
                                }
                              >
                                <FontAwesomeIcon icon={faTimes} /> Remove
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </TabPane>
                <TabPane tabId={CITY_SEARCH_TAB}>
                  <div className="mt-1">
                    <div>{this.renderCountryPicker()}</div>
                    <div>
                      {currentCountry === "United States" &&
                        this.renderUnitedStatesPicker()}
                      {currentCountry === "Canada" &&
                        this.renderCanadianTerritoriesPicker()}
                      {currentCountry === "Australia" &&
                        this.renderAustralianTerritoriesPicker()}
                    </div>
                    <DebounceInput
                      debounceTimeout="500"
                      className="form-control mt-1"
                      placeHolder="Enter city name..."
                      id="searchQuery"
                      autoComplete="off"
                      onChange={(event) =>
                        searchForPlaces(event.target.value, currentState)
                      }
                      disabled={currentState === "unselected"}
                    />
                    <Table>
                      <tbody>
                        {searchResults.map(
                          (place) =>
                            !this.placeAlreadyExists(place) && (
                              <tr>
                                <td>{place.details.name} </td>
                                <td className="text-right">
                                  <Button
                                    outline
                                    size="sm"
                                    color="success"
                                    onClick={() =>
                                      assignProjectToPlace(projectID, place._id)
                                    }
                                  >
                                    <FontAwesomeIcon icon={faPlus} /> Add
                                  </Button>
                                </td>
                              </tr>
                            )
                        )}
                        {hasSearched && (
                          <div className="mt-2">
                            <em>
                              Not listed here?{" "}
                              <Button
                                size="sm"
                                color="success"
                                onClick={() =>
                                  setActiveView(ADD_NEW_LOCATION_VIEW)
                                }
                              >
                                Add Missing Location
                              </Button>
                            </em>
                          </div>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </TabPane>
              </TabContent>
            </Fragment>
          ) : (
            <Fragment>
              <div className="mt-1">
                <div>
                  Add new city to <strong>{currentState}</strong>,{" "}
                  <strong>{currentCountry}</strong>
                </div>
                <hr />
                <InputGroup>
                  <DebounceInput
                    debounceTimeout="500"
                    className="form-control"
                    value={existingPlacesSearchQuery}
                    placeHolder="Name of City/Location .."
                    id="searchQuery"
                    autoComplete="off"
                    onChange={(event) =>
                      searchForExistingPlaces(event.target.value, currentState)
                    }
                  />
                  <InputGroupAddon addonType="append">
                    {existingPlaces.length < 1 ? (
                      <Button
                        size="sm"
                        color="success"
                        onClick={() =>
                          addNewTargetablePlace(
                            addedPlaces,
                            existingPlacesSearchQuery,
                            currentState,
                            currentCountry
                          )
                        }
                      >
                        Add Me
                      </Button>
                    ) : (
                      <Fragment />
                    )}
                  </InputGroupAddon>
                </InputGroup>
              </div>
              {addedPlaces.length > 0 && (
                <Table>
                  <tbody>
                    {addedPlaces.map((place) => (
                      <tr>
                        <td>
                          {place.details.name}, {place.details.parent}
                        </td>
                        <td className="text-right">{place.details.county}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Fragment>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => dismissModal("pickPlacesModal")}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
