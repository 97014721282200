import { connect } from "react-redux";
import { default as component } from "./ProjectDetailsCard.jsx";

// UI Actions
import { activateModal } from "../../common/UI/ActiveModal/duck/actions";

const mapDispatchToProps = {
  activateModal,
};

export default connect(null, mapDispatchToProps)(component);
