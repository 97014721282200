import types from "./types";
import { combineReducers } from "redux";
import { DEFAULT_TAB } from "./constants";

import { default as reviewContentOrder } from "../components/modals/ReviewContentOrderModal/duck/reducer";
import { default as publishContentOrder } from "../components/modals/PublishContentOrderModal/duck/reducer";
import { default as uploadContentOrderToBox } from "../components/modals/AddContentOrderToBoxFolder/duck/reducer";

const isLoading = (state = true, action) => {
  switch (action.type) {
    case types.FINISH_CONTENT_DASHBOARD_LOADING: {
      return false;
    }
    case types.BEGIN_CONTENT_DASHBOARD_LOADING: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const activeTab = (state = DEFAULT_TAB, action) => {
  switch (action.type) {
    case types.CONTENT_DASHBOARD_SET_ACTIVE_TAB: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

const needsEditing = (state = [], action) => {
  switch (action.type) {
    case types.CONTENT_DASHBOARD_RECEIVE_CONTENT_ORDERS: {
      if (action.payload["Ready"] !== undefined) {
        const newArray = [...action.payload["Ready"]];
        return newArray;
      } else {
        console.log("No orders in Ready Status");
        return [];
      }
    }
    default: {
      return state;
    }
  }
};

const needsPosting = (state = [], action) => {
  switch (action.type) {
    case types.CONTENT_DASHBOARD_RECEIVE_CONTENT_ORDERS: {
      if (action.payload["RYNO Accepted"]) {
        return [...action.payload["RYNO Accepted"]];
      } else {
        return [];
      }
    }
    default: {
      return state;
    }
  }
};

const inQueue = (state = [], action) => {
  switch (action.type) {
    case types.CONTENT_DASHBOARD_RECEIVE_CONTENT_ORDERS: {
      const placedOrders = () => {
        if (action.payload["Placed"]) {
          return [...action.payload["Placed"]];
        } else {
          return [];
        }
      };
      const acceptedOrders = () => {
        if (action.payload["TB Accepted"]) {
          return [...action.payload["TB Accepted"]];
        } else {
          return [];
        }
      };
      const ordersAuthorIsWriting = () => {
        if (action.payload["Author Writing"]) {
          return [...action.payload["Author Writing"]];
        } else {
          return [];
        }
      };
      return [
        ...placedOrders(),
        ...acceptedOrders(),
        ...ordersAuthorIsWriting(),
      ];
    }
    default: {
      return state;
    }
  }
};

const recentlyPublished = (state = [], action) => {
  switch (action.type) {
    case types.CONTENT_DASHBOARD_RECEIVE_CONTENT_ORDERS: {
      return action.payload["Recently Published"]
        ? action.payload["Recently Published"]
        : state;
    }
    default: {
      return state;
    }
  }
};

const modalData = combineReducers({
  reviewContentOrder,
  publishContentOrder,
  uploadContentOrderToBox,
});

const contentOrders = combineReducers({
  needsEditing,
  needsPosting,
  inQueue,
  recentlyPublished,
});

export default combineReducers({
  isLoading,
  modalData,
  contentOrders,
  activeTab,
});
