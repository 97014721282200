import { connect } from "react-redux";
import { default as component } from "./editProfileModal.jsx";

// actions
import { dismissModal } from "../../../../common/UI/ActiveModal/duck/actions";
import {
  updateUserProfile,
  adjustUserName,
  adjustUserPhoneNumber,
  adjustUserProfilePictureUrl,
} from "./duck/actions";

// selectors
import { getActiveModal } from "../../../../common/UI/ActiveModal/duck/selectors";
import {
  getLoggedInUser,
  getLoggedInUserPersonalDetails,
} from "../../../../common/UserDetails/duck/selectors";
import { getEditedProfileDetails } from "./duck/selectors.js";

// wrapper container
const MapStateToProps = (state) => {
  return {
    initialValues: {
      personal: getLoggedInUserPersonalDetails(state),
    },
    editedValues: {
      personal: getEditedProfileDetails(state),
    },
    activeModal: getActiveModal(state),
    userDetails: getLoggedInUser(state),
  };
};

const MapDispatchToProps = {
  adjustUserName,
  adjustUserPhoneNumber,
  adjustUserProfilePictureUrl,
  dismissModal,
  updateUserProfile,
};

export default connect(MapStateToProps, MapDispatchToProps)(component);
