import types from "./types";
import { postData } from "../../utils/fetch-requests";

const addNewCustomer = customer => dispatch => {
  return postData("/api/customer/add-new-customer", customer)
    .then(() => {
      dispatch({
        type: "DISMISS_MODAL"
      });
    })
    .then(() => {
      return fetch("/api/customer/search-for-recent-customers")
        .then(res => res.json())
        .then(customers => {
          dispatch({ type: types.BEGIN_CUSTOMER_SEARCH });
          dispatch({
            type: types.RECEIVE_CUSTOMERS,
            payload: customers
          });
          dispatch({ type: types.FINISH_CUSTOMER_SEARCH });
        });
    });
};

// SEARCH FOR CUSTOMERS
const searchForCustomers = event => dispatch => {
  dispatch({ type: types.BEGIN_CUSTOMER_SEARCH });
  dispatch({ type: "SEARCH_FOR_CUSTOMERS", payload: event.target.value });
  return postData("/api/customer/search-for-customers", {
    searchQuery: event.target.value
  }).then(customers => {
    dispatch({
      type: types.RECEIVE_CUSTOMERS,
      payload: customers
    });
    dispatch({ type: types.FINISH_CUSTOMER_SEARCH });
  });
};

// SEARCH FOR RECENT CUSTOMERS
const searchRecentCustomers = () => dispatch => {
  dispatch({ type: types.BEGIN_CUSTOMER_SEARCH });
  return fetch("/api/customer/search-for-recent-customers")
    .then(res => res.json())
    .then(customers => {
      dispatch({
        type: types.RECEIVE_CUSTOMERS,
        payload: customers
      });
      dispatch({ type: types.FINISH_CUSTOMER_SEARCH });
    });
};

export { addNewCustomer, searchRecentCustomers, searchForCustomers };
