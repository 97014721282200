import React, { Fragment } from "react";
import { Table, Badge } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  faArrowAltCircleRight,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

// modals
import { AddProjectLinkModal } from "../modals";
import { RemoveProjectLinkModal } from "../modals";

const ProjectLinksCard = (props) => {
  const determineCopyOrDashboardButton = (link) => {
    switch (link.service) {
      case "Google Adwords Keyword Planner":
      case "Google Analytics":
      case "Google Search Console":
      case "Google Tag Manager": {
        return (
          <CopyToClipboard text={link.url}>
            <Badge color="warning" size="sm" pill style={{ cursor: "pointer" }}>
              <span className="pb-1">Copy Link</span>
            </Badge>
          </CopyToClipboard>
        );
      }
      default: {
        return (
          <a href={link.url} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faArrowAltCircleRight} />
          </a>
        );
      }
    }
  };

  // Actions
  const { activateModal, setFocusedProjectLinkForRemoval } = props;
  // Pieces of State
  const { links } = props;
  return (
    <Fragment>
      <div className="d-flex">
        <div className="ml-auto" />
        <div>
          <Badge
            color="success"
            href="#"
            onClick={() => activateModal("addProjectLink")}
          >
            Add Link
          </Badge>
        </div>
      </div>
      {links.length === 0 ? (
        <Fragment>
          <hr className="mt-2 mb-2" />
          <span>
            <em>There are no links on this account.</em>
          </span>
        </Fragment>
      ) : (
        <Table className="mt-2" size="sm" hover>
          <tbody>
            {links.map((link) => (
              <tr>
                <td>{link.service}</td>
                <td className="text-right">
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    className="mr-1"
                    color="red"
                    onClick={() => setFocusedProjectLinkForRemoval(link)}
                  />
                  {determineCopyOrDashboardButton(link)}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <AddProjectLinkModal />
      <RemoveProjectLinkModal />
    </Fragment>
  );
};

export default ProjectLinksCard;
