import { NAME } from "./constants";

const select = key => state => state.pages[NAME][key];

export const isLoading = select("isLoading");

export const getTargetablePlaces = state => {
  return state.pages[NAME].places;
};

export const getTargetablePlace = state => {
  return state.pages[NAME].modalData.targetablePlace;
};

export const getTargetablePlaceParent = state => {
  return state.pages[NAME].modalData.targetablePlace.details.parent;
};

export const getTargetablePlaceID = state => {
  return state.pages[NAME].modalData.targetablePlace._id;
};
