import { connect } from "react-redux";

import toggleCollapse from "./duck/actions";

import { default as component } from "./MainNavigation.jsx";
import { isCollapsed } from "./duck/selectors";
import { getLoggedInUserAccess, getLoggedInUserEmail } from "../UserDetails/duck/selectors";

const mapStateToProps = state => {
  return {
    accessLevel: getLoggedInUserAccess(state),
    loggedInUserEmail: getLoggedInUserEmail(state),
    isCollapsed: isCollapsed(state)
  };
};

const mapDispatchToProps = {
  toggleCollapse
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
