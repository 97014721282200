import { connect } from "react-redux";
import { default as component } from "./CrowdContentMetaForm.jsx";

// Local Selectors
import {
  getCrowdContentOrderMetaH1,
  getCrowdContentOrderMetaTitle,
  getCrowdContentOrderMetaDescription,
  getCrowdContentOrderMetaSlug,
} from "./duck/selectors";

// Local Actions
import {
  setCrowdContentOrderMetaH1,
  setCrowdContentOrderMetaTitle,
  setCrowdContentOrderMetaDescription,
  setCrowdContentOrderMetaSlug,
  setCrowdContentMetaFormValidity,
} from "./duck/actions";

const mapStateToProps = (state) => {
  return {
    h1: getCrowdContentOrderMetaH1(state),
    title: getCrowdContentOrderMetaTitle(state),
    description: getCrowdContentOrderMetaDescription(state),
    slug: getCrowdContentOrderMetaSlug(state),
  };
};

const mapDispatchToProps = {
  setCrowdContentOrderMetaH1,
  setCrowdContentOrderMetaTitle,
  setCrowdContentOrderMetaDescription,
  setCrowdContentOrderMetaSlug,
  setCrowdContentMetaFormValidity,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
