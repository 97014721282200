import { connect } from "react-redux";
import { default as component } from "./ViewAssignedProjectsModal.jsx";

// UI actions
import { dismissModal } from "../../../../../../common/UI/ActiveModal/duck/actions";
// UI selectors
import { getActiveModal } from "../../../../../../common/UI/ActiveModal/duck/selectors";

// Local Actions
import { setTargetablePlace } from "../../../../../duck/actions";
import { getTargetablePlace } from "../../../../../duck/selectors.js";

const mapStateToProps = state => {
  return {
    activeModal: getActiveModal(state),
    targetablePlace: getTargetablePlace(state)
  };
};

const mapDispatchToProps = {
  dismissModal,
  setTargetablePlace
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
