import { connect } from "react-redux";
import { default as component } from "./CrowdContentDashboard.jsx";

// Local Store
import {
  isLoading,
  getActiveTab,
  getPlacedCrowdContentOrders,
  getReadyForReviewCrowdContentOrders,
  getCrowdContentOrdersThatNeedPosting,
  getRecentlyPublishedCrowdContentOrders,
} from "./duck/selectors.js";
import { fetchCrowdContentOrders, setActiveTab } from "./duck/actions.js";

const mapStateToProps = (state) => {
  return {
    isLoading: isLoading(state),
    activeTab: getActiveTab(state),
    contentOrders: getPlacedCrowdContentOrders(state),
    placedCrowdContentOrders: getPlacedCrowdContentOrders(state),
    readyForReviewCrowdContentOrders: getReadyForReviewCrowdContentOrders(
      state
    ),
    needsPostingContentOrders: getCrowdContentOrdersThatNeedPosting(state),
    recentlyPublishedOrders: getRecentlyPublishedCrowdContentOrders(state),
  };
};

const mapDispatchToProps = {
  fetchCrowdContentOrders,
  setActiveTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
