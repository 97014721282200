// reducers.js
import types from "./types";
import { combineReducers } from "redux";

const name = (state = "", action) => {
  switch (action.type) {
    case types.EDIT_USER_PROFILE_MODAL_ADJUST_NAME:
      return action.payload;
    default:
      return state;
  }
};

const phoneNumber = (state = "", action) => {
  switch (action.type) {
    case types.EDIT_USER_PROFILE_MODAL_ADJUST_PHONE_NUMBER:
      return action.payload;
    default:
      return state;
  }
};

const profilePictureUrl = (state = "", action) => {
  switch (action.type) {
    case types.EDIT_USER_PROFILE_MODAL_ADJUST_PROFILE_PICTURE_URL:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  name,
  phoneNumber,
  profilePictureUrl,
});
