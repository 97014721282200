import { NAME } from "./constants";
const _ = require("lodash");

export const isLoading = (state) => {
  return state.pages[NAME].isLoading;
};

export const getActiveTab = (state) => {
  return state.pages[NAME].activeTab;
};

export const getCrowdContentOrders = (state) => {
  return state.pages[NAME].contentOrders.ordersArray;
};

export const getPlacedCrowdContentOrders = (state) => {
  let placedOrders = state.pages[NAME].contentOrders.ordersArray.filter(
    (order) => {
      return (
        order.status === "1" || order.status === "2" || order.status === "3"
      );
    }
  );
  const sortedOrders = _.orderBy(placedOrders, "order_id", "asc");
  return sortedOrders;
};

export const getReadyForReviewCrowdContentOrders = (state) => {
  let ordersReadyForReview = state.pages[NAME].contentOrders.ordersArray.filter(
    (order) => {
      return order.status === "4";
    }
  );
  return ordersReadyForReview;
};

export const getCrowdContentOrdersThatNeedPosting = (state) => {
  let ordersReadyForPosting = state.pages[
    NAME
  ].contentOrders.ordersNeedingPosting.filter((order) => {
    return order.crowdContent.status.status_ID === "69";
  });
  return ordersReadyForPosting;
};

export const getRecentlyPublishedCrowdContentOrders = (state) => {
  let recentlyPublishedContentOrder = state.pages[
    NAME
  ].contentOrders.ordersRecentlyPublished.filter((order) => {
    return order.crowdContent.status.status_ID === "420";
  });
  return recentlyPublishedContentOrder;
};

// export const getRecentlyPublishedContentOrders = (state) => {
//   const sortedArray = state.pages[NAME].contentOrders.recentlyPublished;
//   sortedArray.sort(function compare(a, b) {
//     var dateA = new Date(a.meta.publishedOn);
//     var dateB = new Date(b.meta.publishedOn);
//     return dateB - dateA;
//   });
//   return sortedArray;
// };
