import { connect } from "react-redux";
import { default as Modal } from "./EditCustomerBusinessName.jsx";

// UI Actions
import { dismissModal } from "../../../common/UI/ActiveModal/duck/actions";
// UI Selectors
import { getActiveModal } from "../../../common/UI/ActiveModal/duck/selectors"

// Local Actions
import { editCustomerBusinessName } from "../../duck/actions";
// Local Selectors
import { getCustomerDetails } from "../../duck/selectors.js";


const mapStateToProps = state => {
  return {
    activeModal: getActiveModal(state),
    name: getCustomerDetails(state).local.business.name,
    _id: getCustomerDetails(state)._id
  };
};

const mapDispatchToProps = {
  dismissModal,
  editCustomerBusinessName
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
