import React, { Fragment } from "react";
import { Badge } from "reactstrap";

import ContentOrderAccordion from "./components/ContentOrderAccordion";

// local imports
import {
  AddContentOrderModal,
  AddCrowdContentOrderModal,
  DecideContentOrderProviderModal,
  ViewContentOrderStatsModal,
} from "../modals";

const ContentOrderCard = (props) => {
  const { contentOrders, activateModal } = props;
  return (
    <Fragment>
      <div
        className={
          contentOrders.length > 0 ? "col-12 pt-2 pb-3" : "col-12 pt-2"
        }
      >
        <div className="text-right">
          <Badge
            href="#"
            color="success"
            onClick={() => activateModal("decideContentOrderProviderModal")}
          >
            New Order
          </Badge>
        </div>
      </div>
      {contentOrders.length === 0 ? <hr /> : <Fragment />}

      {contentOrders.length === 0 ? (
        <div className="text-center pb-2">
          <em>There are currently no Text Broker orders on this account.</em>
        </div>
      ) : (
        contentOrders.map((date) => (
          <ContentOrderAccordion date={date.orderDate} orders={date.orders} />
        ))
      )}
      <AddContentOrderModal />
      <AddCrowdContentOrderModal />
      <DecideContentOrderProviderModal />
      <ViewContentOrderStatsModal contentOrderProvider="textBroker" />
    </Fragment>
  );
};

export default ContentOrderCard;
