import { connect } from "react-redux";
import { default as component } from "./SetProjectMarketingType.jsx";

// local selectors
import {
  getCurrentProjectMarketingType,
  getProjectID,
} from "../../../duck/selectors";
// local actions
import { setProjectMarketingType } from "./duck/actions";

// global selectors
import { getActiveModal } from "../../../../common/UI/ActiveModal/duck/selectors";
// global actions
import { dismissModal } from "../../../../common/UI/ActiveModal/duck/actions";

const mapStateToProps = (state) => {
  return {
    activeModal: getActiveModal(state),
    projectID: getProjectID(state),
    currentMarketingType: getCurrentProjectMarketingType(state),
  };
};

const mapDispatchToProps = {
  setProjectMarketingType,
  dismissModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
