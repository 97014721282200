import { connect } from "react-redux";
import { default as component } from "./SetProjectStatus.jsx";

// UI Actions
import { dismissModal } from "../../../../common/UI/ActiveModal/duck/actions";

// UI Selectors
import { getActiveModal } from "../../../../common/UI/ActiveModal/duck/selectors";

// Local Selectors
import { getProjectStatus, getProjectID } from "../../../duck/selectors.js";

import { setNewProjectStatus } from "./duck/actions";

const mapStateToProps = state => {
  return {
    projectID: getProjectID(state),
    projectStatus: getProjectStatus(state),
    activeModal: getActiveModal(state)
  };
};

const mapDispatchToProps = {
  dismissModal,
  setNewProjectStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
