import React, { Fragment } from "react";

// Components / Containers
import { default as StateDrilldownCard } from "./components/StateDrillDownCard/StateDrilldownCard.js";
import { default as CityListCard } from "./components/CityListCard/CityListCard.js";

// Modals
import { default as AddPlacesBulkModal } from "./components/modals/AddPlacesBulkModal";

const TargetablePlacesSearch = props => {
  const { isLoading } = props;
  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12 col-md-4 p-1">
          <StateDrilldownCard />
        </div>
        <div className="col-sm-12 col-md-8 p-1">
          {isLoading ? <Fragment /> : <CityListCard />}
        </div>
      </div>
      <AddPlacesBulkModal />
    </Fragment>
  );
};

export default TargetablePlacesSearch;
