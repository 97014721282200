import { connect } from "react-redux";
import { default as component } from "./ContentOrdersRecentlyPublishedTable.jsx";

// local selectors and actions
import {
  getRecentlyPublishedContentOrders,
  isLoading,
} from "../../duck/selectors";

const mapStateToProps = (state) => {
  return {
    isLoading: isLoading(state),
    contentOrders: getRecentlyPublishedContentOrders(state),
  };
};

export default connect(mapStateToProps, null)(component);
