export const BEGIN_LOADING_PROJECTS_ADMINISTRATION =
  "BEGIN_LOADING_PROJECTS_ADMINISTRATION";
export const FINISH_LOADING_PROJECTS_ADMINISTRATION =
  "FINISH_LOADING_PROJECTS_ADMINISTRATION";
export const UPDATE_SORTED_PROJECTS_BY_USER = "UPDATE_SORTED_PROJECTS_BY_USER";

export default {
  BEGIN_LOADING_PROJECTS_ADMINISTRATION,
  FINISH_LOADING_PROJECTS_ADMINISTRATION,
  UPDATE_SORTED_PROJECTS_BY_USER,
};
