import types from "./types";
import { combineReducers } from "redux";

const newNote = (state = "", action) => {
  switch (action.type) {
    case types.SET_PROJECT_NEW_NOTE_CONTENTS: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({
  newNote,
});
