import { connect } from "react-redux";
import { default as component } from "./PaginationCustomerSearch.jsx";

// local action
import {
  performPaginationCustomerSearch,
  performPaginationCustomerSearchNumeric,
  performPaginationCancelledCustomerSearch,
} from "./duck/actions";

const mapStateToProps = (state) => {
  return {
    alphaChars: [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
    ],
    numericSelect: "numeric",
  };
};

const mapDispatchToProps = {
  performPaginationCustomerSearch,
  performPaginationCustomerSearchNumeric,
  performPaginationCancelledCustomerSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
