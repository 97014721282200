const CONTENT_ORDER_SET_TARGETED_KEYWORDS =
  "CONTENT_ORDER_SET_TARGETED_KEYWORDS";
const CONTENT_ORDER_REMOVE_KEYWORD_FROM_ORDER =
  "CONTENT_ORDER_REMOVE_KEYWORD_FROM_ORDER";
const CONTENT_ORDER_SET_MIN_KEYWORD_APPEARANCE =
  "CONTENT_ORDER_SET_MIN_KEYWORD_APPEARANCE";
const CONTENT_ORDER_SET_MAX_KEYWORD_APPEARANCE =
  "CONTENT_ORDER_SET_MAX_KEYWORD_APPEARANCE";
const CONTENT_ORDER_SET_KEYWORD_INFLECTION_USAGE =
  "CONTENT_ORDER_SET_KEYWORD_INFLECTION_USAGE";
const CONTENT_ORDER_SET_KEYWORD_STOP_WORDS_USAGE =
  "CONTENT_ORDER_SET_KEYWORD_STOP_WORDS_USAGE";
const NEW_CONTENT_ORDER_RESET_KEYWORDS = "NEW_CONTENT_ORDER_RESET_KEYWORDS";

export default {
  CONTENT_ORDER_SET_TARGETED_KEYWORDS,
  CONTENT_ORDER_REMOVE_KEYWORD_FROM_ORDER,
  CONTENT_ORDER_SET_MIN_KEYWORD_APPEARANCE,
  CONTENT_ORDER_SET_MAX_KEYWORD_APPEARANCE,
  CONTENT_ORDER_SET_KEYWORD_INFLECTION_USAGE,
  CONTENT_ORDER_SET_KEYWORD_STOP_WORDS_USAGE,
  NEW_CONTENT_ORDER_RESET_KEYWORDS,
};
