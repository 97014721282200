import { connect } from "react-redux";
import { default as component } from "./ContentOrdersNeedsPostingTable.jsx";

// global selectors and actions
import { activateModal } from "../../../common/UI/ActiveModal/duck/actions";

// local selectors and actions
import {
  getContentOrdersThatNeedPosting,
  isLoading,
} from "../../duck/selectors";

import { setFocusedContentOrderForUploadingToBox } from "../modals/AddContentOrderToBoxFolder/duck/actions";
import { setFocusedContentOrderForPublishing } from "../modals/PublishContentOrderModal/duck/actions";

const mapStateToProps = (state) => {
  return {
    isLoading: isLoading(state),
    contentOrders: getContentOrdersThatNeedPosting(state),
  };
};

const mapDispatchToProps = {
  activateModal,
  setFocusedContentOrderForUploadingToBox,
  setFocusedContentOrderForPublishing,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
