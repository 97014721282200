import { connect } from "react-redux";
import { default as modal } from "./AddCustomerModal.jsx";

// actions
import { addNewCustomer } from "../duck/actions";
import {
  activateModal,
  dismissModal
} from "../../common/UI/ActiveModal/duck/actions";

// selectors
import { getActiveModal, getModalErrorMessage, isModalActive } from '../../common/UI/ActiveModal/duck/selectors'

const mapStateToProps = state => {
  return {
    activeModal: getActiveModal(state),
    isModalActive: isModalActive(state),
    errorMessage: getModalErrorMessage(state)
  }
};

const mapDispatchToProps = {
  addNewCustomer,
  activateModal,
  dismissModal
};

export default connect(mapStateToProps, mapDispatchToProps)(modal);
