const CONTENT_ORDER_SET_META_H1 = "CONTENT_ORDER_SET_META_H1";
const CONTENT_ORDER_SET_META_TITLE = "CONTENT_ORDER_SET_META_TITLE";
const CONTENT_ORDER_SET_META_DESCRIPTION = "CONTENT_ORDER_SET_META_DESCRIPTION";
const CONTENT_ORDER_SET_META_SLUG = "CONTENT_ORDER_SET_META_SLUG";
const NEW_CONTENT_ORDER_RESET_META = "NEW_CONTENT_ORDER_RESET_META";
const CONTENT_ORDER_SET_META_VALIDITY = "CONTENT_ORDER_SET_META_VALIDITY";

export default {
  CONTENT_ORDER_SET_META_H1,
  CONTENT_ORDER_SET_META_TITLE,
  CONTENT_ORDER_SET_META_DESCRIPTION,
  CONTENT_ORDER_SET_META_SLUG,
  NEW_CONTENT_ORDER_RESET_META,
  CONTENT_ORDER_SET_META_VALIDITY,
};
