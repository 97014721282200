import React, { Component, Fragment } from "react";
import parse from "html-react-parser";
import {
  faLock,
  faLockOpen,
  faPencilAlt,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";

import {
  Card,
  CardBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Alert,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Badge,
  CardTitle,
} from "reactstrap";

export default class ReviewCrowdContentOrderModal extends Component {
  renderMetaCards = (id, contentOrderMeta, metaEditMode, fields) => {
    return (
      <div className="mt-2">
        {fields.map((field) => (
          <Card className="mb-1" id={field}>
            <CardBody className="text-center">
              <CardTitle>
                <strong>
                  {field.charAt(0).toUpperCase() + field.slice(1)}
                </strong>
                {field === "description" &&
                contentOrderMeta[field].length > 155 ? (
                  <Fragment>
                    <span> - </span>
                    <span className="text-danger">
                      Description is too long! {contentOrderMeta[field].length}
                      |155
                    </span>
                  </Fragment>
                ) : (
                  <Fragment />
                )}
                {field === "title" && contentOrderMeta[field].length > 67 ? (
                  <Fragment>
                    <span> - </span>
                    <span className="text-danger">
                      Title is too long! {contentOrderMeta[field].length}|67
                    </span>
                  </Fragment>
                ) : (
                  <Fragment />
                )}
                {field === "title" && contentOrderMeta[field].length < 51 ? (
                  <Fragment>
                    <span> - </span>
                    <span className="text-danger">
                      Title is too short! {contentOrderMeta[field].length}|51
                    </span>
                  </Fragment>
                ) : (
                  <Fragment />
                )}
              </CardTitle>
              <hr />
              <div className="d-flex">
                <div className="flex-grow-1 text-center">
                  {metaEditMode.field === field ? (
                    <input
                      className="form-control col-12"
                      id={field}
                      defaultValue={contentOrderMeta[field]}
                      onChange={(event) =>
                        this.props.setMetaFieldData(field, event.target.value)
                      }
                    />
                  ) : (
                    <span>{contentOrderMeta[field]}</span>
                  )}
                </div>
                <div className="pl-3 pr-3">
                  {metaEditMode.field === field && metaEditMode.editing ? (
                    <Button
                      color="success"
                      disabled={
                        contentOrderMeta[field] === metaEditMode.newData
                      }
                      onClick={() =>
                        this.props.saveNewMetaFieldData(
                          id,
                          field,
                          metaEditMode.newData
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faSave} />
                    </Button>
                  ) : (
                    <FontAwesomeIcon
                      icon={faPencilAlt}
                      onClick={() =>
                        this.props.setMetaEditMode(
                          field,
                          contentOrderMeta[field]
                        )
                      }
                    />
                  )}
                </div>
              </div>
            </CardBody>
          </Card>
        ))}
      </div>
    );
  };

  renderSubmissionStatus = (submissionStatus) => {
    if (submissionStatus === "Waiting on Crowd Content Acceptance") {
      return (
        <Alert color="info">
          Waiting to be accepted by Crowd Content (may take 20-30 seconds)
        </Alert>
      );
    } else if (submissionStatus === "Crowd Content Accepted") {
      return (
        <Alert color="success">
          Order Has been successfully move to "Needs Posting"
        </Alert>
      );
    }
  };

  render() {
    const {
      isLoading,
      submitLocked,
      activeModal,
      contentOrder,
      contentOrderMeta,
      contentType,
      shortCode,
      contentOrderKeywords,
      preview,
      dismissModal,
      toggleSubmitLocked,
      updateFocusedCrowdContentOrderText,
      submissionStatus,
      approvePendingCrowdContentOrder,
      setActiveTab,
      activeTab,
      metaEditMode,
      minWordCount,
      maxWordCount,
      totalWordCount,
    } = this.props;
    return (
      <Modal
        isOpen={activeModal === "reviewCrowdContentOrderModal"}
        toggle={() => dismissModal("reviewCrowdContentOrderModal")}
        size={submissionStatus !== "unsubmitted" ? "md" : "xl"}
      >
        <div className="row m-3">
          <div className="col-9">
            {!isLoading && (
              <Fragment>
                <span>
                  <strong>Title:</strong> {contentOrder.title}
                </span>
                <br />
                <strong>Content Template: </strong>
                {contentType !== null ? (
                  <span>{contentType}</span>
                ) : (
                  <span>Not set</span>
                )}
                <br />
                <strong>Uses Short Code: </strong>
                {shortCode !== null ? (
                  <span>{shortCode}</span>
                ) : (
                  <span>Not set</span>
                )}
              </Fragment>
            )}
          </div>
          <div className="col-3 text-right">
            <strong>CC Order: </strong>
            {!isLoading && <span>{contentOrder.order_id}</span>}
          </div>
        </div>
        <ModalBody>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "ReviewContent",
                })}
                onClick={() => setActiveTab(activeTab, "ReviewContent")}
              >
                Review Content
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "ReviewMeta" })}
                onClick={() => setActiveTab(activeTab, "ReviewMeta")}
              >
                Review Meta
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="ReviewContent">
              {submissionStatus === "unsubmitted" ? (
                <Fragment>
                  {isLoading ? (
                    <span>
                      Be patient - waiting on Crowd Content to provide a preview
                    </span>
                  ) : (
                    <div className="row">
                      <div
                        className="col-6"
                        style={{ height: "450px", overflowY: "scroll" }}
                      >
                        <Fragment>{parse(preview)}</Fragment>
                      </div>
                      <div className="col-6">
                        <Input
                          type="textarea"
                          name="editedContent"
                          id="editedContents"
                          defaultValue={preview}
                          style={{ height: "450px" }}
                          onChange={(event) => {
                            updateFocusedCrowdContentOrderText(event);
                          }}
                        />
                      </div>
                    </div>
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  {this.renderSubmissionStatus(submissionStatus)}
                </Fragment>
              )}
            </TabPane>
            <TabPane tabId="ReviewMeta">
              {submissionStatus === "unsubmitted" ? (
                <Fragment>
                  {isLoading ? (
                    <span>
                      Be patient - waiting on Crowd Content to provide a preview
                    </span>
                  ) : (
                    <Fragment>
                      {this.renderMetaCards(
                        contentOrder.order_id,
                        contentOrderMeta,
                        metaEditMode,
                        ["h1", "description", "slug", "title"]
                      )}
                    </Fragment>
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  {this.renderSubmissionStatus(submissionStatus)}
                </Fragment>
              )}
            </TabPane>
          </TabContent>
        </ModalBody>
        {contentOrderKeywords !== undefined && (
          <ModalFooter>
            <div className="col">
              {!isLoading && (
                <Fragment>
                  <Badge color="info mr-1" pill outline>{`${
                    "Total Word Count: " + totalWordCount
                  }`}</Badge>
                  <Badge color="info mr-1" pill outline>{`${
                    "Ordered Word Count: ( Min: " +
                    minWordCount +
                    " / Max: " +
                    maxWordCount +
                    ")"
                  }`}</Badge>
                </Fragment>
              )}
            </div>
            <div className="col text-right">
              {contentOrderKeywords.map((keyword) => (
                <Badge className="mr-1" color="info" pill outline>
                  {keyword}
                </Badge>
              ))}
            </div>
          </ModalFooter>
        )}
        <ModalFooter>
          <Button
            className="mr-2"
            outline={!submitLocked}
            color={submitLocked ? "warning" : "success"}
            onClick={() => toggleSubmitLocked(submitLocked)}
            disabled={isLoading}
          >
            <FontAwesomeIcon icon={submitLocked ? faLock : faLockOpen} />{" "}
            {submitLocked}
          </Button>

          <Button
            className="button-block"
            color="success"
            disabled={submitLocked}
            onClick={() =>
              approvePendingCrowdContentOrder(contentOrder, preview)
            }
          >
            Process Order
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
