import { postData } from "../../../../../../../utils/fetch-requests";

export const addMilestoneCategoryToProject = (id, category) => (dispatch) => {
  const dataToAdd = {
    _id: id,
    category,
  };
  postData("/api/project/add-milestone-category-to-project", dataToAdd).then(
    (project) => {
      dispatch({
        type: "RECEIVE_PROJECT_DETAILS",
        payload: project,
      });
      dispatch({ type: "FINISH_PROJECT_DETAILS_LOADING" });
    }
  );
};

export const removeMilestoneCategoryFromProject = (id, category) => (
  dispatch
) => {
  const dataToRemove = {
    _id: id,
    category,
  };
  postData(
    "/api/project/remove-milestone-category-from-project",
    dataToRemove
  ).then((project) => {
    dispatch({
      type: "RECEIVE_PROJECT_DETAILS",
      payload: project,
    });
    dispatch({ type: "FINISH_PROJECT_DETAILS_LOADING" });
  });
};
