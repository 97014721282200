const CROWD_CONTENT_ORDER_SET_META_H1 = "CROWD_CONTENT_ORDER_SET_META_H1";
const CROWD_CONTENT_ORDER_SET_META_TITLE = "CROWD_CONTENT_ORDER_SET_META_TITLE";
const CROWD_CONTENT_ORDER_SET_META_DESCRIPTION =
  "CROWD_CONTENT_ORDER_SET_META_DESCRIPTION";
const CROWD_CONTENT_ORDER_SET_META_SLUG = "CROWD_CONTENT_ORDER_SET_META_SLUG";
const NEW_CROWD_CONTENT_ORDER_RESET_META = "NEW_CROWD_CONTENT_ORDER_RESET_META";
const CROWD_CONTENT_ORDER_SET_META_VALIDITY =
  "CROWD_CONTENT_ORDER_SET_META_VALIDITY";

export default {
  CROWD_CONTENT_ORDER_SET_META_H1,
  CROWD_CONTENT_ORDER_SET_META_TITLE,
  CROWD_CONTENT_ORDER_SET_META_DESCRIPTION,
  CROWD_CONTENT_ORDER_SET_META_SLUG,
  NEW_CROWD_CONTENT_ORDER_RESET_META,
  CROWD_CONTENT_ORDER_SET_META_VALIDITY,
};
