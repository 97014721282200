import types from "./types";
import { combineReducers } from 'redux';

const customers = (state = [], action) => {
  switch (action.type) {
    case types.RECEIVE_CUSTOMERS: {
      return action.payload
    }
    default: {
      return state
    }
  }
}

const isLoading = (state = true, action) => {
  switch (action.type) {
    case types.BEGIN_CUSTOMER_SEARCH: {
      return true
    }
    case types.FINISH_CUSTOMER_SEARCH: {
      return false
    }
    default: {
      return state;
    }
  }
}

export default combineReducers({
  customers,
  isLoading
})