const PUBLISHING_CONTENT_ORDERS_SET_ORDER_TO_NEEDS_POSTING =
  "PUBLISHING_CONTENT_ORDERS_SET_ORDER_TO_NEEDS_POSTING";

const PUBLISHING_CONTENT_ORDERS_SET_FOCUSED_ORDER =
  "PUBLISHING_CONTENT_ORDERS_SET_FOCUSED_ORDER";

const PUBLISHING_CONTENT_ORDERS_RECEIVE_CONTENT_ORDER_HTML_CONTENTS =
  "PUBLISHING_CONTENT_ORDERS_RECEIVE_CONTENT_ORDER_HTML_CONTENTS";

const PUBLISHING_CONTENT_ORDERS_BEGIN_LOADING_FOCUSED_CONTENT_ORDER_PREVIEW =
  "PUBLISHING_CONTENT_ORDERS_BEGIN_LOADING_FOCUSED_CONTENT_ORDER_PREVIEW";

const PUBLISHING_CONTENT_ORDERS_FINISH_LOADING_FOCUSED_CONTENT_ORDER_PREVIEW =
  "PUBLISHING_CONTENT_ORDERS_FINISH_LOADING_FOCUSED_CONTENT_ORDER_PREVIEW";

const PENDING_CONTENT_ORDERS_SET_FOCUSED_ORDER_FOR_PUBLISHING =
  "PENDING_CONTENT_ORDERS_SET_FOCUSED_ORDER_FOR_PUBLISHING";

const PUBLISHING_CONTENT_ORDERS_UPDATE_FOCUSED_ORDER_HTML =
  "PUBLISHING_CONTENT_ORDERS_UPDATE_FOCUSED_ORDER_HTML";

const PENDING_CONTENT_ORDER_TOGGLE_SUBMIT_LOCK =
  "PENDING_CONTENT_ORDER_TOGGLE_SUBMIT_LOCK";

const PUBLISHING_CONTENT_ORDERS_SUBMISSION_STARTED =
  "PUBLISHING_CONTENT_ORDERS_SUBMISSION_STARTED";
const PUBLISHING_CONTENT_ORDERS_SUBMISSION_SUCCESSFUL =
  "PUBLISHING_CONTENT_ORDERS_SUBMISSION_SUCCESSFUL";
const PUBLISHING_CONTENT_ORDERS_SUBMISSION_FAILED =
  "PUBLISHING_CONTENT_ORDERS_SUBMISSION_FAILED";

const PUBLISH_CONTENT_ORDERS_MODAL_SET_ACTIVE_TAB =
  "PUBLISH_CONTENT_ORDERS_MODAL_SET_ACTIVE_TAB";

const UNSET_PUBLISH_CONTENT_ORDER_MODAL = "UNSET_PUBLISH_CONTENT_ORDER_MODAL";

export default {
  PUBLISHING_CONTENT_ORDERS_SET_ORDER_TO_NEEDS_POSTING,
  PUBLISHING_CONTENT_ORDERS_SET_FOCUSED_ORDER,
  PUBLISHING_CONTENT_ORDERS_UPDATE_FOCUSED_ORDER_HTML,
  PUBLISHING_CONTENT_ORDERS_RECEIVE_CONTENT_ORDER_HTML_CONTENTS,
  PUBLISHING_CONTENT_ORDERS_BEGIN_LOADING_FOCUSED_CONTENT_ORDER_PREVIEW,
  PUBLISHING_CONTENT_ORDERS_FINISH_LOADING_FOCUSED_CONTENT_ORDER_PREVIEW,
  PENDING_CONTENT_ORDERS_SET_FOCUSED_ORDER_FOR_PUBLISHING,
  PENDING_CONTENT_ORDER_TOGGLE_SUBMIT_LOCK,
  PUBLISHING_CONTENT_ORDERS_SUBMISSION_STARTED,
  PUBLISHING_CONTENT_ORDERS_SUBMISSION_SUCCESSFUL,
  PUBLISHING_CONTENT_ORDERS_SUBMISSION_FAILED,
  PUBLISH_CONTENT_ORDERS_MODAL_SET_ACTIVE_TAB,
  UNSET_PUBLISH_CONTENT_ORDER_MODAL,
};
