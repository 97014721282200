import { connect } from "react-redux";
import { default as component } from "./CustomerAccountManagersCard.jsx";

// UI Actions
import { activateModal } from "../../common/UI/ActiveModal/duck/actions";

// Local Selectors
import { getCustomerDetails } from "../duck/selectors"

const mapStateToProps = state => {
  return {
    customer: getCustomerDetails(state)
  };
};

const mapDispatchToProps = {
  activateModal
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
