import { connect } from "react-redux";
import { default as component } from "./SetProjectMonthlySpend.jsx";

// UI Actions
import { dismissModal } from "../../../../common/UI/ActiveModal/duck/actions";

// UI Selectors
import { getActiveModal } from "../../../../common/UI/ActiveModal/duck/selectors";

// Local Selectors
import {
  getProjectMonthlySpend,
  getProjectID,
} from "../../../duck/selectors.js";

import { setProjectMonthlySpend } from "./duck/actions";

const mapStateToProps = (state) => {
  return {
    projectID: getProjectID(state),
    monthlySpend: getProjectMonthlySpend(state),
    activeModal: getActiveModal(state),
  };
};

const mapDispatchToProps = {
  dismissModal,
  setProjectMonthlySpend,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
