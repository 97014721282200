import { connect } from "react-redux";
import { default as component } from "./MilestoneChecklistTable.jsx";

// localish selectors
import {
  getProjectID,
  getProjectMilestonesCategories,
  getProjectCompletedMilestones,
} from "../../../../../duck/selectors.js";

// local actions
import {
  markMilestoneAsComplete,
  markMilestoneAsIncomplete,
} from "./duck/actions";

const mapStateToProps = (state) => {
  return {
    projectID: getProjectID(state),
    milestoneCategories: getProjectMilestonesCategories(state),
    completedMilestones: getProjectCompletedMilestones(state),
  };
};

const mapDispatchToProps = {
  markMilestoneAsComplete,
  markMilestoneAsIncomplete,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
