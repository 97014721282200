import { combineReducers } from "redux";
import types from "./types";

const isLoading = (state = true, action) => {
  switch (action.type) {
    case types.PENDING_CONTENT_ORDERS_BEGIN_LOADING_FOCUSED_CONTENT_ORDER_PREVIEW: {
      return true;
    }
    case types.UNSET_PENDING_CONTENT_ORDERS_FOCUSED_CONTENT_ORDER: {
      return true;
    }
    case types.PENDING_CONTENT_ORDERS_FINISH_LOADING_FOCUSED_CONTENT_ORDER_PREVIEW: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const submissionStatus = (state = "unsubmitted", action) => {
  switch (action.type) {
    case types.PENDING_CONTENT_ORDERS_SUBMISSION_STARTED: {
      return "Waiting on TextBroker Acceptance";
    }
    case types.PENDING_CONTENT_ORDERS_SUBMISSION_TEXT_BROKER_ACCEPT_SUCCESS: {
      return "TextBroker Accepted";
    }
    case types.PENDING_CONTENT_ORDERS_SUBMISSION_TEXT_BROKER_ACCEPT_FAIL: {
      return "TextBroker Failed to Accept";
    }
    case types.PENDING_CONTENT_ORDERS_SUBMISSION_BOX_DOC_CREATING: {
      return "Waiting on Box Document Creation";
    }
    case types.PENDING_CONTENT_ORDERS_SUBMISSION_BOX_DOC_SUCCESS: {
      return "Box Document Created Successfully";
    }
    case types.PENDING_CONTENT_ORDERS_SUBMISSION_BOX_DOC_FAILURE: {
      return "Box Document Failed to Create";
    }
    case types.UNSET_PENDING_CONTENT_ORDERS_FOCUSED_CONTENT_ORDER: {
      return "unsubmitted";
    }
    default:
      return state;
  }
};

const focusedContentOrder = (state = {}, action) => {
  switch (action.type) {
    case types.PENDING_CONTENT_ORDERS_SET_FOCUSED_ORDER: {
      return action.payload;
    }
    case types.UNSET_PENDING_CONTENT_ORDERS_FOCUSED_CONTENT_ORDER: {
      return {};
    }
    default: {
      return state;
    }
  }
};

const preview = (state = {}, action) => {
  switch (action.type) {
    case types.PENDING_CONTENT_ORDERS_UPDATE_FOCUSED_ORDER_HTML:
    case types.PENDING_CONTENT_ORDERS_RECEIVE_CONTENT_ORDER_PREVIEW: {
      console.log(action.payload);
      return action.payload;
    }
    case types.UNSET_PENDING_CONTENT_ORDERS_FOCUSED_CONTENT_ORDER: {
      return {};
    }
    default:
      return state;
  }
};

const activeTab = (state = "ReviewContent", action) => {
  switch (action.type) {
    case types.PENDING_CONTENT_ORDERS_SET_ACTIVE_TAB: {
      return action.payload;
    }
    case "DISMISS_MODAL":
    case types.UNSET_PENDING_CONTENT_ORDERS_FOCUSED_CONTENT_ORDER: {
      return "ReviewContent";
    }
    default:
      return state;
  }
};

const metaEditMode = (
  state = { editing: false, field: null, newData: "" },
  action
) => {
  switch (action.type) {
    case types.PENDING_CONTENT_ORDERS_SET_META_EDIT_MODE: {
      return action.payload;
    }
    case types.PENDING_CONTENT_ORDERS_SET_META_FIELD_DATA: {
      return {
        editing: true,
        field: action.payload.field,
        newData: action.payload.data,
      };
    }
    case "DISMISS_MODAL":
    case types.UNSET_PENDING_CONTENT_ORDERS_FOCUSED_CONTENT_ORDER: {
      return { editing: false, field: null, newData: "" };
    }
    default:
      return state;
  }
};

const submitLocked = (state = true, action) => {
  switch (action.type) {
    case types.PENDING_CONTENT_ORDER_TOGGLE_SUBMIT_LOCK: {
      return !action.payload;
    }
    case "DISMISS_MODAL":
    case types.UNSET_PENDING_CONTENT_ORDERS_FOCUSED_CONTENT_ORDER: {
      return true;
    }
    default:
      return state;
  }
};

export default combineReducers({
  isLoading,
  activeTab,
  metaEditMode,
  submissionStatus,
  submitLocked,
  focusedContentOrder,
  preview,
});
