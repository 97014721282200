import { connect } from "react-redux";
import { default as ProjectView } from "./ProjectView.jsx";

// UI Selectors
import { getActiveModal } from "../common/UI/ActiveModal/duck/selectors";
// UI Actions
import { activateModal } from "../common/UI/ActiveModal/duck/actions";

// Local Selectors
import { getProjectDetails, getActiveTab, isLoading } from "./duck/selectors";
// Local Actions
import { fetchProject, setActiveTab } from "./duck/actions";

const mapStateToProps = (state) => {
  return {
    activeModal: getActiveModal(state),
    activeTab: getActiveTab(state),
    isLoading: isLoading(state),
    project: getProjectDetails(state),
  };
};

const mapDispatchToProps = {
  activateModal,
  fetchProject,
  setActiveTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectView);
