import React, { Component, Fragment } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Input,
  Alert,
} from "reactstrap";

class AddCustomerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newDetails: {
        local: {
          business: {
            name: "",
          },
        },
      },
    };
  }

  updateDetails = (e) => {
    let newState = Object.assign({}, this.state);
    newState.newDetails.local.business[e.target.id] = e.target.value;
    this.setState(newState);
  };

  render() {
    let {
      activeModal,
      dismissModal,
      errorMessage,
      addNewCustomer,
    } = this.props;
    let { newDetails } = this.state;
    return (
      <Modal
        isOpen={activeModal === "addCustomer"}
        toggle={() => dismissModal("addCustomer")}
      >
        <ModalHeader>Add New Client</ModalHeader>
        <ModalBody>
          <Form onSubmit={(e) => e.preventDefault()}>
            <label>Business Name:</label>
            <Input
              className="form-control mb-1"
              id="name"
              placeholder="Enter business name"
              onChange={(event) => this.updateDetails(event)}
            />
          </Form>
          {errorMessage !== null ? (
            <Alert color="warning" className="col-12 mt-2">
              {errorMessage}
            </Alert>
          ) : (
            <Fragment />
          )}
        </ModalBody>
        <ModalFooter>
          {newDetails.local.business.name === null ||
          newDetails.local.business.name === "" ? (
            <Button className="mr-1" color="success" disabled>
              Submit Changes
            </Button>
          ) : (
            <Button
              className="mr-1"
              color="success"
              onClick={() => addNewCustomer(newDetails)}
            >
              Submit Changes
            </Button>
          )}
          <Button
            color="secondary"
            onClick={() => dismissModal("addNewCustomer")}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default AddCustomerModal;
