import React, { useState } from "react";
import { Input } from "reactstrap";

import { MODAL_NAME } from "./duck/constants";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const SetProjectStatusModal = (props) => {
  const {
    activeModal,
    dismissModal,
    projectID,
    projectStatus,
    setNewProjectStatus,
  } = props;
  const [localProjectStatus, setLocalProjectStatus] = useState(projectStatus);
  return (
    <Modal
      isOpen={activeModal === MODAL_NAME}
      toggle={() => dismissModal(MODAL_NAME)}
    >
      <ModalHeader>Set Project Status</ModalHeader>
      <ModalBody>
        <Input
          type="select"
          defaultValue={localProjectStatus}
          className="form-control"
          onChange={(event) => setLocalProjectStatus(event.target.value)}
        >
          <option value="Active">Active</option>
          <option value="Paused (Requested)">Paused (Request)</option>
          <option value="Paused (Billing)">Paused (Billing)</option>
          <option value="Cancelled">Cancelled</option>
        </Input>
      </ModalBody>
      <ModalFooter>
        <Button
          color="success"
          onClick={() => setNewProjectStatus({ projectID, localProjectStatus })}
        >
          Save
        </Button>
        <Button color="secondary" onClick={() => dismissModal(MODAL_NAME)}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SetProjectStatusModal;
