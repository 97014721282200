import React, { Component, Fragment } from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { Container } from "reactstrap";
import { connect } from "react-redux";

// Shared Elements
import { MainNavigation } from "./common/MainNavigation";

// Actions for Auth
import { checkAuth } from "./common/Authentication/duck/actions";
import { checkAuthLevel } from "./utils/user-validation";

// Title & Meta Component via react-helmet
import { TitleComponent } from "./utils/TitleComponent.jsx";

// Auth Selectors
import {
  isLoggedIn,
  checkingAuth,
} from "./common/Authentication/duck/selectors";

import { getLoggedInUser } from "./common/UserDetails/duck/selectors";

// Pages
import { ContentDashboard } from "./ContentDashboardPage";
import { CrowdContentDashboard } from "./CrowdContentDashboard";
import { CustomerSearch } from "./CustomerSearch";
import { CustomerView } from "./CustomerViewPage";
import { ProjectView } from "./ProjectView";
import { ProjectsAdministration } from "./ProjectsAdministration";
import LoginPage from "./LoginPage";
import LogoutPage from "./LogoutPage";
// import RegistrationPage from "./RegistrationPage";
import { ProfilePage } from "./ProfilePage";
import { UserManagement } from "./UserManagement";
import { TargetablePlaceSearch } from "./TargetablePlaceSearch";

// Pages // Tools
import { KeywordGeneratorTool } from "./KeywordGeneratorTool";

// Build Out
const mapStateToProps = (state) => {
  return {
    isLoggedIn: isLoggedIn(state),
    checkingAuth: checkingAuth(state),
    loggedInUser: getLoggedInUser(state),
  };
};

const mapDispatchToProps = {
  checkAuth,
};

class App extends Component {
  componentDidMount() {
    this.props.checkAuth();
  }

  loadRoutes = () => {
    const { loggedInUser, isLoggedIn } = this.props;
    return (
      <Router>
        <Route
          exact
          path="/"
          render={() =>
            isLoggedIn && checkAuthLevel(loggedInUser.access.level, 1) ? (
              <Redirect to="/customers" />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        <Route
          exact
          path="/login"
          render={() =>
            isLoggedIn ? (
              <Redirect to="/customers" />
            ) : (
              <Fragment>
                <TitleComponent title={`SEO Dashboard | Login`} />
                <LoginPage />
              </Fragment>
            )
          }
        />
        {/* <Route exact path="/register" render={() => <RegistrationPage />} /> */}
        <Route
          exact
          path="/logout"
          render={() =>
            !isLoggedIn ? (
              <Redirect to="/login" />
            ) : (
              <Fragment>
                <TitleComponent title={`SEO Dashboard | Logout`} />
                <LogoutPage />
              </Fragment>
            )
          }
        />
        <Route
          exact
          path="/profile"
          render={() =>
            isLoggedIn ? (
              <Fragment>
                <TitleComponent title="SEO Dashboard | Profile" />
                <MainNavigation />
                <Container>
                  <ProfilePage />
                </Container>
              </Fragment>
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        <Route
          exact
          path="/customers"
          render={() =>
            isLoggedIn ? (
              <Fragment>
                <TitleComponent title="SEO Dashboard | Client Search" />
                <MainNavigation />
                <Container>
                  <CustomerSearch />
                </Container>
              </Fragment>
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        <Route
          exact
          path="/customer/view/:id"
          render={(props) =>
            isLoggedIn ? (
              <Fragment>
                <TitleComponent
                  title={`SEO Dashboard | Client View | ${props.match.params.id}`}
                />
                <MainNavigation />
                <Container>
                  <CustomerView {...props} />
                </Container>
              </Fragment>
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        <Route
          exact
          path="/project/view/:id"
          render={(props) =>
            isLoggedIn ? (
              <Fragment>
                <TitleComponent
                  title={`SEO Dashboard | Project View | ${props.match.params.id}`}
                />
                <MainNavigation />
                <Container>
                  <ProjectView {...props} />
                </Container>
              </Fragment>
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        <Route
          exact
          path="/projects/administration"
          render={(props) =>
            (isLoggedIn && loggedInUser.local.email === "iboda@rynoss.com") ||
            (isLoggedIn && loggedInUser.local.email === "zwest@rynoss.com") ||
            (isLoggedIn && loggedInUser.local.email === "lmccoy@rynoss.com") ||
            (isLoggedIn &&
              loggedInUser.local.email === "jvenidis@rynoss.com") ? (
              <Fragment>
                <TitleComponent
                  title={`SEO Dashboard | Projects | Administration`}
                />
                <MainNavigation />
                <Container>
                  <ProjectsAdministration />
                </Container>
              </Fragment>
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        <Route
          exact
          path="/places"
          render={() =>
            isLoggedIn ? (
              <Fragment>
                <TitleComponent title={`SEO Dashboard | Place Management`} />
                <MainNavigation />
                <Container>
                  <TargetablePlaceSearch />
                </Container>
              </Fragment>
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        <Route
          exact
          path="/users/manage"
          render={() =>
            isLoggedIn && checkAuthLevel(loggedInUser.access.level, 1) ? (
              <Fragment>
                <TitleComponent title={`SEO Dashboard | User Management`} />
                <MainNavigation />
                <Container>
                  <UserManagement />
                </Container>
              </Fragment>
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        <Route
          exact
          path="/content/textbroker"
          render={() =>
            isLoggedIn ? (
              <Fragment>
                <TitleComponent
                  title={`SEO Dashboard | Content | TextBroker Dashboard`}
                />
                <MainNavigation loggedInUser={loggedInUser} />
                <Container>
                  <ContentDashboard loggedInUser={loggedInUser} />
                </Container>
              </Fragment>
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        <Route
          exact
          path="/content/crowdcontent"
          render={() =>
            isLoggedIn ? (
              <Fragment>
                <TitleComponent
                  title={`SEO Dashboard | Content | Crowd Content Dashboard`}
                />
                <MainNavigation loggedInUser={loggedInUser} />
                <Container>
                  <CrowdContentDashboard loggedInUser={loggedInUser} />
                </Container>
              </Fragment>
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        <Route
          exact
          path="/tools/keyword-generator"
          render={() =>
            isLoggedIn ? (
              <Fragment>
                <TitleComponent
                  title={`SEO Dashboard | Tools | Keyword Generator`}
                />
                <MainNavigation loggedInUser={loggedInUser} />
                <Container>
                  <KeywordGeneratorTool loggedInUser={loggedInUser} />
                </Container>
              </Fragment>
            ) : (
              <Redirect to="/login" />
            )
          }
        />
      </Router>
    );
  };

  render() {
    const { checkingAuth } = this.props;
    return (
      <Fragment>
        {checkingAuth ? <Fragment /> : <Fragment>{this.loadRoutes()}</Fragment>}
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
