import React, { Fragment } from "react";
import { MODAL_NAME } from "./duck/constants";

import {
  Badge,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

const AddNoteModal = (props) => {
  const {
    activeModal,
    activateModal,
    dismissModal,
    projectID,
    newNote,
    updateNewNote,
    createNewProjectNote,
  } = props;
  return (
    <Fragment>
      <Badge href="#" color="success" onClick={() => activateModal(MODAL_NAME)}>
        New Note
      </Badge>
      <Modal
        isOpen={activeModal === MODAL_NAME}
        toggle={() => dismissModal(MODAL_NAME)}
      >
        <ModalHeader>Add New Note</ModalHeader>
        <ModalBody>
          <Input
            type="textarea"
            height="300px"
            placeHolder="Insert notes here..."
            onChange={(event) => updateNewNote(event.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            onClick={() => createNewProjectNote({ projectID, newNote })}
            disabled
          >
            Save Note
          </Button>
          <Button color="secondary" onClick={() => dismissModal(MODAL_NAME)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default AddNoteModal;
