import React, { Fragment } from "react";
import { default as AccountManagerProjectsAccordion } from "./AccountManagerProjectsAccordion.js";

const AccountManagerOverviewCard = (props) => {
  const { projects } = props;
  return (
    <Fragment>
      {Object.keys(projects).map((accountManager) => [
        <Fragment>
          <AccountManagerProjectsAccordion
            accountManager={accountManager}
            projects={projects[accountManager]}
          />
        </Fragment>,
      ])}
    </Fragment>
  );
};

export default AccountManagerOverviewCard;
