import React, { useState } from "react";
import { Input } from "reactstrap";

import { MODAL_NAME } from "./duck/constants";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const SetProjectStatusModal = (props) => {
  const {
    activeModal,
    dismissModal,
    projectID,
    monthlySpend,
    setProjectMonthlySpend,
  } = props;
  const [localProjectMonthlySpend, setLocalProjectMonthlySpend] = useState(
    monthlySpend
  );
  return (
    <Modal
      isOpen={activeModal === MODAL_NAME}
      toggle={() => dismissModal(MODAL_NAME)}
    >
      <ModalHeader>Set Project Monthly Spend</ModalHeader>
      <ModalBody>
        <Input
          type="number"
          defaultValue={monthlySpend}
          className="form-control text-right no-arrows"
          onChange={(event) => setLocalProjectMonthlySpend(event.target.value)}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          color="success"
          onClick={() =>
            setProjectMonthlySpend({ projectID, localProjectMonthlySpend })
          }
        >
          Set Spend
        </Button>
        <Button color="secondary" onClick={() => dismissModal(MODAL_NAME)}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SetProjectStatusModal;
