// types
const BEGIN_CUSTOMER_SEARCH = "BEGIN_CUSTOMER_SEARCH";
const FINISH_CUSTOMER_SEARCH = "FINISH_CUSTOMER_SEARCH";

const RECEIVE_CUSTOMERS = "RECEIVE_CUSTOMERS";

export default {
    BEGIN_CUSTOMER_SEARCH,
    FINISH_CUSTOMER_SEARCH,
    RECEIVE_CUSTOMERS
}