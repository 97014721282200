import { combineReducers } from "redux";
import { reducer as activeModal } from "./ActiveModal";
import { reducer as currentView } from "./CurrentView"

const reducers = {
  activeModal,
  currentView
};

export const reducer = combineReducers(reducers);
