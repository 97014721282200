import types from "./types";
import { combineReducers } from "redux";
import { DEFAULT_TAB } from "./constants";
import { findIndex } from "lodash";

import { default as addKeywordListModal } from "../modals/addKeywordGeneratorList/duck/reducer";
import { default as editKeywordListModal } from "../modals/editKeywordGeneratorList/duck/reducer";

const generatedKeywords = (
  state = [{ cityName: "General Keywords", keywords: [] }],
  action
) => {
  switch (action.type) {
    case types.KEYWORD_GENERATOR_TOOL_ADD_GENERATED_KEYWORDS: {
      let newState = [...state];
      action.payload.map((city) => {
        // pull in the properties into their own variables
        const { cityName, keywords } = city;
        // see if the cityName exists in the array - if not, create an empty one.
        if (
          findIndex(newState, (o) => {
            return o.cityName === cityName;
          }) === -1
        ) {
          // push new object into array
          newState.push({ cityName, keywords });
        } else {
          // if cityName exists in array - find it's index and update it's keyword array
          let filteredCityToUpdate = newState.find(
            (city) => city.cityName === cityName
          );
          // push in keywords
          keywords.map((keyword) => {
            filteredCityToUpdate.keywords.push(keyword);
          });
          // note it's index
          let indexInArray = findIndex(newState, (o) => {
            return o.cityName === cityName;
          });
          // splice in new copy
          newState.splice(indexInArray, 1, filteredCityToUpdate);
        }
      });
      return newState;
    }
    default: {
      return state;
    }
  }
};

const keywordLists = (state = [], action) => {
  switch (action.type) {
    case types.KEYWORD_GENERATOR_TOOL_RECEIVE_KEYWORD_LISTS: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

const usedKeywordLists = (state = [], action) => {
  switch (action.type) {
    case types.KEYWORD_GENERATOR_TOOL_MARK_KEYWORD_GROUP_AS_USED: {
      return [...state, action.payload];
    }
    default: {
      return state;
    }
  }
};

const splitCities = (state = [], action) => {
  switch (action.type) {
    case types.KEYWORD_GENERATOR_TOOL_SET_SPLIT_CITIES: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

const stateAbbreviation = (state = "", action) => {
  switch (action.type) {
    case types.KEYWORD_GENERATOR_TOOL_SET_STATE_ABBREVIATION: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

const isLoading = (state = true, action) => {
  switch (action.type) {
    case types.KEYWORD_GENERATOR_TOOL_BEGIN_LOADING: {
      return true;
    }
    case types.KEYWORD_GENERATOR_TOOL_FINISH_LOADING: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const activeTab = (state = DEFAULT_TAB, action) => {
  switch (action.type) {
    case types.KEYWORD_GENERATOR_TOOL_SET_ACTIVE_TAB: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

const modalData = combineReducers({
  addKeywordListModal,
  editKeywordListModal,
});

export default combineReducers({
  activeTab,
  isLoading,
  generatedKeywords,
  keywordLists,
  splitCities,
  stateAbbreviation,
  usedKeywordLists,
  modalData,
});
