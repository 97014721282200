import types from "./types";
import { combineReducers } from "redux";
import { DEFAULT_TAB } from "./constants";

import { default as reviewCrowdContentOrder } from "../components/modals/ReviewCrowdContentOrderModal/duck/reducer";
import { default as publishCrowdContentOrder } from "../components/modals/PublishCrowdContentOrderModal/duck/reducer";

const contentOrders = (
  state = {
    ordersArray: [],
    ordersNeedingPosting: [],
    ordersRecentlyPublished: [],
  },
  action
) => {
  switch (action.type) {
    case types.CROWD_CONTENT_CONTENT_DASHBOARD_RECEIVE_CONTENT_ORDERS: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

const isLoading = (state = false, action) => {
  switch (action.type) {
    case types.CROWD_CONTENT_FINISH_CONTENT_DASHBOARD_LOADING: {
      return false;
    }
    case types.CROWD_CONTENT_BEGIN_CONTENT_DASHBOARD_LOADING: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const activeTab = (state = DEFAULT_TAB, action) => {
  switch (action.type) {
    case types.CROWD_CONTENT_CONTENT_DASHBOARD_SET_ACTIVE_TAB: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

const modalData = combineReducers({
  reviewCrowdContentOrder,
  publishCrowdContentOrder,
});

export default combineReducers({
  isLoading,
  activeTab,
  contentOrders,
  modalData,
});
