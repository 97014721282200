// account managers
const ASSIGN_ACCOUNT_MANAGER = "ASSIGN_ACCOUNT_MANAGER";

// customer business details
const EDIT_CUSTOMER_BUSINESS_NAME = "EDIT_CUSTOMER_BUSINESS_NAME";

// customer industries
const EDIT_CUSTOMER_INDUSTRIES = "EDIT_CUSTOMER_INDUSTRIES";

// project related
const UPDATE_PROJECT_LIST = "UPDATE_PROJECT_LIST"

// Point of Contact related
const ADD_POINT_OF_CONTACT = "ADD_POINT_OF_CONTACT"
const EDIT_POINT_OF_CONTACT = "EDIT_POINT_OF_CONTACT"
const REMOVE_POINT_OF_CONTACT = "REMOVE_POINT_OF_CONTACT"

// UI related
const BEGIN_CUSTOMER_DETAILS_LOADING = "FINISH_CUSTOMER_DETAILS_LOADING"
const FINISH_CUSTOMER_DETAILS_LOADING = "FINISH_CUSTOMER_DETAILS_LOADING"
const RECEIVE_CUSTOMER_DETAILS = "RECEIVE_CUSTOMER_DETAILS";



export default {
  ADD_POINT_OF_CONTACT,
  ASSIGN_ACCOUNT_MANAGER,
  BEGIN_CUSTOMER_DETAILS_LOADING,
  EDIT_CUSTOMER_BUSINESS_NAME,
  EDIT_CUSTOMER_INDUSTRIES,
  EDIT_POINT_OF_CONTACT,
  FINISH_CUSTOMER_DETAILS_LOADING,
  RECEIVE_CUSTOMER_DETAILS,
  REMOVE_POINT_OF_CONTACT,
  UPDATE_PROJECT_LIST
};
