import axios from "axios";
import types from "./types";

const addKeywordGeneratorList = (title, keywords) => (dispatch) => {
  let newKeywordArray = keywords.split("|");
  let newKeywordArrayTrimmed = newKeywordArray.map((el) => {
    return el.trim();
  });

  axios
    .post("/api/keyword-generator/add-new-keyword-list", {
      title,
      keywords: newKeywordArrayTrimmed.sort(),
    })
    .then((res) => {
      dispatch({
        type: "KEYWORD_GENERATOR_TOOL_RECEIVE_KEYWORD_LISTS",
        payload: res.data,
      });
    });
  dispatch({
    type: "DISMISS_MODAL",
  });
};

const setTitleForKeywordList = (title) => (dispatch) => {
  dispatch({
    type: types.KEYWORD_GENERATOR_TOOL_SET_NEW_LIST_TITLE,
    payload: title,
  });
};

const setKeywordsForKeywordList = (keywords) => (dispatch) => {
  dispatch({
    type: types.KEYWORD_GENERATOR_TOOL_SET_NEW_LIST_KEYWORDS,
    payload: keywords,
  });
};

const checkAddKeywordGeneratorListFormValidity =
  (title, keywords) => (dispatch) => {
    console.log(title, keywords);
    if (
      title === "" ||
      title.lenghth < 5 ||
      keywords === "" ||
      keywords.length < 5
    ) {
      dispatch({
        type: types.KEYWORD_GENERATOR_TOOL_SET_NEW_LIST_FORM_VALIDITY,
        payload: true,
      });
    } else {
      dispatch({
        type: types.KEYWORD_GENERATOR_TOOL_SET_NEW_LIST_FORM_VALIDITY,
        payload: false,
      });
    }
  };

export {
  addKeywordGeneratorList,
  setTitleForKeywordList,
  setKeywordsForKeywordList,
  checkAddKeywordGeneratorListFormValidity,
};
