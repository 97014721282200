const CROWD_CONTENT_ORDER_SET_TARGETED_KEYWORDS =
  "CROWD_CONTENT_ORDER_SET_TARGETED_KEYWORDS";
const CROWD_CONTENT_ORDER_REMOVE_KEYWORD_FROM_ORDER =
  "CROWD_CONTENT_ORDER_REMOVE_KEYWORD_FROM_ORDER";
const NEW_CROWD_CONTENT_ORDER_RESET_KEYWORDS =
  "NEW_CROWD_CONTENT_ORDER_RESET_KEYWORDS";

export default {
  CROWD_CONTENT_ORDER_SET_TARGETED_KEYWORDS,
  CROWD_CONTENT_ORDER_REMOVE_KEYWORD_FROM_ORDER,
  NEW_CROWD_CONTENT_ORDER_RESET_KEYWORDS,
};
