import { MODAL_NAME } from "./constants";

export const getEditedProfileName = (state) => {
  return state.pages.profilePage.modalData[MODAL_NAME].name;
};

export const getEditedProfilePhoneNumber = (state) => {
  return state.pages.profilePage.modalData[MODAL_NAME].phoneNumber;
};

export const getEditedProfilePictureUrl = (state) => {
  return state.pages.profilePage.modalData[MODAL_NAME].profilePictureUrl;
};

export const getEditedProfileDetails = (state) => {
  return state.pages.profile.modalData.editProfileModal;
};
