import { connect } from "react-redux";
import { default as component } from "./ProjectMilestoneTrackerCard.jsx";

// global selectors
// global action
import { activateModal } from "../../../common/UI/ActiveModal/duck/actions";
import { getProjectMilestonesCategories } from "../../duck/selectors.js";

// local selectors
// local actions

const mapStateToProp = (state) => {
  return {
    milestoneCategories: getProjectMilestonesCategories(state),
  };
};

const mapDispatchToProp = {
  activateModal,
};

export default connect(mapStateToProp, mapDispatchToProp)(component);
