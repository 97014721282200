import React, { Component, Fragment } from "react";
import { Button, Card } from "reactstrap";

const logo =
  "https://cdn.rynoss.com/wp-content/themes/rynoss/assets/images/RYNOSS-logo.png";

class LoginPage extends Component {
  constructor() {
    super();
    this.state = {
      errorMessage: null,
      loggedOut: false
    };
  }

  componentDidMount() {
    fetch("/api/user/logout")
      .then(res => res.json())
      .then(res => {
        if (res.status === 200) {
          this.setState({ loggedOut: true });
        } else {
          this.setState({
            errorMessage: "There was a problem logging you out."
          });
        }
      });
  }

  render() {
    const { errorMessage, loggedOut } = this.state;
    return (
      <div className="d-flex h-100 justify-content-center justify-content-middle text-center">
        <div className="col-md-3" style={{ marginTop: "10%" }}>
          {loggedOut ? (
            <Card className="p-3 card-shadow">
              <div className="align-self-center">
                <img src={logo} alt="" />
              </div>
              <h5 className="text-muted">
                You have been successfully logged out.
              </h5>
              <Button className="ryno-orange-bg" block href="/login">
                Return to Login
              </Button>
            </Card>
          ) : (
            <Card className="p-3 card-shadow">
              <div className="align-self-center">
                <img src={logo} alt="" />
              </div>
              <h5 className="text-muted">Please wait while we log you out.</h5>
              {errorMessage ? (
                <h6 className="text-muted">{errorMessage}</h6>
              ) : (
                <Fragment />
              )}
            </Card>
          )}
        </div>
      </div>
    );
  }
}

export default LoginPage;
