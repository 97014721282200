import React, { Fragment } from "react";
import { Table, Button, Badge } from "reactstrap";

const ContentOrdersNeedsPostingTable = (props) => {
  const {
    contentOrders,
    setFocusedContentOrderForUploadingToBox,
    setFocusedContentOrderForPublishing,
  } = props;

  const determineButton = (order) => {
    if (
      !order.meta ||
      !order.meta.project ||
      !order.meta.project.boxData ||
      order.meta.project.boxData.folder === null ||
      order.boxData.id === null
    ) {
      return (
        <Button
          size="sm"
          color="warning"
          onClick={() => setFocusedContentOrderForUploadingToBox(order)}
        >
          Order not in Box Parent
        </Button>
      );
    } else {
      return (
        <Button
          size="sm"
          color="success"
          onClick={() => setFocusedContentOrderForPublishing(order)}
        >
          Publish
        </Button>
      );
    }
  };

  const appearShorter = (title) => {
    if (title.length > 35) {
      title = title.substring(0, 32) + "...";
    }
    return title;
  };

  const showIMOBadge = (marketingType) => {
    switch (marketingType) {
      case "IMO":
        return (
          <Badge color="info" style={{ "margin-left": "10px" }} pill>
            IMO
          </Badge>
        );
      default:
        break;
    }
  };

  return (
    <Fragment>
      {contentOrders.length === 0 ? (
        <span>There are no orders needing to be Posting at this time.</span>
      ) : (
        <Table size="sm" hover>
          <tbody>
            {contentOrders.map((order) => (
              <tr>
                <td className="pt-2">{order.textBroker._id}</td>
                <td className="pt-2">
                  <a href={`/project/view/${order.meta.project._id}`}>
                    {appearShorter(
                      order.meta.project.meta.parent.local.business.name
                    )}
                    {showIMOBadge(order.meta.project.local.marketingType)}
                  </a>
                </td>
                <td className="pt-2">
                  {appearShorter(order.textBroker.orderTitle)}
                </td>
                <td className="pt-2 text-center">{order.meta.dueDate}</td>
                <td className="text-right">{determineButton(order)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Fragment>
  );
};

export default ContentOrdersNeedsPostingTable;
