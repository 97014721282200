import { NAME } from "./constants";

const isLoading = (state) => {
  return state.pages[NAME].isLoading;
};

const getActiveProjectCount = (state) => {
  return state.pages[NAME].projects.length;
};

const getCalculatedTotalSpend = (state) => {
  let monthlySpend = 0;
  state.pages[NAME].projects.forEach((project) => {
    if (project.local.monthlySpend === undefined) {
      monthlySpend += 0;
    } else {
      monthlySpend += project.local.monthlySpend;
    }
  });
  return monthlySpend;
};

const getCalculatedPortfolioRatio = (state) => {
  let imoCount = 0;
  let rynoCount = 0;
  state.pages[NAME].projects.forEach((project) => {
    switch (project.local.marketingType) {
      case "IMO":
        imoCount += 1;
        break;
      case "RYNO Built":
        rynoCount += 1;
        break;
      default:
        break;
    }
  });
  return { imoCount, rynoCount };
};

const getSortedProjectsByUser = (state) => {
  const rawProjects = state.pages[NAME].projects;
  const projectOrdersSorted = rawProjects.reduce((acc, project) => {
    const accountManager =
      project.meta.parent.internal.accountManager.personal.name;
    if (acc[accountManager]) {
      acc[accountManager].push(project);
    } else {
      acc[accountManager] = [project];
    }
    return acc;
  }, {});
  console.log(projectOrdersSorted);
  return projectOrdersSorted;
};

export {
  isLoading,
  getActiveProjectCount,
  getCalculatedTotalSpend,
  getCalculatedPortfolioRatio,
  getSortedProjectsByUser,
};
