import { connect } from "react-redux";
import { default as component } from "./EditProjectSpecialNote.jsx";

// UI actions
import { dismissModal } from "../../../../common/UI/ActiveModal/duck/actions";
// UI selectors
import { getActiveModal } from "../../../../common/UI/ActiveModal/duck/selectors";

// Local selectors
import { getCurrentSpecialNote, getEditedSpecialNote } from "./duck/selectors";
import { getProjectID } from "../../../duck/selectors.js";

// Local actions
import {
  updateProjectSpecialNoteContents,
  saveProjectSpecialNote,
} from "./duck/actions";

const mapStateToProps = (state) => {
  return {
    currentSpecialNote: getCurrentSpecialNote(state),
    editedSpecialNote: getEditedSpecialNote(state),
    projectID: getProjectID(state),
    activeModal: getActiveModal(state),
  };
};

const mapDispatchToProps = {
  dismissModal,
  updateProjectSpecialNoteContents,
  saveProjectSpecialNote,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
