import types from "./types";

// CHECK THAT USER IS STILL LOGGED IN

const checkAuth = () => dispatch => {
  fetch("/api/user/fetch-logged-in-user/")
    .then(res => res.json())
    .then(data => {
      if (data.message === "User is not logged in") {
        dispatch({ type: types.AUTHENTICATION_FAILED });
      } else {
        dispatch({
          type: "STORE_USER_DETAILS",
          payload: data
        });
        dispatch({
          type: types.AUTHENTICATION_SUCCEEDED,
          payload: data
        });
      }
    });
};

export { checkAuth };
