import React, { Fragment } from "react";
import { Table } from "reactstrap";

const CrowdContentOrdersPendingOrdersTable = (props) => {
  const { contentOrders } = props;

  const appearShorter = (title) => {
    if (title.length > 35) {
      title = title.substring(0, 32) + "...";
    }
    return title;
  };

  return (
    <Fragment>
      {contentOrders.length === 0 ? (
        <span>There are no orders placed or being written at this time.</span>
      ) : (
        <Table size="sm" hover>
          <tbody>
            {contentOrders.map((order) => (
              <tr>
                <td className="pt-2">
                  <a
                    href={`https://www.crowdcontent.com/client/orders/view/${order.order_id}#summary`}
                    target="_blank"
                  >
                    <span>{order.order_id}</span>
                  </a>
                </td>
                <td className="pt-2">{appearShorter(order.title)} </td>
                <td className="pt-2">{`${order.min_word_count}/${order.max_word_count}`}</td>
                <td className="pt-2">{`$${order.max_cost}`}</td>
                <td className="pt-2">{`${order.quality} Star`}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Fragment>
  );
};

export default CrowdContentOrdersPendingOrdersTable;

// {
//     "order_id": "3179467",
//     "batch_id": "0",
//     "identity_id": "",
//     "project_id": "150688",
//     "title": "Heating Installation in Carmel Valley, CA",
//     "category_label": "home and family",
//     "min_word_count": "400",
//     "max_word_count": "450",
//     "created": "2021-12-27 13:21:53",
//     "modified": "2021-12-28 13:56:24",
//     "package": "website content",
//     "package_type": null,
//     "order_qty": "15",
//     "type": "team",
//     "is_exported": "0",
//     "words_written": null,
//     "field_approval_status": null,
//     "category_id": "1169",
//     "quality": "4",
//     "processing_time": "48",
//     "status": "3",
//     "cpw": "0.120",
//     "max_cost": "54.00",
//     "charge_per_unit": "0",
//     "writer_id": "5109",
//     "writer_screen_name": "Benjamin Daniels",
//     "progress": "0",
//     "file_name": "c7244480d536443060469e5b17154bd5",
//     "file_type": "image/jpeg",
//     "file_ext": "jpg",
//     "hold": "0",
//     "rating_comment": null
// }
