const ADD_NEW_PROJECT_LINK_SET_URL_TO_ADD = "ADD_PROJECT_LINK_SET_URL_TO_ADD";
const ADD_NEW_PROJECT_LINK_SET_SERVICE_TO_ADD =
  "ADD_NEW_PROJECT_LINK_SET_SERVICE_TO_ADD";
const ADD_NEW_PROJECT_LINK_RESET_DATA = "ADD_NEW_PROJECT_LINK_RESET_DATA";
const ADD_NEW_PROJECT_LINK_SET_FORM_VALIDITY =
  "ADD_NEW_PROJECT_LINK_SET_FORM_VALIDITY";
const ADD_NEW_PROJECT_LINK_TO_PROJECT = "ADD_NEW_PROJECT_LINK_TO_PROJECT";

export default {
  ADD_NEW_PROJECT_LINK_SET_URL_TO_ADD,
  ADD_NEW_PROJECT_LINK_SET_SERVICE_TO_ADD,
  ADD_NEW_PROJECT_LINK_TO_PROJECT,
  ADD_NEW_PROJECT_LINK_SET_FORM_VALIDITY,
  ADD_NEW_PROJECT_LINK_RESET_DATA,
};
