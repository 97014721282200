import { default as types } from "./types";
import { combineReducers } from "redux";

const name = (state = null, action) => {
  switch (action.type) {
    // Front-END
    case types.ACTIVATE_MODAL: {
      return action.payload;
    }
    case types.DISMISS_MODAL: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorMessage = (state = null, action) => {
  switch (action.type) {
    case types.SET_MODAL_ERROR_MESSAGE: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({
  name,
  errorMessage,
});
