import { connectWithLifecycle } from "react-lifecycle-component";
import { default as component } from "./UserManagement.jsx";

import { activateModal } from "../common/UI/ActiveModal/duck/actions";

//Local Actions
import { fetchAllUsers } from "./duck/actions";

//Local selectors
import { getLoadingStatus, getUsers } from "./duck/selectors";

const mapStateToProps = (state) => {
  return {
    users: getUsers(state),
    isLoading: getLoadingStatus(state),
  };
};

const mapDispatchToProps = {
  activateModal,
  fetchAllUsers,
};

export default connectWithLifecycle(
  mapStateToProps,
  mapDispatchToProps
)(component);
