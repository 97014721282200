import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";

export default class EditProfileModal extends Component {
  render() {
    let {
      activeModal,
      dismissModal,
      adjustUserName,
      adjustUserPhoneNumber,
      adjustUserProfilePictureUrl,
      updateUserProfile,
      initialValues,
      editedValues,
    } = this.props;
    return (
      <Modal
        isOpen={activeModal === "editProfileModal"}
        toggle={() => dismissModal("editProfileModal")}
      >
        <ModalHeader>Edit User Details</ModalHeader>
        <ModalBody>
          <label>Full Name:</label>
          <Input
            defaultValue={initialValues.personal.name}
            onChange={(event) => adjustUserName(event.target.value)}
          />
          <label>Phone Number:</label>
          <Input
            defaultValue={initialValues.personal.phoneNumber}
            onChange={(event) => adjustUserPhoneNumber(event.target.value)}
          />
          <label>Profile Picture Url:</label>
          <Input
            defaultValue={initialValues.personal.profilePictureUrl}
            onChange={(event) =>
              adjustUserProfilePictureUrl(event.target.value)
            }
          />
        </ModalBody>
        <ModalFooter>
          <Button
            className="mr-1"
            color="success"
            type="submit"
            onClick={() => updateUserProfile(initialValues, editedValues)}
          >
            Submit Changes
          </Button>
          <Button
            color="secondary"
            onClick={() => dismissModal("editProfileModal")}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
