import types from "./types";
import { postData } from "../../../../../utils/fetch-requests";

const updateProjectSpecialNoteContents = (note) => (dispatch) => {
  dispatch({
    type: types.UPDATE_EXISTING_PROJECT_SPECIAL_NOTE_CONTENTS,
    payload: note,
  });
};

const saveProjectSpecialNote = (projectID, newNote) => (dispatch) => {
  const requestData = {
    projectID,
    updatedNote: newNote === "" ? null : newNote,
  };

  postData("/api/project/edit-special-note-on-project", requestData).then(
    (updatedProject) => {
      dispatch({
        type: "RECEIVE_PROJECT_DETAILS",
        payload: updatedProject,
      });
      dispatch({
        type: "DISMISS_MODAL",
      });
      dispatch({
        type: types.EDIT_PROJECT_SPECIAL_NOTE_RESET_DATA,
      });
    }
  );
};

export { updateProjectSpecialNoteContents, saveProjectSpecialNote };
