import types from "./types";

const setFocusedContentOrderForReview = (order) => (dispatch) => {
  dispatch({
    type: types.SET_FOCUSED_CONTENT_ORDER_FOR_REVIEW,
    payload: order,
  });
  dispatch({
    type: "ACTIVATE_MODAL",
    payload: "ViewContentOrderStatsModal",
  });
};

const unsetFocusedContentOrderForReview = () => (dispatch) => {
  dispatch({
    type: types.UNSET_FOCUSED_CONTENT_ORDER_FOR_REVIEW,
    payload: null,
  });
  dispatch({
    type: "DISMISS_MODAL",
    payload: "ViewContentOrderStatsModal",
  });
};

export { setFocusedContentOrderForReview, unsetFocusedContentOrderForReview };
