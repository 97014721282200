import { connect } from "react-redux";
import { default as component } from "./AddNote.jsx";

// UI selectors
import { getActiveModal } from "../../../../common/UI/ActiveModal/duck/selectors";

// UI Actions
import {
  activateModal,
  dismissModal,
} from "../../../../common/UI/ActiveModal/duck/actions";

// Local Actions
import { createNewProjectNote, updateNewNote } from "./duck/actions";

// Local Selectors
import { getProjectNewNoteContents } from "./duck/selectors";
import { getProjectID } from "../../../duck/selectors";

const mapStateToProps = (state) => {
  return {
    activeModal: getActiveModal(state),
    projectID: getProjectID(state),
    newNote: getProjectNewNoteContents(state),
  };
};

const mapDispatchToProps = {
  activateModal,
  dismissModal,
  createNewProjectNote,
  updateNewNote,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
