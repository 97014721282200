const NAME = "addCrowdContentOrderModal";

const minWordRange = [
  300,
  350,
  400,
  450,
  500,
  550,
  600,
  650,
  700,
  750,
  800,
  850,
  900,
  950,
  1000,
  1050,
  1100,
  1150,
  1200,
  1250,
  1300,
  1350,
  1400,
  1450,
  1500,
  1550,
  1600,
  1650,
  1700,
  1750,
  1800,
  1850,
  1900,
  1950,
  2000,
  2050,
  2100,
  2150,
  2200,
  2250,
  2300,
  2350,
  2400,
  2450,
];

const maxWordRange = [
  350,
  400,
  450,
  500,
  550,
  600,
  650,
  700,
  750,
  800,
  850,
  900,
  950,
  1000,
  1050,
  1100,
  1150,
  1200,
  1250,
  1300,
  1350,
  1400,
  1450,
  1500,
  1550,
  1600,
  1650,
  1700,
  1750,
  1800,
  1850,
  1900,
  1950,
  2000,
  2050,
  2100,
  2150,
  2200,
  2250,
  2300,
  2350,
  2400,
  2450,
  2500,
];

export const constants = {
  NAME,
  minWordRange,
  maxWordRange,
};
