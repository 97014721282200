import { postData } from "../../../../../utils/fetch-requests";
import types from "./types";

const setFocusedContentOrderForUploadingToBox = (order) => (dispatch) => {
  dispatch({
    type: types.SET_FOCUSED_ORDER_FOR_UPLOAD_TO_BOX,
    payload: order,
  });
  dispatch({
    type: "ACTIVATE_MODAL",
    payload: "AddContentOrderToBoxFolderModal",
  });
};

const updateContentsForBoxUpload = (event) => (dispatch) => {
  dispatch({
    type: types.UPDATE_CONTENTS_FOR_BOX_UPLOAD,
    payload: event.target.value,
  });
};

const uploadContentsToBox = (contentOrder, editedOrder) => (dispatch) => {
  const dataToSend = {
    contentOrder,
    editedOrder: {
      text: editedOrder,
    },
  };

  dispatch({
    type: types.BEGIN_UPLOAD_OF_CONTENT_ORDER,
  });
  postData("/api/box/upload-content-order-to-box", dataToSend)
    .then((doc) => {
      dispatch({
        type: types.UPLOAD_OF_CONTENT_ORDER_TO_BOX_SUCCESS,
      });
      fetch(`/api/content-orders/fetch-workable-orders`)
        .then((res) => res.json())
        .then((orders) => {
          dispatch({
            type: "CONTENT_DASHBOARD_RECEIVE_CONTENT_ORDERS",
            payload: orders,
          });
          dispatch({
            type: types.UNSET_UPLOADING_CONTENT_ORDERS_FOCUSED_CONTENT_ORDER,
          });
          dispatch({
            type: "DISMISS_MODAL",
          });
        });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: types.UPLOAD_OF_CONTENT_ORDER_TO_BOX_FAILED,
      });
    });
};

const toggleSubmitLocked = (bool) => (dispatch) => {
  dispatch({
    type: types.BOX_CONTENT_ORDER_UPLOAD_TOGGLE_SUBMIT_LOCK,
    payload: bool,
  });
};

export {
  setFocusedContentOrderForUploadingToBox,
  updateContentsForBoxUpload,
  toggleSubmitLocked,
  uploadContentsToBox,
};
