import { connect } from "react-redux";
import { default as component } from "./ProjectsAdministration.jsx";

// local actions
import { fetchAllProjectsAndSort } from "./duck/actions";

// local selectors
import {
  isLoading,
  getActiveProjectCount,
  getCalculatedTotalSpend,
  getCalculatedPortfolioRatio,
  getSortedProjectsByUser,
} from "./duck/selectors";

const MapStateToProps = (state) => {
  return {
    isLoading: isLoading(state),
    projectCount: getActiveProjectCount(state),
    portfolioRatio: getCalculatedPortfolioRatio(state),
    portfolioSpend: getCalculatedTotalSpend(state),
    sortedProjects: getSortedProjectsByUser(state),
  };
};

const MapDispatchToProps = {
  fetchAllProjectsAndSort,
};

export default connect(MapStateToProps, MapDispatchToProps)(component);
