import types from "./types";
import { postData } from "../../utils/fetch-requests";

// Customer

const fetchCustomer = id => dispatch => {
  fetch(`/api/customer/fetch-details/${id}`)
    .then(res => res.json())
    .then(customer => {
      dispatch({
        type: types.RECEIVE_CUSTOMER_DETAILS,
        payload: customer
      });
      dispatch({ type: types.FINISH_CUSTOMER_DETAILS_LOADING });
    });
};

const assignAccountManager = (docID, accountManagerID) => dispatch => {
  const data = {
    _id: docID,
    accountManagerID
  };
  postData("/api/customer/assign-account-manager/", data).then(customer => {
    dispatch({
      type: types.RECEIVE_CUSTOMER_DETAILS,
      payload: customer
    });
    dispatch({
      type: "DISMISS_MODAL"
    });
  });
};

// Customer Industries

const editCustomerIndustries = (industry, industries, id) => dispatch => {
  if (industries.includes(industry)) {
    let newIndustries = [...industries].filter(v => v !== industry);
    const data = {
      _id: id,
      newIndustries
    };
    postData("/api/customer/edit-customer-industries", data).then(res => {
      dispatch({
        type: types.EDIT_CUSTOMER_INDUSTRIES,
        payload: res.doc
      });
    });
  } else {
    let newIndustries = [...industries, industry];
    const data = {
      _id: id,
      newIndustries
    };
    postData("/api/customer/edit-customer-industries", data).then(res => {
      dispatch({
        type: types.EDIT_CUSTOMER_INDUSTRIES,
        payload: res.doc
      });
    });
  }
};

// Customer Details

const editCustomerBusinessName = (newBusinessName, id) => dispatch => {
  const data = {
    _id: id,
    newBusinessName
  };
  postData("/api/customer/edit-customer-business-name", data).then(res => {
    dispatch({
      type: types.EDIT_CUSTOMER_BUSINESS_NAME,
      payload: res.doc
    });
    dispatch({
      type: "DISMISS_MODAL"
    });
  });
};

// Projects

const addProject = (newProject, _id) => dispatch => {
  const data = {
    newProject,
    _id
  };
  postData("/api/project/add-new-project", data).then(customer => {
    dispatch({
      type: "DISMISS_MODAL"
    });
    dispatch({
      type: types.RECEIVE_CUSTOMER_DETAILS,
      payload: customer
    });
  });
};

// Point of Contact

const addPointOfContact = contact => dispatch => {
  dispatch({
    type: types.ADD_POINT_OF_CONTACT,
    payload: contact
  });
};

const editPointOfContact = contact => dispatch => {
  dispatch({
    type: types.EDIT_POINT_OF_CONTACT,
    payload: contact
  });
};

const removePointOfContact = contact => dispatch => {
  dispatch({
    type: types.REMOVE_POINT_OF_CONTACT,
    payload: contact
  });
};

export {
  editCustomerIndustries,
  editCustomerBusinessName,
  assignAccountManager,
  fetchCustomer,
  addProject,
  addPointOfContact,
  editPointOfContact,
  removePointOfContact
};
