import React, { Component, Fragment } from "react";

import {
  Card,
  CardBody,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
} from "reactstrap";
import classNames from "classnames";

import { ContentOrdersCard } from "./components/ContentOrdersCard/";
import { CrowdContentOrdersCard } from "./components/CrowdContentOrdersCard/";
import { ProjectMilestoneTrackerCard } from "./components/ProjectMilestoneTrackerCard/";
import { ProjectLinksCard } from "./components/ProjectLinksCard/";
import { ProjectSpecialNotesCard } from "./components/ProjectSpecialNotesCard/";
import { default as ProjectDetailsCard } from "./components/ProjectDetailsCard.js";

import {
  DEFAULT_TAB,
  CROWD_CONTENT_TAB,
  LINKS_TAB,
  SPECIAL_NOTES_TAB,
} from "./duck/constants";

class ProjectView extends Component {
  componentDidMount() {
    this.props.fetchProject(this.props.match.params.id);
  }

  render() {
    let { isLoading, activeTab, setActiveTab, project } = this.props;
    return (
      <Fragment>
        {isLoading ? (
          <Fragment />
        ) : (
          <div className="row">
            <div className="col-sm-12 col-md-4 p-1">
              <ProjectDetailsCard />
              <ProjectMilestoneTrackerCard />
            </div>
            <div className="col-sm-12 col-md-8 p-2">
              <Card>
                <CardBody>
                  <Nav className="mb-1" tabs>
                    <NavItem>
                      <NavLink
                        className={classNames({
                          active: activeTab === DEFAULT_TAB,
                        })}
                        onClick={() => {
                          setActiveTab(DEFAULT_TAB);
                        }}
                      >
                        <span className="mr-2">
                          TextBroker ({project.contentOrders.length})
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classNames({
                          active: activeTab === CROWD_CONTENT_TAB,
                        })}
                        onClick={() => {
                          setActiveTab(CROWD_CONTENT_TAB);
                        }}
                      >
                        <span className="mr-2">
                          CrowdContent ({project.crowdContentOrders.length})
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classNames({
                          active: activeTab === LINKS_TAB,
                        })}
                        onClick={() => {
                          setActiveTab(LINKS_TAB);
                        }}
                      >
                        <span className="mr-2">Links</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classNames({
                          active: activeTab === SPECIAL_NOTES_TAB,
                        })}
                        onClick={() => {
                          setActiveTab(SPECIAL_NOTES_TAB);
                        }}
                      >
                        <span className="mr-2">Special Notes</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId={DEFAULT_TAB}>
                      <ContentOrdersCard />
                    </TabPane>
                    <TabPane tabId={CROWD_CONTENT_TAB}>
                      <CrowdContentOrdersCard />
                    </TabPane>
                    <TabPane tabId={LINKS_TAB}>
                      <ProjectLinksCard />
                    </TabPane>
                    <TabPane tabId={SPECIAL_NOTES_TAB}>
                      <ProjectSpecialNotesCard />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}
export default ProjectView;
