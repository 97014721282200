import React, { Fragment, useState } from "react";
import { Collapse, Button, CardBody, Card, Table } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Moment from "react-moment";
import {
  faArrowAltCircleRight,
  faCaretRight,
  faCaretDown,
  faClock,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import ContentOrderStatusBadge from "./ContentOrderStatusBadge.js";

const ContentOrderAccordion = (props) => {
  const [collapse, setCollapse] = useState(false);
  // const [status, setStatus] = useState("Closed");
  const toggle = () => setCollapse(!collapse);
  const { orders, setFocusedContentOrderForReview } = props;

  const appearShorter = (title) => {
    if (title.length > 45) {
      title = title.substring(0, 42) + "...";
    }
    return title;
  };

  const determineBackground = () => {
    return !collapse ? "#fef0e5" : "#f77200";
  };

  const determineFontColor = () => {
    return !collapse ? "#000" : "#fff";
  };

  const checkIfAllOrdersAreCompleted = (orders) => {
    let allComplete = true;
    for (let i = 0; i < orders.length; i++) {
      if (orders[i].textBroker.status.status_ID < 7) {
        allComplete = false;
      }
    }
    return allComplete;
  };

  const checkIfAllTimeStamps = (order) => {
    if (
      order.meta.createdOn !== undefined &&
      order.meta.authorWriting !== undefined &&
      order.meta.readyForRYNO !== undefined &&
      order.meta.finishedEditing !== undefined &&
      order.meta.publishedOn !== undefined
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Fragment>
      <Button
        className="btn-block btn-sm text-center"
        onClick={toggle}
        style={{
          marginBottom: "1rem",
          backgroundColor: determineBackground(),
          color: determineFontColor(),
        }}
      >
        <div className="d-flex">
          <div>
            <FontAwesomeIcon
              icon={!collapse ? faCaretRight : faCaretDown}
            ></FontAwesomeIcon>
          </div>
          <div className="flex-grow-1 text-center">{props.date}</div>
          <div className="ml-3">
            {orders.length} Orders
            <Fragment>
              {checkIfAllOrdersAreCompleted(orders) ? (
                <span className="ml-2">
                  <FontAwesomeIcon icon={faCheckCircle} color="success" />
                </span>
              ) : (
                <span className="ml-2">
                  <FontAwesomeIcon icon={faClock} color="secondary" />
                </span>
              )}
            </Fragment>
          </div>
        </div>
      </Button>
      <Collapse isOpen={collapse}>
        <Card className="mb-3">
          <CardBody>
            <Table size="sm">
              <thead>
                <tr>
                  <th>Order Title</th>
                  <th className="text-center">TB Status</th>
                  <th className="text-center">Created On</th>
                  <th className="text-right">Actions</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order) => (
                  <tr>
                    <td>{appearShorter(order.textBroker.orderTitle)}</td>
                    <td className="text-center">
                      <ContentOrderStatusBadge
                        status={order.textBroker.status.status_text}
                      />
                    </td>
                    <td className="text-center">
                      <Moment format="MM/DD/YYYY">
                        {order.meta.createdOn}
                      </Moment>
                    </td>
                    <td className="text-right">
                      <a
                        href={`https://intern.textbroker.com/client/orderadministration/getOrder/item_id:${order.textBroker._id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={faArrowAltCircleRight} />
                      </a>
                      {checkIfAllTimeStamps(order) ? (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="ml-2"
                          onClick={() => setFocusedContentOrderForReview(order)}
                        >
                          <FontAwesomeIcon icon={faClock} />
                        </a>
                      ) : (
                        <Fragment />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Collapse>
    </Fragment>
  );
};

export default ContentOrderAccordion;
