import types from "./types";
import { combineReducers } from "redux";

const view = (state = "milestoneChecklists", action) => {
  switch (action.type) {
    case types.TOGGLE_MILESTONE_TRACKER_VIEW: {
      return action.payload;
    }
    case "DISMISS_MODAL": {
      return "milestoneChecklists";
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({
  view,
});
