import React, { Fragment } from "react";
import { Card, CardBody, Table, Badge } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";

const CustomerResultsTable = ({ customers }) => {
  const getStatusColor = (status) => {
    switch (status) {
      case "Active":
        return "success";
      case "Paused (Request)":
        return "warning";
      case "Paused (Billing)":
        return "warning";
      case "Cancelled":
        return "danger";
      default:
        return "success";
    }
  };

  const getProjectTypeColor = (status) => {
    switch (status) {
      case "IMO":
        return "warning";
      case "RYNO Built":
        return "success";
      default:
        return "secondary";
    }
  };

  return (
    <Fragment>
      {customers.map((customer) => (
        <Card className="mb-1">
          <CardBody>
            <div className="d-flex">
              <div className="flex-grow-1">
                <strong>{customer.local.business.name}</strong> -{" "}
                <a href={`${"/customer/view/" + customer._id}`}>View</a>
              </div>
              {customer.internal.accountManager !== null ? (
                <div className="text-right">
                  SEO Analyst: {customer.internal.accountManager.personal.name}
                </div>
              ) : (
                <div className="text-right">SEO Analyst: None Assigned</div>
              )}
            </div>
            <Table size="sm">
              {customer.local.projects.map((project) => (
                <tr>
                  <td>
                    {project.local.domain} -{" "}
                    <Badge
                      color={getStatusColor(project.meta.status)}
                      className="mr-2"
                    >
                      {project.meta.status}
                    </Badge>
                    <Badge
                      color={
                        project.local.monthlySpend === undefined ||
                        project.local.monthlySpend === null
                          ? "secondary"
                          : "success"
                      }
                      className="mr-2"
                    >
                      {project.local.monthlySpend === null ||
                      project.local.monthlySpend === undefined ? (
                        <span>Spend Not Set</span>
                      ) : (
                        <span>{`$${project.local.monthlySpend}/mo`}</span>
                      )}
                    </Badge>
                    <Badge
                      color={
                        project.local.packageType === undefined ||
                        project.local.packageType === null
                          ? "secondary"
                          : "success"
                      }
                      className="mr-2"
                    >
                      {project.local.packageType === null ||
                      project.local.packageType === undefined ? (
                        <span>SEO Package Not Set</span>
                      ) : (
                        <span>{project.local.packageType}</span>
                      )}
                    </Badge>
                    <Badge
                      color={getProjectTypeColor(project.local.marketingType)}
                      className="mr-2"
                    >
                      {project.local.marketingType === null ||
                      project.local.marketingType === undefined ? (
                        <span>Marketing Type Not Set</span>
                      ) : (
                        <span>{project.local.marketingType}</span>
                      )}
                    </Badge>
                  </td>
                  <td className="text-right">
                    <a href={"/project/view/" + project._id}>
                      <FontAwesomeIcon icon={faArrowAltCircleRight} />
                    </a>
                  </td>
                </tr>
              ))}
            </Table>
          </CardBody>
        </Card>
      ))}
    </Fragment>
  );
};

export default CustomerResultsTable;
