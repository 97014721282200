export function checkAuthLevel(userLevel, requiredLevel) {
  let accessLevel = 0;
  switch (userLevel) {
    case "Super Admin":
      accessLevel = 4;
      if (accessLevel >= requiredLevel) {
        return true;
      } else {
        return false;
      }
    case "Admin":
      accessLevel = 3;
      if (accessLevel >= requiredLevel) {
        return true;
      } else {
        return false;
      }
    case "Editor":
      accessLevel = 2;
      if (accessLevel >= requiredLevel) {
        return true;
      } else {
        return false;
      }
    case "User":
      accessLevel = 1;
      if (accessLevel >= requiredLevel) {
        return true;
      } else {
        return false;
      }
    case "General":
      accessLevel = 1;
      if (accessLevel >= requiredLevel) {
        return true;
      } else {
        return false;
      }
    default:
      break;
  }
}
