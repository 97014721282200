import React, { Fragment } from "react";
import { Card, Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const CustomerAccountManagersCard = (props) => {
  console.log(props);
  const { activateModal } = props;
  const { accountManager } = props.customer.internal;
  return (
    <Card className="p-2 mt-1">
      <div className="d-flex">
        <div className="flex-grow-1 text-center pb-1">Assigned SEO Analyst</div>
        {accountManager === null ? (
          <span className="text-right">
            <FontAwesomeIcon
              icon={faPlus}
              onClick={() => activateModal("editCustomerAccountManager")}
            />
          </span>
        ) : (
          <Fragment />
        )}
      </div>
      <Table size="sm" hover>
        <tbody>
          {accountManager !== null ? (
            <tr onClick={() => activateModal("editCustomerAccountManager")}>
              <td class="text-center">{accountManager.personal.name}</td>
            </tr>
          ) : (
            <tr onClick={() => activateModal("editCustomerAccountManager")}>
              <td class="text-center">No SEO Analyst assigned.</td>
            </tr>
          )}
        </tbody>
      </Table>
    </Card>
  );
};

export default CustomerAccountManagersCard;
