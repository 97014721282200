const BEGIN_PROJECT_DETAILS_LOADING = "BEGIN_PROJECT_DETAILS_LOADING";
const FINISH_PROJECT_DETAILS_LOADING = "FINISH_PROJECT_DETAILS_LOADING";
const RECEIVE_PROJECT_DETAILS = "RECEIVE_PROJECT_DETAILS";
const PROJECT_VIEW_SET_ACTIVE_TAB = "PROJECT_VIEW_SET_ACTIVE_TAB";

export default {
  BEGIN_PROJECT_DETAILS_LOADING,
  FINISH_PROJECT_DETAILS_LOADING,
  RECEIVE_PROJECT_DETAILS,
  PROJECT_VIEW_SET_ACTIVE_TAB
};
