export const BEGIN_LOADING_USER_PROFILE = "BEGIN_LOADING_USER_PROFILE";
export const FINISH_LOADING_USER_PROFILE = "FINISH_LOADING_USER_PROFILE";
export const BEGIN_LOADING_USER_CLIENTS = "BEGIN_LOADING_USER_CLIENTS";
export const FINISH_LOADING_USER_CLIENTS = "FINISH_LOADING_USER_CLIENTS";
export const UPDATE_PROFILE_PAGE_CLIENTS_FOR_LOGGED_IN_USER =
  "UPDATE_PROFILE_PAGE_CLIENTS_FOR_LOGGED_IN_USER";
export const LOAD_USER_DETAILS = "LOAD_USER_DETAILS";

export default {
  BEGIN_LOADING_USER_PROFILE,
  FINISH_LOADING_USER_PROFILE,
  BEGIN_LOADING_USER_CLIENTS,
  FINISH_LOADING_USER_CLIENTS,
  UPDATE_PROFILE_PAGE_CLIENTS_FOR_LOGGED_IN_USER,
  LOAD_USER_DETAILS,
};
