import React from "react";
import { Card, Table } from "reactstrap";
import { default as ProjectMilestoneTrackerModal } from "../modals/ProjectMilestoneTracker/ProjectMilestoneTracker.js";

const ProjectMilestoneTrackerCard = (props) => {
  const { activateModal, milestoneCategories } = props;
  return (
    <Card className="mt-1">
      <div className="d-flex">
        <div className="flex-grow-1 text-center pb-2">
          <strong>Progress Tracking</strong>
        </div>
      </div>
      <div className="m-2">
        <Table size="sm" hover>
          <tbody>
            <tr onClick={() => activateModal("ProjectMilestoneTrackerModal")}>
              <td>
                <div className="d-flex">
                  <div className="flex-grow-1">Milestones</div>
                  <div className="text-right">{milestoneCategories.length}</div>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <ProjectMilestoneTrackerModal />
    </Card>
  );
};

export default ProjectMilestoneTrackerCard;
