import React, { Fragment, useState } from "react";
import { Card, CardBody, Collapse, Table } from "reactstrap";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faCaretDown } from "@fortawesome/free-solid-svg-icons";

const AccountManagerProjectAccordion = (props) => {
  const [collapse, setCollapse] = useState(false);
  const toggle = () => setCollapse(!collapse);

  const calculatePortfolioSpend = (projects) => {
    let monthlySpend = 0;
    projects.forEach((project) => {
      if (project.local.monthlySpend === undefined) {
        monthlySpend += 0;
      } else {
        monthlySpend += project.local.monthlySpend;
      }
    });
    return monthlySpend;
  };

  const getCalculatedPortfolioRatio = (projects) => {
    let imoCount = 0;
    let rynoCount = 0;
    projects.forEach((project) => {
      switch (project.local.marketingType) {
        case "IMO":
          imoCount += 1;
          break;
        case "RYNO Built":
          rynoCount += 1;
          break;
        default:
          break;
      }
    });
    return { imoCount, rynoCount };
  };

  const determineBackground = () => {
    return !collapse ? "#fef0e5" : "#f77200";
  };

  const determineFontColor = () => {
    return !collapse ? "#000" : "#fff";
  };

  const { projects, accountManager } = props;

  const projectRatio = getCalculatedPortfolioRatio(projects);

  return (
    <Fragment>
      <Button
        className="btn-block btn-sm text-center"
        onClick={toggle}
        style={{
          marginBottom: "1rem",
          backgroundColor: determineBackground(),
          color: determineFontColor(),
        }}
      >
        <div className="d-flex">
          <div className="mr-2">
            <FontAwesomeIcon
              icon={!collapse ? faCaretRight : faCaretDown}
            ></FontAwesomeIcon>
          </div>
          <div className="flex-grow-1 text-left">{accountManager}</div>
          <div className="text-left mr-3">{`IMO: ${projectRatio.imoCount} RYNO Built: ${projectRatio.rynoCount}`}</div>
          <div className="ml-3">
            {`Manages $${calculatePortfolioSpend(projects)}/mo`}
          </div>
        </div>
      </Button>
      <Collapse isOpen={collapse}>
        <Card className="mb-3">
          <CardBody>
            <Table size="sm" hover>
              <thead>
                <tr>
                  <th>Project Domain</th>
                  <th className="text-center">SEO Package</th>
                  <th className="text-center">Project Type</th>
                  <th className="text-right">Spend</th>
                </tr>
              </thead>
              <tbody key="orders">
                {projects.map((project) => (
                  <tr key={`project-${project._id}`}>
                    <td>
                      <a href={`/project/view/${project._id}`} target="_blank">
                        {project.local.domain}
                      </a>
                    </td>
                    <td className="text-center">
                      {project.local.packageType === undefined ? (
                        <span>Not Set</span>
                      ) : (
                        <span>{project.local.packageType}</span>
                      )}
                    </td>
                    <td className="text-center">
                      {project.local.marketingType === undefined ? (
                        <span>Not Set</span>
                      ) : (
                        <span>{project.local.marketingType}</span>
                      )}
                    </td>
                    <td className="text-right">
                      {project.local.monthlySpend === undefined ? (
                        <span>Not Set</span>
                      ) : (
                        <span>${project.local.monthlySpend}</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Collapse>
    </Fragment>
  );
};

export default AccountManagerProjectAccordion;
