import React, { Fragment } from "react";
import { Table, Button } from "reactstrap";

const ContentOrdersNeedsReviewTable = (props) => {
  const { contentOrders, setFocusedContentOrder } = props;

  const appearShorter = (title) => {
    if (title.length > 45) {
      title = title.substring(0, 44) + "...";
    }
    return title;
  };

  return (
    <Fragment>
      {contentOrders.length === 0 ? (
        <span>
          There are no orders needing to be edited/approved at this time.
        </span>
      ) : (
        <Table size="sm" hover>
          <tbody>
            {contentOrders.map((order) => (
              <tr>
                <td className="pt-2">
                  <a
                    href={`https://intern.textbroker.com/client/orderadministration/getOrder/item_id:${order.textBroker._id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {order.textBroker._id}
                  </a>
                </td>
                <td className="pt-2">
                  <a href={`/project/view/${order.meta.project._id}`}>
                    {order.meta.project.meta.parent.local.business.name}
                  </a>
                </td>
                <td className="pt-2">
                  {appearShorter(order.textBroker.orderTitle)}
                </td>
                <td className="text-right">
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => setFocusedContentOrder(order)}
                  >
                    Review Now
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Fragment>
  );
};

export default ContentOrdersNeedsReviewTable;
