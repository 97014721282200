import types from "./types";
import { postData } from "../../utils/fetch-requests";

// fetch places by state (also province in when speaking canadian aye)
const fetchPlacesByState = state => dispatch => {
  fetch(`/api/places/fetch-by-state/${state}`)
    .then(res => res.json())
    .then(places => {
      dispatch({
        type: types.RECEIVE_TARGETABLE_PLACES,
        payload: places.sort((a, b) =>
          a.details.name.localeCompare(b.details.name)
        )
      });
      dispatch({ type: types.FINISH_LOADING_TARGETABLE_PLACES });
    });
};

const setTargetablePlace = (id, modal) => dispatch => {
  fetch(`/api/places/fetch-by-id/${id}`)
    .then(res => res.json())
    .then(place => {
      dispatch({
        type: types.SET_TARGETABLE_PLACE,
        payload: place
      });
      dispatch({
        type: "ACTIVATE_MODAL",
        payload: modal
      });
    });
};

const assignProjectToPlace = (projectID, placeID) => dispatch => {
  const data = {
    projectID,
    placeID
  };
  postData("/api/places/assign-project-to-place", data).then(place => {
    dispatch({
      type: types.SET_TARGETABLE_PLACE,
      payload: place
    });
    fetch(`/api/places/fetch-by-state/${place.details.parent}`)
      .then(res => res.json())
      .then(places => {
        dispatch({
          type: types.RECEIVE_TARGETABLE_PLACES,
          payload: places.sort((a, b) =>
            a.details.name.localeCompare(b.details.name)
          )
        });
        dispatch({ type: types.FINISH_LOADING_TARGETABLE_PLACES });
      });
  });
  postData("/api/project/assign-place-to-project", data);
};

const removeProjectFromPlace = (projectID, placeID) => dispatch => {
  const data = {
    projectID,
    placeID
  };
  postData(`/api/places/remove-project-from-place`, data).then(place => {
    dispatch({
      type: types.SET_TARGETABLE_PLACE,
      payload: place
    });
    fetch(`/api/places/fetch-by-state/${place.details.parent}`)
      .then(res => res.json())
      .then(places => {
        dispatch({
          type: types.RECEIVE_TARGETABLE_PLACES,
          payload: places.sort((a, b) =>
            a.details.name.localeCompare(b.details.name)
          )
        });
        dispatch({ type: types.FINISH_LOADING_TARGETABLE_PLACES });
      });
    postData("/api/project/remove-place-from-project", data);
  });
};

const emptyOutPlaces = () => dispatch => {
  dispatch({
    type: types.EMPTY_OUT_PLACES
  });
};

export {
  fetchPlacesByState,
  setTargetablePlace,
  assignProjectToPlace,
  removeProjectFromPlace,
  emptyOutPlaces
};
