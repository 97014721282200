import { connect } from "react-redux";
import { default as component } from "./CrowdContentOrderKeywords.jsx";

// Local Selectors
import { getCrowdContentOrderKeywords } from "./duck/selectors";

// Local Actions
import {
  addKeywordToCrowdContentOrder,
  removeKeywordsFromCrowdContentOrder,
} from "./duck/actions";

const mapStateToProps = (state) => {
  return {
    keywords: getCrowdContentOrderKeywords(state),
  };
};

const mapDispatchToProps = {
  addKeywordToCrowdContentOrder,
  removeKeywordsFromCrowdContentOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
