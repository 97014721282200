import React, { Component, Fragment } from "react";
import { InputGroup, InputGroupAddon, InputGroupText, Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faCheck } from "@fortawesome/free-solid-svg-icons";
import { DebounceInput } from "react-debounce-input";
import { postData } from "../../../../../../../../utils/fetch-requests";

class ViewAssignedProjectsSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchedCustomers: []
    };
    console.log(this.props);
  }

  searchForBusiness = event => {
    const searchQuery = event.target.value;
    postData("/api/customer/search-for-customers", { searchQuery }).then(
      customers =>
        this.setState({
          searchedCustomers: customers
        })
    );
  };

  render() {
    const { searchedCustomers } = this.state;
    const { targetedPlaceID, assignedProjects } = this.props;
    const { assignProjectToPlace, removeProjectFromPlace } = this.props;
    return (
      <Fragment>
        <div className="mb-2">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <FontAwesomeIcon icon={faUser} />
              </InputGroupText>
            </InputGroupAddon>
            <DebounceInput
              minLength={3}
              className="form-control"
              debounceTimeout={500}
              placeholder="Company Name"
              onChange={event => this.searchForBusiness(event)}
              autoComplete="disabled"
            />
          </InputGroup>
        </div>
        {searchedCustomers.length === 0 ? (
          <span>No companies to show.</span>
        ) : (
          <div>
            <Table size="sm" hover>
              <tbody>
                {searchedCustomers.map(customer => (
                  <div className="p-3">
                    <div className="row">
                      <strong>{customer.local.business.name}</strong>
                    </div>
                    <div className="row">
                      <Table>
                        <tbody>
                          {customer.local.projects.map(project => (
                            <Fragment>
                              {assignedProjects.includes(project._id) ? (
                                <tr
                                  onClick={() =>
                                    removeProjectFromPlace(
                                      project._id,
                                      targetedPlaceID
                                    )
                                  }
                                >
                                  <td>{project.local.domain}</td>
                                  <td className="text-right">
                                    <FontAwesomeIcon
                                      color="green"
                                      icon={faCheck}
                                    />
                                  </td>
                                </tr>
                              ) : (
                                <tr
                                  onClick={() =>
                                    assignProjectToPlace(
                                      project._id,
                                      targetedPlaceID
                                    )
                                  }
                                >
                                  <td>{project.local.domain}</td>
                                  <td></td>
                                </tr>
                              )}
                            </Fragment>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </Fragment>
    );
  }
}

export default ViewAssignedProjectsSearch;
