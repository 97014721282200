import React, { Fragment } from "react";
import { MODAL_NAME } from "./duck/constants";

import {
  Alert,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
} from "reactstrap";

const AddKeywordGeneratorListModal = (props) => {
  const {
    dismissModal,
    activeModal,
    newKeywordListTitle,
    newKeywordListKeywords,
    setTitleForKeywordList,
    setKeywordsForKeywordList,
    addKeywordGeneratorList,
    checkAddKeywordGeneratorListFormValidity,
    formValidity,
  } = props;
  return (
    <Fragment>
      <Modal
        isOpen={activeModal === MODAL_NAME}
        toggle={() => dismissModal(MODAL_NAME)}
      >
        <ModalHeader>Add New Keyword List</ModalHeader>
        <ModalBody>
          <Alert color="info">
            Phrases must be separated from each other with a | character.
            <br />
            Shortcodes available: [City] [State] [Abbr]
            <br />
            Example: Best Plumber in [City], [Abbr]
            <br />
            Example Output: Best Plumber in Phoenix, AZ
          </Alert>
          <Form
            onChange={() =>
              checkAddKeywordGeneratorListFormValidity(
                newKeywordListTitle,
                newKeywordListKeywords
              )
            }
          >
            <Input
              type="text"
              placeHolder="Keyword List Title"
              className="form-control mt-2"
              onChange={(event) => setTitleForKeywordList(event.target.value)}
            />
            <Input
              type="textarea"
              placeHolder="Comma, Separated, Keywords go Here!"
              rows="8"
              className="form-control mt-2"
              onChange={(event) =>
                setKeywordsForKeywordList(event.target.value)
              }
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            onClick={() =>
              addKeywordGeneratorList(
                newKeywordListTitle,
                newKeywordListKeywords
              )
            }
            disabled={formValidity}
          >
            Add New Keyword List
          </Button>
          <Button color="secondary" onClick={() => dismissModal(MODAL_NAME)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default AddKeywordGeneratorListModal;
