import { NAME } from "./constants";

export const getSearchResults = (state) => {
  return state.pages.projectView.modalData[NAME].searchResults;
};

export const getSearchResultsExistingPlaces = (state) => {
  return state.pages.projectView.modalData[NAME].searchResultsExistingPlaces;
};

export const getExistingPlacesSearchQuery = (state) => {
  return state.pages.projectView.modalData[NAME].searchQueryExistingPlaces;
};

export const getRecentlyAddedPlaces = (state) => {
  return state.pages.projectView.modalData[NAME].addedMissingPlaces;
};

export const getHasBeenSearched = (state) => {
  return state.pages.projectView.modalData[NAME].hasSearched;
};

export const getActiveView = (state) => {
  return state.pages.projectView.modalData[NAME].activeView;
};

export const getActiveTab = (state) => {
  return state.pages.projectView.modalData[NAME].activeTab;
};
