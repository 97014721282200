import React, { Component, Fragment } from "react";

import { default as CustomerDetailsCard } from "./components/CustomerDetailsCard.js";
import { default as CustomerAccountManagersCard } from "./components/CustomerAccountManagersCard.js";
import { default as CustomerProjectsCard } from "./components/CustomerProjectsCard.js";

import {
  AddProjectModal,
  EditCustomerIndustriesModal,
  EditCustomerBusinessNameModal,
  EditCustomerAccountManagersModal,
} from "./components/modals";

class CustomerViewPage extends Component {
  componentDidMount() {
    this.props.fetchCustomer(this.props.match.params.id);
  }

  render() {
    let { isLoading } = this.props;
    return (
      <Fragment>
        {isLoading ? (
          <Fragment />
        ) : (
          <div className="row">
            <div className="col-sm-12 col-md-4 p-1">
              <CustomerDetailsCard />
              <CustomerAccountManagersCard />
            </div>
            <div className="col-sm-12 col-md-8 p-1">
              <CustomerProjectsCard />
            </div>
            <EditCustomerIndustriesModal />
            <EditCustomerBusinessNameModal />
            <EditCustomerAccountManagersModal />
            <AddProjectModal />
          </div>
        )}
      </Fragment>
    );
  }
}

export default CustomerViewPage;
