import React, { Fragment } from "react";
import { MODAL_NAME } from "./duck/constants";

import {
  Alert,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

const AddNoteModal = (props) => {
  // Globals
  const { activeModal, dismissModal } = props;
  // Local from state
  const { projectID } = props;
  const { focusedLink } = props;
  // Action Triggers
  const { removeProjectLink } = props;
  return (
    <Fragment>
      <Modal
        isOpen={activeModal === MODAL_NAME}
        toggle={() => dismissModal(MODAL_NAME)}
      >
        <ModalHeader>Add New Project Link</ModalHeader>
        <ModalBody>
          <Alert color="danger" className="text-center">
            <strong>You are about to remove the following URL</strong>
            <br />
            <span style={{ fontSize: "18px" }}>{focusedLink.service}</span>
            <br />
            <span>{focusedLink.url}</span>
          </Alert>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => removeProjectLink(projectID, focusedLink)}
          >
            Remove Link
          </Button>
          <Button color="secondary" onClick={() => dismissModal(MODAL_NAME)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default AddNoteModal;
