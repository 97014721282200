import { connect } from "react-redux";
import { default as component } from "./CustomerResultsTable.jsx";
import { getRecentCustomers } from "../duck/selectors";

const mapStateToProps = state => {
  return {
    customers: getRecentCustomers(state)
  };
};

export default connect(mapStateToProps, null)(component);
