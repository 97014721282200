import { connect } from "react-redux";
import { default as component } from "./ProjectLinksCard.jsx";

// UI actions
import { activateModal } from "../../../common/UI/ActiveModal/duck/actions";

// local selectors
import { getProjectLinks } from "./duck/selectors";
import { setFocusedProjectLinkForRemoval } from "../modals/RemoveProjectLink/duck/actions";

const mapStateToProps = (state) => {
  return {
    links: getProjectLinks(state),
  };
};

const mapDispatchToProps = {
  activateModal,
  setFocusedProjectLinkForRemoval,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
