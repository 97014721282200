import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

export const generateTableRowsForCategories = (
  category,
  projectID,
  completedMilestones,
  markMilestoneAsComplete,
  markMilestoneAsIncomplete
) => {
  const determineOptions = (category) => {
    switch (category) {
      case "HVAC - Heating": {
        return [
          "Heating Repair",
          "Heating Installation",
          "Heating Maintenance",
          "Furnace and/or Heat Pumps",
        ];
      }
      case "HVAC - Cooling": {
        return [
          "AC Repair",
          "AC Installation",
          "AC Maintenance",
          "Ductless Mini-splits",
        ];
      }
      case "HVAC - Indoor Air Quality": {
        return ["Indoor Air Quality", "Duct Cleaning"];
      }
      case "HVAC - Agreements": {
        return ["Maintenance Agreement"];
      }
      case "Plumbing - Water Heater & Softeners": {
        return ["Water Heaters", "Water Softeners", "Water Filtration Systems"];
      }
      case "Plumbing - Drains": {
        return ["Drain Cleaning", "Drain Repair"];
      }
      case "Plumbing - Toilets": {
        return ["Toilet Repair", "Toilet Installation"];
      }
      case "Plumbing - Septic & Sewer": {
        return ["Septic Services", "Sewer Services"];
      }
      case "Plumbing - Showers": {
        return ["Shower Repair", "Shower Installation"];
      }
      case "Plumbing - Kitchen": {
        return [
          "Kitchen Sink Installation",
          "Faucet Repair/Installation",
          "Garbage Disposals",
        ];
      }
      case "Electrical - Wiring": {
        return ["Whole-Home Rewiring", "Electrical Upgrading", "Remodels"];
      }
      case "Electrical - Detectors": {
        return ["Smoke Alarms", "Carbon Monoxide"];
      }
      case "Electrical - Repair & Replacement": {
        return [
          "Panel Replacement",
          "Electrical Repair",
          "Whole-House Fan Installation",
          "Electrical Baseboard Installation / Replacement",
        ];
      }
      case "Electrical - Generators": {
        return ["Whole-House Surge Protectors", "Generators"];
      }
      case "Electrical - Switches & Lighting": {
        return [
          "Indoor/Outdoor Lighting",
          "Switch and Outlet Repair/Installation",
        ];
      }
      case "Solar Panels": {
        return ["Solar Panel Installation"];
      }
      case "TSEO": {
        return [
          "Preliminary Audit",
          "Backlink Audit",
          "SEM Rush - Basics Fixed",
        ];
      }
      default: {
        return [];
      }
    }
  };

  const determineStyle = (option, completedMilestones) => {
    if (completedMilestones.includes(option)) {
      return { backgroundColor: "#90EE90" };
    } else {
      return {};
    }
  };

  const options = determineOptions(category);
  return (
    <Fragment>
      {options.length > 0 ? (
        <Fragment>
          {options.map((option) => (
            <tr>
              <td
                onClick={() =>
                  completedMilestones.includes(option)
                    ? markMilestoneAsIncomplete(projectID, option)
                    : markMilestoneAsComplete(projectID, option)
                }
                style={determineStyle(option, completedMilestones)}
              >
                <div className="d-flex">
                  <div className="flex-grow-1">{option}</div>
                  {completedMilestones.includes(option) && (
                    <div>
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </Fragment>
      ) : (
        <span>{category} Checklist has no options set.</span>
      )}
    </Fragment>
  );
};
