import { combineReducers } from "redux";
import types from "./types";

const h1 = (state = "", action) => {
  switch (action.type) {
    case types.CONTENT_ORDER_SET_META_H1: {
      return action.payload;
    }
    case types.NEW_CONTENT_ORDER_RESET_META: {
      return "";
    }
    default: {
      return state;
    }
  }
};

const title = (state = "", action) => {
  switch (action.type) {
    case types.CONTENT_ORDER_SET_META_TITLE: {
      return action.payload;
    }
    case types.NEW_CONTENT_ORDER_RESET_META: {
      return "";
    }
    default: {
      return state;
    }
  }
};

const description = (state = "", action) => {
  switch (action.type) {
    case types.CONTENT_ORDER_SET_META_DESCRIPTION: {
      return action.payload;
    }
    case types.NEW_CONTENT_ORDER_RESET_META: {
      return "";
    }
    default: {
      return state;
    }
  }
};

const slug = (state = "", action) => {
  switch (action.type) {
    case types.CONTENT_ORDER_SET_META_SLUG: {
      return action.payload;
    }
    case types.NEW_CONTENT_ORDER_RESET_META: {
      return "";
    }
    default: {
      return state;
    }
  }
};

const formValidity = (state = false, action) => {
  switch (action.type) {
    case types.CONTENT_ORDER_SET_META_VALIDITY: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({
  h1,
  title,
  description,
  slug,
  formValidity,
});
