import React, { Fragment } from "react";
import { MODAL_NAME } from "./duck/constants";

import {
  Button,
  Input,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

const EditProjectSpecialNoteModal = (props) => {
  // Globals
  const { activeModal, dismissModal } = props;
  // Local from state
  const { projectID } = props;
  // Action Triggers
  const {
    saveProjectSpecialNote,
    updateProjectSpecialNoteContents,
    currentSpecialNote,
    editedSpecialNote,
  } = props;
  return (
    <Fragment>
      <Modal
        isOpen={activeModal === MODAL_NAME}
        toggle={() => dismissModal(MODAL_NAME)}
      >
        <ModalHeader>Edit Project Special Note</ModalHeader>
        <ModalBody>
          <Form>
            <Input
              type="textarea"
              height="300px"
              className="form-control"
              defaultValue={currentSpecialNote}
              value={editedSpecialNote}
              onChange={(event) =>
                updateProjectSpecialNoteContents(event.target.value)
              }
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            onClick={() => saveProjectSpecialNote(projectID, editedSpecialNote)}
          >
            Save Edits
          </Button>
          <Button color="secondary" onClick={() => dismissModal(MODAL_NAME)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default EditProjectSpecialNoteModal;
