import React from "react";
import { Table } from "reactstrap";

const UserDetails = (props) => {
  console.log(props);
  const { name, phoneNumber, profilePictureUrl } = props.personalDetails;
  return (
    <div className="text-center">
      {profilePictureUrl === null || profilePictureUrl === "" ? (
        <span>not set</span>
      ) : (
        <img
          className="rounded-circle"
          src={profilePictureUrl}
          alt=""
          width="250"
        />
      )}
      <Table className="table=sm mt-2">
        <tbody>
          <tr>
            <td className="text-left">Name:</td>
            <td>
              {name === null ? (
                <span className="text-muted text-right">Not Set</span>
              ) : (
                <span className="text-right">{name}</span>
              )}
            </td>
          </tr>
          <tr>
            <td className="text-left">Phone Number:</td>
            <td>
              {phoneNumber === null ? (
                <span className="text-muted text-right">Not Set</span>
              ) : (
                <span className="text-right">{phoneNumber}</span>
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default UserDetails;
