import { connect } from "react-redux";
import { default as component } from "./StateDrilldownCard.jsx";

// UI Selectors
import { getActiveModal } from "../../../common/UI/ActiveModal/duck/selectors";
// UI Actions
import { activateModal } from "../../../common/UI/ActiveModal/duck/actions";

// Local Actions
import { fetchPlacesByState, emptyOutPlaces } from "../../duck/actions";

const mapStateToProps = state => {
  return {
    activeModal: getActiveModal(state)
  };
};

const mapDispatchToProps = {
  activateModal,
  fetchPlacesByState,
  emptyOutPlaces
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
