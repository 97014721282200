import types from "./types";
// import { default as addUserModal } from "../components/modals/PickPlaces/duck/reducer.js";
import { combineReducers } from "redux";

const isLoading = (state = true, action) => {
  switch (action.type) {
    case types.USER_MANAGEMENT_RECEIVE_ALL_USERS: {
      return false;
    }
    case types.USER_MANAGEMENT_BEGIN_FETCHING_USERS: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const users = (state = [], action) => {
  switch (action.type) {
    case types.USER_MANAGEMENT_RECEIVE_ALL_USERS: {
      console.log(typeof action.payload);
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

const activeTab = (state = "defaultTab", action) => {
  switch (action.type) {
    case types.USER_MANAGEMENT_SET_ACTIVE_TAB: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

const modalData = combineReducers({});

export default combineReducers({
  isLoading,
  users,
  activeTab,
  modalData
});
