const KEYWORD_GENERATOR_TOOL_RECEIVE_KEYWORD_LISTS =
  "KEYWORD_GENERATOR_TOOL_RECEIVE_KEYWORD_LISTS";

const KEYWORD_GENERATOR_TOOL_BEGIN_LOADING =
  "KEYWORD_GENERATOR_TOOL_BEGIN_LOADING";
const KEYWORD_GENERATOR_TOOL_FINISH_LOADING =
  "KEYWORD_GENERATOR_TOOL_FINISH_LOADING";

const KEYWORD_GENERATOR_TOOL_ADD_GENERATED_KEYWORDS =
  "KEYWORD_GENERATOR_TOOL_SET_GENERATED_KEYWORDS";

const KEYWORD_GENERATOR_TOOL_SET_ACTIVE_TAB =
  "KEYWORD_GENERATOR_TOOL_SET_ACTIVE_TAB";

const KEYWORD_GENERATOR_TOOL_SET_SPLIT_CITIES =
  "KEYWORD_GENERATOR_TOOL_SET_SPLIT_CITIES";

const KEYWORD_GENERATOR_TOOL_SET_STATE_ABBREVIATION =
  "KEYWORD_GENERATOR_TOOL_SET_STATE_ABBREVIATION";

const KEYWORD_GENERATOR_TOOL_MARK_KEYWORD_GROUP_AS_USED =
  "KEYWORD_GENERATOR_TOOL_MARK_KEYWORD_GROUP_AS_USED";

export default {
  KEYWORD_GENERATOR_TOOL_BEGIN_LOADING,
  KEYWORD_GENERATOR_TOOL_FINISH_LOADING,
  KEYWORD_GENERATOR_TOOL_RECEIVE_KEYWORD_LISTS,
  KEYWORD_GENERATOR_TOOL_ADD_GENERATED_KEYWORDS,
  KEYWORD_GENERATOR_TOOL_SET_ACTIVE_TAB,
  KEYWORD_GENERATOR_TOOL_SET_SPLIT_CITIES,
  KEYWORD_GENERATOR_TOOL_SET_STATE_ABBREVIATION,
  KEYWORD_GENERATOR_TOOL_MARK_KEYWORD_GROUP_AS_USED,
};
