import types from "./types";
import { combineReducers } from "redux";

const service = (state = "0", action) => {
  switch (action.type) {
    case types.ADD_NEW_PROJECT_LINK_SET_SERVICE_TO_ADD: {
      return action.payload;
    }
    case types.ADD_NEW_PROJECT_LINK_RESET_DATA: {
      return "0";
    }
    default: {
      return state;
    }
  }
};

const url = (state = "", action) => {
  switch (action.type) {
    case types.ADD_NEW_PROJECT_LINK_SET_URL_TO_ADD: {
      return action.payload;
    }
    case types.ADD_NEW_PROJECT_LINK_RESET_DATA: {
      return "";
    }
    default: {
      return state;
    }
  }
};

const formValidity = (state = false, action) => {
  switch (action.type) {
    case types.ADD_NEW_PROJECT_LINK_SET_FORM_VALIDITY: {
      return action.payload;
    }
    case types.ADD_NEW_PROJECT_LINK_RESET_DATA: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const displayWarning = (state = false, action) => {
  switch (action.type) {
    case types.ADD_NEW_PROJECT_LINK_DISPLAY_WARNING: {
      return action.payload;
    }
    case types.ADD_NEW_PROJECT_LINK_RESET_DATA: {
      return false;
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({
  url,
  service,
  formValidity,
  displayWarning,
});
