import types from "./types";
import { combineReducers } from "redux";

const focusedLink = (state = {}, action) => {
  switch (action.type) {
    case types.REMOVE_PROJECT_LINK_SET_FOCUSED_LINK: {
      return action.payload;
    }
    case "DISMISS_MODAL":
    case types.REMOVE_PROJECT_LINK_RESET_DATA: {
      return {};
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({
  focusedLink,
});
