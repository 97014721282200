import { connect } from "react-redux";
import { default as component } from "./ViewContentOrderStats.jsx";

// Local Actions
import { unsetFocusedContentOrderForReview } from "./duck/actions";

// UI Selectors
import { getActiveModal } from "../../../../common/UI/ActiveModal/duck/selectors";

// Local Selectors
import { getFocusedContentOrder } from "./duck/selectors.js";

const mapStateToProps = (state) => {
  return {
    activeModal: getActiveModal(state),
    focusedContentOrder: getFocusedContentOrder(state),
  };
};

const mapDispatchToProps = {
  unsetFocusedContentOrderForReview,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
