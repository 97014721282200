import { NAME } from "./constants";

const select = key => state => state.ui[NAME][key];

export const getActiveModal = select("name");
export const getModalErrorMessage = select("errorMessage");

export const isModalActive = name => {
  return name === select("name");
};
