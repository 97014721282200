import React from "react";
import {
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const MainNavigationComponent = (props) => {
  const { toggleCollapse, isCollapsed, loggedInUserEmail } = props;

  const showAdminTab = (email) => {
    if (
      email === "iboda@rynoss.com" ||
      email === "jvenidis@rynoss.com" ||
      email === "zwest@rynoss.com" ||
      email === "lmccoy@rynoss.com"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isIsaac = (email) => {
    return email === "iboda@rynoss.com";
  };

  return (
    <Navbar color="light" light expand="md">
      <NavbarBrand href="/profile">SEO Dash</NavbarBrand>
      <NavbarToggler onClick={() => toggleCollapse} />
      <Collapse isOpen={isCollapsed} navbar>
        <Nav className="ml-auto" navbar>
          <NavItem className="mr-1">
            <NavLink href="/customers/">Clients</NavLink>
          </NavItem>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              Content
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem>
                <NavLink href="/content/textbroker/">TextBroker</NavLink>
              </DropdownItem>
              <DropdownItem>
                <NavLink href="/content/crowdcontent/">Crowd Content</NavLink>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              Tools
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem>
                <NavLink href="/tools/keyword-generator/">
                  Keyword Generator
                </NavLink>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          {showAdminTab(loggedInUserEmail) && (
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Administration
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  <NavLink href="/projects/administration">
                    Projects Administration
                  </NavLink>
                </DropdownItem>
                {isIsaac(loggedInUserEmail) && (
                  <DropdownItem>
                    <NavLink href="/users/manage">Users</NavLink>
                  </DropdownItem>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              Account
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem>
                <NavLink href="/logout">Logout</NavLink>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default MainNavigationComponent;
