import { connect } from "react-redux";
import { default as component } from "./CityListTable.jsx";

// UI Actions
import { activateModal } from "../../../../common/UI/ActiveModal/duck/actions";

// Local Selectors
import { getTargetablePlaces } from "../../../duck/selectors.js";

// Local Actions
import { setTargetablePlace } from "../../../duck/actions";

const mapStateToProps = state => {
  return {
    places: getTargetablePlaces(state)
  };
};

const mapDispatchToProps = {
  activateModal,
  setTargetablePlace
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
