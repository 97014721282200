import types from "./types";
import { combineReducers } from "redux";

const editedSpecialNote = (state = null, action) => {
  switch (action.type) {
    case types.UPDATE_EXISTING_PROJECT_SPECIAL_NOTE_CONTENTS: {
      return action.payload;
    }
    case types.EDIT_PROJECT_SPECIAL_NOTE_RESET_DATA: {
      return null;
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({
  editedSpecialNote,
});
