const PENDING_CONTENT_ORDERS_SET_ORDER_TO_NEEDS_POSTING =
  "PENDING_CONTENT_ORDERS_SET_ORDER_TO_NEEDS_POSTING";

const PENDING_CONTENT_ORDERS_SET_FOCUSED_ORDER =
  "PENDING_CONTENT_ORDERS_SET_FOCUSED_ORDER";

const PENDING_CONTENT_ORDERS_RECEIVE_CONTENT_ORDER_PREVIEW =
  "PENDING_CONTENT_ORDERS_RECEIVE_CONTENT_ORDER_PREVIEW";

const PENDING_CONTENT_ORDERS_BEGIN_LOADING_FOCUSED_CONTENT_ORDER_PREVIEW =
  "PENDING_CONTENT_ORDERS_BEGIN_LOADING_FOCUSED_CONTENT_ORDER_PREVIEW";

const PENDING_CONTENT_ORDERS_FINISH_LOADING_FOCUSED_CONTENT_ORDER_PREVIEW =
  "PENDING_CONTENT_ORDERS_FINISH_LOADING_FOCUSED_CONTENT_ORDER_PREVIEW";

const PENDING_CONTENT_ORDERS_UPDATE_FOCUSED_ORDER_HTML =
  "PENDING_CONTENT_ORDERS_UPDATE_FOCUSED_ORDER_HTML";

const PENDING_CONTENT_ORDER_TOGGLE_SUBMIT_LOCK =
  "PENDING_CONTENT_ORDER_TOGGLE_SUBMIT_LOCK";

const PENDING_CONTENT_ORDERS_SUBMISSION_STARTED =
  "PENDING_CONTENT_ORDERS_SUBMISSION_STARTED";

const PENDING_CONTENT_ORDERS_SUBMISSION_TEXT_BROKER_ACCEPT_SUCCESS =
  "PENDING_CONTENT_ORDERS_SUBMISSION_TEXT_BROKER_ACCEPT_SUCCESS";
const PENDING_CONTENT_ORDERS_SUBMISSION_TEXT_BROKER_ACCEPT_FAIL =
  "PENDING_CONTENT_ORDERS_SUBMISSION_TEXT_BROKER_ACCEPT_FAIL";
const PENDING_CONTENT_ORDERS_SUBMISSION_BOX_DOC_CREATING =
  "PENDING_CONTENT_ORDERS_SUBMISSION_BOX_DOC_CREATING";
const PENDING_CONTENT_ORDERS_SUBMISSION_BOX_DOC_SUCCESS =
  "PENDING_CONTENT_ORDERS_SUBMISSION_BOX_DOC_SUCCESS";
const PENDING_CONTENT_ORDERS_SUBMISSION_BOX_DOC_FAILURE =
  "PENDING_CONTENT_ORDERS_SUBMISSION_BOX_DOC_FAILURE";

const PENDING_CONTENT_ORDERS_SUBMISSION_SUCCESSFUL =
  "PENDING_CONTENT_ORDERS_SUBMISSION_SUCCESSFUL";
const PENDING_CONTENT_ORDERS_SUBMISSION_FAILED =
  "PENDING_CONTENT_ORDERS_SUBMISSION_FAILED";

const UNSET_PENDING_CONTENT_ORDERS_FOCUSED_CONTENT_ORDER =
  "UNSET_PENDING_CONTENT_ORDERS_FOCUSED_CONTENT_ORDER";

const PENDING_CONTENT_ORDERS_SET_ACTIVE_TAB =
  "PENDING_CONTENT_ORDERS_SET_ACTIVE_TAB";

const PENDING_CONTENT_ORDERS_SET_META_EDIT_MODE =
  "PENDING_CONTENT_ORDERS_SET_META_EDIT_MODE";

const PENDING_CONTENT_ORDERS_SET_META_FIELD_DATA =
  "PENDING_CONTENT_ORDERS_SET_META_FIELD_DATA";

const PENDING_CONTENT_ORDERS_SAVE_META_FIELD_DATA =
  "PENDING_CONTENT_ORDERS_SAVE_META_FIELD_DATA";

export default {
  PENDING_CONTENT_ORDERS_SET_ORDER_TO_NEEDS_POSTING,
  PENDING_CONTENT_ORDERS_SET_FOCUSED_ORDER,
  PENDING_CONTENT_ORDERS_UPDATE_FOCUSED_ORDER_HTML,
  PENDING_CONTENT_ORDERS_RECEIVE_CONTENT_ORDER_PREVIEW,
  PENDING_CONTENT_ORDERS_BEGIN_LOADING_FOCUSED_CONTENT_ORDER_PREVIEW,
  PENDING_CONTENT_ORDERS_FINISH_LOADING_FOCUSED_CONTENT_ORDER_PREVIEW,
  PENDING_CONTENT_ORDER_TOGGLE_SUBMIT_LOCK,
  PENDING_CONTENT_ORDERS_SUBMISSION_STARTED,
  PENDING_CONTENT_ORDERS_SUBMISSION_TEXT_BROKER_ACCEPT_SUCCESS,
  PENDING_CONTENT_ORDERS_SUBMISSION_TEXT_BROKER_ACCEPT_FAIL,
  PENDING_CONTENT_ORDERS_SUBMISSION_BOX_DOC_CREATING,
  PENDING_CONTENT_ORDERS_SUBMISSION_BOX_DOC_SUCCESS,
  PENDING_CONTENT_ORDERS_SUBMISSION_BOX_DOC_FAILURE,
  PENDING_CONTENT_ORDERS_SUBMISSION_SUCCESSFUL,
  PENDING_CONTENT_ORDERS_SUBMISSION_FAILED,
  UNSET_PENDING_CONTENT_ORDERS_FOCUSED_CONTENT_ORDER,
  PENDING_CONTENT_ORDERS_SET_ACTIVE_TAB,
  PENDING_CONTENT_ORDERS_SET_META_EDIT_MODE,
  PENDING_CONTENT_ORDERS_SET_META_FIELD_DATA,
  PENDING_CONTENT_ORDERS_SAVE_META_FIELD_DATA,
};
