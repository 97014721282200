import { connect } from "react-redux";

import { default as component } from "./CrowdContentOrderPlacePicker.jsx";

//Local Selectors
import { getProjectTargetedPlaces } from "../../../../../duck/selectors";
import { getCrowdContentOrderTargetedPlace } from "../../duck/selectors";
//Local Actions
import { setCrowdContentOrderTargetPlace } from "../../duck/actions";

const mapStateToProps = (state) => {
  return {
    availableTargets: getProjectTargetedPlaces(state),
    targetedPlace: getCrowdContentOrderTargetedPlace(state),
  };
};

const mapDispatchToProps = {
  setCrowdContentOrderTargetPlace,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
