import { connect } from "react-redux";
import { default as component } from "./TextBrokerOrderResults.jsx";

// local selectors
import { getContentOrderRequestStage } from "../../duck/selectors";

const mapStateToProps = state => {
  return {
    requestStage: getContentOrderRequestStage(state)
  };
};

export default connect(mapStateToProps, null)(component);
