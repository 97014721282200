import { connect } from "react-redux";
import { default as component } from "./ReviewContentOrderModal.jsx";

// Global selectors and actions
import { getActiveModal } from "../../../../common/UI/ActiveModal/duck/selectors";
import { dismissModal } from "../../../../common/UI/ActiveModal/duck/actions";

// Local selectors and actions
import {
  setActiveTab,
  updateFocusedContentOrderText,
  approvePendingContentOrder,
  toggleSubmitLocked,
  setMetaEditMode,
  setMetaFieldData,
  saveNewMetaFieldData,
} from "./duck/actions";

import {
  getActiveTab,
  getMetaEditMode,
  getSubmissionStatus,
  getFocusedContentOrderLoadingStatus,
  getFocusedContentOrderSubmitLockedStatus,
  getFocusedContentOrderDetails,
  getFocusedContentOrderPreview,
} from "./duck/selectors";

const mapStateToProps = (state) => {
  return {
    isLoading: getFocusedContentOrderLoadingStatus(state),
    submissionStatus: getSubmissionStatus(state),
    submitLocked: getFocusedContentOrderSubmitLockedStatus(state),
    activeModal: getActiveModal(state),
    activeTab: getActiveTab(state),
    metaEditMode: getMetaEditMode(state),
    contentOrder: getFocusedContentOrderDetails(state),
    preview: getFocusedContentOrderPreview(state),
  };
};

const mapDispatchToProps = {
  dismissModal,
  setActiveTab,
  setMetaEditMode,
  setMetaFieldData,
  saveNewMetaFieldData,
  toggleSubmitLocked,
  updateFocusedContentOrderText,
  approvePendingContentOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
