import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Table, Button, Card, CardBody } from "reactstrap";
import Moment from "react-moment";

class UserManagement extends Component {
  componentDidMount() {
    this.props.fetchAllUsers();
  }

  render() {
    const { users, isLoading } = this.props;
    return (
      <Card className="mt-2">
        <CardBody>
          <div className="col-12">
            {!isLoading && (
              <Table size="sm" hover>
                <tbody>
                  {users.map((user) => (
                    <tr>
                      <td className="pt-2">{user.personal.name}</td>
                      <td className="text-center pt-2">
                        <Moment format="MM/DD/YYYY">{user.meta.created}</Moment>
                      </td>
                      <td className="text-center pt-2">{user.access.level}</td>
                      <td className="text-right">
                        <Button className="mr-1" size="sm" color="warning">
                          <FontAwesomeIcon size="sm" icon={faEdit} />
                        </Button>
                        <Button size="sm" color="danger">
                          <FontAwesomeIcon size="sm" icon={faTrash} />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default UserManagement;
