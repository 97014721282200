import React from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

const modal = (props) => {
  const {
    activeModal,
    _id,
    industries,
    editCustomerIndustries,
    dismissModal,
  } = props;
  const availableIndustries = [
    "Electrical",
    "HVAC",
    "Insulation",
    "Plumbing",
    "Solar",
    "Roofing",
    "Misc",
  ];
  return (
    <Modal
      isOpen={activeModal === "editCustomerIndustries"}
      toggle={() => dismissModal("editCustomerIndustries")}
    >
      <ModalHeader>Edit Client Industries</ModalHeader>
      <ModalBody>
        {availableIndustries.map((industry) => (
          <Button
            onClick={() => editCustomerIndustries(industry, industries, _id)}
            color={
              props.industries.includes(industry) ? "success" : "secondary"
            }
            block
          >
            {industry}
          </Button>
        ))}
      </ModalBody>
    </Modal>
  );
};

export default modal;
