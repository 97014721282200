import React, { Component } from "react";
import { Table, Modal, ModalHeader, ModalBody } from "reactstrap";

import { postData } from "../../../utils/fetch-requests";

class modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      availableAccountManagers: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.activeModal === null &&
      this.props.activeModal === "editCustomerAccountManager"
    ) {
      postData("/api/user/fetch-users-by-role", {
        role: "Account Manager",
      }).then((res) => {
        this.setState(
          {
            isLoading: false,
            availableAccountManagers: res,
          },
          () => {
            console.log(this.state);
          }
        );
      });
    }
  }

  render() {
    const { activeModal, _id, dismissModal } = this.props;

    return (
      <Modal
        isOpen={activeModal === "editCustomerAccountManager"}
        toggle={() => dismissModal("editCustomerAccountManager")}
      >
        <ModalHeader cssModule={{ "modal-title": "w-100 text-center" }}>
          <div class="d-flex justify-content-center">Select an SEO Analyst</div>
        </ModalHeader>
        <ModalBody>
          {this.state.isLoading ? (
            <span>Loading available SEO Analysts ...</span>
          ) : (
            <span>
              {this.state.availableAccountManagers.length === 0 ? (
                <span>There are no SEO Analysts in the Database</span>
              ) : (
                <Table size="sm" hover>
                  <tbody>
                    {this.state.availableAccountManagers.map(
                      (accountManager) => (
                        <tr>
                          <td
                            className="text-center"
                            onClick={() =>
                              this.props.assignAccountManager(
                                _id,
                                accountManager._id
                              )
                            }
                          >
                            {accountManager.personal.name}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
              )}
            </span>
          )}
        </ModalBody>
      </Modal>
    );
  }
}

export default modal;

// {accountManagers.map(manager => (
//   <Button
//     onClick={() =>
//       editCustomerAccountManagers(manager, accountManagers, _id)
//     }
//     color={
//       this.props.accountManagers.includes(manager)
//         ? "success"
//         : "secondary"
//     }
//     block
//   >
//     {manager}
//   </Button>
// ))}
