import types from "./types";

const fetchKeywordLists = () => (dispatch) => {
  fetch(`/api/keyword-generator/fetch-all-keyword-lists`)
    .then((res) => res.json())
    .then((lists) => {
      dispatch({
        type: types.KEYWORD_GENERATOR_TOOL_RECEIVE_KEYWORD_LISTS,
        payload: lists,
      });
      dispatch({ type: types.KEYWORD_GENERATOR_TOOL_FINISH_LOADING });
    });
};

const setActiveTab = (tab) => (dispatch) => {
  dispatch({
    type: types.KEYWORD_GENERATOR_TOOL_SET_ACTIVE_TAB,
    payload: tab,
  });
};

export { fetchKeywordLists, setActiveTab };
