import types from "./types";

const setCrowdContentOrderMetaH1 = (value) => (dispatch) => {
  dispatch({
    type: types.CROWD_CONTENT_ORDER_SET_META_H1,
    payload: value,
  });
};

const setCrowdContentOrderMetaTitle = (value) => (dispatch) => {
  dispatch({
    type: types.CROWD_CONTENT_ORDER_SET_META_TITLE,
    payload: value,
  });
};

const setCrowdContentOrderMetaDescription = (value) => (dispatch) => {
  dispatch({
    type: types.CROWD_CONTENT_ORDER_SET_META_DESCRIPTION,
    payload: value,
  });
};

const setCrowdContentOrderMetaSlug = (value) => (dispatch) => {
  dispatch({
    type: types.CROWD_CONTENT_ORDER_SET_META_SLUG,
    payload: value,
  });
};

const setCrowdContentMetaFormValidity = (bool) => (dispatch) => {
  dispatch({
    type: types.CROWD_CONTENT_ORDER_SET_META_VALIDITY,
    payload: bool,
  });
};

export {
  setCrowdContentOrderMetaH1,
  setCrowdContentOrderMetaTitle,
  setCrowdContentOrderMetaDescription,
  setCrowdContentOrderMetaSlug,
  setCrowdContentMetaFormValidity,
};
