import types from "./types";

const fetchAllProjectsAndSort = () => (dispatch) => {
  fetch(`/api/project/fetch-all-projects`)
    .then((res) => res.json())
    .then((projects) => {
      dispatch({
        type: types.UPDATE_SORTED_PROJECTS_BY_USER,
        payload: projects,
      });
      dispatch({ type: types.FINISH_LOADING_PROJECTS_ADMINISTRATION });
    });
};

export { fetchAllProjectsAndSort };
