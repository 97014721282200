import React, { Fragment } from "react";
import { Table, Button, Badge } from "reactstrap";

const ContentOrdersNeedsPostingTable = (props) => {
  const { contentOrders, setFocusedCrowdContentOrderForPublishing } = props;

  const appearShorter = (title) => {
    if (title.length > 35) {
      title = title.substring(0, 32) + "...";
    }
    return title;
  };

  const showPriorityBadge = (priority) => {
    switch (priority) {
      case "High Priority":
        return (
          <Badge color="danger" style={{ "margin-left": "10px" }} pill>
            High Priority
          </Badge>
        );
      case "Onboarding Order":
        return (
          <Badge color="warning" style={{ "margin-left": "10px" }} pill>
            Onboarding
          </Badge>
        );
      default:
        break;
    }
  };

  const showIMOBadge = (marketingType) => {
    switch (marketingType) {
      case "IMO":
        return (
          <Badge color="info" style={{ "margin-left": "10px" }} pill>
            IMO
          </Badge>
        );
      default:
        break;
    }
  };

  return (
    <Fragment>
      {contentOrders.length === 0 ? (
        <span>
          There are no Crowd Content orders in need of Posting at this time.
        </span>
      ) : (
        <Table size="sm" hover>
          <tbody>
            {contentOrders.map((order) => (
              <tr>
                <td className="pt-2">{order.crowdContent._id}</td>
                <td className="pt-2">
                  <a href={`/project/view/${order.meta.project._id}`}>
                    {appearShorter(
                      order.meta.project.meta.parent.local.business.name
                    )}
                    {showPriorityBadge(order.meta.orderPriority)}
                    {showIMOBadge(order.meta.project.local.marketingType)}
                  </a>
                </td>
                <td className="pt-2">
                  {order.crowdContent.title !== undefined ? (
                    <span>{appearShorter(order.crowdContent.title)}</span>
                  ) : (
                    <Fragment />
                  )}
                </td>
                <td className="pt-2 text-center">{order.meta.dueDate}</td>
                <td className="text-right">
                  {" "}
                  <Button
                    size="sm"
                    color="success"
                    onClick={() =>
                      setFocusedCrowdContentOrderForPublishing(order)
                    }
                  >
                    Publish
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Fragment>
  );
};

export default ContentOrdersNeedsPostingTable;
