import { connect } from "react-redux";
import { default as component } from "./AccountManagerOverviewCard.jsx";

// local selectors
import { getSortedProjectsByUser } from "../duck/selectors";

// local actions

const mapStateToProps = (state) => {
  return { projects: getSortedProjectsByUser(state) };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(component);
