import types from "./types";
import { combineReducers } from "redux";

const isSearching = (state = false, action) => {
  switch (action.type) {
    case types.PLACE_PICKER_MODAL_FINISH_SEARCH: {
      return false;
    }
    case types.PLACE_PICKER_MODAL_BEGIN_SEARCH: {
      return true;
    }
    case "DISMISS_MODAL": {
      return false;
    }
    default: {
      return state;
    }
  }
};

const hasSearched = (state = false, action) => {
  switch (action.type) {
    case types.PLACE_PICKER_MODAL_HAS_SEARCHED: {
      return true;
    }
    case "DISMISS_MODAL": {
      return false;
    }
    default: {
      return state;
    }
  }
};

const searchResults = (state = [], action) => {
  switch (action.type) {
    case types.PLACE_PICKER_MODAL_RECEIVE_SEARCH_RESULTS: {
      return action.payload;
    }
    case "DISMISS_MODAL":
    case types.PLACE_PICKER_MODAL_EMPTY_OUT_PLACES: {
      return [];
    }
    default: {
      return state;
    }
  }
};

const searchResultsExistingPlaces = (state = [], action) => {
  switch (action.type) {
    case types.PLACE_PICKER_MODAL_RECEIVE_EXISTING_PLACES_SEARCH_RESULTS: {
      return action.payload;
    }
    case "DISMISS_MODAL":
    case types.PLACE_PICKER_MODAL_EMPTY_OUT_PLACES: {
      return [];
    }
    default: {
      return state;
    }
  }
};

const searchQueryExistingPlaces = (state = "", action) => {
  switch (action.type) {
    case types.PLACE_PICKER_MODAL_UPDATE_EXISTING_PLACES_SEARCH_QUERY: {
      return action.payload;
    }
    case types.PLACE_PICKER_MODAL_SUCCESSFULLY_ADDED_NEW_PLACE:
    case types.RECEIVE_NEWLY_ADDED_PLACE:
    case "DISMISS_MODAL": {
      return "";
    }
    default: {
      return state;
    }
  }
};

const addedMissingPlaces = (state = [], action) => {
  switch (action.type) {
    case types.RECEIVE_NEWLY_ADDED_PLACE: {
      return action.payload;
    }
    case "DISMISS_MODAL": {
      return [];
    }
    default: {
      return state;
    }
  }
};

const activeTab = (state = "default", action) => {
  switch (action.type) {
    case types.PLACE_PICKER_SET_ACTIVE_TAB: {
      return action.payload;
    }
    case "DISMISS_MODAL": {
      return "default";
    }
    default: {
      return state;
    }
  }
};

const activeView = (state = "search", action) => {
  switch (action.type) {
    case types.PLACE_PICKER_SET_ACTIVE_VIEW: {
      return action.payload;
    }
    case "DISMISS_MODAL": {
      return "search";
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({
  isSearching,
  activeView,
  activeTab,
  addedMissingPlaces,
  hasSearched,
  searchResults,
  searchQueryExistingPlaces,
  searchResultsExistingPlaces,
});
