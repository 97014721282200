import React from "react";
import { Card } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { default as CustomerProjectsTable } from "./CustomerProjectsTable";

const CustomerProjectsCard = props => {
  return (
    <Card className="p-2 mt-1">
      <div className="d-flex">
        <div className="flex-grow-1">
          <h6 className="text-muted">
            <strong>Projects</strong>
          </h6>
        </div>
        <div className="text-right">
          <FontAwesomeIcon
            icon={faPlus}
            onClick={() => props.activateModal("addNewProject")}
          />
        </div>
      </div>
      <CustomerProjectsTable />
    </Card>
  );
};

export default CustomerProjectsCard;
