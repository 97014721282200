import React, { Fragment } from "react";

import { Input } from "reactstrap";

const CrowdContentOrderMetaForm = (props) => {
  const {
    h1,
    title,
    description,
    slug,
    setCrowdContentOrderMetaTitle,
    setCrowdContentOrderMetaDescription,
    setCrowdContentOrderMetaSlug,
    setCrowdContentOrderMetaH1,
    setCrowdContentMetaFormValidity,
  } = props;

  const validateForm = () => {
    if (
      props.title.length < 1 ||
      props.description.length < 1 ||
      props.slug.length < 1 ||
      props.h1.length < 1
    ) {
      setCrowdContentMetaFormValidity(false);
    } else {
      setCrowdContentMetaFormValidity(true);
    }
  };

  return (
    <Fragment>
      <form onChange={() => validateForm()}>
        <Input
          type="text"
          className="form-control mb-2"
          placeholder="H1"
          value={h1}
          onChange={(event) => {
            setCrowdContentOrderMetaH1(event.target.value);
          }}
        />
        <Input
          type="textarea"
          rows="3"
          className="form-control mb-2"
          placeholder="Meta Description for Content Order"
          value={description}
          onChange={(event) => {
            setCrowdContentOrderMetaDescription(event.target.value);
          }}
        />
        <Input
          type="text"
          className="form-control mb-2"
          placeholder="/slug-for-content-order"
          value={slug}
          onChange={(event) => {
            setCrowdContentOrderMetaSlug(event.target.value);
          }}
        />
        <Input
          type="text"
          className="form-control mb-2"
          placeholder="Meta Title for Content Order"
          value={title}
          onChange={(event) => {
            setCrowdContentOrderMetaTitle(event.target.value);
          }}
        />
      </form>
      {description.length > 142 || title.length > 60 || title.length < 51 ? (
        <div id="meta-alerts" className="text-center mt-1 border border-danger">
          {description.length > 142 && (
            <Fragment>
              <span>
                Description too long! {description.length} | 142 characters.
              </span>
              <br />
            </Fragment>
          )}
          {title.length > 60 && (
            <Fragment>
              <span>Title too long! {title.length} | 60 characters.</span>
              <br />
            </Fragment>
          )}
          {title.length < 51 && (
            <Fragment>
              <span>Title too short! {title.length} | 51 characters.</span>
              <br />
            </Fragment>
          )}
        </div>
      ) : (
        <Fragment />
      )}
    </Fragment>
  );
};

export default CrowdContentOrderMetaForm;
