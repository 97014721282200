import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { Card, CardHeader, CardBody, Button } from "reactstrap";

const appearShorter = (title) => {
  if (title.length > 35) {
    title = title.substring(0, 32) + "...";
  }
  return title;
};

class KeywordListManagementCard extends Component {
  render() {
    let { activateModal, keywordLists, setFocusedKeywordList } = this.props;
    return (
      <Fragment>
        <div class="col-12 text-right mt-3 mb-3">
          <Button
            size="sm"
            color="success"
            onClick={() => activateModal("addKeywordListModal")}
          >
            Add New List
          </Button>
        </div>
        <div className="row">
          {keywordLists.length < 1 ? (
            <div className="col-12">
              There are no keyword lists. Feel free to add one in the top right.
            </div>
          ) : (
            <Fragment>
              {keywordLists.map((list) => (
                <div className="col-12 mt-2">
                  <Card>
                    <CardHeader>
                      <div className="row">
                        <div className="col-8">
                          <strong>{list.title}</strong>
                        </div>
                        <div className="col-4 text-right">
                          <strong>Sort #{list.stackOrderID}</strong>
                        </div>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <div className="row">
                        <div className="col-11">
                          <div className="row">
                            {list.keywords.map((phrase) => (
                              <div className="col-4">
                                {appearShorter(phrase)}
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="text-right col-1">
                          <Button
                            size="sm"
                            color="primary"
                            onClick={() => setFocusedKeywordList(list)}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </Button>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              ))}
            </Fragment>
          )}
        </div>
      </Fragment>
    );
  }
}

export default KeywordListManagementCard;
