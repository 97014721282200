import types from "../../Authentication/duck/types";

const toggleCollapse = () => {
  return {
    type: types.TOGGLE_COLLAPSE
  };
};

export default {
  toggleCollapse
};
