const PENDING_CROWD_CONTENT_ORDERS_SET_ORDER_TO_NEEDS_POSTING =
  "PENDING_CROWD_CONTENT_ORDERS_SET_ORDER_TO_NEEDS_POSTING";

const PENDING_CROWD_CONTENT_ORDERS_SET_FOCUSED_ORDER =
  "PENDING_CROWD_CONTENT_ORDERS_SET_FOCUSED_ORDER";

const PENDING_CROWD_CONTENT_ORDERS_RECEIVE_CROWD_CONTENT_ORDER_PREVIEW =
  "PENDING_CROWD_CONTENT_ORDERS_RECEIVE_CROWD_CONTENT_ORDER_PREVIEW";

const PENDING_CROWD_CONTENT_ORDERS_BEGIN_LOADING_FOCUSED_CROWD_CONTENT_ORDER_PREVIEW =
  "PENDING_CROWD_CONTENT_ORDERS_BEGIN_LOADING_FOCUSED_CROWD_CONTENT_ORDER_PREVIEW";

const PENDING_CROWD_CONTENT_ORDERS_FINISH_LOADING_FOCUSED_CROWD_CONTENT_ORDER_PREVIEW =
  "PENDING_CROWD_CONTENT_ORDERS_FINISH_LOADING_FOCUSED_CROWD_CONTENT_ORDER_PREVIEW";

const PENDING_CROWD_CONTENT_ORDERS_UPDATE_FOCUSED_ORDER_HTML =
  "PENDING_CROWD_CONTENT_ORDERS_UPDATE_FOCUSED_ORDER_HTML";

const PENDING_CROWD_CONTENT_ORDER_TOGGLE_SUBMIT_LOCK =
  "PENDING_CROWD_CONTENT_ORDER_TOGGLE_SUBMIT_LOCK";

const PENDING_CROWD_CONTENT_ORDERS_SUBMISSION_STARTED =
  "PENDING_CROWD_CONTENT_ORDERS_SUBMISSION_STARTED";

const PENDING_CROWD_CONTENT_ORDERS_SUBMISSION_CROWD_CONTENT_ACCEPT_SUCCESS =
  "PENDING_CROWD_CONTENT_ORDERS_SUBMISSION_CROWD_CONTENT_ACCEPT_SUCCESS";
const PENDING_CROWD_CONTENT_ORDERS_SUBMISSION_CROWD_CONTENT_ACCEPT_FAIL =
  "PENDING_CROWD_CONTENT_ORDERS_SUBMISSION_CROWD_CONTENT_ACCEPT_FAIL";
const PENDING_CROWD_CONTENT_ORDERS_SUBMISSION_BOX_DOC_CREATING =
  "PENDING_CROWD_CONTENT_ORDERS_SUBMISSION_BOX_DOC_CREATING";
const PENDING_CROWD_CONTENT_ORDERS_SUBMISSION_BOX_DOC_SUCCESS =
  "PENDING_CROWD_CONTENT_ORDERS_SUBMISSION_BOX_DOC_SUCCESS";
const PENDING_CROWD_CONTENT_ORDERS_SUBMISSION_BOX_DOC_FAILURE =
  "PENDING_CROWD_CONTENT_ORDERS_SUBMISSION_BOX_DOC_FAILURE";

const PENDING_CROWD_CONTENT_ORDERS_SUBMISSION_SUCCESSFUL =
  "PENDING_CROWD_CONTENT_ORDERS_SUBMISSION_SUCCESSFUL";
const PENDING_CROWD_CONTENT_ORDERS_SUBMISSION_FAILED =
  "PENDING_CROWD_CONTENT_ORDERS_SUBMISSION_FAILED";

const UNSET_PENDING_CROWD_CONTENT_ORDERS_FOCUSED_CROWD_CONTENT_ORDER =
  "UNSET_PENDING_CROWD_CONTENT_ORDERS_FOCUSED_CROWD_CONTENT_ORDER";

const PENDING_CROWD_CONTENT_ORDERS_SET_ACTIVE_TAB =
  "PENDING_CROWD_CONTENT_ORDERS_SET_ACTIVE_TAB";

const PENDING_CROWD_CONTENT_ORDERS_SET_META_EDIT_MODE =
  "PENDING_CROWD_CONTENT_ORDERS_SET_META_EDIT_MODE";

const PENDING_CROWD_CONTENT_ORDERS_SET_META_FIELD_DATA =
  "PENDING_CROWD_CONTENT_ORDERS_SET_META_FIELD_DATA";

const PENDING_CROWD_CONTENT_ORDERS_SAVE_META_FIELD_DATA =
  "PENDING_CROWD_CONTENT_ORDERS_SAVE_META_FIELD_DATA";

const PENDING_CROWD_CONTENT_ORDERS_SET_FOCUSED_ORDER_META =
  "PENDING_CROWD_CONTENT_ORDERS_SET_FOCUSED_ORDER_META";

const UNSET_PENDING_CROWD_CONTENT_ORDERS_FOCUSED_CROWD_CONTENT_ORDER_META =
  "UNSET_PENDING_CROWD_CONTENT_ORDERS_FOCUSED_CROWD_CONTENT_ORDER_META";

export default {
  PENDING_CROWD_CONTENT_ORDERS_SET_ORDER_TO_NEEDS_POSTING,
  PENDING_CROWD_CONTENT_ORDERS_SET_FOCUSED_ORDER,
  PENDING_CROWD_CONTENT_ORDERS_UPDATE_FOCUSED_ORDER_HTML,
  PENDING_CROWD_CONTENT_ORDERS_RECEIVE_CROWD_CONTENT_ORDER_PREVIEW,
  PENDING_CROWD_CONTENT_ORDERS_BEGIN_LOADING_FOCUSED_CROWD_CONTENT_ORDER_PREVIEW,
  PENDING_CROWD_CONTENT_ORDERS_FINISH_LOADING_FOCUSED_CROWD_CONTENT_ORDER_PREVIEW,
  PENDING_CROWD_CONTENT_ORDER_TOGGLE_SUBMIT_LOCK,
  PENDING_CROWD_CONTENT_ORDERS_SUBMISSION_STARTED,
  PENDING_CROWD_CONTENT_ORDERS_SUBMISSION_CROWD_CONTENT_ACCEPT_SUCCESS,
  PENDING_CROWD_CONTENT_ORDERS_SUBMISSION_CROWD_CONTENT_ACCEPT_FAIL,
  PENDING_CROWD_CONTENT_ORDERS_SUBMISSION_BOX_DOC_CREATING,
  PENDING_CROWD_CONTENT_ORDERS_SUBMISSION_BOX_DOC_SUCCESS,
  PENDING_CROWD_CONTENT_ORDERS_SUBMISSION_BOX_DOC_FAILURE,
  PENDING_CROWD_CONTENT_ORDERS_SUBMISSION_SUCCESSFUL,
  PENDING_CROWD_CONTENT_ORDERS_SUBMISSION_FAILED,
  UNSET_PENDING_CROWD_CONTENT_ORDERS_FOCUSED_CROWD_CONTENT_ORDER,
  PENDING_CROWD_CONTENT_ORDERS_SET_ACTIVE_TAB,
  PENDING_CROWD_CONTENT_ORDERS_SET_META_EDIT_MODE,
  PENDING_CROWD_CONTENT_ORDERS_SET_META_FIELD_DATA,
  PENDING_CROWD_CONTENT_ORDERS_SAVE_META_FIELD_DATA,
  PENDING_CROWD_CONTENT_ORDERS_SET_FOCUSED_ORDER_META,
  UNSET_PENDING_CROWD_CONTENT_ORDERS_FOCUSED_CROWD_CONTENT_ORDER_META,
};
