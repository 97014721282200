import React, { useState } from "react";
import { Input } from "reactstrap";

import { MODAL_NAME } from "./duck/constants";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const SetProjectPackageTypeModal = (props) => {
  const {
    activeModal,
    dismissModal,
    projectID,
    setProjectPackageType,
    currentPackageType,
  } = props;
  const [localProjectPackagetype, setLocalProjectPackageType] = useState(
    currentPackageType
  );
  return (
    <Modal
      isOpen={activeModal === MODAL_NAME}
      toggle={() => dismissModal(MODAL_NAME)}
    >
      <ModalHeader>Set Project SEO Package</ModalHeader>
      <ModalBody>
        <Input
          type="select"
          defaultValue={
            currentPackageType === null ? "Not Set" : currentPackageType
          }
          className="form-control"
          onChange={(event) => setLocalProjectPackageType(event.target.value)}
        >
          <option value="Not Set" disabled>
            Not Set
          </option>
          <option value="Rural">Rural</option>
          <option value="Rookie">Rookie</option>
          <option value="Pro">Pro</option>
          <option value="Veteran">Veteran</option>
          <option value="Legend">Legend</option>
        </Input>
      </ModalBody>
      <ModalFooter>
        <Button
          color="success"
          onClick={() =>
            setProjectPackageType({
              projectID,
              packageType: localProjectPackagetype,
            })
          }
          disabled={
            localProjectPackagetype === null ||
            currentPackageType === localProjectPackagetype
          }
        >
          Save
        </Button>
        <Button color="secondary" onClick={() => dismissModal(MODAL_NAME)}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SetProjectPackageTypeModal;
