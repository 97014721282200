import React, { Component, Fragment } from "react";
import { default as KeywordGeneratorToolMainForm } from "./components/KeywordGeneratorToolMainForm/KeywordGeneratorToolMainForm.js";
import { default as KeywordListManagementCard } from "./components/KeywordListManagementCard/KeywordListManagementCard.js";
import { default as KeywordGeneratorOutputCard } from "./components/KeywordGeneratorOutputCard/KeywordGeneratorOutputCard.js";
import { default as AddKeywordGeneratorListModal } from "./modals/addKeywordGeneratorList/addKeywordGeneratorList.js";
import { default as EditKeywordGeneratorListModal } from "./modals/editKeywordGeneratorList/editKeywordGeneratorList.js";
import {
  Card,
  CardBody,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
} from "reactstrap";
import classNames from "classnames";

import {
  DEFAULT_TAB,
  KEYWORD_GROUPS_TAB,
  GENERATED_KEYWORDS_TAB,
} from "./duck/constants";

class KeywordGeneratorTool extends Component {
  componentDidMount() {
    this.props.fetchKeywordLists();
  }

  render() {
    let { isLoading, activeTab, setActiveTab, usedKeywordLists } = this.props;
    return (
      <Fragment>
        {isLoading ? (
          <Fragment />
        ) : (
          <div className="row">
            <div className="col-md-12 p-2">
              <Card>
                <CardBody>
                  <Nav className="mb-1" tabs>
                    <NavItem>
                      <NavLink
                        className={classNames({
                          active: activeTab === DEFAULT_TAB,
                        })}
                        onClick={() => setActiveTab(DEFAULT_TAB)}
                      >
                        <span className="mr-2">Keyword Generator</span>
                      </NavLink>
                    </NavItem>
                    {usedKeywordLists.length > 0 ? (
                      <NavItem>
                        <NavLink
                          className={classNames({
                            active: activeTab === GENERATED_KEYWORDS_TAB,
                          })}
                          onClick={() => setActiveTab(GENERATED_KEYWORDS_TAB)}
                        >
                          <span className="mr-2">Generated Keywords</span>
                        </NavLink>
                      </NavItem>
                    ) : (
                      <Fragment />
                    )}
                    <NavItem className="ml-auto">
                      <NavLink
                        className={classNames({
                          active: activeTab === KEYWORD_GROUPS_TAB,
                        })}
                        onClick={() => setActiveTab(KEYWORD_GROUPS_TAB)}
                      >
                        <span className="mr-2">Keyword Groups</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId={DEFAULT_TAB}>
                      <KeywordGeneratorToolMainForm />
                    </TabPane>
                  </TabContent>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId={GENERATED_KEYWORDS_TAB}>
                      <KeywordGeneratorOutputCard />
                    </TabPane>
                  </TabContent>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId={KEYWORD_GROUPS_TAB}>
                      <KeywordListManagementCard />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
              <AddKeywordGeneratorListModal />
              <EditKeywordGeneratorListModal />
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default KeywordGeneratorTool;
