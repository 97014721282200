import { connect } from "react-redux";
import { default as component } from "./AddPlacesBulkModal.jsx";

// UI actions
import { dismissModal } from "../../../../common/UI/ActiveModal/duck/actions";
// UI selectors
import { getActiveModal } from "../../../../common/UI/ActiveModal/duck/selectors";

// Local Actions
import { insertPlacesBulk } from "./duck/actions";

const mapStateToProps = state => {
  return {
    activeModal: getActiveModal(state)
  };
};

const mapDispatchToProps = {
  dismissModal,
  insertPlacesBulk
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
