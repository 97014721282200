export const availableCategories = [
  "HVAC - Heating",
  "HVAC - Cooling",
  "HVAC - Indoor Air Quality",
  "HVAC - Agreements",
  "Plumbing - Water Heater & Softeners",
  "Plumbing - Drains",
  "Plumbing - Toilets",
  "Plumbing - Showers",
  "Plumbing - Kitchen",
  "Plumbing - Septic & Sewer",
  "Electrical - Wiring",
  "Electrical - Detectors",
  "Electrical - Repair & Replacement",
  "Electrical - Generators",
  "Electrical - Switches & Lighting",
  "Solar Panels",
  "TSEO",
];
