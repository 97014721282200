import React from "react";
import { Card } from "reactstrap";
import { default as ProjectDetailsTable } from "./ProjectDetailsTable.js";
import { default as PickPlacesModal } from "./modals/PickPlaces/PickPlaces.js";
import { default as SetProjectStatusModal } from "./modals/SetProjectStatus/SetProjectStatus.js";
import { default as SetProjectMonthlySpendModal } from "./modals/SetProjectMonthlySpend/SetProjectMonthlySpend.js";
import { default as SetProjectPackageTypeModal } from "./modals/SetProjectPackageType/SetProjectPackageType.js";
import { default as SetProjectMarketingTypeModal } from "./modals/SetProjectMarketingType/SetProjectMarketingType.js";

const ProjectDetailsCard = () => {
  return (
    <Card className="p-2 mt-1">
      <div className="d-flex">
        <div className="flex-grow-1 text-center pb-2">
          <strong>Project Information</strong>
        </div>
      </div>
      <ProjectDetailsTable />
      <PickPlacesModal />
      <SetProjectStatusModal />
      <SetProjectMonthlySpendModal />
      <SetProjectPackageTypeModal />
      <SetProjectMarketingTypeModal />
    </Card>
  );
};

export default ProjectDetailsCard;
