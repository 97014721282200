import React, { Component } from "react";
import { Card, CardBody, Input } from "reactstrap";

// import available countries and states
import {
  availableUnitedStates,
  availableCanadianTerritories,
} from "./constants";

class StateDrilldownCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCountry: "United States",
      currentState: "unselected",
    };
  }

  renderCountryPicker = () => {
    const { currentCountry } = this.state;
    const AvailableCountries = ["United States", "Canada"];
    return (
      <div>
        <label>Select a Country:</label>
        <Input
          type="select"
          className="form-control"
          id="state"
          defaultValue={currentCountry}
          onChange={(event) => this.setSelectedCountry(event.target.value)}
        >
          {AvailableCountries.map((countryName, index) => (
            <option
              selected={countryName === AvailableCountries[index]}
              value={countryName}
            >
              {countryName}
            </option>
          ))}
        </Input>
      </div>
    );
  };

  setSelectedCountry = (selectedCountry) => {
    let newState = Object.assign({}, this.state);
    newState.currentCountry = selectedCountry;
    if (selectedCountry === "United States") {
      newState.currentState = "unselected";
      this.props.emptyOutPlaces();
    } else {
      newState.currentState = "unselected";
      this.props.emptyOutPlaces();
    }
    this.setState(newState, () => {
      console.log(this.state);
    });
  };

  renderUnitedStatesPicker = () => {
    return (
      <div className="mt-1">
        <label>Select a State:</label>
        <Input
          type="select"
          className="form-control"
          id="state"
          defaultValue="unselected"
          value={this.state.currentState}
          onChange={(event) => this.setSelectedState(event.target.value)}
        >
          <option value="unselected" disabled>
            Please select a State
          </option>
          {availableUnitedStates.map((stateName, index) => (
            <option
              selected={stateName === availableUnitedStates[index]}
              value={stateName}
            >
              {stateName}
            </option>
          ))}
        </Input>
      </div>
    );
  };

  renderCanadianTerritoriesPicker = () => {
    return (
      <div className="mt-1">
        <label>Select a Territory:</label>
        <Input
          type="select"
          className="form-control"
          id="state"
          defaultValue="Alberta"
          value={this.state.currentState}
          onChange={(event) => this.setSelectedState(event.target.value)}
        >
          <option value="unselected" disabled>
            Please Select a Territory
          </option>
          {availableCanadianTerritories.map((territoryName, index) => (
            <option
              selected={territoryName === availableCanadianTerritories[index]}
              value={territoryName}
            >
              {territoryName}
            </option>
          ))}
        </Input>
      </div>
    );
  };

  setSelectedState = (selectedState) => {
    let newState = Object.assign({}, this.state);
    newState.currentState = selectedState;
    this.setState(newState, () => {
      this.props.fetchPlacesByState(selectedState);
    });
  };

  render() {
    const { currentCountry } = this.state;
    return (
      <Card className="p-2">
        <CardBody>
          <div>{this.renderCountryPicker()}</div>
          <div>
            {currentCountry === "United States"
              ? this.renderUnitedStatesPicker()
              : this.renderCanadianTerritoriesPicker()}
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default StateDrilldownCard;
