import React, { Fragment } from "react";
import { MODAL_NAME } from "./duck/constants";

import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

const DecideContentOrderProviderModal = (props) => {
  // Globals
  const { activeModal, dismissModal } = props;
  // Action Triggers
  const { activateModal } = props;
  return (
    <Fragment>
      <Modal
        isOpen={activeModal === MODAL_NAME}
        toggle={() => dismissModal(MODAL_NAME)}
      >
        <ModalHeader>Select a Content Order Provider</ModalHeader>
        <ModalBody>
          <Button
            color="warning"
            className="btn-block"
            onClick={() => activateModal("addContentOrderModal")}
          >
            Text Broker
          </Button>
          <Button
            color="warning"
            className="btn-block"
            onClick={() => activateModal("addCrowdContentOrderModal")}
          >
            Crowd Content
          </Button>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default DecideContentOrderProviderModal;
