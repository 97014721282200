import { postData } from "../../../../../utils/fetch-requests";

const setProjectMarketingType = (data) => (dispatch) => {
  postData("/api/project/set-project-marketing-type", data).then((project) => {
    dispatch({
      type: "RECEIVE_PROJECT_DETAILS",
      payload: project,
    });
    dispatch({
      type: "DISMISS_MODAL",
    });
  });
};

export { setProjectMarketingType };
