const KEYWORD_GENERATOR_TOOL_SET_ALTERED_LIST_TITLE =
  "KEYWORD_GENERATOR_TOOL_SET_ALTERED_LIST_TITLE";
const KEYWORD_GENERATOR_TOOL_SET_ALTERED_LIST_KEYWORDS =
  "KEYWORD_GENERATOR_TOOL_SET_ALTERED_LIST_KEYWORDS";
const KEYWORD_GENERATOR_TOOL_SET_ALTERED_LIST_FORM_VALIDITY =
  "KEYWORD_GENERATOR_TOOL_SET_ALTERED_LIST_FORM_VALIDITY";
const KEYWORD_GENERATOR_TOOL_SET_FOCUSED_KEYWORD_LIST =
  "KEYWORD_GENERATOR_TOOL_SET_FOCUSED_KEYWORD_LIST";
const KEYWORD_GENERATOR_TOOL_SET_ALTERED_LIST_STACK_ORDER_ID =
  "KEYWORD_GENERATOR_TOOL_SET_ALTERED_LIST_STACK_ORDER_ID";

export default {
  KEYWORD_GENERATOR_TOOL_SET_ALTERED_LIST_TITLE,
  KEYWORD_GENERATOR_TOOL_SET_ALTERED_LIST_KEYWORDS,
  KEYWORD_GENERATOR_TOOL_SET_ALTERED_LIST_FORM_VALIDITY,
  KEYWORD_GENERATOR_TOOL_SET_FOCUSED_KEYWORD_LIST,
  KEYWORD_GENERATOR_TOOL_SET_ALTERED_LIST_STACK_ORDER_ID,
};
