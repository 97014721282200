import { connect } from "react-redux";
import { default as component } from "./MilestoneChecklistSelectorTable.jsx";

// global selectors
// global actions
// local selectors
import {
  getProjectID,
  getProjectMilestonesCategories,
} from "../../../../../duck/selectors";
// local actions
import {
  addMilestoneCategoryToProject,
  removeMilestoneCategoryFromProject,
} from "./duck/actions";

const mapStateToProps = (state) => {
  return {
    projectID: getProjectID(state),
    milestoneCategories: getProjectMilestonesCategories(state),
  };
};

const mapDispatchToProps = {
  addMilestoneCategoryToProject,
  removeMilestoneCategoryFromProject,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
