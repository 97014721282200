const CROWD_CONTENT_BEGIN_CONTENT_DASHBOARD_LOADING =
  "CROWD_CONTENT_BEGIN_CONTENT_DASHBOARD_LOADING";
const CROWD_CONTENT_FINISH_CONTENT_DASHBOARD_LOADING =
  "CROWD_CONTENT_FINISH_CONTENT_DASHBOARD_LOADING";
const CROWD_CONTENT_CONTENT_DASHBOARD_RECEIVE_CONTENT_ORDERS =
  "CROWD_CONTENT_CONTENT_DASHBOARD_RECEIVE_CONTENT_ORDERS";
const CROWD_CONTENT_CONTENT_DASHBOARD_SET_ACTIVE_TAB =
  "CROWD_CONTENT_CONTENT_DASHBOARD_SET_ACTIVE_TAB";

export default {
  CROWD_CONTENT_BEGIN_CONTENT_DASHBOARD_LOADING,
  CROWD_CONTENT_FINISH_CONTENT_DASHBOARD_LOADING,
  CROWD_CONTENT_CONTENT_DASHBOARD_RECEIVE_CONTENT_ORDERS,
  CROWD_CONTENT_CONTENT_DASHBOARD_SET_ACTIVE_TAB,
};
