import React, { Fragment } from "react";
import { constants } from "../../duck/constants";
import { Button, Input, InputGroup, Alert } from "reactstrap";

const CrowdContentOrderForm = (props) => {
  const validateForm = () => {
    if (
      parseInt(props.minimumWords) >= parseInt(props.maximumWords) ||
      parseInt(props.minimumWords) === 1 ||
      parseInt(props.maximumWords) === 1 ||
      props.writingQuality === "0" ||
      props.processingTime === "0" ||
      props.shortCode === "1" ||
      props.orderPriority === "1" ||
      props.orderTitle === null ||
      props.orderTitle === "" ||
      props.orderTitle.length <= 2 ||
      props.orderDescription === null ||
      props.orderDescription === "" ||
      props.orderDescription.length <= 3
    ) {
      props.setFormValidity(false);
    } else {
      props.setFormValidity(true);
    }
  };

  const {
    minimumWords,
    maximumWords,
    writingQuality,
    processingTime,
    shortCode,
    orderPriority,
    contentType,
    templateType,
    orderTitle,
    orderDescription,
    specialNotes,
    monthlySpend,
    setCrowdContentOrderMinimumWords,
    setCrowdContentOrderMaximumWords,
    setCrowdContentOrderWritingQuality,
    setCrowdContentOrderProcessingTime,
    setCrowdContentOrderShortCode,
    setCrowdContentOrderPriority,
    setCrowdContentOrderContentType,
    setCrowdContentOrderTemplateType,
    setCrowdContentOrderTitle,
    setCrowdContentOrderDescription,
    setCrowdContentOrderSpecialNotes,
  } = props;

  return (
    <Fragment>
      {templateType === null ? (
        <div className="text-center">
          <div className="col-12 mb-2">
            <strong>Is this a RYNO Charge based Order?</strong>
          </div>
          <Button
            color="primary"
            className="btn-block"
            onClick={() => setCrowdContentOrderTemplateType("Charge")}
          >
            Yes
          </Button>{" "}
          <Button
            color="secondary"
            className="btn-block"
            onClick={() => setCrowdContentOrderTemplateType("Standard")}
          >
            No
          </Button>
        </div>
      ) : (
        <fragment>
          <Alert className="col-12 mb-2 text-center" color="warning">
            Monthly Spend is ${monthlySpend} we suggest{" "}
            {monthlySpend < 3000 ? "3 Star Quality" : "4 Star Quality"}
          </Alert>
          <form onChange={() => validateForm()}>
            <InputGroup className="mb-1">
              <Input
                type="select"
                name="minimumWords"
                id="minimumWords"
                className="form-control mr-1"
                defaultValue={minimumWords}
                onChange={(event) =>
                  setCrowdContentOrderMinimumWords(event.target.value)
                }
              >
                <option value="1" disabled>
                  Min. Words
                </option>
                {constants.minWordRange.map((n) => (
                  <option key={n} value={n}>
                    {n}
                  </option>
                ))}
              </Input>
              <Input
                type="select"
                name="maximumWords"
                id="maximumWords"
                className="form-control mr-1"
                defaultValue={maximumWords}
                onChange={(event) =>
                  setCrowdContentOrderMaximumWords(event.target.value)
                }
              >
                <option value="1" disabled>
                  Max. Words
                </option>
                {constants.maxWordRange.map((n) => (
                  <option key={n} value={n}>
                    {n}
                  </option>
                ))}
              </Input>
              <Input
                type="select"
                name="writingQuality"
                id="writingQuality"
                className="form-control mr-1"
                defaultValue={writingQuality}
                onChange={(event) =>
                  setCrowdContentOrderWritingQuality(event.target.value)
                }
              >
                <option value="0" disabled>
                  Writing Quality?
                </option>
                <option value="3">3 stars</option>
                <option value="4">4 stars</option>
              </Input>
            </InputGroup>
            <InputGroup className="mb-1">
              <Input
                type="select"
                name="processingTime"
                id="processingTime"
                className="form-control mr-1"
                defaultValue={processingTime}
                onChange={(event) =>
                  setCrowdContentOrderProcessingTime(event.target.value)
                }
              >
                <option value="0" disabled>
                  Processing Time?
                </option>
                <option value="1">1 days</option>
                <option value="2">2 days</option>
                <option value="3">3 days</option>
                <option value="4">4 days</option>
                <option value="5">5 days</option>
                <option value="6">6 days</option>
                <option value="7">7 days</option>
                <option value="8">8 days</option>
                <option value="9">9 days</option>
                <option value="10">10 days</option>
              </Input>
              <Input
                type="select"
                name="useShortCode"
                id="useShortCode"
                className="form-control mr-1"
                value={shortCode}
                onChange={(event) =>
                  setCrowdContentOrderShortCode(event.target.value)
                }
              >
                <option value="1" disabled>
                  Use Short Code?
                </option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Input>
            </InputGroup>
            <Input
              type="select"
              name="contentType"
              id="contentType"
              className="form-control mr-1 mb-1"
              value={contentType}
              onChange={(event) =>
                setCrowdContentOrderContentType(event.target.value)
              }
            >
              <option value="1" disabled>
                Select Content Type?
              </option>
              <option value="About Us Page">About Us Page</option>
              <option value="General Non-HVAC City Page">
                General Non-HVAC City Page
              </option>
              <option value="General Non-HVAC Service Page">
                General Non-HVAC Service Page
              </option>
              <option value="HVAC City Page">HVAC City Page</option>
              <option value="Multiple Verticals City Page">
                Multiple Verticals City Page
              </option>
              <option value="Multiple Services Page">
                Multiple Services Page
              </option>
              <option value="HVAC Service Page">HVAC Service Page</option>
            </Input>

            <Input
              type="select"
              name="orderPriority"
              id="orderPriority"
              className="form-control mr-1 mb-1"
              value={orderPriority}
              onChange={(event) =>
                setCrowdContentOrderPriority(event.target.value)
              }
            >
              <option value="1" disabled>
                High Priority / Onboarding Content?
              </option>
              <option value="Standard Order">Standard Order</option>
              <option value="High Priority">High Priority</option>
              <option value="Onboarding Order">Onboarding Order</option>
            </Input>

            <Input
              type="text"
              className="form-control mb-2"
              placeholder="Title"
              name="orderTitle"
              id="title"
              value={orderTitle}
              onChange={(event) => {
                setCrowdContentOrderTitle(event.target.value);
              }}
            />
            <Input
              type="textarea"
              rows={5}
              className="form-control mb-2"
              placeholder="Description... "
              name="orderDescription"
              id="description"
              value={orderDescription}
              onChange={(event) => {
                setCrowdContentOrderDescription(event.target.value);
              }}
            />
            <div className="text-center mb-2"> -- Things to Avoid --</div>

            <Input
              type="textarea"
              rows={2}
              className="form-control mb-2"
              placeholder="Instructions on what to Avoid... "
              name="orderThingsToAvoid"
              id="orderThingsToAvoid"
              value={specialNotes}
              onChange={(event) => {
                setCrowdContentOrderSpecialNotes(event.target.value);
              }}
            />
          </form>
        </fragment>
      )}
    </Fragment>
  );
};

export default CrowdContentOrderForm;
