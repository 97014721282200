import types from "./types";

const fetchAllUsers = () => dispatch => {
  fetch("/api/user/fetch-all-users")
    .then(res => res.json())
    .then(users => {
      dispatch({
        type: types.USER_MANAGEMENT_RECEIVE_ALL_USERS,
        payload: users
      });
    });
};

export { fetchAllUsers };
