import { connect } from "react-redux";
import { default as component } from "./ContentOrderKeywords.jsx";

// Local Selectors
import {
  getContentOrderKeywords,
  getContentOrderKeywordsMin,
  getContentOrderKeywordsMax,
  getContentOrderKeywordsUseInflections,
  getContentOrderKeywordsUseStopWords,
} from "./duck/selectors";

// Local Actions
import {
  addKeywordToContentOrder,
  removeKeywordsFromContentOrder,
  setContentOrderKeywordMinAppearance,
  setContentOrderKeywordMaxAppearance,
  setContentOrderKeywordInflectionUsage,
  setContentOrderKeywordStopWordUsage,
} from "./duck/actions";

const mapStateToProps = (state) => {
  return {
    keywords: getContentOrderKeywords(state),
    min: getContentOrderKeywordsMin(state),
    max: getContentOrderKeywordsMax(state),
    use_inflections: getContentOrderKeywordsUseInflections(state),
    use_stopwords: getContentOrderKeywordsUseStopWords(state),
  };
};

const mapDispatchToProps = {
  addKeywordToContentOrder,
  removeKeywordsFromContentOrder,
  setContentOrderKeywordMinAppearance,
  setContentOrderKeywordMaxAppearance,
  setContentOrderKeywordInflectionUsage,
  setContentOrderKeywordStopWordUsage,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
