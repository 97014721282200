import { connect } from "react-redux";
import { default as Modal } from "./EditCustomerAccountManager.jsx";

// UI actions
import { dismissModal } from "../../../common/UI/ActiveModal/duck/actions";
// UI selectors
import { getActiveModal } from "../../../common/UI/ActiveModal/duck/selectors";

// Local actions
import { assignAccountManager } from "../../duck/actions";
// Local selectors
import { getCustomerDetails } from "../../duck/selectors";

const mapStateToProps = (state) => {
  return {
    activeModal: getActiveModal(state),
    accountManager: getCustomerDetails(state).internal.accountManager,
    _id: getCustomerDetails(state)._id,
  };
};

const mapDispatchToProps = {
  dismissModal,
  assignAccountManager,
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
