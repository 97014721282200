import React, { Fragment } from "react";
import { MODAL_NAME } from "./duck/constants";

import {
  Alert,
  Button,
  InputGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
} from "reactstrap";

const EditKeywordGeneratorListModal = (props) => {
  const {
    dismissModal,
    activeModal,
    alteredKeywordListTitle,
    alteredKeywordListKeywords,
    alteredKeywordListStackOrderID,
    setAlteredTitleForKeywordList,
    setAlteredKeywordsForKeywordList,
    setAlteredStackOrderIDForKeywordList,
    editKeywordGeneratorList,
    focusedKeywordList,
    checkEditKeywordGeneratorListFormValidity,
    formValidity,
  } = props;
  return (
    <Fragment>
      <Modal
        isOpen={activeModal === MODAL_NAME}
        toggle={() => dismissModal(MODAL_NAME)}
      >
        <ModalHeader>Edit Keyword List</ModalHeader>
        <ModalBody>
          <Alert color="info">
            Phrases must be separated from each other with a | character.
            <br />
            Shortcodes available: [City] [State] [Abbr]
            <br />
            Example: Best Plumber in [City], [Abbr]
            <br />
            Example Output: Best Plumber in Phoenix, AZ
            <br />
            <br />
            The Stack Order ID sorts Lists in an ascending manner.
          </Alert>
          <Form
            onChange={() =>
              checkEditKeywordGeneratorListFormValidity(
                alteredKeywordListTitle,
                alteredKeywordListKeywords
              )
            }
          >
            <InputGroup className="mt-2">
              <Input
                type="text"
                placeHolder="Keyword List Title"
                className="form-control w-75"
                defaultValue={focusedKeywordList.title}
                onChange={(event) =>
                  setAlteredTitleForKeywordList(event.target.value)
                }
              />
              <Input
                type="Number"
                className="form-control"
                defaultValue={focusedKeywordList.stackOrderID}
                onChange={(event) =>
                  setAlteredStackOrderIDForKeywordList(event.target.value)
                }
              />
            </InputGroup>
            <Input
              type="textarea"
              placeHolder="Comma, Separated, Keywords go Here!"
              rows="8"
              className="form-control mt-2"
              defaultValue={focusedKeywordList.keywords}
              onChange={(event) =>
                setAlteredKeywordsForKeywordList(event.target.value)
              }
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            onClick={() =>
              editKeywordGeneratorList(
                focusedKeywordList._id,
                alteredKeywordListTitle,
                alteredKeywordListKeywords,
                alteredKeywordListStackOrderID
              )
            }
            disabled={formValidity}
          >
            Edit Keyword List
          </Button>
          <Button color="secondary" onClick={() => dismissModal(MODAL_NAME)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default EditKeywordGeneratorListModal;
