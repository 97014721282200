import { connect } from "react-redux";
import { default as component } from "./ProjectMilestoneTracker.jsx";

// global selectors
import { getActiveModal } from "../../../../common/UI/ActiveModal/duck/selectors";
// global actions
import { dismissModal } from "../../../../common/UI/ActiveModal/duck/actions";

// //local selectors
import { getMilestoneTrackerView } from "./duck/selectors.js";

// //local action
import { toggleMilestoneTrackerView } from "./duck/actions";

const mapStateToProps = (state) => {
  return {
    activeModal: getActiveModal(state),
    currentView: getMilestoneTrackerView(state),
  };
};

const mapDispatchToProps = {
  dismissModal,
  toggleMilestoneTrackerView,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
