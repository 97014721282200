import { connect } from "react-redux";
import { default as component } from "./CrowdContentOrderForm.jsx";

// Local Action
import {
  setCrowdContentOrderMinimumWords,
  setCrowdContentOrderMaximumWords,
  setCrowdContentOrderWritingQuality,
  setCrowdContentOrderProcessingTime,
  setCrowdContentOrderShortCode,
  setCrowdContentOrderPriority,
  setCrowdContentOrderContentType,
  setCrowdContentOrderTemplateType,
  setCrowdContentOrderTitle,
  setCrowdContentOrderDescription,
  setCrowdContentOrderSpecialNotes,
  setFormValidity,
} from "../../duck/actions";

// Local Selectors
import {
  getProjectMonthlySpend,
  getProjectParentCompanyName,
} from "../../../../../duck/selectors.js";
import {
  getCrowdContentOrderMinimumWords,
  getCrowdContentOrderMaximumWords,
  getCrowdContentOrderWritingQuality,
  getCrowdContentOrderProcessingTime,
  getCrowdContentOrderShortCode,
  getCrowdContentOrderPriority,
  getCrowdContentOrderContentType,
  getCrowdContentOrderTemplateType,
  getCrowdContentOrderTitle,
  getCrowdContentOrderDescription,
  getCrowdContentOrderSpecialNotes,
} from "../../duck/selectors.js";

const mapStateToProps = (state) => {
  return {
    companyName: getProjectParentCompanyName(state),
    minimumWords: getCrowdContentOrderMinimumWords(state),
    maximumWords: getCrowdContentOrderMaximumWords(state),
    writingQuality: getCrowdContentOrderWritingQuality(state),
    processingTime: getCrowdContentOrderProcessingTime(state),
    shortCode: getCrowdContentOrderShortCode(state),
    orderPriority: getCrowdContentOrderPriority(state),
    contentType: getCrowdContentOrderContentType(state),
    templateType: getCrowdContentOrderTemplateType(state),
    orderTitle: getCrowdContentOrderTitle(state),
    orderDescription: getCrowdContentOrderDescription(state),
    specialNotes: getCrowdContentOrderSpecialNotes(state),
    monthlySpend: getProjectMonthlySpend(state),
  };
};

const mapDispatchToProps = {
  setCrowdContentOrderMinimumWords,
  setCrowdContentOrderMaximumWords,
  setCrowdContentOrderWritingQuality,
  setCrowdContentOrderProcessingTime,
  setCrowdContentOrderShortCode,
  setCrowdContentOrderPriority,
  setCrowdContentOrderContentType,
  setCrowdContentOrderTemplateType,
  setCrowdContentOrderTitle,
  setCrowdContentOrderDescription,
  setCrowdContentOrderSpecialNotes,
  setFormValidity,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
