import { postData } from "../../../../../../../utils/fetch-requests";

export const markMilestoneAsComplete = (id, milestone) => (dispatch) => {
  const milestoneToMarkComplete = {
    _id: id,
    milestone,
  };
  postData(
    "/api/project/mark-project-milestone-as-complete",
    milestoneToMarkComplete
  ).then((project) => {
    dispatch({
      type: "RECEIVE_PROJECT_DETAILS",
      payload: project,
    });
    dispatch({ type: "FINISH_PROJECT_DETAILS_LOADING" });
  });
};

export const markMilestoneAsIncomplete = (id, milestone) => (dispatch) => {
  const milestoneToMarkIncomplete = {
    _id: id,
    milestone,
  };
  postData(
    "/api/project/mark-project-milestone-as-incomplete",
    milestoneToMarkIncomplete
  ).then((project) => {
    dispatch({
      type: "RECEIVE_PROJECT_DETAILS",
      payload: project,
    });
    dispatch({ type: "FINISH_PROJECT_DETAILS_LOADING" });
  });
};
