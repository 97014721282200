import types from "./types";
import { combineReducers } from 'redux'

const isLoading = (state = true, action) => {
  switch (action.type) {
    case types.FINISH_CUSTOMER_DETAILS_LOADING: {
      return false
    }
    case types.BEGIN_CUSTOMER_DETAILS_LOADING: {
      return true
    }
    default: {
      return state
    }
  }
}

const customer = (state = {}, action) => {
  switch (action.type) {
    case types.EDIT_CUSTOMER_INDUSTRIES:
    case types.EDIT_CUSTOMER_BUSINESS_NAME:
    case types.RECEIVE_CUSTOMER_DETAILS: {
      return action.payload
    }
    default: {
      return state
    }
  }
}

export default combineReducers({
  isLoading,
  customer
});
