import { connect } from "react-redux";
import { default as Modal } from "./EditCustomerIndustries.jsx";

//UI Selectors
import { getActiveModal } from "../../../common/UI/ActiveModal/duck/selectors"
// UI Actions
import { dismissModal } from "../../../common/UI/ActiveModal/duck/actions";

//Local Selectors
import { getCustomerDetails } from "../../duck/selectors"

//Local Actions
import { editCustomerIndustries } from "../../duck/actions";

const mapStateToProps = state => {
  return {
    activeModal: getActiveModal(state),
    industries: getCustomerDetails(state).local.business.industries,
    _id: getCustomerDetails(state)._id
  };
};

const mapDispatchToProps = {
  dismissModal,
  editCustomerIndustries
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
