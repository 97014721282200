import { connect } from "react-redux";
import { default as component } from "./KeywordGeneratorToolMainForm.jsx";

// local selectors
import {
  getSplitCities,
  getKeywordLists,
  getUsedKeywordLists,
  getStateAbbreviation,
} from "./duck/selectors.js";

// local actions
import {
  setSplitCities,
  setStateAbbreviation,
  addKeywordListAndGenerate,
} from "./duck/actions.js";

const mapStateToProps = (state) => {
  return {
    splitCities: getSplitCities(state),
    stateAbbreviation: getStateAbbreviation(state),
    keywordLists: getKeywordLists(state),
    usedKeywordLists: getUsedKeywordLists(state),
  };
};

const mapDispatchToProps = {
  setSplitCities,
  setStateAbbreviation,
  addKeywordListAndGenerate,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
