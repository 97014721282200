import { NAME } from "../../../duck/constants";
import { MODAL_NAME } from "./constants";

export const getNewKeywordListTitle = (state) => {
  return state.tools[NAME].modalData[MODAL_NAME].newKeywordListTitle;
};

export const getNewKeywordListKeywords = (state) => {
  return state.tools[NAME].modalData[MODAL_NAME].newKeywordListKeywords;
};

export const getNewKeywordListFormValidity = (state) => {
  return state.tools[NAME].modalData[MODAL_NAME].formValidity;
};
