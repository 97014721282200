import React from "react";
import { Badge } from "reactstrap";

const ContentOrderStatusBadge = (props) => {
  const { status } = props;

  const determineColor = (status) => {
    switch (status) {
      case "Deleted":
      case "Order Refused":
      case "Internal Error": {
        return "#a74528";
      }
      case "Placed": {
        return "#0373FC";
      }
      case "TB Accepted": {
        return "#0373FC";
      }
      case "Author Writing": {
        return "#FC03C2";
      }
      case "Ready": {
        return "#28a745";
      }
      case "RYNO Accepted": {
        return "#b213ea";
      }
      case "Delivered": {
        return "#23923d";
      }
      case "Needs Editing": {
        return "#f09f05";
      }
      case "Rejection Granted": {
        return "#db69c0";
      }
      case "Waiting": {
        return "#a7288a";
      }
      case "Recently Published": {
        return "#38d00a";
      }
      default: {
        break;
      }
    }
  };

  return (
    <Badge
      size="sm"
      className="mr-1"
      style={{ backgroundColor: determineColor(status) }}
    >
      {status === "Recently Published" ? (
        <span>Published</span>
      ) : (
        <span>{status}</span>
      )}
    </Badge>
  );
};

export default ContentOrderStatusBadge;
