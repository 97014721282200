import { connect } from "react-redux";
import { default as TargetablePlacesSearch } from "./TargetablePlaceSearch.jsx";

// UI Selectors
import { getActiveModal } from "../common/UI/ActiveModal/duck/selectors";
// UI Actions
import { activateModal } from "../common/UI/ActiveModal/duck/actions";

const mapStateToProps = state => {
  return {
    activeModal: getActiveModal(state),
    placeResults: []
  };
};

const mapDispatchToProps = {
  activateModal
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TargetablePlacesSearch);
