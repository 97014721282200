import types from "./types";
// import { postData } from "../../utils/fetch-requests";

const fetchCrowdContentOrders = () => (dispatch) => {
  fetch(`/api/crowd-content/list-orders`)
    .then((res) => res.json())
    .then((orders) => {
      dispatch({
        type: types.CROWD_CONTENT_CONTENT_DASHBOARD_RECEIVE_CONTENT_ORDERS,
        payload: orders,
      });
      dispatch({ type: types.CROWD_CONTENT_FINISH_CONTENT_DASHBOARD_LOADING });
    });
};

const setActiveTab = (tab) => (dispatch) => {
  dispatch({
    type: types.CROWD_CONTENT_CONTENT_DASHBOARD_SET_ACTIVE_TAB,
    payload: tab,
  });
};

export { fetchCrowdContentOrders, setActiveTab };
