import { connect } from "react-redux";
import { default as component } from "./KeywordGeneratorOutputCard.jsx";

// global selectors
import { getGeneratedKeywords } from "../../duck/selectors";

const mapStateToProps = (state) => {
  return {
    generatedKeywords: getGeneratedKeywords(state),
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(component);
