const BEGIN_LOADING_TARGETABLE_PLACES = "BEGIN_LOADING_TARGETABLE_PLACES";
const FINISH_LOADING_TARGETABLE_PLACES = "FINISH_LOADING_TARGETABLE_PLACES";

const RECEIVE_TARGETABLE_PLACES = "RECEIVE_TARGETABLE_PLACES";

const SET_TARGETABLE_PLACE = "SET_TARGETABLE_PLACE";

const EMPTY_OUT_PLACES = "EMPTY_OUT_PLACES";

export default {
  BEGIN_LOADING_TARGETABLE_PLACES,
  FINISH_LOADING_TARGETABLE_PLACES,
  EMPTY_OUT_PLACES,
  RECEIVE_TARGETABLE_PLACES,
  SET_TARGETABLE_PLACE
};
