import { NAME } from "./constants";

const select = (key) => (state) => state[NAME][key];

export const getLoggedInUser = (state) => {
  return state.loggedInUser;
};

export const getLoggedInUserEmail = state => {
  return state.loggedInUser.local.email
}


export const getLoggedInUserLocalDetails = select("local");
export const getLoggedInUserPersonalDetails = select("personal");
export const getLoggedInUserRoles = select("roles");
export const getLoggedInUserAccess = (state) => {
  return state.loggedInUser.access.level;
};

/*

    local: {
      email: String,
      password: String,
      profilePictureUrl: String
    },
    personal: {
      name: {
        type: String,
        default: null
      },
      phoneNumber: {
        type: String,
        default: null
      }
    },
    roles: { type: String, default: "User" },
    access: {
      level: {
        type: String,
        required: [true, "Please provide a valid user level."],
        default: "User"
      }
    },
    meta: {
      created: {
        type: Date,
        default: Date.now
      }
    }

*/
