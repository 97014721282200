import types from "./types";
import { combineReducers } from "redux";

const isLoading = (state = false, action) => {
  switch (action.type) {
    case types.FINISH_LOAING_TARGETABLE_PLACES: {
      return false;
    }
    case types.BEGIN_LOADING_TARGETABLE_PLACES: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const places = (state = [], action) => {
  switch (action.type) {
    case types.RECEIVE_TARGETABLE_PLACES: {
      return action.payload;
    }
    case types.EMPTY_OUT_PLACES: {
      return [];
    }
    default: {
      return state;
    }
  }
};

const targetablePlace = (state = null, action) => {
  switch (action.type) {
    case types.SET_TARGETABLE_PLACE: {
      return action.payload;
    }
    case types.CLEAR_TARGETABLE_PLACE: {
      return {};
    }
    default: {
      return state;
    }
  }
};

const modalData = combineReducers({
  targetablePlace
});

export default combineReducers({
  isLoading,
  places,
  modalData
});
