import React, { Fragment } from "react";
import { Input } from "reactstrap";

const CrowdContentOrderKeywords = (props) => {
  const {
    keywords,
    addKeywordToCrowdContentOrder,
    removeKeywordsFromCrowdContentOrder,
  } = props;
  console.log(props);
  return (
    <Fragment>
      <Input
        placeholder="Input your first keyword.."
        className="form-control col-12 mb-1"
        value={keywords[0]}
        onChange={(event) => {
          addKeywordToCrowdContentOrder(keywords, event.target.value, 0);
        }}
      />
      <Input
        placeholder="Input your second keyword.."
        className="form-control col-12 mb-1"
        value={keywords[1]}
        onChange={(event) => {
          addKeywordToCrowdContentOrder(keywords, event.target.value, 1);
        }}
      />
      <Input
        placeholder="Input your third keyword.."
        className="form-control col-12 mb-1"
        value={keywords[2]}
        onChange={(event) => {
          addKeywordToCrowdContentOrder(keywords, event.target.value, 2);
        }}
      />
    </Fragment>
  );
};

export default CrowdContentOrderKeywords;
