import { postData } from "../../../../../utils/fetch-requests";

const setProjectPackageType = (data) => (dispatch) => {
  postData("/api/project/set-project-package-type", data).then((project) => {
    dispatch({
      type: "RECEIVE_PROJECT_DETAILS",
      payload: project,
    });
    dispatch({
      type: "DISMISS_MODAL",
    });
  });
};

export { setProjectPackageType };
