import React, { Fragment } from "react";
import { Table, Badge } from "reactstrap";

const ContentOrdersNeedsReviewTable = (props) => {
  const { contentOrders } = props;

  const appearShorter = (title) => {
    if (title.length > 45) {
      title = title.substring(0, 44) + "...";
    }
    return title;
  };

  const determineColor = (statusText) => {
    switch (statusText) {
      case "Placed":
        return { backgroundColor: "#0373FC" };
        break;
      default:
        return { backgroundColor: "#FC03C2" };
        break;
    }
  };

  return (
    <Fragment>
      {contentOrders.length === 0 ? (
        <span>There are no orders being written at this time.</span>
      ) : (
        <Table size="sm" hover>
          <tbody>
            {contentOrders.map((order) => (
              <tr>
                <td className="pt-2">
                  <a
                    href={`https://intern.textbroker.com/client/orderadministration/getOrder/item_id:${order.textBroker._id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {order.textBroker._id}
                  </a>
                </td>
                <td className="pt-2">
                  {order.meta.project.meta.parent.local.business.name}
                </td>
                <td className="pt-2">
                  {appearShorter(order.textBroker.orderTitle)}
                </td>
                <td className="pt-2 text-right">
                  <Badge
                    pill
                    outline
                    style={determineColor(order.textBroker.status.status_text)}
                  >
                    {order.textBroker.status.status_text}
                  </Badge>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Fragment>
  );
};

export default ContentOrdersNeedsReviewTable;
