import { connect } from "react-redux";
import CustomerViewComponent from "./CustomerView.jsx";

// Local Actions
import { fetchCustomer } from "./duck/actions";

// Local Selectors
import { getCustomerDetails, isLoading } from "./duck/selectors"

const mapStateToProps = state => {
  return {
    customer: getCustomerDetails(state),
    isLoading: isLoading(state)
  };
};

const mapDispatchToProps = {
  fetchCustomer
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerViewComponent);
