import types from "./types";

const activateModal = modal => {
  return {
    type: types.ACTIVATE_MODAL,
    payload: modal
  };
};

const dismissModal = modal => {
  return {
    type: types.DISMISS_MODAL,
    payload: modal
  };
};

export { activateModal, dismissModal };
