import { connect } from "react-redux";
import { default as component } from "./addCrowdContentOrder.jsx";

// UI actions
import { dismissModal } from "../../../../common/UI/ActiveModal/duck/actions";
// UI selectors
import { getActiveModal } from "../../../../common/UI/ActiveModal/duck/selectors";

// Local Selects from Parent
import {
  getProjectDomainName,
  getProjectTargetedPlaces,
  getProjectID,
  getProjectMonthlySpend,
} from "../../../duck/selectors.js";

// Local Selectors
import {
  getCrowdContentOrderCurrentView,
  getCrowdContentOrderDetails,
  getCrowdContentOrderFormSubmittedStatus,
  getCrowdContentOrderFormValidity,
  getCrowdContentOrderMetaValidity,
  getCrowdContentOrderRequestStage,
  getCrowdContentOrderTemplateType,
} from "./duck/selectors.js";

// Local actions

import {
  setCrowdContentOrderCurrentView,
  retrySendingCrowdContentOrder,
  resetCrowdContentOrderForm,
  submitCrowdContentOrder,
} from "./duck/actions.js";

const mapStateToProps = (state) => {
  return {
    activeModal: getActiveModal(state),
    projectID: getProjectID(state),
    projectDomain: getProjectDomainName(state),
    monthlySpend: getProjectMonthlySpend(state),
    crowdContentOrder: getCrowdContentOrderDetails(state),
    formValidity: getCrowdContentOrderFormValidity(state),
    metaValidity: getCrowdContentOrderMetaValidity(state),
    formSubmitted: getCrowdContentOrderFormSubmittedStatus(state),
    currentView: getCrowdContentOrderCurrentView(state),
    templateType: getCrowdContentOrderTemplateType(state),
    formSubmitted: getCrowdContentOrderFormSubmittedStatus(state),
    requestStage: getCrowdContentOrderRequestStage(state),
    projectTargetablePlaces: getProjectTargetedPlaces(state),
  };
};

const mapDispatchToProps = {
  dismissModal,
  setCrowdContentOrderCurrentView,
  retrySendingCrowdContentOrder,
  resetCrowdContentOrderForm,
  submitCrowdContentOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
