const NAME = "addCrowdContentOrder";

export const getCrowdContentOrderDetails = (state) => {
  return state.pages.projectView.modalData[NAME];
};

export const getCrowdContentOrderMinimumWords = (state) => {
  return state.pages.projectView.modalData[NAME].minimumWords;
};

export const getCrowdContentOrderMaximumWords = (state) => {
  return state.pages.projectView.modalData[NAME].maximumWords;
};

export const getCrowdContentOrderWritingQuality = (state) => {
  return state.pages.projectView.modalData[NAME].writingQuality;
};

export const getCrowdContentOrderProcessingTime = (state) => {
  return state.pages.projectView.modalData[NAME].processingTime;
};

export const getCrowdContentOrderShortCode = (state) => {
  return state.pages.projectView.modalData[NAME].shortCode;
};

export const getCrowdContentOrderPriority = (state) => {
  return state.pages.projectView.modalData[NAME].orderPriority;
};

export const getCrowdContentOrderContentType = (state) => {
  return state.pages.projectView.modalData[NAME].contentType;
};

export const getCrowdContentOrderTemplateType = (state) => {
  return state.pages.projectView.modalData[NAME].templateType;
};

export const getCrowdContentOrderTitle = (state) => {
  return state.pages.projectView.modalData[NAME].orderTitle;
};

export const getCrowdContentOrderDescription = (state) => {
  return state.pages.projectView.modalData[NAME].orderDescription;
};

export const getCrowdContentOrderSpecialNotes = (state) => {
  return state.pages.projectView.modalData[NAME].specialNotes;
};

export const getCrowdContentOrderTargetedPlace = (state) => {
  return state.pages.projectView.modalData[NAME].targetedPlace;
};

export const getCrowdContentOrderFormSubmittedStatus = (state) => {
  return state.pages.projectView.modalData[NAME].formSubmitted;
};

export const getCrowdContentOrderCurrentView = (state) => {
  return state.pages.projectView.modalData[NAME].currentView;
};

export const getCrowdContentOrderRequestStage = (state) => {
  return state.pages.projectView.modalData[NAME].requestStage;
};

export const getCrowdContentOrderFormValidity = (state) => {
  return state.pages.projectView.modalData[NAME].formValidity;
};

export const getCrowdContentOrderMetaValidity = (state) => {
  return state.pages.projectView.modalData[NAME].crowdContentOrderMeta
    .formValidity;
};
