import React, { Component, Fragment } from "react";
import parse from "html-react-parser";
import {
  faLock,
  faLockOpen,
  faPencilAlt,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";

import {
  Card,
  CardBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Alert,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Badge,
  CardTitle,
} from "reactstrap";

export default class ReviewContentOrderModal extends Component {
  renderMetaCards = (id, contentMeta, metaEditMode, fields) => {
    return (
      <div className="mt-2">
        {fields.map((field) => (
          <Card className="mb-1" id={field}>
            <CardBody className="text-center">
              <CardTitle>
                <strong>
                  {field.charAt(0).toUpperCase() + field.slice(1)}
                </strong>
                {field === "description" && contentMeta[field].length > 155 ? (
                  <Fragment>
                    <span> - </span>
                    <span className="text-danger">
                      Description is too long! {contentMeta[field].length}|155
                    </span>
                  </Fragment>
                ) : (
                  <Fragment />
                )}
                {field === "title" && contentMeta[field].length > 67 ? (
                  <Fragment>
                    <span> - </span>
                    <span className="text-danger">
                      Title is too long! {contentMeta[field].length}|67
                    </span>
                  </Fragment>
                ) : (
                  <Fragment />
                )}
                {field === "title" && contentMeta[field].length < 51 ? (
                  <Fragment>
                    <span> - </span>
                    <span className="text-danger">
                      Title is too short! {contentMeta[field].length}|51
                    </span>
                  </Fragment>
                ) : (
                  <Fragment />
                )}
              </CardTitle>
              <hr />
              <div className="d-flex">
                <div className="flex-grow-1 text-center">
                  {metaEditMode.field === field ? (
                    <input
                      className="form-control col-12"
                      id={field}
                      defaultValue={contentMeta[field]}
                      onChange={(event) =>
                        this.props.setMetaFieldData(field, event.target.value)
                      }
                    />
                  ) : (
                    <span>{contentMeta[field]}</span>
                  )}
                </div>
                <div className="pl-3 pr-3">
                  {metaEditMode.field === field && metaEditMode.editing ? (
                    <Button
                      color="success"
                      disabled={contentMeta[field] === metaEditMode.newData}
                      onClick={() =>
                        this.props.saveNewMetaFieldData(
                          id,
                          field,
                          metaEditMode.newData
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faSave} />
                    </Button>
                  ) : (
                    <FontAwesomeIcon
                      icon={faPencilAlt}
                      onClick={() =>
                        this.props.setMetaEditMode(field, contentMeta[field])
                      }
                    />
                  )}
                </div>
              </div>
            </CardBody>
          </Card>
        ))}
      </div>
    );
  };

  renderSubmissionStatus = (submissionStatus) => {
    if (submissionStatus === "Waiting on Box Document Creation") {
      return <Alert color="info">Waiting on Box Document Creation</Alert>;
    } else if (submissionStatus === "Waiting on TextBroker Acceptance") {
      return (
        <Alert color="info">
          Box Document Created Successfully and attached.
          <br />
          Waiting to be accepted by Text Broker (may take 20-30 seconds)
        </Alert>
      );
    } else if (submissionStatus === "TextBroker Accepted") {
      return (
        <Alert color="success">
          Box Document Created Successfully and attached.
          <br />
          Order Has been successfully move to "Needs Posting"
        </Alert>
      );
    }
  };

  getWordCount = (contents) => {
    let lineCount = contents.split(/\r\n|\r|\n/).length;
    contents.replace(/<[^>]*>/g, " ");
    contents.replace(/\s+/g, " ");
    contents.trim();
    const wordCount = contents.split(" ").length + lineCount;
    return wordCount;
  };

  render() {
    const {
      isLoading,
      submitLocked,
      activeModal,
      contentOrder,
      preview,
      dismissModal,
      toggleSubmitLocked,
      updateFocusedContentOrderText,
      submissionStatus,
      approvePendingContentOrder,
      setActiveTab,
      activeTab,
      metaEditMode,
    } = this.props;
    return (
      <Modal
        isOpen={activeModal === "ReviewContentOrderModal"}
        toggle={() => dismissModal("ReviewContentOrderModal")}
        size={submissionStatus !== "unsubmitted" ? "md" : "xl"}
      >
        <ModalHeader>
          Review Text Broker Order{" "}
          {contentOrder.textBroker !== undefined && (
            <span>{contentOrder.textBroker._id}</span>
          )}
        </ModalHeader>
        <ModalBody>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "ReviewContent",
                })}
                onClick={() => setActiveTab(activeTab, "ReviewContent")}
              >
                Review Content
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "ReviewMeta" })}
                onClick={() => setActiveTab(activeTab, "ReviewMeta")}
              >
                Review Meta
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="ReviewContent">
              {submissionStatus === "unsubmitted" ? (
                <Fragment>
                  {isLoading ? (
                    <span>
                      Be patient - waiting on TextBroker to provide a preview
                    </span>
                  ) : (
                    <div className="row">
                      <div
                        className="col-6"
                        style={{ height: "500px", overflowY: "scroll" }}
                      >
                        <Fragment>{parse(preview.text)}</Fragment>
                      </div>
                      <div className="col-6">
                        <Input
                          type="textarea"
                          name="editedContent"
                          id="editedContents"
                          defaultValue={preview.text}
                          style={{ height: "500px" }}
                          onChange={(event) => {
                            updateFocusedContentOrderText(preview, event);
                          }}
                        />
                      </div>
                    </div>
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  {this.renderSubmissionStatus(submissionStatus)}
                </Fragment>
              )}
            </TabPane>
            <TabPane tabId="ReviewMeta">
              {submissionStatus === "unsubmitted" ? (
                <Fragment>
                  {isLoading ? (
                    <span>
                      Be patient - waiting on TextBroker to provide a preview
                    </span>
                  ) : (
                    <Fragment>
                      {this.renderMetaCards(
                        contentOrder._id,
                        contentOrder.contentMeta,
                        metaEditMode,
                        ["h1", "description", "slug", "title"]
                      )}
                    </Fragment>
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  {this.renderSubmissionStatus(submissionStatus)}
                </Fragment>
              )}
            </TabPane>
          </TabContent>
        </ModalBody>
        {contentOrder.keywords !== undefined && (
          <ModalFooter>
            <div className="col">
              {contentOrder.tone !== null && (
                <Badge color="info mr-1" pill outline>{`${
                  "Tone: " + contentOrder.tone
                }`}</Badge>
              )}
              {!isLoading && (
                <Badge color="info mr-1" pill outline>{`${
                  "Word Count: " + this.getWordCount(preview.text)
                }`}</Badge>
              )}
            </div>
            <div className="col text-right">
              {contentOrder.keywords.map((keyword) => (
                <Badge className="mr-1" color="info" pill outline>
                  {keyword}
                </Badge>
              ))}
            </div>
          </ModalFooter>
        )}
        <ModalFooter>
          <Button
            className="mr-2"
            outline={!submitLocked}
            color={submitLocked ? "warning" : "success"}
            onClick={() => toggleSubmitLocked(submitLocked)}
            disabled={isLoading}
          >
            <FontAwesomeIcon icon={submitLocked ? faLock : faLockOpen} />{" "}
            {submitLocked}
          </Button>

          <Button
            className="button-block"
            color="success"
            disabled={submitLocked}
            onClick={() => approvePendingContentOrder(contentOrder, preview)}
          >
            Process Order
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
