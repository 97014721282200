import React, { Fragment } from "react";
import { InputGroup, Input } from "reactstrap";

const ContentOrderKeywords = (props) => {
  const {
    keywords,
    min,
    max,
    use_inflections,
    use_stopwords,
    addKeywordToContentOrder,
    removeKeywordsFromContentOrder,
    setContentOrderKeywordMinAppearance,
    setContentOrderKeywordMaxAppearance,
    setContentOrderKeywordInflectionUsage,
    setContentOrderKeywordStopWordUsage,
  } = props;
  console.log(props);
  return (
    <Fragment>
      <Input
        placeholder="Input your first keyword.."
        className="form-control col-12 mb-1"
        value={keywords[0]}
        onChange={(event) => {
          event.target.value !== ""
            ? addKeywordToContentOrder(keywords, event.target.value, 0)
            : removeKeywordsFromContentOrder(keywords, 0);
        }}
      />
      <Input
        placeholder="Input your second keyword.."
        className="form-control col-12 mb-1"
        value={keywords[1]}
        onChange={(event) => {
          event.target.value !== ""
            ? addKeywordToContentOrder(keywords, event.target.value, 1)
            : removeKeywordsFromContentOrder(keywords, 1);
        }}
      />
      <InputGroup className="mb-1">
        <Input
          type="select"
          name="minAppearance"
          id="minAppearance"
          className="form-control mr-1"
          value={min}
          onChange={(event) =>
            setContentOrderKeywordMinAppearance(event.target.value)
          }
        >
          <option value={0} disabled>
            Use each word (min)?
          </option>
          <option value={1}>1</option>
          <option value={2}>2</option>
        </Input>
        <Input
          type="select"
          name="maxAppearance"
          id="maxAppearance"
          className="form-control"
          value={max}
          onChange={(event) =>
            setContentOrderKeywordMaxAppearance(event.target.value)
          }
          disabled={min === 0}
        >
          <option value={0} disabled>
            Use each word (max)?
          </option>
          <option value={min + 1}>{min + 1}</option>
          <option value={min + 2}>{min + 2}</option>
          <option value={min + 3}>{min + 3}</option>
        </Input>
      </InputGroup>
      <InputGroup className="mb-1">
        <Input
          type="select"
          name="use_inflections"
          id="use_inflections"
          className="form-control mr-1"
          value={use_inflections}
          onChange={(event) =>
            setContentOrderKeywordInflectionUsage(event.target.value)
          }
        >
          <option value="1" disabled>
            Use Inflections?
          </option>
          <option value="true">True</option>
          <option value="false">False</option>
        </Input>
        <Input
          type="select"
          name="maxAppearance"
          id="maxAppearance"
          className="form-control"
          value={use_stopwords}
          onChange={(event) =>
            setContentOrderKeywordStopWordUsage(event.target.value)
          }
        >
          <option value="1" disabled>
            Use Stop Words?
          </option>
          <option value="true">True</option>
          <option value="false">False</option>
        </Input>
      </InputGroup>
    </Fragment>
  );
};

export default ContentOrderKeywords;
