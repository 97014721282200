import React from "react";
import { Table } from "reactstrap";

const ProjectDetailsTable = (props) => {
  const { activateModal } = props;
  const { status } = props.project.meta;
  const {
    domain,
    targetedPlaces,
    monthlySpend,
    packageType,
    marketingType,
  } = props.project.local;

  // No longer in use - circle back and remove.
  // const renderStatus = (status) => {
  //   console.log(status);
  //   switch (status) {
  //     case "billingPause":
  //       return "Paused (Billing)";
  //     case "requestedPause":
  //       return "Paused (Customer Request)";
  //     case "cancelled":
  //       return "Cancelled";
  //     default:
  //       return "Active";
  //   }
  // };

  return (
    <Table className="text-center" size="sm" hover>
      <tbody>
        <tr>
          <td className="text-left">Domain:</td>
          <td className="text-right">{domain}</td>
        </tr>
        <tr onClick={() => activateModal("setProjectStatusModal")}>
          <td className="text-left">Status:</td>
          <td className="text-right">{status}</td>
        </tr>
        <tr onClick={() => activateModal("pickPlacesModal")}>
          <td className="text-left">Places:</td>
          <td className="text-right">{targetedPlaces.length} Targeted</td>
        </tr>
        <tr onClick={() => activateModal("setProjectMonthlySpendModal")}>
          <td className="text-left">Monthly Spend:</td>
          <td className="text-right">${monthlySpend}</td>
        </tr>
        <tr onClick={() => activateModal("setProjectPackageTypeModal")}>
          <td className="text-left">SEO Package</td>
          <td className="text-right">
            {packageType === null ? (
              <span>Not Set</span>
            ) : (
              <span>{packageType}</span>
            )}
          </td>
        </tr>
        <tr onClick={() => activateModal("setProjectMarketingTypeModal")}>
          <td className="text-left">Marketing Type</td>
          <td className="text-right">
            {marketingType === null ? (
              <span>Not Set</span>
            ) : (
              <span>{marketingType}</span>
            )}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default ProjectDetailsTable;
