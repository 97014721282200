import { postData } from "../../../../utils/fetch-requests";

export const performPaginationCustomerSearch = (queryValue) => (dispatch) => {
  // SEARCH FOR CUSTOMERS
  dispatch({ type: "BEGIN_CUSTOMER_SEARCH" });
  dispatch({ type: "SEARCH_FOR_CUSTOMERS", payload: queryValue });
  return postData("/api/customer/search-for-customers-by-beginning-letter", {
    searchQuery: queryValue,
  }).then((customers) => {
    dispatch({
      type: "RECEIVE_CUSTOMERS",
      payload: customers,
    });
    dispatch({ type: "FINISH_CUSTOMER_SEARCH" });
  });
};

export const performPaginationCustomerSearchNumeric = () => (dispatch) => {
  // SEARCH FOR CUSTOMERS
  dispatch({ type: "BEGIN_CUSTOMER_SEARCH" });
  dispatch({ type: "SEARCH_FOR_CUSTOMERS", payload: null });
  return postData("/api/customer/search-for-customers-by-beginning-numeric", {
    searchQuery: null,
  }).then((customers) => {
    dispatch({
      type: "RECEIVE_CUSTOMERS",
      payload: customers,
    });
    dispatch({ type: "FINISH_CUSTOMER_SEARCH" });
  });
};

export const performPaginationCancelledCustomerSearch = () => (dispatch) => {
  // SEARCH FOR CUSTOMERS
  dispatch({ type: "BEGIN_CUSTOMER_SEARCH" });
  dispatch({ type: "SEARCH_FOR_CUSTOMERS", payload: null });
  return postData("/api/customer/search-for-cancelled-customers", {
    searchQuery: null,
  }).then((customers) => {
    dispatch({
      type: "RECEIVE_CUSTOMERS",
      payload: customers,
    });
    dispatch({ type: "FINISH_CUSTOMER_SEARCH" });
  });
};

export default {
  performPaginationCustomerSearch,
  performPaginationCustomerSearchNumeric,
  performPaginationCancelledCustomerSearch,
};
