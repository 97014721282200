// reducers.js
import types from "./types";
import { combineReducers } from "redux";
import { default as editProfileModal } from "../components/modals/editProfileModal/duck/reducer";

const isLoading = (state = true, action) => {
  switch (action.type) {
    case types.BEGIN_LOADING_USER_PROFILE:
      return true;
    case types.FINISH_LOADING_USER_PROFILE:
      return false;
    default:
      return state;
  }
};

const isLoadingClients = (state = true, action) => {
  switch (action.type) {
    case types.BEGIN_LOADING_USER_CLIENTS:
      return true;
    case types.FINISH_LOADING_USER_CLIENTS:
      return false;
    default:
      return state;
  }
};

const clientsAssignedToUser = (state = [], action) => {
  switch (action.type) {
    case types.UPDATE_PROFILE_PAGE_CLIENTS_FOR_LOGGED_IN_USER:
      return action.payload;
    default:
      return state;
  }
};

const modalData = combineReducers({
  editProfileModal,
});

export default combineReducers({
  isLoading,
  isLoadingClients,
  clientsAssignedToUser,
  modalData,
});
