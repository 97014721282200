import { connect } from "react-redux";
import { default as component } from "./ContentMetaForm.jsx";

// Local Selectors
import {
  getContentOrderMetaH1,
  getContentOrderMetaTitle,
  getContentOrderMetaDescription,
  getContentOrderMetaSlug,
} from "./duck/selectors";

// Local Actions
import {
  setContentOrderMetaH1,
  setContentOrderMetaTitle,
  setContentOrderMetaDescription,
  setContentOrderMetaSlug,
  setMetaFormValidity,
} from "./duck/actions";

const mapStateToProps = (state) => {
  return {
    h1: getContentOrderMetaH1(state),
    title: getContentOrderMetaTitle(state),
    description: getContentOrderMetaDescription(state),
    slug: getContentOrderMetaSlug(state),
  };
};

const mapDispatchToProps = {
  setContentOrderMetaH1,
  setContentOrderMetaTitle,
  setContentOrderMetaDescription,
  setContentOrderMetaSlug,
  setMetaFormValidity,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
