import { combineReducers } from "redux";
import types from "./types";

const alteredKeywordListTitle = (state = "", action) => {
  switch (action.type) {
    case types.KEYWORD_GENERATOR_TOOL_SET_ALTERED_LIST_TITLE: {
      return action.payload;
    }
    case "DISMISS_MODAL": {
      return "";
    }
    default: {
      return state;
    }
  }
};

const alteredKeywordListKeywords = (state = "", action) => {
  switch (action.type) {
    case types.KEYWORD_GENERATOR_TOOL_SET_ALTERED_LIST_KEYWORDS: {
      return action.payload;
    }
    case "DISMISS_MODAL": {
      return "";
    }
    default: {
      return state;
    }
  }
};

const alteredKeywordListStackOrderID = (state = "", action) => {
  switch (action.type) {
    case types.KEYWORD_GENERATOR_TOOL_SET_ALTERED_LIST_STACK_ORDER_ID: {
      return action.payload;
    }
    case "DISMISS_MODAL": {
      return "";
    }
    default: {
      return state;
    }
  }
};

const focusedKeywordList = (
  state = { title: "", keywords: [], _id: "" },
  action
) => {
  switch (action.type) {
    case types.KEYWORD_GENERATOR_TOOL_SET_FOCUSED_KEYWORD_LIST: {
      const newState = {
        title: action.payload.title,
        keywords: action.payload.keywords.join(" | "),
        stackOrderID: action.payload.stackOrderID,
        _id: action.payload._id,
      };
      return newState;
    }
    case "DISMISS_MODAL": {
      return { title: "", keywords: [], _id: "" };
    }
    default: {
      return state;
    }
  }
};

const formValidity = (state = true, action) => {
  switch (action.type) {
    case types.KEYWORD_GENERATOR_TOOL_SET_ALTERED_LIST_FORM_VALIDITY: {
      return action.payload;
    }
    case "DISMISS_MODAL": {
      return true;
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({
  alteredKeywordListTitle,
  alteredKeywordListKeywords,
  alteredKeywordListStackOrderID,
  focusedKeywordList,
  formValidity,
});
