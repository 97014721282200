import React, { Fragment } from "react";
import Loader from "react-loader-spinner";

const LoadingSpinnerWithMessage = props => {
  const { message } = props;
  return (
    <Fragment>
      <div className="col-12 text-center">
        <Loader type="Puff" color="#f87300" height={100} width={100} />
      </div>
      <div className="col-12 text-center mt-2">{message}</div>
    </Fragment>
  );
};

export default LoadingSpinnerWithMessage;
