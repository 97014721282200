const KEYWORD_GENERATOR_TOOL_SET_NEW_LIST_TITLE =
  "KEYWORD_GENERATOR_TOOL_SET_NEW_LIST_TITLE";
const KEYWORD_GENERATOR_TOOL_SET_NEW_LIST_KEYWORDS =
  "KEYWORD_GENERATOR_TOOL_SET_NEW_LIST_KEYWORDS";
const KEYWORD_GENERATOR_TOOL_SET_NEW_LIST_FORM_VALIDITY =
  "KEYWORD_GENERATOR_TOOL_SET_NEW_LIST_FORM_VALIDITY";

export default {
  KEYWORD_GENERATOR_TOOL_SET_NEW_LIST_TITLE,
  KEYWORD_GENERATOR_TOOL_SET_NEW_LIST_KEYWORDS,
  KEYWORD_GENERATOR_TOOL_SET_NEW_LIST_FORM_VALIDITY,
};
