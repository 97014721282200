import { connect } from "react-redux";
import { default as component } from "./ContentOrdersNeedsReviewTable.jsx";

// Global Actions & Selectors
import { dismissModal } from "../../../common/UI/ActiveModal/duck/actions";

// Local Actions & Selectors
import {
  isLoading,
  getContentOrdersThatNeedEditing,
} from "../../duck/selectors";
import { setFocusedContentOrder } from "../modals/ReviewContentOrderModal/duck/actions";

const mapStateToProps = (state) => {
  return {
    isLoading: isLoading(state),
    contentOrders: getContentOrdersThatNeedEditing(state),
  };
};

const mapDispatchToProps = {
  dismissModal,
  setFocusedContentOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
