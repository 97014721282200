import { NAME } from "../../../duck/constants";
const _ = require("lodash");

export const getSplitCities = (state) => {
  return state.tools[NAME].splitCities;
};

export const getStateAbbreviation = (state) => {
  return state.tools[NAME].stateAbbreviation;
};

export const getKeywordLists = (state) => {
  return state.tools[NAME].keywordLists;
};

export const getUsedKeywordLists = (state) => {
  return state.tools[NAME].usedKeywordLists;
};

export const getGeneratedWords = (state) => {
  return state.tools[NAME].generatedWords;
};
