import React, { Component, Fragment } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { DebounceInput } from "react-debounce-input";

import { default as CustomerResultsTable } from "./components/CustomerResultsTable";
import { default as AddCustomerModal } from "./components/AddCustomerModal";
import { PaginationCustomerSearch } from "./components/PaginationCustomerSearch";

class CustomerSearchComponent extends Component {
  componentDidMount() {
    this.props.searchRecentCustomers();
  }

  render() {
    const {
      currentView,
      activateModal,
      toggleView,
      searchForCustomers,
      isLoading,
    } = this.props;
    console.log(this.props);
    return (
      <div className="row">
        <div className="col-12 p-2">
          <Card>
            <CardBody>
              <CardTitle>
                <div className="d-flex align-middle">
                  <div className="flex-grow-1">
                    <PaginationCustomerSearch />
                  </div>
                  <div className="mr-3 pt-2">
                    <span>
                      <FontAwesomeIcon
                        icon={faPlus}
                        onClick={() => activateModal("addCustomer")}
                      />
                    </span>
                  </div>
                  <div className="pt-1">
                    <DebounceInput
                      type="text"
                      id="searchQuery"
                      className="text-right pr-2"
                      placeholder="Client Name"
                      minLength={2}
                      debounceTimeout={500}
                      onChange={searchForCustomers}
                    />
                  </div>
                </div>
              </CardTitle>
              {isLoading ? <Fragment /> : <CustomerResultsTable />}
            </CardBody>
          </Card>
          <AddCustomerModal />
        </div>
      </div>
    );
  }
}

export default CustomerSearchComponent;
