import { connect } from "react-redux";
import { default as component } from "./ProfilePage.jsx";

// global actions
import {
  activateModal,
  dismissModal,
} from "../common/UI/ActiveModal/duck/actions";

// local actions
import { fetchClientsByLoggedInUser } from "./duck/actions";

// selectors
import { getLoggedInUser } from "../common/UserDetails/duck/selectors";

const MapStateToProps = (state) => {
  return {
    loggedInUser: getLoggedInUser(state),
  };
};

const MapDispatchToProps = {
  activateModal,
  dismissModal,
  fetchClientsByLoggedInUser,
};

export default connect(MapStateToProps, MapDispatchToProps)(component);
