import { connect } from "react-redux";
import { default as component } from "./KeywordGeneratorTool.jsx";

// local store
import {
  isLoading,
  getActiveTab,
  getGeneratedKeywords,
  getUsedKeywordLists,
} from "./duck/selectors.js";

// local actions
import { setActiveTab, fetchKeywordLists } from "./duck/actions.js";

const mapStateToProps = (state) => {
  return {
    activeTab: getActiveTab(state),
    isLoading: isLoading(state),
    generatedKeywords: getGeneratedKeywords(state),
    usedKeywordLists: getUsedKeywordLists(state),
  };
};

const mapDispatchToProps = {
  setActiveTab,
  fetchKeywordLists,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
