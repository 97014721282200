const SET_FOCUSED_ORDER_FOR_UPLOAD_TO_BOX =
  "SET_FOCUSED_ORDER_FOR_UPLOAD_TO_BOX";
const UPDATE_CONTENTS_FOR_BOX_UPLOAD = "UPDATE_CONTENTS_FOR_BOX_UPLOAD";
const BEGIN_UPLOAD_OF_CONTENT_ORDER = "BEGIN_UPLOAD_OF_CONTENT_ORDER";
const UPLOAD_OF_CONTENT_ORDER_TO_BOX_FAILED =
  "UPLOAD_OF_CONTENT_ORDER_TO_BOX_FAILED";
const BOX_CONTENT_ORDER_UPLOAD_TOGGLE_SUBMIT_LOCK =
  "BOX_CONTENT_ORDER_UPLOAD_TOGGLE_SUBMIT_LOCK";

const UNSET_UPLOADING_CONTENT_ORDERS_FOCUSED_CONTENT_ORDER =
  "UNSET_UPLOADING_CONTENT_ORDERS_FOCUSED_CONTENT_ORDER";

const UPLOAD_OF_CONTENT_ORDER_TO_BOX_SUCCESS =
  "UPLOAD_OF_CONTENT_ORDER_TO_BOX_SUCCESS";
const UPLOAD_CONTENT_TO_BOX_UPDATE_FOCUSED_ORDER_HTML =
  "UPLOAD_CONTENT_TO_BOX_UPDATE_FOCUSED_ORDER_HTML";

export default {
  SET_FOCUSED_ORDER_FOR_UPLOAD_TO_BOX,
  UPDATE_CONTENTS_FOR_BOX_UPLOAD,
  BEGIN_UPLOAD_OF_CONTENT_ORDER,
  UPLOAD_OF_CONTENT_ORDER_TO_BOX_FAILED,
  BOX_CONTENT_ORDER_UPLOAD_TOGGLE_SUBMIT_LOCK,
  UNSET_UPLOADING_CONTENT_ORDERS_FOCUSED_CONTENT_ORDER,
  UPLOAD_OF_CONTENT_ORDER_TO_BOX_SUCCESS,
  UPLOAD_CONTENT_TO_BOX_UPDATE_FOCUSED_ORDER_HTML,
};
