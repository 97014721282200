import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import { Table, Badge } from "reactstrap";

const CustomerProjectsTable = (props) => {
  const { projects } = props;

  const getStatusColor = (status) => {
    switch (status) {
      case "Active":
        return "success";
      case "Paused (Request)":
        return "warning";
      case "Paused (Billing)":
        return "warning";
      case "Cancelled":
        return "danger";
      default:
        return "success";
    }
  };

  return (
    <Fragment>
      {projects.length === 0 ? (
        <span>There are no projects for this customer in the database.</span>
      ) : (
        <Table size="sm" hover>
          <thead>
            <th>Project Domain</th>
            <th className="text-center">Status</th>
            <th className="text-center">Monthly Spend</th>
            <th className="text-right">Actions</th>
          </thead>
          <tbody>
            {projects.map((project) => (
              <tr>
                <td>{project.local.domain}</td>
                <td className="text-center">
                  <Badge
                    size="sm"
                    color={getStatusColor(project.meta.status)}
                    className="mr-1"
                  >
                    {project.meta.status}
                  </Badge>
                </td>
                <td className="text-center">
                  {project.local.monthlySpend === 0 ? (
                    <span>Not Set</span>
                  ) : (
                    <span>${project.local.monthlySpend}</span>
                  )}
                </td>
                <td className="text-right">
                  <a href={"/project/view/" + project._id}>
                    <FontAwesomeIcon icon={faArrowAltCircleRight} />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Fragment>
  );
};

export default CustomerProjectsTable;
