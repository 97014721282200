import { NAME } from "./constants";

export const getContentOrderMetaH1 = (state) => {
  return state.pages.projectView.modalData.addContentOrder[NAME].h1;
};

export const getContentOrderMetaTitle = (state) => {
  return state.pages.projectView.modalData.addContentOrder[NAME].title;
};

export const getContentOrderMetaDescription = (state) => {
  return state.pages.projectView.modalData.addContentOrder[NAME].description;
};

export const getContentOrderMetaSlug = (state) => {
  return state.pages.projectView.modalData.addContentOrder[NAME].slug;
};
