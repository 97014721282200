import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { default as ContentOrderForm } from "./components/ContentOrderForm/ContentOrderForm.js";
import { default as ContentOrderPlacePicker } from "./components/ContentOrderPlacePicker/ContentOrderPlacePicker.js";
import { default as ContentOrderKeywords } from "./components/ContentOrderKeywords/ContentOrderKeywords.js";
import { default as ContentMetaForm } from "./components/ContentMetaForm/ContentMetaForm.js";
import { default as TextBrokerOrderResults } from "./components/TextBrokerOrderResults/TextBrokerOrderResults.js";

import { faMap, faBullseye, faCode } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export default class AddContentOrderModal extends Component {
  render() {
    const {
      activeModal,
      currentView,
      contentOrder,
      templateType,
      dismissModal,
      formSubmitted,
      formValidity,
      metaValidity,
      projectDomain,
      projectID,
      projectTargetablePlaces,
      requestStage,
      setContentOrderCurrentView,
      submitContentOrder,
      retrySendingContentOrder,
      resetContentOrderForm,
    } = this.props;

    const checkFormValidity = () => {
      if (formValidity && metaValidity) {
        return false;
      } else {
        return true;
      }
    };

    return (
      <Modal
        isOpen={activeModal === "addContentOrderModal"}
        toggle={() => dismissModal("addContentOrderModal")}
      >
        <ModalHeader>Submit New Text Broker Content Order</ModalHeader>
        <ModalBody>
          {!formSubmitted && currentView === "default" && <ContentOrderForm />}
          {!formSubmitted && currentView === "Pick Places" && (
            <ContentOrderPlacePicker />
          )}
          {!formSubmitted && currentView === "Pick Keywords" && (
            <ContentOrderKeywords />
          )}
          {!formSubmitted && currentView === "Set Content Meta" && (
            <ContentMetaForm />
          )}
          {formSubmitted && <TextBrokerOrderResults />}
        </ModalBody>
        <ModalFooter>
          {requestStage === "default" ? (
            <Fragment>
              {projectTargetablePlaces.length > 0 && (
                <Button
                  outline
                  className="mr-1"
                  color={currentView === "Pick Places" ? "warning" : "info"}
                  onClick={() =>
                    setContentOrderCurrentView(currentView, "Pick Places")
                  }
                  active={currentView === "Pick Places"}
                  disabled={templateType === null}
                >
                  <FontAwesomeIcon icon={faMap} />
                </Button>
              )}
              <Button
                outline
                className="mr-1"
                color={currentView === "Pick Keywords" ? "warning" : "info"}
                onClick={() =>
                  setContentOrderCurrentView(currentView, "Pick Keywords")
                }
                active={currentView === "Pick Keywords"}
                disabled={templateType === null}
              >
                <FontAwesomeIcon icon={faBullseye} />
              </Button>
              <Button
                outline
                className="mr-auto"
                color={currentView === "Set Content Meta" ? "warning" : "info"}
                onClick={() =>
                  setContentOrderCurrentView(currentView, "Set Content Meta")
                }
                active={currentView === "Set Content Meta"}
                disabled={templateType === null}
              >
                <FontAwesomeIcon icon={faCode} />
              </Button>
              <Button
                className="mr-1"
                color="success"
                disabled={formSubmitted || checkFormValidity()}
                onClick={() =>
                  submitContentOrder(contentOrder, projectDomain, projectID)
                }
              >
                Submit
              </Button>
              <Button
                color="secondary"
                onClick={() => dismissModal("addContentOrderModal")}
              >
                Cancel
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              {requestStage === "sending" && (
                <Button className="btn-block" color="success" disabled>
                  Sending
                </Button>
              )}
              {requestStage === "updating keywords" && (
                <Button className="btn-block" color="success" disabled>
                  Updating Keywords
                </Button>
              )}
              {requestStage === "failed" && (
                <Button
                  className="btn-block"
                  color="success"
                  onClick={() => retrySendingContentOrder()}
                >
                  Attempt Resending
                </Button>
              )}
              {requestStage === "success" && (
                <Button
                  className="btn-block"
                  color="success"
                  onClick={() => resetContentOrderForm()}
                >
                  Submit another order!
                </Button>
              )}
            </Fragment>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}
