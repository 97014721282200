import React, { Fragment } from "react";
import { MODAL_NAME } from "./duck/constants";

import {
  Alert,
  Button,
  Input,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

const AddNoteModal = (props) => {
  // Globals
  const { activeModal, dismissModal } = props;
  // Local from state
  const { projectID } = props;
  const { newProjectLink, formValidity, displayWarning } = props;
  const { service } = props.newProjectLink;
  // Action Triggers
  const {
    setNewProjectLinkUrl,
    setNewProjectLinkService,
    addNewProjectLink,
  } = props;
  return (
    <Fragment>
      <Modal
        isOpen={activeModal === MODAL_NAME}
        toggle={() => dismissModal(MODAL_NAME)}
      >
        <ModalHeader>Add New Project Link</ModalHeader>
        <ModalBody>
          <Form>
            <Input
              type="select"
              className="form-control mb-1"
              defaultValue={service}
              onChange={(event) => setNewProjectLinkService(event.target.value)}
            >
              <option value="0" disabled>
                Please select a Service
              </option>
              <option disabled>--- CRMs ---</option>
              <option value="RYNOhub">RYNOhub</option>
              <option value="SalesForce">SalesForce</option>
              <option disabled>--- Google ---</option>
              <option value="Google Adwords Keyword Planner">
                Adwords Keyword Planner
              </option>
              <option value="Google Analytics">Analytics</option>
              <option value="Google Search Console">Search Console</option>
              <option value="Google Tag Manager">Tag Manager</option>
              <option value="Google Drive Folder">Drive Folder</option>
              <option disabled>--- Trello ---</option>
              <option value="Trello Parent Card">Parent Project Card</option>
              <option value="Trello SEO Card">SEO Card</option>
              <option value="Trello TSEO Card">TSEO Card</option>
              <option disabled>--- Misc ---</option>
              <option value="Advanced Web Rankings">AWR</option>
              <option value="ManageWP WordPress Dashboard">
                ManageWP Dashboard
              </option>
              <option value="SEMrush Overview">SEMrush Overview</option>
            </Input>
            <Input
              type="text"
              className="form-control"
              placeholder="Insert full URL here .. https://sample.url/12345"
              onChange={(event) => setNewProjectLinkUrl(event.target.value)}
              disabled={service === "0"}
            />
          </Form>
          {displayWarning && (
            <Alert color="info" className="mt-2">
              <span style={{ fontSize: "12px", lineHeight: "12px" }}>
                <em>
                  This field requires the following validation: <br />
                  <ul>
                    <li>http:// or https://</li>
                    <li>No spaces</li>
                    <li>Minimum 10 characters long.</li>
                  </ul>
                </em>
              </span>
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            disabled={!formValidity}
            onClick={() => addNewProjectLink(projectID, newProjectLink)}
          >
            Add Link
          </Button>
          <Button color="secondary" onClick={() => dismissModal(MODAL_NAME)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default AddNoteModal;
