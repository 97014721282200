import { NAME } from "../../../duck/constants";
import { MODAL_NAME } from "./constants";

export const getAlteredKeywordListTitle = (state) => {
  return state.tools[NAME].modalData[MODAL_NAME].alteredKeywordListTitle;
};

export const getAlteredKeywordListKeywords = (state) => {
  return state.tools[NAME].modalData[MODAL_NAME].alteredKeywordListKeywords;
};

export const getAlteredKeywordListStackOrderID = (state) => {
  return state.tools[NAME].modalData[MODAL_NAME].alteredKeywordListStackOrderID;
};

export const getAlteredKeywordListFormValidity = (state) => {
  return state.tools[NAME].modalData[MODAL_NAME].formValidity;
};

export const getFocusedKeywordList = (state) => {
  return state.tools[NAME].modalData[MODAL_NAME].focusedKeywordList;
};
