import axios from "axios";
import types from "./types";

const editKeywordGeneratorList =
  (listID, title, modifiedKeywords, stackOrderID) => (dispatch) => {
    let newKeywordArray = modifiedKeywords.split("|");
    let newKeywordArrayTrimmed = newKeywordArray.map((el) => {
      return el.trim();
    });

    axios
      .post("/api/keyword-generator/update-existing-keyword-list", {
        _id: listID,
        title,
        keywords: newKeywordArrayTrimmed.sort(),
        stackOrderID,
      })
      .then((res) => {
        dispatch({
          type: "KEYWORD_GENERATOR_TOOL_RECEIVE_KEYWORD_LISTS",
          payload: res.data,
        });
      });
    dispatch({
      type: "DISMISS_MODAL",
    });
  };

const setAlteredTitleForKeywordList = (title) => (dispatch) => {
  dispatch({
    type: types.KEYWORD_GENERATOR_TOOL_SET_ALTERED_LIST_TITLE,
    payload: title,
  });
};

const setAlteredKeywordsForKeywordList = (keywords) => (dispatch) => {
  dispatch({
    type: types.KEYWORD_GENERATOR_TOOL_SET_ALTERED_LIST_KEYWORDS,
    payload: keywords,
  });
};

const setAlteredStackOrderIDForKeywordList = (stackOrderID) => (dispatch) => {
  dispatch({
    type: types.KEYWORD_GENERATOR_TOOL_SET_ALTERED_LIST_STACK_ORDER_ID,
    payload: stackOrderID,
  });
};

const checkEditKeywordGeneratorListFormValidity =
  (title, keywords) => (dispatch) => {
    if (
      title === "" ||
      title.lenghth < 5 ||
      keywords === "" ||
      keywords.length < 5
    ) {
      dispatch({
        type: types.KEYWORD_GENERATOR_TOOL_SET_ALTERED_LIST_FORM_VALIDITY,
        payload: true,
      });
    } else {
      dispatch({
        type: types.KEYWORD_GENERATOR_TOOL_SET_ALTERED_LIST_FORM_VALIDITY,
        payload: false,
      });
    }
  };

export {
  editKeywordGeneratorList,
  setAlteredTitleForKeywordList,
  setAlteredKeywordsForKeywordList,
  setAlteredStackOrderIDForKeywordList,
  checkEditKeywordGeneratorListFormValidity,
};
