import types from "./types";

const fetchWorkableOrders = () => (dispatch) => {
  fetch(`/api/content-orders/fetch-workable-orders`)
    .then((res) => res.json())
    .then((orders) => {
      dispatch({
        type: types.CONTENT_DASHBOARD_RECEIVE_CONTENT_ORDERS,
        payload: orders,
      });
      dispatch({ type: types.FINISH_CONTENT_DASHBOARD_LOADING });
    });
};

const setActiveTab = (tab) => (dispatch) => {
  dispatch({
    type: types.CONTENT_DASHBOARD_SET_ACTIVE_TAB,
    payload: tab,
  });
};

export { fetchWorkableOrders, setActiveTab };
