import { postData } from "../../../../../utils/fetch-requests";
import types from "./types";

const setFocusedCrowdContentOrderForPublishing = (order) => (dispatch) => {
  dispatch({
    type:
      types.PUBLISHING_CROWD_CONTENT_ORDERS_SET_FOCUSED_ORDER_FOR_PUBLISHING,
    payload: order,
  });
  dispatch({
    type: "ACTIVATE_MODAL",
    payload: "PublishCrowdContentOrderModal",
  });
};

const publishCrowdContentOrder = (contentOrder) => (dispatch) => {
  postData("/api/crowd-content/move-to-published", {
    order_id: contentOrder.crowdContent._id,
  })
    .then((doc) => {
      fetch(`/api/crowd-content/list-orders`)
        .then((res) => res.json())
        .then((orders) => {
          dispatch({
            type: "CROWD_CONTENT_DASHBOARD_RECEIVE_CONTENT_ORDERS",
            payload: orders,
          });
        });
      dispatch({
        type: "DISMISS_MODAL",
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: types.PUBLISHING_CROWD_CONTENT_ORDERS_SUBMISSION_FAILED,
      });
    });
};

const setPublishCrowdContentOrderActiveTab = (tab) => (dispatch) => {
  dispatch({
    type: types.PUBLISH_CROWD_CONTENT_ORDERS_MODAL_SET_ACTIVE_TAB,
    payload: tab,
  });
};

const toggleSubmitLocked = (bool) => (dispatch) => {
  dispatch({
    type: types.PENDING_CROWD_CONTENT_ORDER_TOGGLE_SUBMIT_LOCK,
    payload: bool,
  });
};

export {
  setFocusedCrowdContentOrderForPublishing,
  publishCrowdContentOrder,
  setPublishCrowdContentOrderActiveTab,
  toggleSubmitLocked,
};
