import React, { Component, Fragment } from "react";
import { ViewAssignedProjectsSearch } from "./components";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export default class ViewAssignedProjectsModal extends Component {
  constructor(props) {
    super(props);
    console.log(props);
  }

  render() {
    const { activeModal, dismissModal, targetablePlace } = this.props;
    return (
      <Modal
        isOpen={activeModal === "viewAssignedProjectsModal"}
        toggle={() => dismissModal("viewAssignedProjectsModal")}
      >
        <ModalHeader>
          Project for{" "}
          {targetablePlace === null ? (
            <Fragment />
          ) : (
            <span>
              {targetablePlace.details.name}, {targetablePlace.details.parent}
            </span>
          )}
        </ModalHeader>
        <ModalBody>
          <ViewAssignedProjectsSearch
            assignedProjects={
              targetablePlace !== null && targetablePlace.relationships.projects
            }
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => this.props.dismissModal("viewAssignedProjectsModal")}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
