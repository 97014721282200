import { postData } from "../../../../../utils/fetch-requests";
import types from "./types";

const setFocusedContentOrder = (order) => (dispatch) => {
  dispatch({
    type: types.PENDING_CONTENT_ORDERS_SET_FOCUSED_ORDER,
    payload: order,
  });
  dispatch({
    type:
      types.PENDING_CONTENT_ORDERS_BEGIN_LOADING_FOCUSED_CONTENT_ORDER_PREVIEW,
  });
  dispatch({
    type: "ACTIVATE_MODAL",
    payload: "ReviewContentOrderModal",
  });
  postData("/api/textbroker/preview-order", {
    order_id: order.textBroker._id,
  }).then((res) => {
    let orderPreview = res.data;
    orderPreview.text = orderPreview.text.substring(92);
    orderPreview.text = orderPreview.text.replace(`<br><hr>`, "");
    dispatch({
      type: types.PENDING_CONTENT_ORDERS_RECEIVE_CONTENT_ORDER_PREVIEW,
      payload: orderPreview,
    });
    dispatch({
      type:
        types.PENDING_CONTENT_ORDERS_FINISH_LOADING_FOCUSED_CONTENT_ORDER_PREVIEW,
    });
  });
};

const updateFocusedContentOrderText = (preview, event) => (dispatch) => {
  dispatch({
    type: types.PENDING_CONTENT_ORDERS_UPDATE_FOCUSED_ORDER_HTML,
    payload: { title: preview.title, text: event.target.value },
  });
};

const approvePendingContentOrder = (contentOrder, editedOrder) => (
  dispatch
) => {
  dispatch({
    type: types.PENDING_CONTENT_ORDER_TOGGLE_SUBMIT_LOCK,
    payload: true,
  });
  dispatch({
    type: types.PENDING_CONTENT_ORDERS_SUBMISSION_BOX_DOC_CREATING,
  });
  postData("/api/box/upload-content-order", {
    contentOrder,
    editedOrder,
  })
    .then((doc) => {
      dispatch({
        type: types.PENDING_CONTENT_ORDERS_SUBMISSION_STARTED,
      });
      postData("/api/textbroker/approve-content-order", contentOrder).then(
        (doc) => {
          dispatch({
            type: types.PENDING_CONTENT_ORDERS_SUBMISSION_SUCCESSFUL,
          });
          setTimeout(() => {
            fetch(`/api/content-orders/fetch-workable-orders`)
              .then((res) => res.json())
              .then((orders) => {
                dispatch({
                  type: "CONTENT_DASHBOARD_RECEIVE_CONTENT_ORDERS",
                  payload: orders,
                });
                dispatch({
                  type:
                    types.UNSET_PENDING_CONTENT_ORDERS_FOCUSED_CONTENT_ORDER,
                });
                dispatch({ type: "DISMISS_MODAL" });
              });
          }, 1000);
        }
      );
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: types.PENDING_CONTENT_ORDERS_SUBMISSION_FAILED,
      });
    });
};

const toggleSubmitLocked = (bool) => (dispatch) => {
  dispatch({
    type: types.PENDING_CONTENT_ORDER_TOGGLE_SUBMIT_LOCK,
    payload: bool,
  });
};

const setActiveTab = (currentlyActiveTab, tabToActivate) => (dispatch) => {
  if (currentlyActiveTab !== tabToActivate) {
    dispatch({
      type: types.PENDING_CONTENT_ORDERS_SET_ACTIVE_TAB,
      payload: tabToActivate,
    });
  }
};

const setMetaEditMode = (field, newData) => (dispatch) => {
  dispatch({
    type: types.PENDING_CONTENT_ORDERS_SET_META_EDIT_MODE,
    payload: { editing: true, field, newData },
  });
};

const setMetaFieldData = (field, data) => (dispatch) => {
  dispatch({
    type: types.PENDING_CONTENT_ORDERS_SET_META_FIELD_DATA,
    payload: { field, data },
  });
};

const saveNewMetaFieldData = (_id, field, data) => (dispatch) => {
  postData("/api/content-orders/update-content-order-meta", {
    _id,
    field,
    data,
  }).then((doc) => {
    dispatch({
      type: types.PENDING_CONTENT_ORDERS_SET_FOCUSED_ORDER,
      payload: doc,
    });
    dispatch({
      type: types.PENDING_CONTENT_ORDERS_SET_META_EDIT_MODE,
      payload: { editing: false, field: null, newData: "" },
    });
    fetch(`/api/content-orders/fetch-workable-orders`)
      .then((res) => res.json())
      .then((orders) => {
        dispatch({
          type: "CONTENT_DASHBOARD_RECEIVE_CONTENT_ORDERS",
          payload: orders,
        });
      });
  });
};

export {
  setFocusedContentOrder,
  setActiveTab,
  setMetaEditMode,
  setMetaFieldData,
  saveNewMetaFieldData,
  updateFocusedContentOrderText,
  approvePendingContentOrder,
  toggleSubmitLocked,
};
