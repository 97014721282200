import { NAME } from "./constants";

export const getCrowdContentOrderMetaH1 = (state) => {
  return state.pages.projectView.modalData.addCrowdContentOrder[NAME].h1;
};

export const getCrowdContentOrderMetaTitle = (state) => {
  return state.pages.projectView.modalData.addCrowdContentOrder[NAME].title;
};

export const getCrowdContentOrderMetaDescription = (state) => {
  return state.pages.projectView.modalData.addCrowdContentOrder[NAME]
    .description;
};

export const getCrowdContentOrderMetaSlug = (state) => {
  return state.pages.projectView.modalData.addCrowdContentOrder[NAME].slug;
};
