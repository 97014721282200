const PLACE_PICKER_MODAL_BEGIN_SEARCH = "PLACE_PICKER_MODAL_BEGIN_SEARCH";
const PLACE_PICKER_MODAL_FINISH_SEARCH = "PLACE_PICKER_MODAL_FINISH_SEARCH";
const PLACE_PICKER_MODAL_RECEIVE_SEARCH_RESULTS =
  "PLACE_PICKER_MODAL_RECEIVE_SEARCH_RESULTS";
const PLACE_PICKER_MODAL_EMPTY_OUT_PLACES =
  "PLACE_PICKER_MODAL_EMPTY_OUT_PLACES";

const PLACE_PICKER_SET_ACTIVE_TAB = "PLACE_PICKER_SET_ACTIVE_TAB";
const PLACE_PICKER_SET_ACTIVE_VIEW = "PLACE_PICKER_SET_ACTIVE_VIEW";

const PLACE_PICKER_MODAL_HAS_SEARCHED = "PLACE_PICKER_MODAL_HAS_SEARCHED";
const PLACE_PICKER_MODAL_RECEIVE_EXISTING_PLACES_SEARCH_RESULTS =
  "PLACE_PICKER_MODAL_RECEIVE_EXISTING_PLACES_SEARCH_RESULTS";
const PLACE_PICKER_MODAL_UPDATE_EXISTING_PLACES_SEARCH_QUERY =
  "PLACE_PICKER_MODAL_UPDATE_EXISTING_PLACES_SEARCH_QUERY";
const PLACE_PICKER_MODAL_SUCCESSFULLY_ADDED_NEW_PLACE =
  "PLACE_PICKER_MODAL_SUCCESSFULLY_ADDED_NEW_PLACE";
const RECEIVE_NEWLY_ADDED_PLACE = "RECEIVE_NEWLY_ADDED_PLACE";

export default {
  PLACE_PICKER_MODAL_BEGIN_SEARCH,
  PLACE_PICKER_MODAL_FINISH_SEARCH,
  PLACE_PICKER_MODAL_RECEIVE_SEARCH_RESULTS,
  PLACE_PICKER_MODAL_EMPTY_OUT_PLACES,
  PLACE_PICKER_MODAL_HAS_SEARCHED,
  PLACE_PICKER_SET_ACTIVE_TAB,
  PLACE_PICKER_SET_ACTIVE_VIEW,
  PLACE_PICKER_MODAL_RECEIVE_EXISTING_PLACES_SEARCH_RESULTS,
  PLACE_PICKER_MODAL_SUCCESSFULLY_ADDED_NEW_PLACE,
  PLACE_PICKER_MODAL_UPDATE_EXISTING_PLACES_SEARCH_QUERY,
  RECEIVE_NEWLY_ADDED_PLACE,
};
