import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faMap, faBullseye, faCode } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { default as CrowdContentOrderForm } from "./components/CrowdContentOrderForm/CrowdContentOrderForm.js";
import { default as CrowdContentOrderMetaForm } from "./components/CrowdContentMetaForm/CrowdContentMetaForm.js";
import { default as CrowdContentOrderPlacePicker } from "./components/CrowdContentOrderPlacePicker/CrowdContentOrderPlacePicker.js";
import { default as CrowdContentOrderKeywords } from "./components/CrowdContentOrderKeywords/CrowdContentOrderKeywords.js";

export default class AddCrowdContentOrderModal extends Component {
  render() {
    const {
      activeModal,
      dismissModal,
      projectDomain,
      projectID,
      crowdContentOrder,
      formValidity,
      metaValidity,
      currentView,
      templateType,
      formSubmitted,
      requestStage,
      projectTargetablePlaces,
      setCrowdContentOrderCurrentView,
      resetCrowdContentOrderForm,
      submitCrowdContentOrder,
    } = this.props;

    const checkFormValidity = () => {
      if (formValidity && metaValidity) {
        return false;
      } else {
        return true;
      }
    };

    return (
      <Modal
        isOpen={activeModal === "addCrowdContentOrderModal"}
        toggle={() => dismissModal("addCrowdContentOrderModal")}
      >
        <ModalHeader>Submit New Crowd Content Order</ModalHeader>
        <ModalBody>
          {!formSubmitted && currentView === "default" && (
            <CrowdContentOrderForm />
          )}
          {!formSubmitted && currentView === "Pick Places" && (
            <CrowdContentOrderPlacePicker />
          )}
          {!formSubmitted && currentView === "Pick Keywords" && (
            <CrowdContentOrderKeywords />
          )}
          {!formSubmitted && currentView === "Set Content Meta" && (
            <CrowdContentOrderMetaForm />
          )}
          {formSubmitted && <Fragment />}
        </ModalBody>
        <ModalFooter>
          {requestStage === "default" ? (
            <Fragment>
              {projectTargetablePlaces.length > 0 && (
                <Button
                  outline
                  className="mr-1"
                  color={currentView === "Pick Places" ? "warning" : "info"}
                  onClick={() =>
                    setCrowdContentOrderCurrentView(currentView, "Pick Places")
                  }
                  active={currentView === "Pick Places"}
                  disabled={templateType === null}
                >
                  <FontAwesomeIcon icon={faMap} />
                </Button>
              )}
              <Button
                outline
                className="mr-1"
                color={currentView === "Pick Keywords" ? "warning" : "info"}
                onClick={() =>
                  setCrowdContentOrderCurrentView(currentView, "Pick Keywords")
                }
                active={currentView === "Pick Keywords"}
                disabled={templateType === null}
              >
                <FontAwesomeIcon icon={faBullseye} />
              </Button>
              <Button
                outline
                className="mr-auto"
                color={currentView === "Set Content Meta" ? "warning" : "info"}
                onClick={() =>
                  setCrowdContentOrderCurrentView(
                    currentView,
                    "Set Content Meta"
                  )
                }
                active={currentView === "Set Content Meta"}
                disabled={templateType === null}
              >
                <FontAwesomeIcon icon={faCode} />
              </Button>
              <Button
                className="mr-1"
                color="success"
                disabled={formSubmitted || checkFormValidity()}
                onClick={() =>
                  submitCrowdContentOrder(
                    crowdContentOrder,
                    projectDomain,
                    projectID
                  )
                }
              >
                Submit
              </Button>
              <Button
                color="secondary"
                onClick={() => dismissModal("addContentOrderModal")}
              >
                Cancel
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              {requestStage === "sending" && (
                <Button className="btn-block" color="success" disabled>
                  Sending
                </Button>
              )}
              {requestStage === "success" && (
                <Button
                  className="btn-block"
                  color="success"
                  onClick={() => resetCrowdContentOrderForm()}
                >
                  Submit another order!
                </Button>
              )}
            </Fragment>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}
