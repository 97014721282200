const NAME = "addContentOrder";

export const getContentOrderDetails = (state) => {
  return state.pages.projectView.modalData[NAME];
};

export const getContentOrderMinimumWords = (state) => {
  return state.pages.projectView.modalData[NAME].minimumWords;
};

export const getContentOrderMaximumWords = (state) => {
  return state.pages.projectView.modalData[NAME].maximumWords;
};

export const getContentOrderTurnAround = (state) => {
  return state.pages.projectView.modalData[NAME].turnAround;
};

export const getContentOrderDueDate = (state) => {
  return state.pages.projectView.modalData[NAME].dueDate;
};

export const getContentOrderShortCode = (state) => {
  return state.pages.projectView.modalData[NAME].shortCode;
};

export const getContentOrderContentType = (state) => {
  return state.pages.projectView.modalData[NAME].contentType;
};

export const getContentOrderTemplateType = (state) => {
  return state.pages.projectView.modalData[NAME].templateType;
};

export const getContentOrderTitle = (state) => {
  return state.pages.projectView.modalData[NAME].orderTitle;
};

export const getContentOrderDescription = (state) => {
  return state.pages.projectView.modalData[NAME].orderDescription;
};

export const getContentOrderSpecialNotes = (state) => {
  return state.pages.projectView.modalData[NAME].specialNotes;
};

export const getContentOrderFileUploads = (state) => {
  return state.pages.projectView.modalData[NAME].fileUploads;
};

export const getContentOrderTargetedPlace = (state) => {
  return state.pages.projectView.modalData[NAME].targetedPlace;
};

export const getContentOrderFormSubmittedStatus = (state) => {
  return state.pages.projectView.modalData[NAME].formSubmitted;
};

export const getContentOrderCurrentView = (state) => {
  return state.pages.projectView.modalData[NAME].currentView;
};

export const getContentOrderRequestStage = (state) => {
  return state.pages.projectView.modalData[NAME].requestStage;
};

export const getContentOrderFormValidity = (state) => {
  return state.pages.projectView.modalData[NAME].formValidity;
};

export const getContentOrderMetaValidity = (state) => {
  return state.pages.projectView.modalData[NAME].contentMeta.formValidity;
};
