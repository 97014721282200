const BEGIN_CONTENT_DASHBOARD_LOADING = "BEGIN_CONTENT_DASHBOARD_LOADING";
const FINISH_CONTENT_DASHBOARD_LOADING = "FINISH_CONTENT_DASHBOARD_LOADING";
const CONTENT_DASHBOARD_RECEIVE_CONTENT_ORDERS =
  "CONTENT_DASHBOARD_RECEIVE_CONTENT_ORDERS";
const CONTENT_DASHBOARD_SET_ACTIVE_TAB = "CONTENT_DASHBOARD_SET_ACTIVE_TAB";

export default {
  BEGIN_CONTENT_DASHBOARD_LOADING,
  FINISH_CONTENT_DASHBOARD_LOADING,
  CONTENT_DASHBOARD_RECEIVE_CONTENT_ORDERS,
  CONTENT_DASHBOARD_SET_ACTIVE_TAB,
};
