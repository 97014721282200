import { NAME } from "./constants";

export const getFocusedContentOrderDetails = (state) => {
  return state.pages.contentDashboard.modalData[NAME].focusedContentOrder;
};

export const getFocusedContentOrderPreview = (state) => {
  return state.pages.contentDashboard.modalData[NAME].preview;
};

export const getFocusedContentOrderNewBoxHTML = (state) => {
  return state.pages.contentDashboard.modalData[NAME].contents;
};

export const getFocusedContentOrderSubmitLockedStatus = (state) => {
  return state.pages.contentDashboard.modalData[NAME].submitLocked;
};

export const getSubmissionStatus = (state) => {
  return state.pages.contentDashboard.modalData[NAME].submissionStatus;
};
