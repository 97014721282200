import { connect } from "react-redux";
import { default as component } from "./PublishContentOrderModal.jsx";

// Global selectors and actions
import { getActiveModal } from "../../../../common/UI/ActiveModal/duck/selectors";
import { dismissModal } from "../../../../common/UI/ActiveModal/duck/actions";

// Local selectors and actions
import {
  toggleSubmitLocked,
  setFocusedContentOrderForPublishing,
  setPublishContentOrderActiveTab,
  publishContentOrder,
} from "./duck/actions";

import {
  getSubmissionStatus,
  getFocusedContentOrderLoadingStatus,
  getFocusedContentOrderSubmitLockedStatus,
  getFocusedContentOrderDetails,
  getFocusedContentOrderHTMLContents,
  getFocusedContentOrderPublishModalActiveTab,
} from "./duck/selectors";

const mapStateToProps = (state) => {
  return {
    isLoading: getFocusedContentOrderLoadingStatus(state),
    activeTab: getFocusedContentOrderPublishModalActiveTab(state),
    submissionStatus: getSubmissionStatus(state),
    submitLocked: getFocusedContentOrderSubmitLockedStatus(state),
    activeModal: getActiveModal(state),
    contentOrder: getFocusedContentOrderDetails(state),
    htmlContents: getFocusedContentOrderHTMLContents(state),
  };
};

const mapDispatchToProps = {
  dismissModal,
  toggleSubmitLocked,
  setPublishContentOrderActiveTab,
  setFocusedContentOrderForPublishing,
  publishContentOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
