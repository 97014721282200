import { postData } from "../../../../../utils/fetch-requests";
import types from "./types";

export const adjustUserName = (name) => (dispatch) => {
  dispatch({
    type: types.EDIT_USER_PROFILE_MODAL_ADJUST_NAME,
    payload: name,
  });
};

export const adjustUserPhoneNumber = (phoneNumber) => (dispatch) => {
  dispatch({
    type: types.EDIT_USER_PROFILE_MODAL_ADJUST_PHONE_NUMBER,
    payload: phoneNumber,
  });
};

export const adjustUserProfilePictureUrl = (url) => (dispatch) => {
  dispatch({
    type: types.EDIT_USER_PROFILE_MODAL_ADJUST_PROFILE_PICTURE_URL,
    payload: url,
  });
};

export const updateUserProfile = (initialValues, editedValues) => (
  dispatch
) => {
  const editedUser = {
    personal: {
      name:
        editedValues.personal.name !== initialValues.personal.name &&
        editedValues.personal.name !== ""
          ? editedValues.personal.name
          : initialValues.personal.name,
      phoneNumber:
        editedValues.personal.phoneNumber !==
          initialValues.personal.phoneNumber &&
        editedValues.personal.phoneNumber !== ""
          ? editedValues.personal.phoneNumber
          : initialValues.personal.phoneNumber,
      profilePictureUrl:
        editedValues.personal.profilePictureUrl !==
          initialValues.personal.profilePictureUrl &&
        editedValues.personal.profilePictureUrl !== ""
          ? editedValues.personal.profilePictureUrl
          : null,
    },
  };
  dispatch({ type: "BEGIN_LOADING_USER_PROFILE" });
  postData("/api/user/update-user-personal-details", editedUser).then((res) => {
    dispatch({ type: "UPDATE_USER_DETAILS", payload: res });
  });
  dispatch({ type: "DISMISS_MODAL" });
  dispatch({ type: "FINISH_LOADING_USER_PROFILE" });
};
