import types from "./types";
import { postData } from "../../../../../utils/fetch-requests";

const setFocusedProjectLinkForRemoval = (link) => (dispatch) => {
  dispatch({
    type: types.REMOVE_PROJECT_LINK_SET_FOCUSED_LINK,
    payload: link,
  });
  dispatch({
    type: "ACTIVATE_MODAL",
    payload: "removeProjectLink",
  });
};

const removeProjectLink = (projectID, linkToRemove) => (dispatch) => {
  const requestData = {
    projectID,
    linkToRemove,
  };
  postData("/api/project/remove-link-from-project", requestData).then(
    (updatedProject) => {
      dispatch({
        type: "RECEIVE_PROJECT_DETAILS",
        payload: updatedProject,
      });
      dispatch({
        type: "DISMISS_MODAL",
      });
      dispatch({
        type: types.REMOVE_PROJECT_LINK_RESET_DATA,
      });
    }
  );
};

export { setFocusedProjectLinkForRemoval, removeProjectLink };
