import { combineReducers } from "redux";
import types from "./types";

const submissionStatus = (state = "unsubmitted", action) => {
  switch (action.type) {
    case types.BEGIN_UPLOAD_OF_CONTENT_ORDER: {
      return "Uploading Content Order to Box";
    }
    case types.UPLOAD_OF_CONTENT_ORDER_TO_BOX_SUCCESS: {
      return "Successfully uploaded content order.";
    }
    case types.UPLOAD_OF_CONTENT_ORDER_TO_BOX_FAILED: {
      return "Failed to upload content order.";
    }
    case types.UNSET_UPLOADING_CONTENT_ORDERS_FOCUSED_CONTENT_ORDER: {
      return "unsubmitted";
    }
    default:
      return state;
  }
};

const contents = (state = "", action) => {
  switch (action.type) {
    case types.UPDATE_CONTENTS_FOR_BOX_UPLOAD: {
      return action.payload;
    }
    case types.UNSET_UPLOADING_CONTENT_ORDERS_FOCUSED_CONTENT_ORDER: {
      return "";
    }
    default: {
      return state;
    }
  }
};

const focusedContentOrder = (state = {}, action) => {
  switch (action.type) {
    case types.SET_FOCUSED_ORDER_FOR_UPLOAD_TO_BOX: {
      return action.payload;
    }
    case types.UNSET_UPLOADING_CONTENT_ORDERS_FOCUSED_CONTENT_ORDER: {
      return {};
    }
    default: {
      return state;
    }
  }
};

const submitLocked = (state = true, action) => {
  switch (action.type) {
    case types.BOX_CONTENT_ORDER_UPLOAD_TOGGLE_SUBMIT_LOCK: {
      return !action.payload;
    }
    case types.UNSET_UPLOADING_CONTENT_ORDERS_FOCUSED_CONTENT_ORDER: {
      return true;
    }
    default:
      return state;
  }
};

export default combineReducers({
  contents,
  submissionStatus,
  submitLocked,
  focusedContentOrder,
});
