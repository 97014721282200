import { combineReducers } from "redux";
import types from "./types";

const isLoading = (state = true, action) => {
  switch (action.type) {
    case types.PUBLISHING_CONTENT_ORDERS_BEGIN_LOADING_FOCUSED_CONTENT_ORDER_PREVIEW: {
      return true;
    }
    case types.UNSET_PUBLISH_CONTENT_ORDER_MODAL: {
      return true;
    }
    case types.PUBLISHING_CONTENT_ORDERS_FINISH_LOADING_FOCUSED_CONTENT_ORDER_PREVIEW: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const submissionStatus = (state = "unsubmitted", action) => {
  switch (action.type) {
    case types.PUBLISHING_CONTENT_ORDERS_SUBMISSION_STARTED: {
      return "Saving Content Order";
    }
    case types.PUBLISHING_CONTENT_ORDERS_SUBMISSION_TEXT_BROKER_ACCEPT_SUCCESS: {
      return "Successfully Saved Content Order";
    }
    case types.PUBLISHING_CONTENT_ORDERS_SUBMISSION_TEXT_BROKER_ACCEPT_FAIL: {
      return "Failed to Save Content Order";
    }
    case types.UNSET_PUBLISH_CONTENT_ORDER_MODAL: {
      return "unsubmitted";
    }
    default:
      return state;
  }
};

const focusedContentOrder = (state = {}, action) => {
  switch (action.type) {
    case types.PENDING_CONTENT_ORDERS_SET_FOCUSED_ORDER_FOR_PUBLISHING: {
      return action.payload;
    }
    case types.UNSET_PUBLISH_CONTENT_ORDER_MODAL: {
      return {};
    }
    default: {
      return state;
    }
  }
};

const htmlContents = (state = "", action) => {
  switch (action.type) {
    case types.PUBLISHING_CONTENT_ORDERS_RECEIVE_CONTENT_ORDER_HTML_CONTENTS: {
      return action.payload;
    }
    case types.UNSET_PUBLISH_CONTENT_ORDER_MODAL: {
      return "";
    }
    default: {
      return state;
    }
  }
};

const submitLocked = (state = true, action) => {
  switch (action.type) {
    case types.PENDING_CONTENT_ORDER_TOGGLE_SUBMIT_LOCK: {
      return !action.payload;
    }
    case types.UNSET_PUBLISH_CONTENT_ORDER_MODAL: {
      return true;
    }
    default:
      return state;
  }
};

const activeTab = (state = "PUBLISH_ORDER_MODAL_CONTENT_TAB", action) => {
  switch (action.type) {
    case types.PUBLISH_CONTENT_ORDERS_MODAL_SET_ACTIVE_TAB: {
      return action.payload;
    }
    case types.UNSET_PUBLISH_CONTENT_ORDER_MODAL: {
      return "PUBLISH_ORDER_MODAL_CONTENT_TAB";
    }
    default:
      return state;
  }
};

export default combineReducers({
  isLoading,
  activeTab,
  submissionStatus,
  submitLocked,
  focusedContentOrder,
  htmlContents,
});
