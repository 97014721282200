import React, { Fragment } from "react";
import { Table } from "reactstrap";

import { generateTableRowsForCategories } from "./duck/constants";

const MilestoneChecklistTable = (props) => {
  const {
    milestoneCategories,
    projectID,
    completedMilestones,
    markMilestoneAsComplete,
    markMilestoneAsIncomplete,
  } = props;
  return (
    <Fragment>
      {milestoneCategories.length > 0 ? (
        <Fragment>
          {milestoneCategories.map((category) => (
            <div className="pt-1">
              <div className="col-12 pl-1">
                <strong>{category}</strong>
              </div>
              <Table size="sm" hover>
                <tbody>
                  {generateTableRowsForCategories(
                    category,
                    projectID,
                    completedMilestones,
                    markMilestoneAsComplete,
                    markMilestoneAsIncomplete
                  )}
                </tbody>
              </Table>
            </div>
          ))}
        </Fragment>
      ) : (
        <span>No Categories Selected.</span>
      )}
    </Fragment>
  );
};

export default MilestoneChecklistTable;
