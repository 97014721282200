import { NAME } from "./constants";
import moment from "moment";

const select = (key) => (state) => state.pages[NAME][key];

export const isLoading = select("isLoading");

export const getProjectID = (state) => {
  return state.pages[NAME].project._id;
};

export const getProjectDetails = (state) => {
  return state.pages[NAME].project;
};

export const getProjectDomainName = (state) => {
  return state.pages[NAME].project.local.domain;
};

export const getContentOrders = (state) => {
  const { contentOrders } = state.pages[NAME].project;
  let contentOrdersByDate = [];
  for (let i = 0; i < contentOrders.length; i++) {
    const orderDate = moment(contentOrders[i].meta.createdOn).format(
      "MMMM Do YYYY"
    );
    if (
      contentOrdersByDate
        .map((dateObj) => dateObj.orderDate)
        .indexOf(orderDate) === -1
    ) {
      const dateForArray = { orderDate, orders: [] };
      contentOrdersByDate.push(dateForArray);
      const index = contentOrdersByDate
        .map((dateObj) => dateObj.orderDate)
        .indexOf(orderDate);
      contentOrdersByDate[index]["orders"].push(contentOrders[i]);
    } else {
      const index = contentOrdersByDate
        .map((dateObj) => dateObj.orderDate)
        .indexOf(orderDate);
      contentOrdersByDate[index].orders.push(contentOrders[i]);
    }
  }
  return contentOrdersByDate;
};

export const getCrowdContentOrders = (state) => {
  const { crowdContentOrders } = state.pages[NAME].project;
  let crowdContentOrdersByDate = [];
  for (let i = 0; i < crowdContentOrders.length; i++) {
    const orderDate = moment(crowdContentOrders[i].meta.createdOn).format(
      "MMMM Do YYYY"
    );
    if (
      crowdContentOrdersByDate
        .map((dateObj) => dateObj.orderDate)
        .indexOf(orderDate) === -1
    ) {
      const dateForArray = { orderDate, orders: [] };
      crowdContentOrdersByDate.push(dateForArray);
      const index = crowdContentOrdersByDate
        .map((dateObj) => dateObj.orderDate)
        .indexOf(orderDate);
      crowdContentOrdersByDate[index]["orders"].push(crowdContentOrders[i]);
    } else {
      const index = crowdContentOrdersByDate
        .map((dateObj) => dateObj.orderDate)
        .indexOf(orderDate);
      crowdContentOrdersByDate[index].orders.push(crowdContentOrders[i]);
    }
  }
  return crowdContentOrdersByDate;
};

export const getProjectNotes = (state) => {
  return state.pages[NAME].project.notes;
};

export const getProjectTargetedPlaces = (state) => {
  return state.pages[NAME].project.local.targetedPlaces;
};

export const getProjectParentDetails = (state) => {
  return state.pages[NAME].project.meta.parent;
};

export const getProjectParentCompanyName = (state) => {
  return state.pages[NAME].project.meta.parent.local.business.name;
};

export const getProjectStatus = (state) => {
  return state.pages[NAME].project.meta.status;
};

export const getProjectMonthlySpend = (state) => {
  return state.pages[NAME].project.local.monthlySpend;
};

export const getCurrentProjectPackageType = (state) => {
  return state.pages[NAME].project.local.packageType;
};

export const getCurrentProjectMarketingType = (state) => {
  return state.pages[NAME].project.local.marketingType;
};

export const getActiveTab = (state) => {
  return state.pages[NAME].activeTab;
};

export const getProjectMilestonesCategories = (state) => {
  return state.pages[NAME].project.milestones.categories;
};

export const getProjectCompletedMilestones = (state) => {
  return state.pages[NAME].project.milestones.completedMilestones;
};
