import types from "./types";

const toggleMilestoneTrackerView = (currentView) => (dispatch) => {
  dispatch({
    type: types.TOGGLE_MILESTONE_TRACKER_VIEW,
    payload:
      currentView === "milestoneChecklists"
        ? "checklistPicker"
        : "milestoneChecklists",
  });
};

export { toggleMilestoneTrackerView };
