import { NAME } from "./constants";

export const isLoading = (state) => {
  return state.tools[NAME].isLoading;
};

export const getActiveTab = (state) => {
  return state.tools[NAME].activeTab;
};

export const getGeneratedKeywords = (state) => {
  return state.tools[NAME].generatedKeywords;
};

export const getKeywordLists = (state) => {
  return state.tools[NAME].keywordLists;
};

export const getUsedKeywordLists = (state) => {
  return state.tools[NAME].usedKeywordLists;
};
