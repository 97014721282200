import { connect } from "react-redux";
import { default as component } from "./PickPlaces.jsx";

//UI actions
import { dismissModal } from "../../../../common/UI/ActiveModal/duck/actions";
//UI selectors
import { getActiveModal } from "../../../../common/UI/ActiveModal/duck/selectors";

// Local Actions
import {
  addNewTargetablePlace,
  assignProjectToPlace,
  searchForPlaces,
  searchForExistingPlaces,
  removeProjectFromPlace,
  setActiveTab,
  setActiveView,
} from "./duck/actions";

// Local Selectors
import {
  getProjectTargetedPlaces,
  getProjectID,
} from "../../../duck/selectors";
import {
  getSearchResults,
  getSearchResultsExistingPlaces,
  getHasBeenSearched,
  getActiveTab,
  getActiveView,
  getExistingPlacesSearchQuery,
  getRecentlyAddedPlaces,
} from "./duck/selectors";

const mapStateToProps = (state) => {
  return {
    projectID: getProjectID(state),
    targetedPlaces: getProjectTargetedPlaces(state),
    searchResults: getSearchResults(state),
    existingPlaces: getSearchResultsExistingPlaces(state),
    existingPlacesSearchQuery: getExistingPlacesSearchQuery(state),
    addedPlaces: getRecentlyAddedPlaces(state),
    hasSearched: getHasBeenSearched(state),
    activeModal: getActiveModal(state),
    activeTab: getActiveTab(state),
    activeView: getActiveView(state),
  };
};

const mapDispatchToProps = {
  addNewTargetablePlace,
  dismissModal,
  searchForPlaces,
  searchForExistingPlaces,
  assignProjectToPlace,
  removeProjectFromPlace,
  setActiveTab,
  setActiveView,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
