import types from "./types";

const addKeywordToContentOrder = (keywords, keyword, position) => (
  dispatch
) => {
  let newKeywords = Array.from(keywords);
  newKeywords[position] = keyword;
  console.log(newKeywords);
  dispatch({
    type: types.CONTENT_ORDER_SET_TARGETED_KEYWORDS,
    payload: newKeywords,
  });
};

const removeKeywordsFromContentOrder = () => (dispatch) => {
  let newKeywords = [];
  dispatch({
    type: types.CONTENT_ORDER_SET_TARGETED_KEYWORDS,
    payload: newKeywords,
  });
};

const setContentOrderKeywordMinAppearance = (value) => (dispatch) => {
  dispatch({
    type: types.CONTENT_ORDER_SET_MIN_KEYWORD_APPEARANCE,
    payload: parseInt(value),
  });
};

const setContentOrderKeywordMaxAppearance = (value) => (dispatch) => {
  dispatch({
    type: types.CONTENT_ORDER_SET_MAX_KEYWORD_APPEARANCE,
    payload: parseInt(value),
  });
};

const setContentOrderKeywordInflectionUsage = (bool) => (dispatch) => {
  dispatch({
    type: types.CONTENT_ORDER_SET_KEYWORD_INFLECTION_USAGE,
    payload: bool,
  });
};

const setContentOrderKeywordStopWordUsage = (bool) => (dispatch) => {
  dispatch({
    type: types.CONTENT_ORDER_SET_KEYWORD_STOP_WORDS_USAGE,
    payload: bool,
  });
};

export {
  addKeywordToContentOrder,
  removeKeywordsFromContentOrder,
  setContentOrderKeywordMinAppearance,
  setContentOrderKeywordMaxAppearance,
  setContentOrderKeywordInflectionUsage,
  setContentOrderKeywordStopWordUsage,
};
