import types from "./types";
import { default as pickPlaces } from "../components/modals/PickPlaces/duck/reducer.js";
import { default as addProjectLink } from "../components/modals/AddProjectLink/duck/reducer.js";
import { default as specialNote } from "../components/modals/AddProjectSpecialNote/duck/reducer.js";
import { default as editSpecialNote } from "../components/modals/EditProjectSpecialNote/duck/reducer.js";
import { default as removeProjectLink } from "../components/modals/RemoveProjectLink/duck/reducer.js";
import { default as addContentOrder } from "../components/modals/addContentOrder/duck/reducer.js";
import { default as addCrowdContentOrder } from "../components/modals/addCrowdContentOrder/duck/reducer.js";
import { default as milestoneTracker } from "../components/modals/ProjectMilestoneTracker/duck/reducer.js";
import { default as addNote } from "../components/modals/AddNote/duck/reducer.js";
import { default as focusedContentOrder } from "../components/modals/ViewContentOrderStats/duck/reducer.js";
import { combineReducers } from "redux";

const isLoading = (state = true, action) => {
  switch (action.type) {
    case types.FINISH_PROJECT_DETAILS_LOADING: {
      return false;
    }
    case types.BEGIN_PROJECT_DETAILS_LOADING: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const project = (state = {}, action) => {
  switch (action.type) {
    case types.RECEIVE_PROJECT_DETAILS: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

const activeTab = (state = "defaultTab", action) => {
  switch (action.type) {
    case types.PROJECT_VIEW_SET_ACTIVE_TAB: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

const modalData = combineReducers({
  pickPlaces,
  addContentOrder,
  addCrowdContentOrder,
  addProjectLink,
  specialNote,
  editSpecialNote,
  removeProjectLink,
  milestoneTracker,
  focusedContentOrder,
  addNote,
});

export default combineReducers({
  isLoading,
  project,
  activeTab,
  modalData,
});
