import { connect } from "react-redux";
import { default as component } from "./ContentOrderAccordion.jsx";

// UI Selectors
import { getActiveModal } from "../../../../common/UI/ActiveModal/duck/selectors";
// UI Actions
import { setFocusedContentOrderForReview } from "../../modals/ViewContentOrderStats/duck/actions";

const mapStateToProps = (state) => {
  return {
    activeModal: getActiveModal(state),
  };
};

const mapDispatchToProps = {
  setFocusedContentOrderForReview,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
