import { combineReducers } from "redux";
import types from "./types";

const keywords = (state = [], action) => {
  switch (action.type) {
    case types.CONTENT_ORDER_SET_TARGETED_KEYWORDS: {
      return action.payload;
    }
    case types.NEW_CONTENT_ORDER_RESET_KEYWORDS: {
      return [];
    }
    default: {
      return state;
    }
  }
};

const minAppearance = (state = 0, action) => {
  switch (action.type) {
    case types.CONTENT_ORDER_SET_MIN_KEYWORD_APPEARANCE: {
      return action.payload;
    }
    case types.NEW_CONTENT_ORDER_RESET_KEYWORDS: {
      return 0;
    }
    default: {
      return state;
    }
  }
};

const maxAppearance = (state = 0, action) => {
  switch (action.type) {
    case types.CONTENT_ORDER_SET_MAX_KEYWORD_APPEARANCE: {
      return action.payload;
    }
    case types.NEW_CONTENT_ORDER_RESET_KEYWORDS: {
      return 0;
    }
    default: {
      return state;
    }
  }
};

const useInflections = (state = 1, action) => {
  switch (action.type) {
    case types.CONTENT_ORDER_SET_KEYWORD_INFLECTION_USAGE: {
      return action.payload;
    }
    case types.NEW_CONTENT_ORDER_RESET_KEYWORDS: {
      return 1;
    }
    default: {
      return state;
    }
  }
};

const useStopWords = (state = 1, action) => {
  switch (action.type) {
    case types.CONTENT_ORDER_SET_KEYWORD_STOP_WORDS_USAGE: {
      return action.payload;
    }
    case types.NEW_CONTENT_ORDER_RESET_KEYWORDS: {
      return 1;
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({
  keywords,
  minAppearance,
  maxAppearance,
  useInflections,
  useStopWords,
});
