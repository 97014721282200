import types from "./types";
import { postData } from "../../../../../utils/fetch-requests";

const setFormValidity = () => (dispatch, getState) => {
  const state = getState();
  const { url } = state.pages.projectView.modalData.addProjectLink;
  if (
    url.includes("http") &&
    url.includes(".com") &&
    url.length > 10 &&
    !url.includes(" ")
  ) {
    dispatch({
      type: types.ADD_NEW_PROJECT_LINK_SET_FORM_VALIDITY,
      payload: true,
    });
  } else {
    dispatch({
      type: types.ADD_NEW_PROJECT_LINK_SET_FORM_VALIDITY,
      payload: false,
    });
    dispatch({
      type: types.ADD_NEW_PROJECT_LINK_DISPLAY_WARNING,
      payload: true,
    });
  }
};

const setNewProjectLinkUrl = (url) => (dispatch) => {
  dispatch({
    type: types.ADD_NEW_PROJECT_LINK_SET_URL_TO_ADD,
    payload: url,
  });
  dispatch(setFormValidity());
};

const setNewProjectLinkService = (service) => (dispatch) => {
  dispatch({
    type: types.ADD_NEW_PROJECT_LINK_SET_SERVICE_TO_ADD,
    payload: service,
  });
  dispatch(setFormValidity());
};

const addNewProjectLink = (projectID, newProjectLink) => (dispatch) => {
  const requestData = {
    projectID,
    newProjectLink,
  };
  postData("/api/project/add-link-to-project", requestData).then(
    (updatedProject) => {
      dispatch({
        type: "RECEIVE_PROJECT_DETAILS",
        payload: updatedProject,
      });
      dispatch({
        type: "DISMISS_MODAL",
      });
      dispatch({
        type: types.ADD_NEW_PROJECT_LINK_RESET_DATA,
      });
    }
  );
};

export { setNewProjectLinkUrl, setNewProjectLinkService, addNewProjectLink };
