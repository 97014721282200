import { connect } from "react-redux";
import { default as component } from "./CustomerSearch.jsx";

// Local Actions
import {
  searchRecentCustomers,
  searchForCustomers,
} from "./duck/actions";

// UI Actions
import {
  activateModal,
  dismissModal
} from "../common/UI/ActiveModal/duck/actions";

import {
  toggleView
} from "../common/UI/CurrentView/duck/actions";

// Local Selectors
import { isLoading, getCustomerSearchResults } from './duck/selectors'

// UI Selectors
import { getActiveModal } from '../common/UI/ActiveModal/duck/selectors'
import { getCurrentView } from '../common/UI/CurrentView/duck/selectors'

const mapStateToProps = state => {
  return {
    currentView: getCurrentView(state),
    customers: getCustomerSearchResults(state),
    isLoading: isLoading(state),
    isModalOpen: getActiveModal(state)
  };
};

const mapDispatchToProps = {
  searchRecentCustomers,
  searchForCustomers,
  activateModal,
  dismissModal,
  toggleView
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
