import React, { Fragment, Component } from "react";
import { default as AccountManagerOverviewCard } from "./components/AccountManagerOverviewCard.js";
import { Card, CardBody, CardTitle } from "reactstrap";

class ProjectAdministration extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.props.fetchAllProjectsAndSort();
  }

  render() {
    const {
      isLoading,
      projectCount,
      portfolioRatio,
      portfolioSpend,
    } = this.props;
    return (
      <Fragment>
        {isLoading ? (
          <span>Currently Loading Projects by User</span>
        ) : (
          <Fragment>
            <Card className="mt-2">
              <CardBody>
                <CardTitle>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <span>Active Projects by User</span>
                    </div>
                    <div className="mr-3">
                      <span className="mr-1">
                        Active Projects: {projectCount}
                      </span>
                      <span>
                        (IMO: {portfolioRatio.imoCount} RYNO Built:{" "}
                        {portfolioRatio.rynoCount})
                      </span>
                    </div>
                    <div className="mr-3">
                      <span>Monthly Spend: ${portfolioSpend}</span>
                    </div>
                  </div>
                </CardTitle>
                <AccountManagerOverviewCard />
              </CardBody>
            </Card>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default ProjectAdministration;
