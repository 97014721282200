import { NAME } from "./constants";

export const getContentOrderKeywords = (state) => {
  return state.pages.projectView.modalData.addContentOrder[NAME].keywords;
};

export const getContentOrderKeywordsMin = (state) => {
  return state.pages.projectView.modalData.addContentOrder[NAME].minAppearance;
};

export const getContentOrderKeywordsMax = (state) => {
  return state.pages.projectView.modalData.addContentOrder[NAME].maxAppearance;
};

export const getContentOrderKeywordsUseInflections = (state) => {
  return state.pages.projectView.modalData.addContentOrder[NAME].useInflections;
};

export const getContentOrderKeywordsUseStopWords = (state) => {
  return state.pages.projectView.modalData.addContentOrder[NAME].useStopWords;
};
