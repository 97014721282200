import React, { Component, Fragment } from "react";
import parse from "html-react-parser";
import { faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Alert,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";

export default class AddContentOrderToBoxFolderModal extends Component {
  constructor(props) {
    super(props);
    console.log(this.props);
  }

  render() {
    const {
      submitLocked,
      activeModal,
      contentOrder,
      contents,
      dismissModal,
      toggleSubmitLocked,
      updateContentsForBoxUpload,
      submissionStatus,
      uploadContentsToBox,
    } = this.props;
    return (
      <Modal
        isOpen={activeModal === "AddContentOrderToBoxFolderModal"}
        toggle={() => dismissModal("AddContentOrderToBoxFolderModal")}
        size={submissionStatus !== "unsubmitted" ? "md" : "xl"}
      >
        <ModalHeader>
          Review Text Broker Order{" "}
          {contentOrder.textBroker !== undefined && (
            <span>{contentOrder.textBroker._id}</span>
          )}
        </ModalHeader>
        <ModalBody>
          {submissionStatus === "unsubmitted" ? (
            <div className="row">
              <div
                className="col-6"
                style={{ height: "500px", overflowY: "scroll" }}
              >
                {contents.length < 1 ? (
                  <span>
                    Begin placing your HTML to the right to see a preview.
                  </span>
                ) : (
                  <Fragment>{parse(contents)}</Fragment>
                )}
              </div>
              <div className="col-6">
                <Input
                  type="textarea"
                  name="editedContent"
                  id="editedContents"
                  placeholder="Put HTML for Box Upload here..."
                  style={{ height: "500px" }}
                  onChange={(event) => {
                    updateContentsForBoxUpload(event);
                  }}
                />
              </div>
            </div>
          ) : (
            <Fragment>
              {submissionStatus === "Uploading Content Order to Box" && (
                <Alert color="info">Waiting on Box Document Creation</Alert>
              )}
              {submissionStatus === "Successfully uploaded content order." && (
                <Alert color="success">
                  Box Document Created Successfully and attached.
                </Alert>
              )}
              {submissionStatus === "Failed to upload content order." && (
                <Alert className="text-center" color="danger">
                  Failed to Upload to Box
                  <br />
                  Give these details to Isaac:
                  <hr />
                  {contentOrder.meta.project.boxData.folder}
                  {contentOrder.textBroker._id}
                </Alert>
              )}
            </Fragment>
          )}
        </ModalBody>
        <ModalFooter>
          <div className="mr-auto">
            <Button
              outline={!submitLocked}
              color={submitLocked ? "warning" : "success"}
              onClick={() => toggleSubmitLocked(submitLocked)}
              disabled={submissionStatus !== "unsubmitted"}
            >
              <FontAwesomeIcon icon={submitLocked ? faLock : faLockOpen} />{" "}
              {submitLocked}
            </Button>
          </div>
          <div className="text-right">
            <Button
              className="button-block"
              color="success"
              disabled={submitLocked}
              onClick={() => uploadContentsToBox(contentOrder, contents)}
            >
              Upload Content to Box
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}
