import React, { Component, Fragment } from "react";
import { faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

import { DEFAULT_TAB, SPECIAL_NOTES_TAB } from "./duck/constants";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

export default class PublishContentOrderModal extends Component {
  constructor(props) {
    super(props);
    console.log(this.props);
  }

  showWordPressButton = (links) => {
    const ManageWP = links.find(
      ({ service }) => service === "ManageWP WordPress Dashboard"
    );
    if (ManageWP) {
      return (
        <ModalFooter>
          <Button
            className="btn-block"
            color="primary"
            onClick={() => window.open(ManageWP.url)}
            hover
          >
            Open WordPress Dashboard
          </Button>
        </ModalFooter>
      );
    }
  };

  render() {
    const {
      isLoading,
      submitLocked,
      activeTab,
      activeModal,
      contentOrder,
      htmlContents,
      dismissModal,
      setPublishContentOrderActiveTab,
      toggleSubmitLocked,
      publishContentOrder,
    } = this.props;
    return (
      <Modal
        isOpen={activeModal === "PublishContentOrderModal"}
        toggle={() => dismissModal("PublishContentOrderModal")}
      >
        <ModalHeader>
          Publish Content Order{" "}
          {contentOrder.textBroker !== undefined && (
            <span>{contentOrder.textBroker._id}</span>
          )}
        </ModalHeader>
        <ModalBody>
          <Fragment>
            <Nav className="mb-1" tabs>
              <NavItem>
                <NavLink
                  className={classNames({
                    active: activeTab === DEFAULT_TAB,
                  })}
                  onClick={() => {
                    setPublishContentOrderActiveTab(DEFAULT_TAB);
                  }}
                >
                  <span className="mr-2">Order Details</span>
                </NavLink>
              </NavItem>
              {!isLoading && contentOrder.meta.project.specialNotes !== null && (
                <NavItem>
                  <NavLink
                    className={classNames({
                      active: activeTab === SPECIAL_NOTES_TAB,
                    })}
                    onClick={() => {
                      setPublishContentOrderActiveTab(SPECIAL_NOTES_TAB);
                    }}
                  >
                    <span className="mr-2">Special Notes</span>
                  </NavLink>
                </NavItem>
              )}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={DEFAULT_TAB}>
                {contentOrder.contentMeta !== undefined && (
                  <div>
                    <b>H1:</b>
                    {contentOrder.contentMeta.h1 !== null ? (
                      <span>{contentOrder.contentMeta.h1}</span>
                    ) : (
                      <span> Not set</span>
                    )}
                    <br />
                    <b>Meta Description:</b>
                    {contentOrder.contentMeta.description !== null ? (
                      <span>{" " + contentOrder.contentMeta.description}</span>
                    ) : (
                      <span> Not set</span>
                    )}
                    <br />
                    <b>Slug:</b>
                    {contentOrder.contentMeta.slug !== null ? (
                      <span>{" " + contentOrder.contentMeta.slug}</span>
                    ) : (
                      <span> Not set</span>
                    )}
                    <br />
                    <b>Meta Title:</b>
                    {contentOrder.contentMeta.title !== null ? (
                      <span>{" " + contentOrder.contentMeta.title}</span>
                    ) : (
                      <span> Not set</span>
                    )}

                    <br />
                    <hr />
                  </div>
                )}
                <Input
                  type="textarea"
                  rows="12"
                  value={
                    !isLoading ? htmlContents : "Be Patient Loading Details"
                  }
                  onKeyPress={() => {
                    return false;
                  }}
                />
              </TabPane>
              <TabPane tabId={SPECIAL_NOTES_TAB}>
                {!isLoading && (
                  <pre>{contentOrder.meta.project.specialNotes}</pre>
                )}
              </TabPane>
            </TabContent>
          </Fragment>
        </ModalBody>
        {!isLoading && contentOrder.meta.project.links !== undefined ? (
          <Fragment>
            {this.showWordPressButton(contentOrder.meta.project.links)}
          </Fragment>
        ) : (
          <Fragment />
        )}
        <ModalFooter>
          <div className="mr-auto">
            <Button
              outline={!submitLocked}
              color={submitLocked ? "warning" : "success"}
              onClick={() => toggleSubmitLocked(submitLocked)}
              disabled={isLoading}
            >
              <FontAwesomeIcon icon={submitLocked ? faLock : faLockOpen} />{" "}
              {submitLocked}
            </Button>
          </div>
          <div className="text-right">
            <Button
              className="button-block"
              color="success"
              disabled={submitLocked}
              onClick={() => publishContentOrder(contentOrder)}
            >
              Process Order
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}
