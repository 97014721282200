import { postData } from "../../../../../utils/fetch-requests";
import types from "./types";

const setFocusedCrowdContentOrder = (order) => (dispatch) => {
  dispatch({
    type: types.PENDING_CROWD_CONTENT_ORDERS_SET_FOCUSED_ORDER,
    payload: order,
  });
  dispatch({
    type:
      types.PENDING_CROWD_CONTENT_ORDERS_BEGIN_LOADING_FOCUSED_CROWD_CONTENT_ORDER_PREVIEW,
  });
  dispatch({
    type: "ACTIVATE_MODAL",
    payload: "reviewCrowdContentOrderModal",
  });
  // Get the Order meta from the DB
  postData("/api/crowd-content/get-order-from-db", {
    order_id: order.order_id,
  }).then((orderMeta) => {
    // Store the Meta in State
    dispatch({
      type: types.PENDING_CROWD_CONTENT_ORDERS_SET_FOCUSED_ORDER_META,
      payload: orderMeta,
    });
    postData("/api/crowd-content/get-order-preview", {
      order_id: order.order_id,
    }).then((res) => {
      let orderPreview = res.content;
      dispatch({
        type:
          types.PENDING_CROWD_CONTENT_ORDERS_RECEIVE_CROWD_CONTENT_ORDER_PREVIEW,
        payload: orderPreview,
      });
      dispatch({
        type:
          types.PENDING_CROWD_CONTENT_ORDERS_FINISH_LOADING_FOCUSED_CROWD_CONTENT_ORDER_PREVIEW,
      });
    });
  });
};

const updateFocusedCrowdContentOrderText = (event) => (dispatch) => {
  dispatch({
    type: types.PENDING_CROWD_CONTENT_ORDERS_UPDATE_FOCUSED_ORDER_HTML,
    payload: event.target.value,
  });
};

const approvePendingCrowdContentOrder = (CrowdContentOrder, ContentText) => (
  dispatch
) => {
  let contentData = {
    order_id: CrowdContentOrder.order_id,
    newContent: ContentText,
  };

  dispatch({
    type: types.PENDING_CROWD_CONTENT_ORDER_TOGGLE_SUBMIT_LOCK,
    payload: true,
  });

  postData("/api/crowd-content/approve-content-order", contentData)
    .then((doc) => {
      dispatch({
        type: types.PENDING_CROWD_CONTENT_ORDERS_SUBMISSION_SUCCESSFUL,
      });
      setTimeout(() => {
        fetch(`/api/crowd-content/list-orders`)
          .then((res) => res.json())
          .then((orders) => {
            dispatch({
              type: "CROWD_CONTENT_CONTENT_DASHBOARD_RECEIVE_CONTENT_ORDERS",
              payload: orders,
            });
            dispatch({
              type: "CROWD_CONTENT_FINISH_CONTENT_DASHBOARD_LOADING",
            });
            dispatch({ type: "DISMISS_MODAL" });
          });
      }, 500);
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: types.PENDING_CROWD_CONTENT_ORDERS_SUBMISSION_FAILED,
      });
    });
};

const toggleSubmitLocked = (bool) => (dispatch) => {
  dispatch({
    type: types.PENDING_CROWD_CONTENT_ORDER_TOGGLE_SUBMIT_LOCK,
    payload: bool,
  });
};

const setActiveTab = (currentlyActiveTab, tabToActivate) => (dispatch) => {
  if (currentlyActiveTab !== tabToActivate) {
    dispatch({
      type: types.PENDING_CROWD_CONTENT_ORDERS_SET_ACTIVE_TAB,
      payload: tabToActivate,
    });
  }
};

const setMetaEditMode = (field, newData) => (dispatch) => {
  dispatch({
    type: types.PENDING_CROWD_CONTENT_ORDERS_SET_META_EDIT_MODE,
    payload: { editing: true, field, newData },
  });
};

const setMetaFieldData = (field, data) => (dispatch) => {
  dispatch({
    type: types.PENDING_CROWD_CONTENT_ORDERS_SET_META_FIELD_DATA,
    payload: { field, data },
  });
};

const saveNewMetaFieldData = (_id, field, data) => (dispatch) => {
  postData("/api/crowd-content/update-content-order-meta", {
    _id,
    field,
    data,
  }).then((modifiedOrder) => {
    dispatch({
      type: types.PENDING_CROWD_CONTENT_ORDERS_SET_FOCUSED_ORDER_META,
      payload: modifiedOrder,
    });
    dispatch({
      type: types.PENDING_CROWD_CONTENT_ORDERS_SET_META_EDIT_MODE,
      payload: { editing: false, field: null, newData: "" },
    });
    // fetch(`/api/crowd-content/list-orders`)
    //   .then((res) => res.json())
    //   .then((orders) => {
    //     dispatch({
    //       type: "CROWD_CONTENT_CONTENT_DASHBOARD_RECEIVE_CONTENT_ORDERS",
    //       payload: orders,
    //     });
    //   });
  });
};

export {
  setFocusedCrowdContentOrder,
  setActiveTab,
  setMetaEditMode,
  setMetaFieldData,
  saveNewMetaFieldData,
  updateFocusedCrowdContentOrderText,
  approvePendingCrowdContentOrder,
  toggleSubmitLocked,
};
