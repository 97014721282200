import { postData } from "../../../../../utils/fetch-requests";
import types from "./types";

const setActiveTab = (tab) => (dispatch) => {
  dispatch({
    type: types.PLACE_PICKER_SET_ACTIVE_TAB,
    payload: tab,
  });
};

const setActiveView = (view) => (dispatch) => {
  dispatch({
    type: types.PLACE_PICKER_SET_ACTIVE_VIEW,
    payload: view,
  });
};

const removeProjectFromPlace = (projectID, placeID) => (dispatch) => {
  const data = {
    projectID,
    placeID,
  };
  postData("/api/project/remove-place-from-project", data).then((project) => {
    dispatch({
      type: "RECEIVE_PROJECT_DETAILS",
      payload: project,
    });
  });
};

const assignProjectToPlace = (projectID, placeID) => (dispatch) => {
  const data = {
    projectID,
    placeID,
  };
  postData("/api/project/assign-place-to-project", data).then((project) => {
    dispatch({
      type: "RECEIVE_PROJECT_DETAILS",
      payload: project,
    });
  });
};

const searchForPlaces = (searchQuery, parent) => (dispatch) => {
  postData("/api/places/search-for-places-by-name", {
    searchQuery,
    parent,
  }).then((places) => {
    dispatch({
      type: types.PLACE_PICKER_MODAL_RECEIVE_SEARCH_RESULTS,
      payload: places,
    });
    dispatch({
      type: types.PLACE_PICKER_MODAL_HAS_SEARCHED,
    });
  });
};

const searchForExistingPlaces = (searchQuery, parent) => (dispatch) => {
  const titleCase = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.replace(word[0], word[0].toUpperCase());
      })
      .join(" ");
  };

  const searchData = {
    searchQuery: titleCase(searchQuery),
    parent,
  };

  postData("/api/places/search-for-existing-places-by-name", searchData).then(
    (places) => {
      dispatch({
        type: types.PLACE_PICKER_MODAL_UPDATE_EXISTING_PLACES_SEARCH_QUERY,
        payload: searchQuery,
      });
      dispatch({
        type: types.PLACE_PICKER_MODAL_RECEIVE_EXISTING_PLACES_SEARCH_RESULTS,
        payload: places,
      });
    }
  );
};

const addNewTargetablePlace = (
  recentlyAddedPlaces,
  placeName,
  parent,
  country
) => (dispatch) => {
  const titleCase = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.replace(word[0], word[0].toUpperCase());
      })
      .join(" ");
  };

  const newPlaceName = titleCase(placeName);
  const weatherCode =
    "CUSTOM" + placeName.trim().toUpperCase() + parent.trim().toUpperCase();
  const placeToAdd = {
    details: {
      name: newPlaceName,
      parent: parent,
      country: country,
    },
    meta: { weatherCode: weatherCode },
  };
  postData("/api/places/insert-single-place", placeToAdd).then((place) => {
    const newPlaces = [...recentlyAddedPlaces];
    newPlaces.push(place);
    dispatch({
      type: types.RECEIVE_NEWLY_ADDED_PLACE,
      payload: newPlaces,
    });
  });
};

export {
  addNewTargetablePlace,
  assignProjectToPlace,
  removeProjectFromPlace,
  searchForPlaces,
  searchForExistingPlaces,
  setActiveTab,
  setActiveView,
};
