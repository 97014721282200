import { combineReducers } from "redux";
import types from "./types";

const submissionStatus = (state = "unsubmitted", action) => {
  switch (action.type) {
    case types.PUBLISHING_CROWD_CONTENT_ORDERS_SUBMISSION_STARTED: {
      return "Saving Content Order";
    }
    case types.PUBLISHING_CROWD_CONTENT_ORDERS_SUBMISSION_CROWD_CONTENT_ACCEPT_SUCCESS: {
      return "Successfully Saved Content Order";
    }
    case types.PUBLISHING_CROWD_CONTENT_ORDERS_SUBMISSION_CROWD_CONTENT_ACCEPT_FAIL: {
      return "Failed to Save Content Order";
    }
    case types.UNSET_PUBLISH_CROWD_CONTENT_ORDER_MODAL: {
      return "unsubmitted";
    }
    default:
      return state;
  }
};

const focusedCrowdContentOrder = (state = {}, action) => {
  switch (action.type) {
    case types.PUBLISHING_CROWD_CONTENT_ORDERS_SET_FOCUSED_ORDER_FOR_PUBLISHING: {
      return action.payload;
    }
    case types.UNSET_PUBLISH_CROWD_CONTENT_ORDER_MODAL: {
      return {};
    }
    default: {
      return state;
    }
  }
};

const submitLocked = (state = true, action) => {
  switch (action.type) {
    case types.PENDING_CROWD_CONTENT_ORDER_TOGGLE_SUBMIT_LOCK: {
      return !action.payload;
    }
    case types.UNSET_PUBLISH_CROWD_CONTENT_ORDER_MODAL: {
      return true;
    }
    default:
      return state;
  }
};

const activeTab = (state = "PUBLISH_ORDER_MODAL_CONTENT_TAB", action) => {
  switch (action.type) {
    case types.PUBLISH_CROWD_CONTENT_ORDERS_MODAL_SET_ACTIVE_TAB: {
      return action.payload;
    }
    case types.UNSET_PUBLISH_CROWD_CONTENT_ORDER_MODAL: {
      return "PUBLISH_ORDER_MODAL_CONTENT_TAB";
    }
    default:
      return state;
  }
};

export default combineReducers({
  activeTab,
  submissionStatus,
  submitLocked,
  focusedCrowdContentOrder,
});
