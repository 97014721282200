const EDIT_USER_PROFILE_MODAL_ADJUST_NAME =
  "EDIT_USER_PROFILE_MODAL_ADJUST_NAME";
const EDIT_USER_PROFILE_MODAL_ADJUST_PHONE_NUMBER =
  "EDIT_USER_PROFILE_MODAL_ADJUST_PHONE_NUMBER";
const EDIT_USER_PROFILE_MODAL_ADJUST_PROFILE_PICTURE_URL =
  "EDIT_USER_PROFILE_MODAL_ADJUST_PROFILE_PICTURE_URL";

export default {
  EDIT_USER_PROFILE_MODAL_ADJUST_NAME,
  EDIT_USER_PROFILE_MODAL_ADJUST_PHONE_NUMBER,
  EDIT_USER_PROFILE_MODAL_ADJUST_PROFILE_PICTURE_URL,
};
