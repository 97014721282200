import { connect } from "react-redux";
import { default as component } from "./KeywordListManagementCard.jsx";

// global selectors
import { getKeywordLists } from "../../duck/selectors";

// global action
import { activateModal } from "../../../common/UI/ActiveModal/duck/actions";

// local action
import { setFocusedKeywordList } from "./duck/actions.js";

const mapStateToProps = (state) => {
  return {
    keywordLists: getKeywordLists(state),
  };
};

const mapDispatchToProps = {
  activateModal,
  setFocusedKeywordList,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
