export const getNewProjectLinkService = (state) => {
  return state.pages.projectView.modalData.addProjectLink.service;
};

export const getNewProjectLinkUrl = (state) => {
  return state.pages.projectView.modalData.addProjectLink.url;
};

export const getNewProjectLinkFormValidity = (state) => {
  return state.pages.projectView.modalData.addProjectLink.formValidity;
};

export const getNewProjectLinkDisplayWarning = (state) => {
  return state.pages.projectView.modalData.addProjectLink.displayWarning;
};
