import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  CardHeader,
  CardBody,
  Input,
  InputGroup,
  Button,
  Table,
} from "reactstrap";

const appearShorter = (title) => {
  if (title.length > 35) {
    title = title.substring(0, 32) + "...";
  }
  return title;
};

class KeywordGeneratorOutputCard extends Component {
  render() {
    let {
      keywordLists,
      usedKeywordLists,
      splitCities,
      stateAbbreviation,
      setSplitCities,
      setStateAbbreviation,
      addKeywordListAndGenerate,
    } = this.props;
    return (
      <div className="row">
        <div className="col-md-12 p-2">
          <Card>
            <CardBody>
              <InputGroup>
                <div className="col-md-10">
                  <Input
                    placeHolder="Comma separated cities! Example: Phoenix, Scottsdale, Tempe "
                    onChange={(event) => setSplitCities(event)}
                  />
                </div>
                <div className="col-md-2">
                  <Input
                    placeHolder="State abbrv."
                    onChange={(event) => setStateAbbreviation(event)}
                  />
                </div>
              </InputGroup>
              <hr />
              {keywordLists.map((list) => (
                <Fragment>
                  {usedKeywordLists.includes(list.title) ? (
                    <Fragment />
                  ) : (
                    <Card className="mt-2">
                      <CardHeader>
                        <div className="row">
                          <div className="col-11">
                            <strong>{list.title}</strong>
                          </div>
                          <div className="col text-right">
                            <Button
                              color="success"
                              size="sm"
                              onClick={() =>
                                addKeywordListAndGenerate(
                                  splitCities,
                                  list,
                                  stateAbbreviation
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </Button>
                          </div>
                        </div>
                      </CardHeader>
                    </Card>
                  )}
                </Fragment>
              ))}
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

export default KeywordGeneratorOutputCard;
