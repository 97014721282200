import { connect } from "react-redux";
import { default as component } from "./DecideContentOrderProvider.jsx";

// UI Selectors
import { getActiveModal } from "../../../../common/UI/ActiveModal/duck/selectors.js";
// UI Actions
import {
  activateModal,
  dismissModal,
} from "../../../../common/UI/ActiveModal/duck/actions";

const mapStateToProps = (state) => {
  return {
    activeModal: getActiveModal(state),
  };
};

const mapDispatchToProps = {
  activateModal,
  dismissModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
