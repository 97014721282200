import { connect } from "react-redux";
import { default as component } from "./AccountManagerProjectsAccordion.jsx";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, null)(component);
