import { combineReducers } from "redux";
import types from "./types";

const keywords = (state = [], action) => {
  switch (action.type) {
    case types.CROWD_CONTENT_ORDER_SET_TARGETED_KEYWORDS: {
      return action.payload;
    }
    case types.NEW_CROWD_CONTENT_ORDER_RESET_KEYWORDS: {
      return [];
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({
  keywords,
});
