import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { reducer as authReducer } from "./app/common/Authentication/";
import { reducer as navReducer } from "./app/common/MainNavigation/";
import { reducer as uiReducer } from "./app/common/UI/";
import { reducer as userReducer } from "./app/common/UserDetails/";

// Page Reducers
import { reducer as contentDashboard } from "./app/ContentDashboardPage";
import { reducer as crowdContentDashboard } from "./app/CrowdContentDashboard";
import { reducer as customerSearch } from "./app/CustomerSearch/";
import { reducer as customerView } from "./app/CustomerViewPage/";
import { reducer as keywordGenerator } from "./app/KeywordGeneratorTool";
import { reducer as profile } from "./app/ProfilePage/";
import { reducer as projectView } from "./app/ProjectView/";
import { reducer as projectsAdministration } from "./app/ProjectsAdministration/";
import { reducer as targetablePlaceSearch } from "./app/TargetablePlaceSearch";
import { reducer as userManagement } from "./app/UserManagement";

const pageReducer = combineReducers({
  contentDashboard,
  crowdContentDashboard,
  customerView,
  customerSearch,
  profile,
  projectView,
  projectsAdministration,
  targetablePlaceSearch,
  userManagement,
});

const toolReducer = combineReducers({
  keywordGenerator,
});

const rootReducer = combineReducers({
  auth: authReducer,
  ui: uiReducer,
  nav: navReducer,
  form: formReducer,
  loggedInUser: userReducer,
  pages: pageReducer,
  tools: toolReducer,
});

export default rootReducer;
