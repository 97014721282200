import React, { Component, Fragment } from "react";
import { default as ContentOrdersNeedsReviewTable } from "./components/ContentOrdersNeedsReviewTable/ContentOrdersNeedsReviewTable.js";
import { default as ContentOrdersNeedsPostingTable } from "./components/ContentORdersNeedsPostingTable/ContentOrdersNeedsPostingTable.js";
import { default as ContentOrdersWaitingOnContentTable } from "./components/ContentOrdersWaitingOnContentTable/ContentOrdersWaitingOnContentTable.js";
import { default as ContentOrdersRecentlyPublishedTable } from "./components/ContentOrdersRecentlyPublished/ContentOrdersRecentlyPublishedTable.js";

import {
  ReviewContentOrderModal,
  AddContentOrderToBoxFolderModal,
  PublishContentOrderModal,
} from "./components/modals";

import {
  Card,
  CardBody,
  Nav,
  NavLink,
  NavItem,
  Badge,
  TabContent,
  TabPane,
} from "reactstrap";
import classNames from "classnames";

import {
  IN_QUEUE_TAB,
  DEFAULT_TAB,
  EDITED_ORDERS_TAB,
  RECENTLY_COMPLETED_ORDERS_TAB,
} from "./duck/constants";

class ProjectView extends Component {
  componentDidMount() {
    this.props.fetchWorkableOrders();
  }

  render() {
    let { isLoading, contentOrders, activeTab, setActiveTab } = this.props;
    return (
      <Fragment>
        {isLoading ? (
          <Fragment />
        ) : (
          <div className="row">
            <div className="col-md-12 p-2">
              <Card>
                <CardBody>
                  <Nav className="mb-1" tabs>
                    <NavItem>
                      <NavLink
                        className={classNames({
                          active: activeTab === IN_QUEUE_TAB,
                        })}
                        onClick={() => setActiveTab(IN_QUEUE_TAB)}
                      >
                        <span className="mr-2">In Queue</span>
                        <Badge color="secondary">
                          {contentOrders.inQueue.length}
                        </Badge>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classNames({
                          active: activeTab === DEFAULT_TAB,
                        })}
                        onClick={() => setActiveTab(DEFAULT_TAB)}
                      >
                        <span className="mr-2">Needs Editing</span>
                        <Badge color="secondary">
                          {contentOrders.needsEditing.length}
                        </Badge>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classNames({
                          active: activeTab === EDITED_ORDERS_TAB,
                        })}
                        onClick={() => setActiveTab(EDITED_ORDERS_TAB)}
                      >
                        <span className="mr-2">Needs Posting</span>
                        <Badge color="secondary">
                          {contentOrders.needsPosting.length}
                        </Badge>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classNames({
                          active: activeTab === RECENTLY_COMPLETED_ORDERS_TAB,
                        })}
                        onClick={() =>
                          setActiveTab(RECENTLY_COMPLETED_ORDERS_TAB)
                        }
                      >
                        <span className="mr-2">Recently Published</span>
                        <Badge color="secondary">
                          {contentOrders.recentlyPublished.length}
                        </Badge>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId={IN_QUEUE_TAB}>
                      <ContentOrdersWaitingOnContentTable />
                    </TabPane>
                    <TabPane tabId={DEFAULT_TAB}>
                      <ContentOrdersNeedsReviewTable />
                    </TabPane>
                    <TabPane tabId={EDITED_ORDERS_TAB}>
                      <ContentOrdersNeedsPostingTable />
                    </TabPane>
                    <TabPane tabId={RECENTLY_COMPLETED_ORDERS_TAB}>
                      <ContentOrdersRecentlyPublishedTable />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </div>
          </div>
        )}
        <ReviewContentOrderModal />
        <AddContentOrderToBoxFolderModal />
        <PublishContentOrderModal />
      </Fragment>
    );
  }
}

export default ProjectView;
