import { connect } from "react-redux";
import { default as component } from "./UserDetails.jsx";

//selectors
import {
  getLoggedInUserLocalDetails,
  getLoggedInUserPersonalDetails,
} from "../../common/UserDetails/duck/selectors";

// wrapper container
const MapStateToProps = (state) => {
  return {
    localDetails: getLoggedInUserLocalDetails(state),
    personalDetails: getLoggedInUserPersonalDetails(state),
  };
};

export default connect(MapStateToProps, null)(component);
