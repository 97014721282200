import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { Card, CardHeader, CardBody, Table } from "reactstrap";

class KeywordGeneratorToolMainForm extends Component {
  render() {
    let { generatedKeywords } = this.props;
    return (
      <div className="row">
        <div className="col-md-12 p-1">
          <Card>
            <CardBody>
              {generatedKeywords.map((keywordList) => (
                <Fragment>
                  {keywordList.keywords.length > 0 ? (
                    <Fragment>
                      <Card className="mt-2">
                        <CardHeader>
                          <strong>{keywordList.cityName}</strong>
                        </CardHeader>
                        <CardBody>
                          <div className="row">
                            <div className="col-11">
                              <div className="row">
                                {keywordList.keywords.map((phrase) => (
                                  <div className="col-4">{phrase}</div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                      <br />
                    </Fragment>
                  ) : (
                    <Fragment />
                  )}
                </Fragment>
              ))}
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

export default KeywordGeneratorToolMainForm;
