import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSkull } from "@fortawesome/free-solid-svg-icons";

const PaginationCustomerSearch = (props) => {
  const {
    alphaChars,
    performPaginationCustomerSearch,
    performPaginationCustomerSearchNumeric,
    performPaginationCancelledCustomerSearch,
  } = props;
  return (
    <Fragment>
      <div style={{ display: "inline-block" }}>
        {alphaChars.map((char) => (
          <div
            className="p-2"
            style={{
              display: "inline-block",
              backgroundColor: "#f5f5f5",
              marginRight: "1px",
            }}
            onClick={() => performPaginationCustomerSearch(char)}
          >
            <span>{char.toUpperCase()}</span>
          </div>
        ))}
      </div>
      <div style={{ display: "inline-block" }}>
        <div
          className="p-2"
          style={{
            display: "inline-block",
            backgroundColor: "#f5f5f5",
            marginRight: "1px",
          }}
          onClick={() => performPaginationCustomerSearchNumeric()}
        >
          <span>#</span>
        </div>
      </div>
      <div style={{ display: "inline-block" }}>
        <div
          className="p-2"
          style={{
            display: "inline-block",
            backgroundColor: "#f5f5f5",
            marginRight: "1px",
          }}
          onClick={() => performPaginationCancelledCustomerSearch()}
        >
          <span>
            <FontAwesomeIcon icon={faSkull} />
          </span>
        </div>
      </div>
    </Fragment>
  );
};

export default PaginationCustomerSearch;
