import React, { Fragment } from "react";
import { Card, CardBody, Table, Badge } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";

const UserAssignedClients = (props) => {
  const { assignedClients, isLoading } = props;

  const getStatusColor = (status) => {
    switch (status) {
      case "Active":
        return "success";
      case "Paused (Request)":
        return "warning";
      case "Paused (Billing)":
        return "warning";
      case "Cancelled":
        return "danger";
      default:
        return "success";
    }
  };

  const calculateSpend = (clients) => {
    let totalSpend = 0;
    for (let i = 0; i < clients.length; i++) {
      console.log(clients[i]);
      for (let p = 0; p < clients[i].local.projects.length; p++) {
        if (clients[i].local.projects[p].meta.status === "Active") {
          totalSpend += clients[i].local.projects[p].local.monthlySpend;
        }
      }
    }
    return totalSpend;
  };

  const checkForActiveProjects= projects => {
    let activeProjects = 0;
    for (let i = 0; i < projects.length; i++){
      if (projects[i].meta.status === "Active"){
        activeProjects++
      }
      if ( activeProjects === 0){
        return false
      } else {
        return true
      }
    }
  }

  return (
    <Fragment>
      {isLoading ? (
        <span>Clients are being Loaded</span>
      ) : (
        <Fragment>
          {assignedClients.length === 0 ? (
            <span>You have no clients assigned to you.</span>
          ) : (
            <div>
              <div className="d-flex mb-1">
                <div className="flex-grow-1">
                  Client Count: {assignedClients.length}
                </div>
                <div>
                  Total Portfolio Monthly Spend: $
                  {calculateSpend(assignedClients)}
                </div>
              </div>
              {assignedClients.map((client) => (
                <Fragment>
                  {checkForActiveProjects(client.local.projects) ? (
                    <Card>
                    <CardBody>
                      <div className="mb-2">
                        <strong>
                          {client.local.business.name} -{" "}
                          <a href={`/customer/view/${client._id}`}>view</a>
                        </strong>
                      </div>
                      <Table size="sm" hover>
                        <thead>
                          <th>Project Domain</th>
                          <th className="text-center">Spend</th>
                          <th className="text-center">Status</th>
                          <th className="text-center">Actions</th>
                        </thead>
                        <tbody>
                          {client.local.projects.map((project) => (
                            <tr>
                              <td>{project.local.domain}</td>
                              <td className="text-center">
                                {project.local.monthlySpend === 0 ? (
                                  <span>Not Set</span>
                                ) : (
                                  <span>{`$${project.local.monthlySpend}`}</span>
                                )}
                              </td>
                              <td className="text-center">
                                <Badge
                                  color={getStatusColor(project.meta.status)}
                                >
                                  {project.meta.status}
                                </Badge>
                              </td>
                              <td className="text-center">
                                <a href={"/project/view/" + project._id}>
                                  <FontAwesomeIcon icon={faArrowAltCircleRight} />
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                  ) : <Fragment/>}
                </Fragment>
              ))}
            </div>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default UserAssignedClients;
