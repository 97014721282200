import React, { Fragment, Component } from "react";
import { Button, Card, CardBody, Input, InputGroup } from "reactstrap";

// components
import { default as CityListTable } from "./components/CityListTable.js";

class CityListCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredPlaces: [],
    };
  }

  searchFilterCities = (query, places) => {
    let matchedPlaces = [];
    for (let i = 0; i < places.length; i++) {
      if (places[i].details.name.toLowerCase().includes(query.toLowerCase())) {
        matchedPlaces.push(places[i]);
      }
    }
    this.setState(
      {
        filteredPlaces: matchedPlaces,
      },
      () => {
        console.log(this.state);
      }
    );
  };

  render() {
    const { loggedInUserAccess, activateModal, places } = this.props;
    const { filteredPlaces } = this.state;
    return (
      <Card className="p-2">
        <CardBody>
          <div className="d-flex mb-2">
            <div className="flex-grow-1 mr-2">
              <InputGroup>
                <Input
                  autoComplete="none"
                  type="text"
                  bsSize="sm"
                  id="searchFilterCities"
                  className="form-control"
                  placeHolder={`Search ${places.length} results..`}
                  onChange={(event) =>
                    this.searchFilterCities(event.target.value, places)
                  }
                  disabled={places.length === 0}
                />
              </InputGroup>
            </div>
            {loggedInUserAccess === "Super Admin" ? (
              <div className="text-right">
                <Button
                  color="warning"
                  size="sm"
                  onClick={() => {
                    activateModal("addPlacesBulkModal");
                  }}
                >
                  Bulk Add Cities
                </Button>
              </div>
            ) : (
              <Fragment />
            )}
          </div>
          <CityListTable filteredPlaces={filteredPlaces} />
        </CardBody>
      </Card>
    );
  }
}

export default CityListCard;
