import React, { Component } from "react";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";

export default class EditProfileModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newCities: [],
    };
  }

  countNewCities = (e) => {
    let json = e.target.value;
    let newJson = json.replace(/'/g, '"');
    console.log(newJson);
    let newCities = JSON.parse(newJson);
    console.log(newCities);
    // let arrayOfNewCities = new Array(newCities);
    // console.log(arrayOfNewCities[1]);
    // this.setState(newCities, () => {
    //   console.log(this.state.newCities.length);
    // });
  };

  render() {
    const { activeModal, dismissModal } = this.props;
    const { newCities } = this.state;
    return (
      <Modal
        isOpen={activeModal === "addPlacesBulkModal"}
        toggle={() => dismissModal("addPlacesBulkModal")}
      >
        <ModalHeader>Bulk Add Places</ModalHeader>
        <ModalBody>
          There are {newCities} cities ready to submit.
          <Input
            type="textarea"
            className="form-control"
            name="newBulkCities"
            id="newBulkCities"
            style={{ height: "500px" }}
            onChange={(event) => this.countNewCities(event)}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            className="mr-1"
            color="success"
            type="submit"
            onClick={() => this.props.insertPlacesBulk(this.state.newCities)}
          >
            Submit
          </Button>
          <Button
            color="secondary"
            onClick={() => this.props.dismissModal("addPlacesBulkModal")}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
