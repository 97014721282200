import { connect } from "react-redux";
import { default as Modal } from "./AddProject.jsx";

// UI actions
import { dismissModal } from "../../../common/UI/ActiveModal/duck/actions";
// UI selectors
import { getActiveModal } from "../../../common/UI/ActiveModal/duck/selectors"

// Local actions
import { addProject } from "../../duck/actions";
// Local selectors
import { getCustomerDetails } from "../../duck/selectors";

const mapStateToProps = state => {
  return {
    activeModal: getActiveModal(state),
    _id: getCustomerDetails(state)._id
  };
};

const mapDispatchToProps = {
  dismissModal,
  addProject
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
