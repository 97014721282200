import { postData } from "../../../../../utils/fetch-requests";
import types from "./types";

const setFocusedContentOrderForPublishing = (order) => (dispatch) => {
  dispatch({
    type: types.PENDING_CONTENT_ORDERS_SET_FOCUSED_ORDER_FOR_PUBLISHING,
    payload: order,
  });
  dispatch({
    type:
      types.PUBLISHING_CONTENT_ORDERS_BEGIN_LOADING_FOCUSED_CONTENT_ORDER_PREVIEW,
  });
  dispatch({
    type: "ACTIVATE_MODAL",
    payload: "PublishContentOrderModal",
  });
  postData("/api/box/get-box-file-html", {
    id: order.boxData.id,
  }).then((res) => {
    dispatch({
      type: types.PUBLISHING_CONTENT_ORDERS_RECEIVE_CONTENT_ORDER_HTML_CONTENTS,
      payload: res.data,
    });
    dispatch({
      type:
        types.PUBLISHING_CONTENT_ORDERS_FINISH_LOADING_FOCUSED_CONTENT_ORDER_PREVIEW,
    });
  });
};

const publishContentOrder = (contentOrder) => (dispatch) => {
  postData("/api/content-orders/move-to-published", contentOrder)
    .then((doc) => {
      fetch(`/api/content-orders/fetch-workable-orders`)
        .then((res) => res.json())
        .then((orders) => {
          dispatch({
            type: "CONTENT_DASHBOARD_RECEIVE_CONTENT_ORDERS",
            payload: orders,
          });
        });
      dispatch({
        type: "DISMISS_MODAL",
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: types.PUBLISHING_CONTENT_ORDERS_SUBMISSION_FAILED,
      });
    });
};

const setPublishContentOrderActiveTab = (tab) => (dispatch) => {
  dispatch({
    type: types.PUBLISH_CONTENT_ORDERS_MODAL_SET_ACTIVE_TAB,
    payload: tab,
  });
};

const toggleSubmitLocked = (bool) => (dispatch) => {
  dispatch({
    type: types.PENDING_CONTENT_ORDER_TOGGLE_SUBMIT_LOCK,
    payload: bool,
  });
};

export {
  setFocusedContentOrderForPublishing,
  publishContentOrder,
  setPublishContentOrderActiveTab,
  toggleSubmitLocked,
};
