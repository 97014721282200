import { combineReducers } from "redux";
import types from "./types";

const newKeywordListTitle = (state = "", action) => {
  switch (action.type) {
    case types.KEYWORD_GENERATOR_TOOL_SET_NEW_LIST_TITLE: {
      return action.payload;
    }
    case "DISMISS_MODAL": {
      return "";
    }
    default: {
      return state;
    }
  }
};

const newKeywordListKeywords = (state = "", action) => {
  switch (action.type) {
    case types.KEYWORD_GENERATOR_TOOL_SET_NEW_LIST_KEYWORDS: {
      return action.payload;
    }
    case "DISMISS_MODAL": {
      return "";
    }
    default: {
      return state;
    }
  }
};

const formValidity = (state = true, action) => {
  switch (action.type) {
    case types.KEYWORD_GENERATOR_TOOL_SET_NEW_LIST_FORM_VALIDITY: {
      return action.payload;
    }
    case "DISMISS_MODAL": {
      return true;
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({
  newKeywordListTitle,
  newKeywordListKeywords,
  formValidity,
});
