import types from './types'
import { combineReducers } from 'redux'

const name = (state = 'default', action) => {
    switch (action.type) {
        case types.TOGGLE_VIEW: {
            return action.payload
        }
        case types.RESET_VIEW: {
            return 'default'
        }
        default: {
            return state
        }
    }
}

export default combineReducers({
    name
});