import types from "./types";

const addKeywordToCrowdContentOrder = (keywords, keyword, position) => (
  dispatch
) => {
  let newKeywords = Array.from(keywords);
  newKeywords[position] = keyword;
  console.log(newKeywords);
  dispatch({
    type: types.CROWD_CONTENT_ORDER_SET_TARGETED_KEYWORDS,
    payload: newKeywords,
  });
};

const removeKeywordsFromCrowdContentOrder = (keywords, indexToRemove) => (
  dispatch
) => {
  let newKeywords = Array.from(keywords);
  newKeywords.splice(indexToRemove, 1);
  dispatch({
    type: types.CROWD_CONTENT_ORDER_SET_TARGETED_KEYWORDS,
    payload: newKeywords,
  });
};

export { addKeywordToCrowdContentOrder, removeKeywordsFromCrowdContentOrder };
