import React, { Fragment } from "react";
import { Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

// Modals
import { ViewAssignedProjectsModal } from "./modals";

const CityListTabe = props => {
  const { places, filteredPlaces, setTargetablePlace } = props;
  return (
    <Fragment>
      {places.length === 0 && filteredPlaces.length === 0 ? (
        <span>There are no cities to display.</span>
      ) : (
        <Fragment>
          <Table size="sm" hover>
            <thead>
              <th>Name</th>
              <th className="text-right">Population</th>
              <th className="text-right">Meta</th>
            </thead>
            <tbody>
              {filteredPlaces.length === 0 ? (
                <Fragment>
                  {places.map(place => (
                    <tr>
                      <td>{place.details.name}</td>
                      <td className="text-right">
                        {place.meta.population === null ? (
                          <span>
                            <em>Not set</em>
                          </span>
                        ) : (
                          <span>{places.meta.population}</span>
                        )}
                      </td>
                      <td className="text-right">
                        {place.relationships.projects.length !== 0 ? (
                          <FontAwesomeIcon
                            icon={faUser}
                            style={{ color: "#5cb85c" }}
                            onClick={() =>
                              setTargetablePlace(
                                place._id,
                                "viewAssignedProjectsModal"
                              )
                            }
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faUser}
                            style={{ color: "#E0E0E0" }}
                            onClick={() =>
                              setTargetablePlace(
                                place._id,
                                "viewAssignedProjectsModal"
                              )
                            }
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </Fragment>
              ) : (
                <Fragment>
                  {filteredPlaces.sort().map(place => (
                    <tr>
                      <td>{place.details.name}</td>
                      <td className="text-right">
                        {place.meta.population === null ? (
                          <span>
                            <em>Not set</em>
                          </span>
                        ) : (
                          <span>{places.meta.population}</span>
                        )}
                      </td>
                      <td className="text-right">
                        {place.relationships.projects.length !== 0 ? (
                          <FontAwesomeIcon
                            icon={faUser}
                            style={{ color: "#5cb85c" }}
                            onClick={() =>
                              setTargetablePlace(
                                place._id,
                                "viewAssignedProjectsModal"
                              )
                            }
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faUser}
                            style={{ color: "#E0E0E0" }}
                            onClick={() =>
                              setTargetablePlace(
                                place._id,
                                "viewAssignedProjectsModal"
                              )
                            }
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </Fragment>
              )}
            </tbody>
          </Table>
          <ViewAssignedProjectsModal />
        </Fragment>
      )}
    </Fragment>
  );
};

export default CityListTabe;
