import { connect } from "react-redux";
import { default as UserAssignedClients } from "./UserAssignedClients.jsx";

// selectors
import {
  getUsersAssignedClients,
  getUserProfileAssignedClientsLoadingStatus,
} from "./duck/selectors";

const mapStateToProps = (state) => {
  return {
    isLoading: getUserProfileAssignedClientsLoadingStatus(state),
    assignedClients: getUsersAssignedClients(state),
  };
};

export default connect(mapStateToProps, null)(UserAssignedClients);
