import { NAME } from "./constants";

export const getFocusedCrowdContentOrderDetails = (state) => {
  return state.pages.crowdContentDashboard.modalData[NAME]
    .focusedCrowdContentOrder;
};

export const getFocusedCrowdContentOrderLoadingStatus = (state) => {
  return state.pages.crowdContentDashboard.modalData[NAME].isLoading;
};

export const getFocusedCrowdContentOrderSubmitLockedStatus = (state) => {
  return state.pages.crowdContentDashboard.modalData[NAME].submitLocked;
};

export const getFocusedCrowdContentOrderPublishModalActiveTab = (state) => {
  return state.pages.crowdContentDashboard.modalData[NAME].activeTab;
};

export const getSubmissionStatus = (state) => {
  return state.pages.crowdContentDashboard.modalData[NAME].submissionStatus;
};
