import types from "./types";

const setContentOrderMetaH1 = (value) => (dispatch) => {
  dispatch({
    type: types.CONTENT_ORDER_SET_META_H1,
    payload: value,
  });
};

const setContentOrderMetaTitle = (value) => (dispatch) => {
  dispatch({
    type: types.CONTENT_ORDER_SET_META_TITLE,
    payload: value,
  });
};

const setContentOrderMetaDescription = (value) => (dispatch) => {
  dispatch({
    type: types.CONTENT_ORDER_SET_META_DESCRIPTION,
    payload: value,
  });
};

const setContentOrderMetaSlug = (value) => (dispatch) => {
  dispatch({
    type: types.CONTENT_ORDER_SET_META_SLUG,
    payload: value,
  });
};

const setMetaFormValidity = (bool) => (dispatch) => {
  dispatch({
    type: types.CONTENT_ORDER_SET_META_VALIDITY,
    payload: bool,
  });
};

export {
  setContentOrderMetaH1,
  setContentOrderMetaTitle,
  setContentOrderMetaDescription,
  setContentOrderMetaSlug,
  setMetaFormValidity,
};
