import { connect } from "react-redux";
import { default as component } from "./CrowdContentOrdersApprovedOrdersTable.jsx";

// global selectors and actions
import { activateModal } from "../../../common/UI/ActiveModal/duck/actions.js";

// local selectors and actions
import {
  getCrowdContentOrdersThatNeedPosting,
  isLoading,
} from "../../duck/selectors";

import { setFocusedCrowdContentOrderForPublishing } from "../modals/PublishCrowdContentOrderModal/duck/actions";

const mapStateToProps = (state) => {
  return {
    isLoading: isLoading(state),
    contentOrders: getCrowdContentOrdersThatNeedPosting(state),
  };
};

const mapDispatchToProps = {
  activateModal,
  setFocusedCrowdContentOrderForPublishing,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
