import types from "./types";

const setFocusedKeywordList = (keywordList) => (dispatch) => {
  dispatch({
    type: types.KEYWORD_GENERATOR_TOOL_SET_FOCUSED_KEYWORD_LIST,
    payload: keywordList,
  });
  dispatch({
    type: "ACTIVATE_MODAL",
    payload: "editKeywordListModal",
  });
};

export { setFocusedKeywordList };
