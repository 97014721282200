import { connect } from "react-redux";
import { default as component } from "./ViewAssignedProjectsSearch.jsx";

// Local selectors
import {
  getTargetablePlaceParent,
  getTargetablePlaceID
} from "../../../../../../../duck/selectors";

// Local actions
import {
  assignProjectToPlace,
  removeProjectFromPlace
} from "../../../../../../../duck/actions";

const mapStateToProps = state => {
  return {
    targetedPlaceID: getTargetablePlaceID(state),
    targetedPlaceParent: getTargetablePlaceParent(state)
  };
};

const mapDispatchToProps = {
  assignProjectToPlace,
  removeProjectFromPlace
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
