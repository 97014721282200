import types from "./types";

const loggedInUser = (state = {}, action) => {
  switch (action.type) {
    // Front-END
    case types.STORE_USER_DETAILS: {
      return action.payload;
    }
    case types.UPDATE_USER_DETAILS: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

export default loggedInUser;
